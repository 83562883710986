<template>
  <div>
    <div class="floating-heading">
      <h4><b> P&L Report</b></h4>
    </div>
    <div class="col-md-12 mt-1 text-success">
      <div class="form-outline text-primary">
        <span class="inline">
          <div class="row mt-4">
            <div class="col-md-3">
              <label class="form-label" for="form1" display="inline"
                >E-Out Start Date</label
              >
              <input
                style="height: 3em"
                type="date"
                id="form1"
                placeholder="dd/mm/yyyy"
                class="form-control form-icon-trailing"
                v-model="E_TRANSFER_OUT_START_DATE"
              />
            </div>
            <div class="col-md-3">
              <label class="form-label" for="form1" display="inline"
                >E-Out End Date</label
              >
              <input
                style="height: 3em"
                type="date"
                id="form1"
                placeholder="dd/mm/yyyy"
                class="form-control form-icon-trailing"
                v-model="E_TRANSFER_OUT_END_DATE"
              />
            </div>
            <div class="col-md-3">
              <label class="form-label" for="form1" display="inline"
                >Type of Transaction</label
              >
              <select
                class="form-control"
                style="height: 3em"
                v-model="TRANSACTION_TYPE"
              >
                <option value="">Select</option>
                <option value="New">New</option>
                <option value="Preowned">Preowned</option>
              </select>
            </div>
            <div class="col-md-1">
              <button
                type="submit"
                style="margin-top: 27px; font-size: 0.9rem !important"
                class="btn btn-primary"
                @click="getPriceDetails"
              >
                Search
              </button>
            </div>
            <div class="col-md-2 p-0 mt-4">
              <button
                style="margin-left: 5em"
                type="button"
                class="custom-btn custom-excel-btn"
                @click="exportExcel()"
              >
                <i class="fa-solid fa-file-excel"></i> Excel
              </button>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div class="col-md-12" style="width: 1060px">
      <div class="content-body-wrapper mt-4">
        <div class="page-content">
          <div class="page-data">
            <div class="data-table master-table">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>SERIAL NUMBER</th>
                    <th>CUSTOMER NAME</th>
                    <th>VEHICLE MODEL</th>
                    <th>CAR PLATE</th>
                    <th>E-OUT DATE</th>
                    <th>BROKER</th>
                    <th>SALESMAN</th>
                    <th>SELLING PRICE</th>
                    <th>FINANCE</th>
                    <th>LOAN AMOUNT</th>
                    <th>LOAN TERM</th>
                    <th>INTEREST RATE</th>
                    <th>MONTHLY INSTALLMENT</th>
                    <th>LOAN COMM %</th>
                    <th>LOAN COMM</th>
                    <th>TOTAL LOAN COMM WITH GST RECEIVED</th>
                    <th>ADMIN FEE</th>
                    <th>ADMIN FEE(SALESMAN COMM)</th>
                    <th>LOAN COMM(SALESMAN)</th>
                    <th>ADMIN FEE(NET AFTER SALESMAN COMM)</th>
                    <th>REMARKS</th>
                  </tr>
                </thead>
                <tbody
                  v-for="user_detail in system_role_details"
                  :key="user_detail.RECORD_SYS_ID"
                >
                  <tr style="text-align: center">
                    <td>{{ user_detail.SL_NO }}</td>
                    <td>{{ user_detail.CUSTOMER_NAME }}</td>
                    <td>{{ user_detail.VEHICLE_MODEL }}</td>
                    <td>{{ user_detail.CAR_PLATE }}</td>
                    <td>{{ user_detail.E_OUT_DATE }}</td>
                    <td>{{ user_detail.BROKER_1 }}</td>
                    <td>{{ user_detail.SALESMAN }}</td>
                    <td>{{ user_detail.SELLING_PRICE }}</td>
                    <td>{{ user_detail.FINANCE }}</td>
                    <td>{{ user_detail.LOAN_AMOUNT }}</td>
                    <td>{{ user_detail.LOAN_TERM }}</td>
                    <td>{{ user_detail.INTEREST_RATE }}</td>
                    <td>{{ user_detail.MONTHLY_INSTALLMENT }}</td>
                    <td>{{ user_detail.LOAN_COMM_PERCENTAGE }}</td>
                    <td></td>
                    <td>{{ user_detail.TOTAL_LOAN_COMM_WITH_GST_RECEIVED }}</td>
                    <td>{{ user_detail.ADMIN_FEE }}</td>
                    <td>{{ user_detail.ADMIN_FEE_SALESMAN_COMMISSION }}</td>
                    <td>{{ user_detail.LOAN_COMMISSION_SALESMAN }}</td>
                    <td>{{ user_detail.ADMIN_FEE_NET_AFTER_SALESMAN_COMM }}</td>
                    <td>{{ user_detail.REMARKS }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
import moment from "moment";
export default {
  data() {
    return {
      system_role_details: [],
      E_TRANSFER_OUT_START_DATE: "",
      E_TRANSFER_OUT_END_DATE: "",
      TRANSACTION_TYPE: "",
      USER_SYS_ID: "",
      user_details: [],
    };
  },

  computed: {
    system_role_groups() {
      const gr = [];
      this.system_role_details.forEach((g) => {
        if (!gr.includes(g.BODY_TYPE)) gr.push(g.BODY_TYPE);
      });
      return gr;
    },
  },

  methods: {
    getGroupDetails(group) {
      return this.system_role_details.filter((role) => role.BODY_TYPE == group);
    },

    generateRandomColor() {
      let maxVal = 0xffffff;
      let randomNumber = Math.random() * maxVal;
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`;
    },
    getGroupStyles() {
      let color = this.generateRandomColor();
      return {
        "background-color": color,
        padding: "5px 6px !important",
        "border-width": "3px",
        "border-color": "#fff",
      };
    },

    getPriceDetails() {
      this.E_TRANSFER_OUT_START_DATE = this.formatDate(
        this.E_TRANSFER_OUT_START_DATE
      );
      this.E_TRANSFER_OUT_END_DATE = this.formatDate(
        this.E_TRANSFER_OUT_END_DATE
      );
      this.axios
        .get(apiEndPoints.pnl_report, {
          params: {
            ITEM: "SPECIFIC",
            E_TRANSFER_OUT_START_DATE: this.E_TRANSFER_OUT_START_DATE,
            E_TRANSFER_OUT_END_DATE: this.E_TRANSFER_OUT_END_DATE,
            TRANSACTION_TYPE: this.TRANSACTION_TYPE,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.system_role_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.system_role_details = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    exportExcel() {
      const tHeader = [
        "SERIAL NUMBER",
        "CUSTOMER NAME",
        "VEHICLE MODEL",
        "CAR PLATE",
        "E-OUT DATE",
        "BROKER",
        "SALESMAN",
        "SELLING PRICE",
        "FINANCE",
        "LOAN AMOUNT",
        "LOAN TERM",
        "INTEREST RATE",
        "MONTHLY INSTALLMENT",
        "LOAN COMM %",
        "LOAN COMM",
        "TOTAL LOAN COMM WITH GST RECEIVED",
        "ADMIN FEE",
        "ADMIN FEE(SALESMAN COMM)",
        "LOAN COMM(SALESMAN)",
        "ADMIN FEE(NET AFTER SALESMAN COMM)",
        "REMARKS",
      ];

      const filterVal = [
        "SL_NO",
        "CUSTOMER_NAME",
        "VEHICLE_MODEL",
        "CAR_PLATE",
        "E_OUT_DATE",
        "BROKER_1",
        "SALESMAN",
        "SELLING_PRICE",
        "FINANCE",
        "LOAN_AMOUNT",
        "LOAN_TERM",
        "INTEREST_RATE",
        "MONTHLY_INSTALLMENT",
        "LOAN_COMM_PERCENTAGE",
        "",
        "TOTAL_LOAN_COMM_WITH_GST_RECEIVED",
        "ADMIN_FEE",
        "ADMIN_FEE_SALESMAN_COMMISSION",
        "LOAN_COMMISSION_SALESMAN",
        "ADMIN_FEE_NET_AFTER_SALESMAN_COMM",
        "REMARKS",
      ];

      let system_role_groups = [];
      this.system_role_groups.forEach((role) => {
        this.getGroupDetails(role).forEach((item, index) => {
          if (index > 0) {
            item.BODY_TYPE = "";
          }
          system_role_groups.push(item);
        });
      });
      const data = this.formatJson(filterVal, system_role_groups);
      this.generateXlsx(tHeader, data, "P&L Report");
    },
  },
};
</script>
