<template>
  <div>
    <div class="floating-heading">
      <h4>Admin <span>Master Form</span></h4>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data mt-2">
          <div class="master-selection">
            <div class="row">
              <div class="col-md-6">
                <label class="frm-label">Select Role</label>
                <select
                  class="form-control"
                  v-model="selected_role"
                  @change="getPrevillgesByRole"
                >
                  <option :value="null">Select</option>
                  <option
                    v-for="system_role_detail in system_role_details"
                    :value="system_role_detail.SYSTEM_ROLE_SYS_ID"
                    :key="system_role_detail.SYSTEM_ROLE_SYS_ID"
                  >
                    {{ system_role_detail.SYSTEM_ROLE }}
                  </option>

                  <!-- <option value="Vehicle-body-type">Vehicle Body Type</option>
                                                        <option value="Vehicle-model">Vehicle Model</option>
                                                        <option value="Vehicle-colour">Vehicle Colour</option>
                                                        <option value="Vehicle-location">Vehicle Location</option> -->
                </select>
              </div>
              <div class="col-md-6">
                <label class="frm-label">Description</label>
                <textarea disabled rows="1" class="form-control"></textarea>
              </div>
            </div>
          </div>
          <div class="Privilege box mt-4">
            <div class="privilege_tree">
              <!-- <ul id="frontend">
                                                        <li><button class="floatbtn2"> <i class="fa fa-plus" onclick="changeicon(this)"></i></button> <span class="caret">User Management</span>
                                                      <ul class="treeul animate__animated animate__fadeIn">
                                                          <li><input type="checkbox"><label class="frm-label-2"> Add Employee</label></li>
                                                          <li><input type="checkbox"><label class="frm-label-2"> View Employee</label></li>
                                                          <li><input type="checkbox"><label class="frm-label-2"> Edit Employee</label></li>
                                                          <li><input type="checkbox"><label class="frm-label-2"> Delete Employee</label></li>
                                                      </ul>
                                                      </li>
                                                    
                                                         <li class="mt-3"><button class="floatbtn2"> <i class="fa fa-plus" onclick="changeicon(this)"></i></button> <span class="caret">Insurance</span>
                                                            <ul class="treeul animate__animated animate__fadeIn">
                                                                <li><input type="checkbox"><label class="frm-label-2"> Add Quotation</label></li>
                                                                <li><input type="checkbox"><label class="frm-label-2"> View Quotation</label></li>
                                                                <li><input type="checkbox"><label class="frm-label-2"> Edit Quotation</label></li>
                                                                <li><input type="checkbox"><label class="frm-label-2"> Delete Quotation</label></li>
                                                            </ul>
                                                            </li>
                                                    </ul> -->

              <div
                class="master-table"
                v-for="(previlege, prv_index) in previleges"
                :key="prv_index"
              >
                <table class="table table-bordered mt-3">
                  <thead>
                    <tr>
                      <th colspan="8">{{ previlege.MODULE }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td>Full Access</td>
                      <td>View</td>
                      <td>Create</td>
                      <td>Edit</td>
                      <td>Delete</td>
                    </tr>
                    <tr
                      v-for="(submodule, subModuleIndex) in previlege.SUBMODULE"
                      :key="subModuleIndex"
                    >
                      <td>{{ submodule.NAME }}</td>
                      <td>
                        <b-form-checkbox
                          id="checkbox-full"
                          v-model="submodule.ACCESS.FULL_ACCESS"
                          name="checkbox-full"
                          @change="
                            onFullAccessChange(
                              $event,
                              prv_index,
                              subModuleIndex
                            )
                          "
                          :value="1"
                          :unchecked-value="0"
                        ></b-form-checkbox>
                      </td>
                      <td>
                        <b-form-checkbox
                          id="checkbox-view"
                          v-model="submodule.ACCESS.VIEW_ACCESS"
                          @change="
                            onAccessChange($event, prv_index, subModuleIndex)
                          "
                          name="checkbox-view"
                          :value="1"
                          :unchecked-value="0"
                        ></b-form-checkbox>
                      </td>
                      <td>
                        <b-form-checkbox
                          id="checkbox-create"
                          v-model="submodule.ACCESS.CREATE_ACCESS"
                          @change="
                            onAccessChange($event, prv_index, subModuleIndex)
                          "
                          name="checkbox-create"
                          :value="1"
                          :unchecked-value="0"
                        ></b-form-checkbox>
                      </td>
                      <td>
                        <b-form-checkbox
                          id="checkbox-update"
                          v-model="submodule.ACCESS.UPDATE_ACCESS"
                          @change="
                            onAccessChange($event, prv_index, subModuleIndex)
                          "
                          name="checkbox-update"
                          :value="1"
                          :unchecked-value="0"
                        ></b-form-checkbox>
                      </td>
                      <td>
                        <b-form-checkbox
                          id="checkbox-delete"
                          v-model="submodule.ACCESS.DELETE_ACCESS"
                          @change="
                            onAccessChange($event, prv_index, subModuleIndex)
                          "
                          name="checkbox-delete"
                          :value="1"
                          :unchecked-value="0"
                        ></b-form-checkbox>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- <div class="form-check">
                                                      <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                                      <label class="form-check-label" for="defaultCheck1">
                                                        Allow users to handle the data and transactions for assigned customers only.
                                                      </label>
                                                    </div>
                                                    <div class="form-check">
                                                      <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                                                      <label class="form-check-label" for="defaultCheck1">
                                                        Allow users to add, edit and delete vendor's bank account details.
                                                      </label>
                                                    </div> -->
            </div>

            <div class="row mt-3">
              <div class="col-md-1">
                <button
                  class="custom-btn custom-success-btn"
                  @click="setPrevillgesByRole"
                >
                  Save
                </button>
              </div>
              <div class="col-md-2">
                <button class="custom-btn custom-danger-btn">Cancel</button>
              </div>
            </div>
          </div>
          <!-- </div>
                                    </div>
                                 </div>
                              </div>
                           </div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- create modal -->

  <!-- edit modal -->

  <!--- delete modal-->
</template>

<script>
import apiEndPoints from "../../endpoints.js";

import { Global } from "@/global.js";

export default {
  data() {
    return {
      system_role_details: [],

      selected_row: {},
      selected_role: null,
      previleges: [],
    };
  },

  mounted() {
    this.getSystemRoleDetails();
    // $(document).on("click",".floatbtn2",function(e){
    //   var _this=$(this);
    //   //$(".floatbtn2_plus").trigger("click");
    //  //let treeul =_this.next("ul.treeul") ;
    //  _this.children("i").removeClass("fa-times").addClass("fa-plus");

    //  var _parent=_this.parent("li");
    //  var _child=_parent.find("ul.treeul");
    //  var isTrue=_child.hasClass("active");
    //  if(isTrue){
    //     _child.removeClass("active");
    //     _this.children("i").removeClass("fa-times").addClass("fa-plus");
    //  }
    //  else{
    //  _child.addClass("active");
    //  _this.children("i").removeClass("fa-plus").addClass("fa-times");
    //  }
    //_this.attr("class","floatbtn2_plus")       //_this.children("i").addClass("fa-times");

    //   _this.next("i").remove();
    //   _this.children("i").addClass("fa-times");
    //   })
    //   $(document).on("click",".floatbtn2_plus",function(e){
    //     var _this=$(this);
    //    //let treeul =_this.next("ul.treeul") ;
    //    var _parent=_this.parent("li");
    //    var _child=_parent.find("ul.treeul");
    //    var isTrue=_child.hasClass("active");
    //    if(isTrue){

    //       _child.removeClass("active");
    //    }
    //    _this.attr("class","floatbtn2");
    //    _this.children("i").removeClass("fa-times").addClass("fa-plus");

    //  $(document).ready(function () {
    //         $("select")
    //             .change(function () {
    //                 $(this)
    //                     .find("option:selected")
    //                     .each(function () {
    //                         var optionValue = $(this).attr("value");
    //                         if (optionValue) {
    //                             $(".box")
    //                                 .not("." + optionValue)
    //                                 .hide();
    //                             $("." + optionValue).show();
    //                         } else {
    //                             $(".box").hide();
    //                         }
    //                     });
    //             })
    //             .change();
  },

  methods: {
    getSystemRoleDetails() {
      this.axios
        .get(apiEndPoints.system_role_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.system_role_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getPrevillgesByRole() {
      this.axios
        .get(apiEndPoints.view_role_privilege_info, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.selected_role,
          },
        })
        .then((response) => {
          this.previleges = response.data;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    setPrevillgesByRole() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let collection = [];
      this.previleges.forEach((module) => {
        module.SUBMODULE.forEach((submodule) => {
          collection.push({
            ITEM: "ADD_UPDATE",
            RECORD_SYS_ID: "0",
            ROLE_SYS_ID: this.selected_role,
            MODULE_SYS_ID: module.ID,
            SUB_MODULE_SYS_ID: submodule.ID,
            CREATE_ACCESS: parseInt(submodule.ACCESS.CREATE_ACCESS),
            VIEW_ACCESS: parseInt(submodule.ACCESS.VIEW_ACCESS),
            UPDATE_ACCESS: parseInt(submodule.ACCESS.UPDATE_ACCESS),
            DELETE_ACCESS: parseInt(submodule.ACCESS.DELETE_ACCESS),
            FULL_ACCESS: parseInt(submodule.ACCESS.FULL_ACCESS),
            CREATED_BY: user_details.UserId,
          });
        });
      });

      this.axios
        .post(apiEndPoints.post_add_update_role_privileges, collection)
        .then((response) => {
          Global.showSuccessAlert(true, "success", response.data.response);
        })
        .catch((err) => {
          //console.log(err);
          if (err.data.status == false || err.data.status == "false") {
            Global.showErrorAlert(true, "error", err.data.response);
          }
          if (error.response.status != 401 || error.response.Status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    onFullAccessChange(event, moduleIndex, subModuleIndex) {
      let module = this.previleges[moduleIndex];
      if (event) {
        module.SUBMODULE[subModuleIndex].ACCESS = {
          CREATE_ACCESS: 1,
          DELETE_ACCESS: 1,
          FULL_ACCESS: 1,
          UPDATE_ACCESS: 1,
          VIEW_ACCESS: 1,
        };
      }
      this.$forceUpdate();
    },

    onAccessChange(event, moduleIndex, subModuleIndex) {
      let module = this.previleges[moduleIndex];
      if (!event) {
        module.SUBMODULE[subModuleIndex].ACCESS.FULL_ACCESS = 0;
      } else {
        let access = module.SUBMODULE[subModuleIndex].ACCESS;
        if (
          parseInt(access.CREATE_ACCESS) &&
          parseInt(access.VIEW_ACCESS) &&
          parseInt(access.UPDATE_ACCESS) &&
          parseInt(access.DELETE_ACCESS)
        ) {
          module.SUBMODULE[subModuleIndex].ACCESS.FULL_ACCESS = 1;
        }
      }
      this.$forceUpdate();
    },
  },
};
</script>
