<template>
  <div>
    <div class="Sytem-role master-table box mt-4">
      <div class="row">
        <div class="col-md-12 offset-md-11">
          <button
            type="submit"
            value=""
            id=""
            class="custom-btn add-btn"
            name=""
            data-bs-toggle="modal"
            data-bs-target="#master_system_role_modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </button>
        </div>
      </div>

      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th>SL. No.</th>
            <th scope="col">Finance Company</th>
            <th scope="col">Address</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody
          v-for="(system_role_detail, index) in system_role_details"
          :key="system_role_detail.SYSTEM_ROLE_SYS_ID"
        >
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ system_role_detail.FINANCE_COMPANY }}</td>
            <td>
              {{ system_role_detail.ADDRESS_LINE_1 }}<br />{{
                system_role_detail.ADDRESS_LINE_2
              }}<br />
              {{ system_role_detail.COUNTRY_NAME }}<br />{{
                system_role_detail.PIN_CODE
              }}
            </td>
            <td class="table-btn" colspan="2">
              <button
                type="button"
                class="custom-btn p-0"
                @click="
                  showModal(
                    system_role_detail,
                    '#master_system_role_edit_modal'
                  )
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit"
                >
                  <path
                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                  ></path>
                  <path
                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                  ></path>
                </svg>
              </button>
              <button
                type="button"
                class="custom-btn p-0"
                @click="
                  showModal(
                    system_role_detail,
                    '#master_system_role_delete_modal'
                  )
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path
                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                  ></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--create  modal -->

    <div
      class="modal modal-right fade"
      id="master_system_role_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Add Finance Company
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <ValidationObserver ref="sysroleform" v-slot="{ invalid }">
                  <form>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label"
                          >Finance Company<span style="color: red">*</span>
                        </label>
                        <input
                          type="text"
                          name="Finance Company"
                          placeholder="Enter Finance Company"
                          v-model="create_system_role.FINANCE_COMPANY"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_system_role.FINANCE_COMPANY != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label"
                          >Address <span style="color: red">*</span></label
                        >
                        <textarea
                          type="text"
                          name="Address "
                          placeholder="Enter Address"
                          v-model="create_system_role.ADDRESS_LINE_1"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == '' &&
                              create_system_role.ADDRESS_LINE_1 != null,
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Country</label>
                        <!-- <input type="text" disabled
                                        name="country"
                                        placeholder="Singapore"
                                        v-model="create_system_role.COUNTRY_NAME" 
                                        class="form-control"
                                        :class="{
                                         'is-invalid': errors[0] != null,
                                         'is-valid': errors[0] == '' && create_system_role.COUNTRY_NAME != null,
                                             }"> -->
                        <b-form-select
                          name="Country"
                          v-model="create_system_role.COUNTRY_SYS_ID"
                          @change="getcountrysysid"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == '' &&
                              create_system_role.COUNTRY_SYS_ID != null,
                          }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >-- Select --</b-form-select-option
                            >
                          </template>
                          <option
                            v-for="cost_type_info_detail in cost_type_info_details"
                            :value="cost_type_info_detail.COUNTRY_SYS_ID"
                            :key="cost_type_info_detail.COUNTRY_SYS_ID"
                          >
                            {{ cost_type_info_detail.COUNTRY_NAME }}
                          </option>
                        </b-form-select>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label"
                          >Post Code<span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="Post Code"
                          placeholder="Enter Post Code"
                          v-model="create_system_role.PIN_CODE"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == '' &&
                              create_system_role.PIN_CODE != null,
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <div class="modal-footer-2">
                      <div class="row mt-4">
                        <div class="col-md-5">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            :disabled="invalid"
                            @click="createSystemRole"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            @click="emptyfu"
                            data-bs-dismiss="modal"
                            class="custom-btn custom-danger-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--edit  modal -->

    <div
      class="modal modal-right fade"
      id="master_system_role_edit_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Finance Company
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <ValidationObserver ref="sysroleeditform" v-slot="{ invalid }">
                  <form>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Finace Company</label>
                        <input
                          type="text"
                          name="Finace Company"
                          placeholder="Enter Finance Company"
                          v-model="selected_row.FINANCE_COMPANY"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              selected_row.FINANCE_COMPANY != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Address Line 1</label>
                        <textarea
                          type="text"
                          name="Address "
                          placeholder="Enter Address"
                          v-model="selected_row.ADDRESS_LINE_1"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == '' &&
                              selected_row.ADDRESS_LINE_1 != null,
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Country</label>
                        <!-- <input type="text" disabled
                                        name="country"
                                        placeholder="Singapore"
                                        v-model="COUNTRY_NAME" 
                                        class="form-control"
                                        :class="{
                                         'is-invalid': errors[0] != null,
                                         'is-valid': errors[0] == '' && COUNTRY_NAME != null,
                                             }"> -->
                        <b-form-select
                          name="Country"
                          v-model="selected_row.COUNTRY_SYS_ID"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == '' &&
                              selected_row.COUNTRY_NAME != null,
                          }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >-- Select --</b-form-select-option
                            >
                          </template>
                          <option
                            v-for="cost_type_info_detail in cost_type_info_details"
                            :value="cost_type_info_detail.COUNTRY_SYS_ID"
                            :key="cost_type_info_detail.COUNTRY_SYS_ID"
                          >
                            {{ cost_type_info_detail.COUNTRY_NAME }}
                          </option>
                        </b-form-select>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Post Code</label>
                        <input
                          type="text"
                          name="Post Code"
                          placeholder="Enter Post Code"
                          v-model="selected_row.PIN_CODE"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == '' && selected_row.PIN_CODE != null,
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <div class="modal-footer-2">
                      <div class="row mt-4">
                        <div class="col-md-5">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            :disabled="invalid"
                            @click="updateSystemRole"
                          >
                            Update
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            class="custom-btn custom-danger-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--- delete modal-->

    <div class="modal" id="master_system_role_delete_modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete ?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteSystemRole"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      system_role_details: [],
      cost_type_info_details: [],
      create_system_role: {
        ITEM: "ADD",
        RECORD_SYS_ID: "0",
        FINANCE_COMPANY: "",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",

        COUNTRY_NAME: "",
        COUNTRY_SYS_ID: "",
        PIN_CODE: "",
        CREATED_BY: "",
      },
      selected_row: {},
    };
  },

  mounted() {
    this.getSystemRoleDetails();
    this.getCountryInfo();
  },

  methods: {
    emptyfu() {
      this.create_system_role.SYSTEM_ROLE = "";
    },

    getSystemRoleDetails() {
      this.axios
        .get(apiEndPoints.finace_company, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("MON", response);
          //this.system_role_details = response.data.response;
          if (response.data.status == "true") {
            this.system_role_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.system_role_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    createSystemRole() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_system_role["CREATED_BY"] = user_details.UserId;

      this.$refs.sysroleform.validate().then((success) => {
        if (!success) {
          return;
        }
        this.axios
          .post(
            apiEndPoints.add_update_finance_company,
            this.create_system_role
          )
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              $("#master_system_role_modal").modal("hide");
              this.getSystemRoleDetails();
              this.emptyfu();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      });
    },

    showModal(row, id) {
      this.selected_row = row;
      //console.log("riju", this.selected_row);
      $(id).modal("show");
    },

    updateSystemRole() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let update_payload = {
        ITEM: "UPDATE",
        RECORD_SYS_ID: this.selected_row.FINANCE_COMPANY_SYS_ID,
        FINANCE_COMPANY: this.selected_row.FINANCE_COMPANY,
        ADDRESS_LINE_1: this.selected_row.ADDRESS_LINE_1,
        ADDRESS_LINE_2: this.selected_row.ADDRESS_LINE_2,
        PIN_CODE: this.selected_row.PIN_CODE,
        COUNTRY_NAME: this.selected_row.COUNTRY_NAME,
        COUNTRY_SYS_ID: this.selected_row.COUNTRY_SYS_ID,
        ADDRESS_SYS_ID: this.selected_row.ADDRESS_SYS_ID,
        CREATED_BY: user_details.UserId,

        //COUNTRY_SYS_ID: this.cost_type_info_details.COUNTRY_SYS_ID,
        //COUNTRY_NAME: this.cost_type_info_details.COUNTRY_NAME,
        //ADDRESS_SYS_ID: this.system_role_details.ADDRESS_SYS_ID,
      };

      this.$refs.sysroleeditform.validate().then((success) => {
        if (!success) {
          return;
        }

        this.axios
          .post(apiEndPoints.add_update_finance_company, update_payload)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              $("#master_system_role_edit_modal").modal("hide");
              this.getSystemRoleDetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      });
    },

    deleteSystemRole() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let delete_payload = {
        ITEM: "DELETE",
        RECORD_SYS_ID: this.selected_row.FINANCE_COMPANY_SYS_ID,
        CREATED_BY: user_details.UserId,
      };
      this.axios
        .post(apiEndPoints.add_update_finance_company, delete_payload)
        .then((response) => {
          $("#master_system_role_delete_modal").modal("hide");
          this.getSystemRoleDetails();
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getCountryInfo() {
      this.axios
        .get(apiEndPoints.view_country, {
          params: { ITEM: "VIEW_ALL", RECORD_SYS_ID: 0 },
        })
        .then((response) => {
          //console.log(response);

          if (response.data.status == "true") {
            this.cost_type_info_details = response.data.response;
            this.$nextTick(() => {
              this.create_system_role.COUNTRY_SYS_ID =
                this.cost_type_info_details[0].COUNTRY_SYS_ID;
              this.getcountrysysid();
            });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.system_role_details = [];
            this.create_system_role.COUNTRY_SYS_ID = null;
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getcountrysysid() {
      this.create_system_role.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.create_system_role.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
      this.selected_row.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.create_system_role.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
    },
  },
};
</script>
