<template>
  <div>
    <div class="Vehicle-model master-table box mt-4">
      <div class="row">
        <div class="col-md-12 offset-md-11">
          <button
            type="submit"
            value=""
            id=""
            class="custom-btn add-btn"
            name=""
            data-bs-toggle="modal"
            data-bs-target="#master_vehicle_model_modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label class="frm-label">Select Make</label>
          <select
            class="form-control"
            v-model="this.create_vehicle_model.VEHICLE_MAKE_SYS_ID"
            @change="getSelectedMake($event.target.value)"
          >
            <option>Select</option>
            <option
              v-for="vehicle_make_detail in vehicle_make_details"
              :key="vehicle_make_detail.VEHICLE_MAKE_SYS_ID"
              :value="vehicle_make_detail.VEHICLE_MAKE_SYS_ID"
            >
              {{ vehicle_make_detail.VEHICLE_MAKE }}
            </option>
          </select>
          <!-- <select class="form-select" aria-label="Default select example">
                                                    <option selected>Open this select menu</option>
                                                    <option value="1" id="show">Toyota</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                  </select> -->
        </div>
      </div>
      <div v-if="vehicle_model_details.length > 0">
        <table class="table table-bordered mt-3">
          <thead>
            <tr>
              <th>SL. No.</th>
              <th scope="col">Model Name</th>
              <th scope="col">Short Name</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(vehicle_model_detail, index) in vehicle_model_details"
              :key="vehicle_model_detail.VEHICLE_MODEL_SYS_ID"
            >
              <td>
                <b>{{ index + 1 }}</b>
              </td>
              <td>{{ vehicle_model_detail.VEHICLE_MODEL }}</td>
              <td>{{ vehicle_model_detail.MODEL_SHORT_NAME }}</td>
              <td class="table-btn" colspan="2">
                <button
                  type="button"
                  class="custom-btn p-0"
                  @click="
                    getVehicleManufracturerSpecificDetails(
                      vehicle_model_detail,
                      '#master_vehicle_model_edit_modal'
                    )
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit"
                  >
                    <path
                      d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                    ></path>
                    <path
                      d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                    ></path>
                  </svg>
                </button>
                <button
                  type="button"
                  class="custom-btn p-0"
                  data-bs-toggle="modal"
                  data-bs-target="#delete_frm"
                  @click="
                    showModal(
                      vehicle_model_detail,
                      '#master_vehicle_model_delete_modal'
                    )
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trash-2"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line>
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="mt-3 text-center">No Data Found</p>
      </div>
    </div>

    <!-- create modal -->

    <div
      class="modal modal-right fade"
      id="master_vehicle_model_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Add Vehicle Model
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <ValidationObserver ref="vehiclemodelform" v-slot="{ invalid }">
                  <div class="col-md-12 mt-4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label">Make</label>
                      <select
                        name="Make"
                        v-model="create_vehicle_model.VEHICLE_MAKE_SYS_ID"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vehicle_model.VEHICLE_MAKE_SYS_ID != '',
                        }"
                      >
                        <option value="">Select</option>
                        <option
                          v-for="vehicle_make_detail in vehicle_make_details"
                          :key="vehicle_make_detail.VEHICLE_MAKE_SYS_ID"
                          :value="vehicle_make_detail.VEHICLE_MAKE_SYS_ID"
                        >
                          {{ vehicle_make_detail.VEHICLE_MAKE }}
                        </option>
                      </select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mt-4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label">Model Name</label>
                      <input
                        type="text"
                        name="Model Name"
                        placeholder="Enter Model Name"
                        v-model="create_vehicle_model.VEHICLE_MODEL"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vehicle_model.VEHICLE_MODEL != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12 mt-4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label">Short Name</label>
                      <input
                        type="text"
                        name="Short Name"
                        placeholder="Enter Short Name"
                        v-model="create_vehicle_model.MODEL_SHORT_NAME"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vehicle_model.MODEL_SHORT_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-5">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          :disabled="invalid"
                          @click="createVehicleModel"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          @click="emptyfu"
                          data-bs-dismiss="modal"
                          class="custom-btn custom-danger-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- edit modal -->

    <div
      class="modal modal-right fade"
      id="master_vehicle_model_edit_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Vehicle Model
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-md-12 mt-4">
                  <label class="frm-label">Make</label>
                  <input
                    type="text"
                    disabled
                    v-model="vehile_make_specific_details.VEHICLE_MAKE"
                    class="form-control"
                  />
                </div>
                <ValidationObserver ref="vehiclemodelform" v-slot="{ invalid }">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="col-md-12 mt-4">
                      <label class="frm-label">Model Name</label>
                      <input
                        type="text"
                        name="Model Name"
                        placeholder="Enter Model Name"
                        v-model="selected_row.VEHICLE_MODEL"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            selected_row.VEHICLE_MODEL != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="col-md-12 mt-4">
                      <label class="frm-label">Short Name</label>
                      <input
                        type="text"
                        name="Short Name"
                        placeholder="Enter Short Name"
                        v-model="selected_row.MODEL_SHORT_NAME"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            selected_row.VEHICLE_MODEL != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>

                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-5">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          :disabled="invalid"
                          @click="updateVehicleModel"
                        >
                          Update
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          class="custom-btn custom-danger-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--- delete modal-->

    <div class="modal" id="master_vehicle_model_delete_modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete ?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteVehicleModel"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      vehicle_make_details: [],
      vehicle_model_details: [],
      create_vehicle_model: {
        ITEM: "ADD",
        RECORD_SYS_ID: "0",
        VEHICLE_MODEL: "",
        VEHICLE_MAKE_SYS_ID: "",
        MODEL_SHORT_NAME: "",
        CREATED_BY: "",
      },
      selected_row: {},
      selected_make: "",
      vehile_make_specific_details: {},
    };
  },

  mounted() {
    this.getVehicleManufracturerDetails();
  },

  methods: {
    emptyfu() {
      this.create_vehicle_model.VEHICLE_MODEL = "";
      this.create_vehicle_model.MODEL_SHORT_NAME = "";
    },

    getVehicleManufracturerDetails() {
      this.axios
        .get(apiEndPoints.vechile_manufracturer_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          // this.vehicle_make_details = response.data.response;
          //console.log("vehicle model", response);
          if (response.data.status == "true") {
            this.vehicle_make_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.vehicle_make_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getVehicleManufracturerSpecificDetails(vehicle_model_detail, model_id) {
      this.axios
        .get(apiEndPoints.vechile_manufracturer_api, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vehicle_model_detail.VEHICLE_MAKE_SYS_ID,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.response.length > 0) {
            this.vehile_make_specific_details = response.data.response[0];
          }
          //console.log("MODEL", this.vehile_make_specific_details);
          this.selected_row = vehicle_model_detail;
          $(model_id).modal("show");
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getSelectedMake(vehicle_make_sys_id) {
      this.create_vehicle_model.VEHICLE_MAKE_SYS_ID = vehicle_make_sys_id;
      this.axios
        .get(apiEndPoints.vechile_model_type_api, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vehicle_make_sys_id,
          },
        })
        .then((response) => {
          //console.log("model vehicle", response);
          //this.vehicle_model_details = response.data.response;
          if (response.data.status == "true") {
            this.vehicle_model_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.vehicle_model_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    createVehicleModel() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_vehicle_model["CREATED_BY"] = user_details.UserId;

      this.$refs.vehiclemodelform.validate().then((success) => {
        //console.log(JSON.stringify(this.create_vehicle_model));

        this.axios
          .post(
            apiEndPoints.create_update_vechile_model,
            this.create_vehicle_model
          )
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              $("#master_vehicle_model_modal").modal("hide");
              this.emptyfu();
              // location.reload()
              this.getSelectedMake(
                this.create_vehicle_model.VEHICLE_MAKE_SYS_ID
              );
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            //console.log(err);
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      });
    },

    getSelectedMakeOption(event) {
      this.create_vehicle_model["VEHICLE_MAKE_SYS_ID"] = event.target.value;
    },

    showModal(row, id) {
      this.selected_row = row;
      //console.log(this.selected_row);
      $(id).modal("show");
    },

    updateVehicleModel() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let update_payload = {
        ITEM: "UPDATE",
        RECORD_SYS_ID: this.selected_row.VEHICLE_MODEL_SYS_ID,
        VEHICLE_MAKE_SYS_ID:
          this.vehile_make_specific_details.VEHICLE_MAKE_SYS_ID,
        VEHICLE_MODEL: this.selected_row.VEHICLE_MODEL,
        MODEL_SHORT_NAME: this.selected_row.MODEL_SHORT_NAME,
        CREATED_BY: user_details.UserId,
      };
      this.$refs.vehiclemodeleditform.validate().then((success) => {
        if (!success) {
          return;
        }

        this.axios
          .post(apiEndPoints.create_update_vechile_model, update_payload)
          .then((response) => {
            $("#master_vehicle_model_edit_modal").modal("hide");
            this.getVehicleManufracturerDetails();
          })
          .catch((err) => {
            //console.log(err);
          });
      });
    },

    deleteVehicleModel() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let delete_payload = {
        ITEM: "DELETE",
        RECORD_SYS_ID: this.selected_row.VEHICLE_MODEL_SYS_ID,
        CREATED_BY: user_details.UserId,
      };
      this.axios
        .post(apiEndPoints.create_update_vechile_model, delete_payload)
        .then((response) => {
          Global.showSuccessAlert(true, "success", response.data.response);
          $("#master_vehicle_model_delete_modal").modal("hide");
          //this.getVehicleManufracturerDetails()
          // location.reload()
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
