<template>
  <div>
    <div class="Vehicle-colour master-table box mt-4">
      <div class="row">
        <div class="col-md-12 offset-md-11">
          <button
            type="submit"
            value=""
            id=""
            class="custom-btn add-btn"
            name=""
            data-bs-toggle="modal"
            data-bs-target="#master_vehicle_colour_modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </button>
        </div>
      </div>

      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th>SL. No.</th>
            <th scope="col">Vehicle Colour</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody
          v-for="(vechile_color_detail, index) in vechile_color_details"
          :key="vechile_color_detail.VEHICLE_COLOR_SYS_ID"
        >
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ vechile_color_detail.VEHICLE_COLOR }}</td>
            <td class="table-btn" colspan="2">
              <button
                type="button"
                class="custom-btn p-0"
                @click="
                  showModal(
                    vechile_color_detail,
                    '#master_vehicle_colour_edit_modal'
                  )
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit"
                >
                  <path
                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                  ></path>
                  <path
                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                  ></path>
                </svg>
              </button>
              <button
                type="button"
                class="custom-btn p-0"
                @click="
                  showModal(
                    vechile_color_detail,
                    '#master_vehicle_colour_delete_modal'
                  )
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path
                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                  ></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- create modal -->

    <div
      class="modal modal-right fade"
      id="master_vehicle_colour_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Add Vehicle Colour
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <ValidationObserver ref="vehiclecolorform" v-slot="{ invalid }">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="col-md-12 mt-4">
                      <label class="frm-label">Vehicle Colour</label>
                      <input
                        type="text"
                        name="Vehicle Colour"
                        placeholder="Enter Vehicle Colour"
                        v-model="create_vehicle_color.VEHICLE_COLOR_NAME"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vehicle_color.VEHICLE_COLOR_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-5">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          @click="createVehicleColor"
                          :disabled="invalid"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          @click="emptyfu"
                          data-bs-dismiss="modal"
                          class="custom-btn custom-danger-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- edit modal -->

    <div
      class="modal modal-right fade"
      id="master_vehicle_colour_edit_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Vehicle Colour
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <ValidationObserver
                  ref="vehiclecoloreditform"
                  v-slot="{ invalid }"
                >
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="col-md-12 mt-4">
                      <label class="frm-label">Vehicle Colour</label>
                      <input
                        type="text"
                        name="Vehicle Colour"
                        placeholder="Enter Vehicle Colour"
                        v-model="selected_row.VEHICLE_COLOR"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            selected_row.VEHICLE_COLOR != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-5">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          @click="updateVehicleColor"
                          :disabled="invalid"
                        >
                          Update
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          class="custom-btn custom-danger-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--- delete modal-->

    <div class="modal" id="master_vehicle_colour_delete_modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete ?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteVehicleColor"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      vechile_color_details: [],
      create_vehicle_color: {
        ITEM: "ADD",
        RECORD_SYS_ID: "0",
        VEHICLE_COLOR_NAME: "",
        CREATED_BY: "",
      },
      selected_row: {},
    };
  },

  mounted() {
    this.getVechileColorDetails();
  },

  methods: {
    emptyfu() {
      this.create_vehicle_color.VEHICLE_COLOR_NAME = "";
    },

    getVechileColorDetails() {
      this.axios
        .get(apiEndPoints.vechile_color_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          //this.vechile_color_details = response.data.response;
          if (response.data.status == "true") {
            this.vechile_color_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.vechile_color_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    createVehicleColor() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_vehicle_color["CREATED_BY"] = user_details.UserId;

      this.$refs.vehiclecolorform.validate().then((success) => {
        if (!success) {
          return;
        }
        this.axios
          .post(
            apiEndPoints.create_update_vechile_color,
            this.create_vehicle_color
          )
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              $("#master_vehicle_colour_modal").modal("hide");
              this.getVechileColorDetails();
              this.emptyfu();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      });
    },

    showModal(row, id) {
      this.selected_row = row;
      $(id).modal("show");
    },

    updateVehicleColor() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let update_payload = {
        ITEM: "UPDATE",
        RECORD_SYS_ID: this.selected_row.VEHICLE_COLOR_SYS_ID,
        VEHICLE_COLOR_NAME: this.selected_row.VEHICLE_COLOR,
        CREATED_BY: user_details.UserId,
      };
      this.$refs.vehiclecoloreditform.validate().then((success) => {
        if (!success) {
          return;
        }

        this.axios
          .post(apiEndPoints.create_update_vechile_color, update_payload)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              $("#master_vehicle_colour_edit_modal").modal("hide");
              this.getVechileColorDetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      });
    },

    deleteVehicleColor() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let delete_payload = {
        ITEM: "DELETE",
        RECORD_SYS_ID: this.selected_row.VEHICLE_COLOR_SYS_ID,
        CREATED_BY: user_details.UserId,
      };
      this.axios
        .post(apiEndPoints.create_update_vechile_color, delete_payload)
        .then((response) => {
          $("#master_vehicle_colour_delete_modal").modal("hide");
          this.getVechileColorDetails();
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
