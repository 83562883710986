<template>
  <div>
    <div v-if="loading">
      <div class="preloder loading_main_background" id="upload_loader">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>
    <div class="floating-heading">
      <h4><b>ETS Price List</b></h4>
    </div>
    <div class="col-md-12 mt-1 text-success">
      <div class="form-outline text-primary">
        <span class="inline">
          <div class="row">
            <div class="col-md-10"></div>
            <div class="col-md-2 p-0 mt-4">
              <button
                style="margin-left: 5em"
                type="button"
                class="custom-btn custom-excel-btn"
                @click="exportExcel()"
              >
                <i class="fa-solid fa-file-excel"></i> Excel
              </button>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div class="col-md-12 mt-2" style="width: 1060px">
      <div class="content-body-wrapper">
        <div class="page-content">
          <div class="page-data">
            <div class="data-table master-table">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <!-- <th colspan="2"></th> -->
                    <th>SL. No.</th>
                    <th>BROKER</th>
                    <th>VEHICLE.NO</th>
                    <th>GST</th>
                    <th>REG.DATE</th>
                    <th>MAKE/MODEL</th>
                    <th>COLOR</th>
                    <th>OMV/E-TSF</th>
                    <th>UL/PARF</th>
                    <th>PROPELLANT</th>
                    <th>O</th>
                    <th>COE $</th>
                    <th>COE EXP</th>
                    <th>R/TAX EXP</th>
                    <th>PRICE $</th>
                    <th>LOC</th>
                  </tr>
                </thead>
                <tbody class="network_Group">
                  <tr
                    v-for="(system_role_detail,index) in system_role_details"
                    :key="system_role_detail.SYSTEM_ROLE_SYS_ID"
                  >
                    <!-- <td
                      :style="getGroupStyles()"
                      v-if="!hasInGroup(system_role_detail.BODY_TYPE)"
                      :rowspan="
                        getGroupDetails(system_role_detail.BODY_TYPE).length
                      "
                    >
                      <div
                        class="num-box-2"
                        :style="{
                          '-ms-transform': 'translateY(20%)',
                          transform: 'translateY(20%)',
                        }"
                      >
                        <p>{{ system_role_detail.BODY_TYPE }}</p>
                      </div>
                    </td> -->
                    <!-- <td colspan="1" v-else></td> -->
                    <td colspan="1">
                      {{ 1 + index }}
                    </td>
                    <td colspan="1">{{ system_role_detail.BROKER_NAME }}</td>
                    <td colspan="1">{{ system_role_detail.VEHICLE_NUMBER }}</td>
                    <td colspan="1">{{ system_role_detail.GST }}</td>
                    <td colspan="1">
                      {{ system_role_detail.REGISTRATION_DATE }}
                    </td>
                    <td colspan="1">{{ system_role_detail.MAKE_NAME }}</td>
                    <td colspan="1">{{ system_role_detail.COLOR_NAME }}</td>
                    <td>{{ system_role_detail.OMV_E_TSF }}</td>
                    <td>{{ system_role_detail.UL_PARF }}</td>
                    <td>{{ system_role_detail.PROPELLANT }}</td>
                    <td>{{ system_role_detail.O }}</td>
                    <td>{{ system_role_detail.COE_TO_PAY }}</td>
                    <td>{{ system_role_detail.COE_EXPIRY_DATE }}</td>
                    <td>{{ system_role_detail.ROAD_TAX_EXPIRY_DATE }}</td>
                    <td>{{ system_role_detail.PRICE_LIST_PLUS }}</td>
                    <td class="table-btn" colspan="2"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import NProgress from "nprogress";
import { Global } from "@/global.js";
export default {
  data() {
    return {
      loading: false,
      system_role_details: [],
      user_details: {},
      USER_SYS_ID: "",
      row_indetifier: "",
    };
  },

  mounted() {
    this.getPriceDetails();
  },
  computed: {
    system_role_groups() {
      const gr = [];
      this.system_role_details.forEach((g) => {
        if (!gr.includes(g.BODY_TYPE)) gr.push(g.BODY_TYPE);
      });
      return gr;
    },
  },
  methods: {
    hasInGroup(group_name) {
      if (group_name == this.row_indetifier) {
        return true;
      }
      this.row_indetifier = group_name;
      return false;
    },
    getGroupDetails(group) {
      return this.system_role_details.filter((role) => role.BODY_TYPE == group);
    },
    generateRandomColor() {
      let maxVal = 0xffffff;
      let randomNumber = Math.random() * maxVal;
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`;
    },
    getGroupStyles() {
      let color = this.generateRandomColor();
      return {
        "background-color": color,
        padding: "60px 6px !important",
        margin: 0,
        "border-width": "3px",
        "border-color": "#fff",
      };
    },

    getPriceDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let GET_SYS_ID = JSON.parse(localStorage.getItem("ets_user_details"));
      this.axios
        .get(apiEndPoints.ets_price, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            BROKER_SYS_ID: GET_SYS_ID,
            PAGE_SIZE: 4,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.system_role_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.system_role_details = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },
    exportExcel() {
      const tHeader = [
        "Type",
        "SL. No.",
        "BROKER",
        "VEHICLE.NO",
        "GST",
        "REG.DATA",
        "MAKE/MODEL",
        "COLOR",
        "OMV/E-TSF",
        "UL/PARF",
        "PROPELLANT",
        "O",
        "COE $",
        "COE EXP",
        "R/TAX EXP",
        "PRICE $",
        "LOC",
      ];
      const filterVal = [
        "BODY_TYPE",
        "BODY_TYPE_SL_NO",
        "BROKER_NAME",
        "VEHICLE_NUMBER",
        "GST",
        "REGISTRATION_DATE",
        "MAKE_NAME",
        "COLOR_NAME",
        "OMV_E_TSF",
        "UL_PARF",
        "PROPELLANT",
        "O",
        "COE_TO_PAY",
        "COE_EXPIRY_DATE",
        "ROAD_TAX_EXPIRY_DATE",
        "PRICE_LIST_PLUS",
      ];
      let system_role_groups = [];
      this.system_role_groups.forEach((role) => {
        this.getGroupDetails(role).forEach((item, index) => {
          if (index > 0) {
            item.BODY_TYPE = "";
          }
          system_role_groups.push(item);
        });
      });
      const data = this.formatJson(filterVal, system_role_groups);
      this.generateXlsx(tHeader, data, "lek-price-list");
    },
  },
};
</script>
<style scoped>
.loading_main_background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(42, 42, 43, 0.45);
  z-index: 9999;
  /* display: none; */
}
/* .preloder {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgb(0, 155, 254);
    z-index: 9999;
} */

.preloder img {
  width: auto;
  margin: 12% 38%;
  border-radius: 50%;
  opacity: 0.6;
}
.loading_main_background img {
  width: 5% !important;
  margin: 20% 50% !important;
  opacity: 1 !important;
  position: absolute;
}
img {
  vertical-align: middle;
  border-style: none;
}
</style>
