<template>
  <div>
    <div class="floating-heading">
      <h4><span>Asking Price</span></h4>
      <div class="floating-search-btn-container">
        <button
          id="srch_btn"
          data-bs-toggle="modal"
          data-bs-target="#left_modal_sm"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="">
      <div class="animate__animated animate__zoomIn">
        <div class="card-wrap shadow-main">
          <div class="card-wrap-body cus-body-wrap">
            <div class="filter-panel-wrap">
              <div class="content-body-wrapper mt-4">
                <div class="page-content">
                  <div class="page-data">
                    <div class="data-table admin-master-table">
                      <p>
                        <b
                          >Only assigned vehicle incharge will get the listed
                          vehicle here</b
                        >
                      </p>
                      <table class="table table-bordered table-hover">
                        <thead>
                          <tr style="background: #ebd8c3">
                            <th>SL. No.</th>
                            <th scope="col">Vehicle Status</th>
                            <th scope="col">Vehicle Number</th>
                            <th scope="col">Make And Model</th>
                            <th scope="col">Body Type</th>
                            <th scope="col">Propellant</th>
                            <th scope="col">Colour</th>
                            <th scope="col">Asking Price ($)</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(user_detail, index) in view_price"
                          :key="user_detail.RECORD_SYS_ID"
                        >
                          <tr>
                            <td>{{ index + 1 }}</td>
                            <td
                              @click="
                                showModal(user_detail, '#admin_edit_modal')
                              "
                            >
                              {{ user_detail.VEHICLE_STATUS }}
                            </td>
                            <td
                              @click="
                                showModal(user_detail, '#admin_edit_modal')
                              "
                            >
                              {{ user_detail.VEHICLE_NUMBER }}
                            </td>
                            <td
                              @click="
                                showModal(user_detail, '#admin_edit_modal')
                              "
                            >
                              {{ user_detail.MAKE_NAME }}
                              {{ user_detail.MODEL_NAME }}
                            </td>
                            <td
                              @click="
                                showModal(user_detail, '#admin_edit_modal')
                              "
                            >
                              {{ user_detail.BODY_TYPE }}
                            </td>
                            <td
                              @click="
                                showModal(user_detail, '#admin_edit_modal')
                              "
                            >
                              {{ user_detail.PROPELLANT }}
                            </td>
                            <td
                              @click="
                                showModal(user_detail, '#admin_edit_modal')
                              "
                            >
                              {{ user_detail.COLOR_NAME }}
                            </td>
                            <td
                              @click="
                                showModal(user_detail, '#admin_edit_modal')
                              "
                            >
                              {{ user_detail.ASKING_PRICE }}
                            </td>
                            <td class="table-btn">
                              <button
                                type="button"
                                @click="showModal(user_detail)"
                                class="custom-btn p-0"
                                data-bs-toggle="modal"
                                data-bs-target="#admin_edit_modal"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-edit"
                                >
                                  <path
                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                  ></path>
                                  <path
                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                  ></path>
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal left  -->
    <div
      class="modal modal-left fade"
      id="left_modal_sm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="left_modal_sm"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search By</h5>
          </div>
          <div class="modal-body search-panel">
            <div class="row">
              <div class="col-md-12">
                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="All"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">All Combinations</div>
                </label>

                <label class="rad-label">
                  <input type="radio" class="rad-input" name="rad" value="FY" />
                  <div class="rad-design"></div>
                  <div class="rad-text">Vehicle Status</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="program"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Vehicle Number</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="project"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Make</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="partner"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Model</div>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 div_1 mt-1">
                <label class="frm-label">Status</label>
                <select class="form-control">
                  <option value="0">Select Status</option>
                  <option value="1">Ordered</option>
                  <option value="1">Not-Ordered</option>
                </select>
              </div>
              <div class="col-md-12 div_3 mt-1">
                <label class="frm-label">Vehicle Number</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Vehicle No."
                />
              </div>
              <div class="col-md-12 div_4 mt-1">
                <label class="frm-label">Make</label>
                <select class="form-control">
                  <option value="0">Select Make</option>
                  <option value="1">Toyota</option>
                  <option value="2">Nissan</option>
                </select>
              </div>
              <div class="col-md-12 div_5 mt-1">
                <label class="frm-label">Model</label>
                <select class="form-control">
                  <option value="0">Select Model</option>
                  <option value="38">Toyota2018</option>
                  <option value="2">Nissan2010</option>
                </select>
              </div>
              <div class="col-md-12 div_6 mt-2">
                <div class="btn-conainer">
                  <button
                    type="button"
                    id="srch-btn"
                    class="custom-btn custom-success-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- edit modal -->
    <div
      class="modal modal-right fade"
      id="admin_edit_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style="width: 25%">
        <!-- style="width: 55% !important;" -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Asking Price
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body admin-edt-frm">
            <div class="mt-3">
              <label class="frm-label-2">Existing Asking Price</label>
              <input
                type="text"
                v-model="this.update_price.ASKING_PRICE"
                class="form-control"
                disabled
              />
            </div>
            <ValidationObserver v-slot="{ invalid }">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="col-md-8 mt-3">
                  <label class="frm-label">New Asking Price</label>
                  <input
                    type="text"
                    name="New Asking Price"
                    placeholder="Enter New Asking Price"
                    v-model="update_price.NEW_ASKING_PRICE"
                    class="form-control"
                    :class="{
                      'is-invalid': errors[0] != null,
                      'is-valid':
                        errors[0] == '' &&
                        update_price.NEW_ASKING_PRICE != null,
                    }"
                  />
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>

              <div class="modal-footer-2">
                <div class="row mt-4">
                  <div class="col-md-5">
                    <button
                      type="button"
                      :disabled="invalid"
                      @click="updateAskingPrice()"
                      class="custom-btn custom-success-btn"
                    >
                      Save
                    </button>
                  </div>
                  <div class="col-md-2">
                    <button
                      type="button"
                      class="custom-btn custom-danger-btn"
                      data-bs-dismiss="modal"
                      @click="editfun()"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      // update_price:{
      //     EXISTING_ASKING_PRICE:"",
      // },
      update_price: {},
      view_price: [],
      vehicledetails: [],
      ASKING_PRICE: "",
      NEW_ASKING_PRICE: "",
    };
  },
  mounted() {
    this.viewAskingPrice();
  },
  methods: {
    //   editaskingprice(data){
    //      //console.log(data.ASKING_PRICE);
    //     this.EXISTING_ASKING_PRICE= data.ASKING_PRICE;
    //     this.update_price["RECORD_SYS_ID"] =data.VEHICLE_SYS_ID ;
    //   },

    getvehicledetails(page_no = 1) {
      this.filtershowhide();
      this.axios
        .get(apiEndPoints.view_vehicle_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            VEHICLE_STATUS: this.STATUS,
            VEHICLE_NUMBER: this.VEHICLENUMBER,
            VEHICLE_MAKE: this.MAKENAME,
            VEHICLE_MODEL: this.MODELNAME,
            PAGE_SIZE: 8,
            PAGE_NO: page_no,
          },
        })
        .then((response) => {
          //console.log(response);

          if (response.data.status == "true") {
            if (page_no == 1) {
              this.vehicledetails = [];
            }
            this.vehicledetails = [
              ...this.vehicledetails,
              ...response.data.response,
            ];
            this.vehicledetails_page_no += 1;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            if (page_no == 1) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //modal for edit
    showModal(row, id) {
      this.update_price = row;
      //console.log("rm", this.update_price);
      $(id).modal("show");
    },
    viewAskingPrice() {
      //console.log("function call");
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      //console.log("hello", user_details);
      this.axios
        .get(apiEndPoints.update_asking_price, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: user_details.UserId,
          },
        })
        .then((response) => {
          //console.log("moon", response);
          if (response.data.status == "true") {
            this.view_price = response.data.response;
            //console.log("show price", this.view_price.ASKING_PRICE);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.view_price = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    updateAskingPrice() {
      //console.log("monnn");
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.update_price["ASKING_PRICE"] = this.update_price.ASKING_PRICE;
      this.update_price["ITEM"] = "ASKING_PRICE_UPDATE";
      this.update_price["CREATED_BY"] = user_details.UserId;
      this.update_price["SUB_ITEM"] = "ASKING_PRICE_UPDATE";
      this.update_price["RECORD_SYS_ID"] = this.update_price.VEHICLE_SYS_ID;

      //console.log("check", JSON.stringify(this.update_price));

      this.axios
        .post(apiEndPoints.add_vehicle_details, this.update_price)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);

            this.update_price.ASKING_PRICE = this.update_price.NEW_ASKING_PRICE;
            this.editfun();
            //this.viewAskingPrice();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    editfun() {
      this.update_price.NEW_ASKING_PRICE = "";
    },
  },
};
</script>
