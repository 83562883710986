<template>
  <div class="page-data">
    <div v-if="loading">
      <!-- <div class="loading"></div> -->
      <div class="preloder loading_main_background" id="upload_loader">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>
    <div class="modal-body">
      <div class="content-body-wrapper mt-4">
        <div class="page-content">
          <div class="page-data">
            <ValidationObserver ref="bankform" v-slot="{ invalid }">
              <form>
                <div class="row admin-edt-frm mt-2">
                  <!-- left sec  -->
                  <div class="col-md-6 left-side-frm">
                    <h5 class="line-heading"><span>Customer Details</span></h5>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Info<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <b-input-group>
                              <b-form-input
                                id="CustomerInfo"
                                name="CustomerInfo"
                                v-model="sellertype"
                                class="form-control"
                                placeholder="Enter customer"
                                list="datalistOptions"
                                @keyup="onKeyUpCustomerInfo"
                                @change="onChnageCustomerInfo"
                              ></b-form-input>
                              <b-input-group-append>
                                <b-button
                                  variant="info"
                                  data-bs-toggle="modal"
                                  data-bs-target="#customer_modal"
                                >
                                  <i class="fa-solid fa-plus"></i>
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <datalist id="datalistOptions">
                              <option
                                v-for="(
                                  customer_info, index
                                ) in all_customer_array"
                                :key="index"
                              >
                                {{ customer_info.CUSTOMER_NAME }}
                              </option>
                            </datalist>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="DisplayCustomerType != ''">
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Type<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              id="CustomerType"
                              name="CustomerType"
                              class="form-control"
                              v-model="DisplayCustomerType"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12"
                        v-if="
                          DisplayCustomerType == 'individual' &&
                          DisplayIdNumber != ''
                        "
                      >
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >NRIC Number<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              id="NRIC Number"
                              name="NRIC Number"
                              class="form-control"
                              v-model="DisplayIdNumber"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12"
                        v-if="
                          DisplayCustomerType == 'Company' &&
                          DisplayIdNumber != ''
                        "
                      >
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >UEN Number<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              id="UEN Number"
                              name="UEN Number"
                              class="form-control"
                              v-model="DisplayIdNumber"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="DisplayCustomerName != ''">
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Name<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="text"
                              id="Customer Name"
                              name="Customer Name"
                              class="form-control"
                              v-model="DisplayCustomerName"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-12"
                        v-if="DisplayCustomerAddress != ''"
                      >
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Customer Address<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <textarea
                              style="height: 4em"
                              id="Customer Address"
                              name="Customer Address"
                              class="form-control"
                              v-model="DisplayCustomerAddress"
                              disabled
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- right sec -->
                  <div class="col-md-6 left-side-frm">
                    <h5 class="line-heading"><span>Vehicle Details</span></h5>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Vehicle No<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <b-input-group>
                                <b-form-input
                                  id="VehicleNo"
                                  name="VehicleNo"
                                  class="form-control"
                                  placeholder="Enter Vehicle No"
                                  v-model="vehicle_number"
                                  @keyup="onKeyUpVehicleInfo"
                                  @change="onChnageVehicleInfo"
                                  :disabled="is_disabled"
                                  list="datalistOptionsVehicles"
                                  :class="{
                                    'is-invalid': errors[0] != null,
                                    'is-valid':
                                      errors[0] == null && vehicle_number != '',
                                  }"
                                ></b-form-input>
                              </b-input-group>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </ValidationProvider>
                            <datalist id="datalistOptionsVehicles">
                              <option
                                v-for="(
                                  vehicle_details, index
                                ) in all_vehicle_details"
                                :key="index"
                                :value="vehicle_details.VEHICLE_NUMBER"
                              ></option>
                            </datalist>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" v-if="vehicle_number">
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Make<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <select
                                name="Make"
                                v-model="create_quotation.MAKE_SYS_ID"
                                class="form-control"
                                :disabled="is_disabled"
                                :class="{
                                  'is-invalid': errors[0] != null,
                                  'is-valid':
                                    errors[0] == null &&
                                    create_quotation.MAKE_SYS_ID != '',
                                }"
                              >
                                <option value="">Select Make</option>
                                <option
                                  v-for="makenm in Manufracturename"
                                  :value="makenm.VEHICLE_MAKE_SYS_ID"
                                  :key="makenm.VEHICLE_MAKE_SYS_ID"
                                >
                                  {{ makenm.VEHICLE_MAKE }}
                                </option>
                              </select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12" v-if="vehicle_number">
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Model<span style="color: red">*</span></label
                            >
                          </div>
                          <div class="col-md-8">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                type="name"
                                name="Model"
                                v-model.trim="create_quotation.MODEL_NAME"
                                class="form-control"
                                placeholder="Enter Model"
                                :disabled="is_disabled"
                                :class="{
                                  'is-invalid': errors[0] != null,
                                  'is-valid':
                                    errors[0] == null &&
                                    create_quotation.MODEL_NAME != '',
                                }"
                              />
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12" v-if="vehicle_number">
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Manufacture Year<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                type="number"
                                name="Manufactory Year"
                                v-model="create_quotation.MANUFACTURE_YEAR"
                                class="form-control"
                                placeholder="Enter Manufacture Year"
                                :disabled="is_disabled"
                                :class="{
                                  'is-invalid': errors[0] != null,
                                  'is-valid':
                                    errors[0] == null &&
                                    create_quotation.MANUFACTURE_YEAR != '',
                                }"
                              />
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12" v-if="vehicle_number">
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Orginal Reg. Date<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <input
                                type="text"
                                name="Orginal reg date"
                                v-model.trim="
                                  create_quotation.ORIGINAL_REGISTRATION_DATE
                                "
                                class="form-control"
                                placeholder="Enter Orginal Reg no"
                                :disabled="is_disabled"
                                :class="{
                                  'is-invalid': errors[0] != null,
                                  'is-valid':
                                    errors[0] == null &&
                                    create_quotation.ORIGINAL_REGISTRATION_DATE !=
                                      '',
                                }"
                              />
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-12">
                        <div class="row mt-2">
                          <div class="col-md-4">
                            <label class="frm-label-2 align-right"
                              >Yr Made<span style="color: red"
                                >*</span
                              ></label
                            >
                          </div>
                          <div class="col-md-8">
                            <input
                              type="number"
                              name="Yr Made"
                              v-model.trim="create_quotation.REGISTRATION_DATE"
                              class="form-control"
                              placeholder="Enter Year Made"
                            />
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>

                  <div class="col-md-12">
                    <br />
                    <h5 class="line-heading">
                      <span>Motor Insurance Quotation</span>
                    </h5>
                    <div class="col-md-12">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >Renewal NCD<span style="color: red">*</span></label
                          >
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <input
                              type="name"
                              name="Renwal NCD"
                              v-model.trim="create_quotation.RENEWAL_NCD"
                              class="form-control"
                              placeholder="Enter Renewal Ncd"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_quotation.RENEWAL_NCD != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >Existing Insurance<span style="color: red"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <input
                              type="name"
                              name="Existing Insurance"
                              v-model.trim="create_quotation.EXISITING_INSURER"
                              class="form-control"
                              placeholder="Enter Existing Insurance"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_quotation.EXISITING_INSURER != '',
                              }"
                            />
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >Previous Registration Number</label
                          >
                        </div>
                        <div class="col-md-8">
                          <input
                            type="text"
                            name="Previous Registration Number"
                            v-model.trim="
                              create_quotation.PREVIOUS_REGISTRATION_NO
                            "
                            class="form-control"
                            placeholder="Enter Previous Registration Number"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right"
                            >Type Of Coverage<span style="color: red"
                              >*</span
                            ></label
                          >
                        </div>
                        <div class="col-md-8">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <select
                              name="type of cover"
                              v-model="create_quotation.COVER_TYPE"
                              class="form-control"
                              :class="{
                                'is-invalid': errors[0] != null,
                                'is-valid':
                                  errors[0] == null &&
                                  create_quotation.COVER_TYPE != '',
                              }"
                            >
                              <option value="">Select</option>
                              <option>COMPREHENSIVE</option>
                              <option>TPFT</option>
                              <option>TPO</option>
                            </select>
                            <div class="invalid-feedback">{{ errors[0] }}</div>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right">
                            Finance Company
                          </label>
                        </div>
                        <div class="col-md-8">
                          <select
                            name="type of cover"
                            v-model="create_quotation.FINANCE_COMPANY_SYS_ID"
                            @change="
                              getFinanceCompanyName(
                                create_quotation.FINANCE_COMPANY_SYS_ID
                              )
                            "
                            class="form-control"
                          >
                            <option value="">Select</option>
                            <option
                              v-for="(
                                finace_company, finace_index
                              ) in finace_company_list"
                              :key="finace_index"
                              :value="
                                finace_company.FINANCE_COMPANY_BANK_SYS_ID
                              "
                            >
                              {{ finace_company.FINANCE_COMPANY_BANK_NAME }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="row mt-2">
                        <div class="col-md-4">
                          <label class="frm-label-2 align-right">Remarks</label>
                        </div>
                        <div class="col-md-8">
                          <input
                            type="name"
                            name="Remarks"
                            v-model.trim="create_quotation.QUOTATION_REMARKS"
                            class="form-control"
                            placeholder="Enter Remarks"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-1">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          @click="addQuotation()"
                          :disabled="invalid"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-1">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                          @click="$router.push({ name: 'viewquotation' })"
                        >
                          Cancel
                        </button>
                      </div>
                      <div class="col-md-5"></div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal-right fade"
      id="customer_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Customer</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mt-2">
                <label class="frm-label"
                  >Customer Type <span style="color: red">*</span></label
                >
                <select
                  id="customertype"
                  v-model="create_customer.CUSTOMER_TYPE"
                  class="form-control"
                >
                  <option value="">Select</option>
                  <option value="individual">Individual</option>
                  <option value="Company">Company</option>
                </select>
              </div>

              <div
                class="individual box"
                v-if="create_customer.CUSTOMER_TYPE == 'individual'"
              >
                <ValidationObserver ref="cus">
                  <div class="row">
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >NRIC Number <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="NRIC Number"
                          v-model.trim="create_customer.ID_NUMBER"
                          class="form-control"
                          placeholder="Enter NRIC Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_customer.ID_NUMBER != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Full Name <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Full Name"
                          v-model.trim="create_customer.CUSTOMER_NAME"
                          class="form-control"
                          placeholder="Enter full Name"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_customer.CUSTOMER_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider
                        rules="required|mobileNumber"
                        v-slot="{ errors }"
                      >
                        <label class="frm-label"
                          >Mobile Number<span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Mobile.NO"
                          v-model.trim="create_customer.CUSTOMER_CONTACT"
                          class="form-control"
                          placeholder="Enter Mobile Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_customer.CUSTOMER_CONTACT != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <label class="frm-label"
                          >Email ID <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="Email Id"
                          v-model.trim="create_customer.CUSTOMER_EMAIL"
                          class="form-control"
                          placeholder="Enter Email ID"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_customer.CUSTOMER_EMAIL != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Address Line 1
                          <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Address 1"
                          v-model.trim="create_customer.ADDRESS_LINE_1"
                          class="form-control"
                          placeholder="Enter Address"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_customer.ADDRESS_LINE_1 != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Address Line 2</label>
                      <input
                        type="text"
                        name="Address Line 2"
                        v-model.trim="create_customer.ADDRESS_LINE_2"
                        class="form-control"
                        placeholder="Enter Address"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >post Code <span style="color: red">*</span></label
                        >
                        <input
                          type="number"
                          name="Post code"
                          v-model.trim="create_customer.PIN_CODE"
                          class="form-control"
                          placeholder="Enter Post Code"
                          maxlength="6"
                          minlength="6"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_customer.PIN_CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider v-slot="{ errors }">
                        <label class="frm-label"
                          >Country <span style="color: red">*</span></label
                        >
                        <b-form-select
                          name="Country"
                          v-model="create_customer.COUNTRY_NAME"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_customer.COUNTRY_NAME != '',
                          }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >-- Select --</b-form-select-option
                            >
                          </template>
                          <option value="Singapore">Singapore</option>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="modal-footer">
                      <div class="row mt-4">
                        <div class="col-md-3">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            @click="Addcustomer"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="custom-btn custom-danger-btn"
                            data-bs-dismiss="modal"
                            @click="emptyCustomerDetails"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>

              <div
                class="Company box"
                v-if="create_customer.CUSTOMER_TYPE == 'Company'"
              >
                <ValidationObserver ref="bankfo">
                  <div class="row">
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >UEN Number <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="UEN Number"
                          v-model.trim="create_company.ID_NUMBER"
                          class="form-control"
                          placeholder="Enter UEN Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.ID_NUMBER != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Company Name <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Company Name"
                          v-model.trim="create_company.CUSTOMER_NAME"
                          class="form-control"
                          placeholder="Enter Company Name"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.CUSTOMER_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Person Incharge
                          <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="person incharge"
                          v-model.trim="create_company.PERSON_INCHARGE"
                          class="form-control"
                          placeholder="Enter Person incharge"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.PERSON_INCHARGE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider
                        rules="required|mobileNumber"
                        v-slot="{ errors }"
                      >
                        <label class="frm-label"
                          >Mobile.NO <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Mobile Number"
                          v-model.trim="create_company.CUSTOMER_CONTACT"
                          class="form-control"
                          placeholder="Enter Mobile Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.CUSTOMER_CONTACT != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <label class="frm-label"
                          >Email ID<span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="gmail"
                          v-model.trim="create_company.CUSTOMER_EMAIL"
                          class="form-control"
                          placeholder="Enter Email ID"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.CUSTOMER_EMAIL != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Address Line 1
                          <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="address line 1"
                          v-model.trim="create_company.ADDRESS_LINE_1"
                          class="form-control"
                          placeholder="Enter Address"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.ADDRESS_LINE_1 != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Address Line 2 </label>
                      <input
                        type="name"
                        name="Address Line 2"
                        v-model.trim="create_company.ADDRESS_LINE_2"
                        class="form-control"
                        placeholder="Enter Address"
                      />
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >post Code <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Post code"
                          v-model.trim="create_company.PIN_CODE"
                          class="form-control"
                          placeholder="Enter Post Code"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.PIN_CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider v-slot="{ errors }">
                        <label class="frm-label"
                          >Country <span style="color: red">*</span></label
                        >
                        <b-form-select
                          name="Country"
                          v-model="create_company.COUNTRY_NAME"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.COUNTRY_NAME != '',
                          }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >-- Select --</b-form-select-option
                            >
                          </template>
                          <option value="">Singapore</option>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="modal-footer">
                      <div class="row mt-4">
                        <div class="col-md-3">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            @click="Addcompany"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="custom-btn custom-danger-btn"
                            data-bs-dismiss="modal"
                            @click="emptyCustomerDetails"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
import NProgress from "nprogress";

export default {
  data() {
    return {
      loading: false,
      vehicle_details: [],
      create_quotation: {
        MANUFACTURE_YEAR: "",
        ITEM: "ADD",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        VEHICLE_NO: "",
        MAKE_SYS_ID: "",
        EXISITING_INSURER: "",
        MODEL_NAME: "",
        PREVIOUS_REGISTRATION_NO: "",
        ORIGINAL_REGISTRATION_DATE: "",
        RENEWAL_NCD: "",
        COVER_TYPE: "",
        QUOTATION_REMARKS: "",
        VEHICLE_SYS_ID: "",
        FINANCE_COMPANY_SYS_ID: "",
        FINANCE_COMPANY: "",
      },
      sellertype: "",
      DisplayCustomerType: "",
      DisplayCustomerName: "",
      DisplayCustomerAddress: "",
      DisplayIdNumber: "",
      all_customer_array: [],
      all_vehicle_details: [],
      vehicle_number: "",
      Manufracturename: [],
      CUSTOMER_SYS_ID: null,
      is_disabled: false,
      create_company: {
        ITEM: "ADD",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        PERSON_INCHARGE: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "",
        COUNTRY_SYS_ID: "1",
        RECORD_SYS_ID: "0",
        OFFICE_NUMBER: "",
        CREATED_BY: "",
        ID_NUMBER: "",
        CUSTOMER_EMAIL: "",
      },
      create_customer: {
        ITEM: "ADD",
        NRIC_NUMBER: "",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        LAST_NAME: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "Singapore",
        COUNTRY_SYS_ID: "1",
        ID_NUMBER: "",
        CUSTOMER_EMAIL: "",
      },
      finace_company_list: [],
    };
  },

  mounted() {
    this.getAllCustomerDetails();
    this.getVehicledetails();
    this.getManufracturename();
    this.getFinanceCompnyList();
  },

  methods: {
    getManufracturename() {
      this.axios
        .get(apiEndPoints.view_vehicle_manufacture_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          this.Manufracturename = response.data.response;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    addQuotation() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_quotation.CREATED_BY = user_details.UserId;
      this.create_quotation.VEHICLE_NO = this.vehicle_number;
      this.create_quotation["CUSTOMER_SYS_ID"] = this.CUSTOMER_SYS_ID;
      this.create_quotation.VEHICLE_SYS_ID = this.create_quotation
        .VEHICLE_SYS_ID
        ? this.create_quotation.VEHICLE_SYS_ID
        : 0;

      this.axios
        .post(apiEndPoints.Add_Quotation, this.create_quotation)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.$router.push({ name: "viewquotation" });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    onKeyUpCustomerInfo() {
      if (this.sellertype == "") {
        this.DisplayCustomerType = "";
        this.DisplayIdNumber = "";
        this.DisplayCustomerName = "";
        this.DisplayCustomerAddress = "";
      }
    },

    onChnageCustomerInfo() {
      // let array = this.sellertype.split("-");
      let CustomerIdNumber = this.sellertype;
      if (CustomerIdNumber) {
        let cusObj = this.all_customer_array.find(
          (items) => items.CUSTOMER_NAME == CustomerIdNumber
        );
        if (cusObj) {
          this.DisplayCustomerType = cusObj.CUSTOMER_TYPE;
          this.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
          this.DisplayIdNumber = cusObj.ID_NUMBER;
          this.DisplayCustomerName = cusObj.CUSTOMER_NAME;
          let address =
            cusObj.ADDRESS_LINE_1 +
            " ," +
            cusObj.COUNTRY_NAME +
            "-" +
            cusObj.PIN_CODE;
          this.DisplayCustomerAddress = address;
        }
      }
    },

    //Get All Customer Info
    getAllCustomerDetails() {
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 20,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.all_customer_array = response.data.response;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    getVehicledetails() {
      this.axios
        .get(apiEndPoints.view_vehicle_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            VEHICLE_STATUS: "",
            VEHICLE_NUMBER: "",
            VEHICLE_MAKE: "",
            VEHICLE_MODEL: "",
            PAGE_SIZE: 10,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.all_vehicle_details = response.data.response;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    onKeyUpVehicleInfo() {
      if (this.vehicle_number == "") {
        this.create_quotation.MODEL_NAME = "";
        this.create_quotation.MANUFACTURE_YEAR = "";
        this.create_quotation.ORIGINAL_REGISTRATION_DATE = "";
        (this.create_quotation.MAKE_SYS_ID = ""),
          (this.create_quotation.VEHICLE_SYS_ID = "");
        this.is_disabled = false;
      }
    },

    onChnageVehicleInfo() {
      let is_existed = this.all_vehicle_details.find(
        (items) => items.VEHICLE_NUMBER == this.vehicle_number
      );
      if (is_existed) {
        this.create_quotation.MODEL_NAME = is_existed.MODEL_NAME;
        this.create_quotation.MANUFACTURE_YEAR = is_existed.MANUFACTURE_YEAR;
        this.create_quotation.ORIGINAL_REGISTRATION_DATE =
          is_existed.ORIGINAL_REGISTRATION_DATE;
        this.create_quotation.MAKE_SYS_ID = is_existed.MAKE_SYS_ID;
        this.create_quotation.VEHICLE_SYS_ID = is_existed.RECORD_SYS_ID;
        this.is_disabled = true;
      } else {
        this.is_disabled = false;
      }
    },

    Addcustomer() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_customer["CREATED_BY"] = user_details.UserId;
      this.$refs.cus.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_customer)
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getAllCustomerDetails();
                this.emptyCustomerDetails();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },

    Addcompany() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_company["CREATED_BY"] = user_details.UserId;
      this.create_company["CUSTOMER_TYPE"] = this.create_customer.CUSTOMER_TYPE;
      this.$refs.bankfo.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_company)
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getAllCustomerDetails();
                this.emptyCustomerDetails();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },

    emptyCustomerDetails() {
      $("#customer_modal").modal("hide");
      this.create_customer = {
        ITEM: "ADD",
        NRIC_NUMBER: "",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        LAST_NAME: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "Singapore",
        COUNTRY_SYS_ID: "1",
        ID_NUMBER: "",
        GMAIL_ID: "",
      };
      this.create_company = {
        ITEM: "ADD",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        PERSON_INCHARGE: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "",
        COUNTRY_SYS_ID: "1",
        RECORD_SYS_ID: "0",
        OFFICE_NUMBER: "",
        CREATED_BY: "",
        ID_NUMBER: "",
        GMAIL_ID: "",
      };
    },

    // finace_company_list
    getFinanceCompnyList(id) {
      this.axios
        .get(apiEndPoints.view_finance_company_bank, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.finace_company_list = response.data.response;
            if (id) {
              this.getFinanceCompanyName(id);
            }
          }
        });
    },

    getFinanceCompanyName(id) {
      let existed_finance_company = this.finace_company_list.find(
        (items) => items.FINANCE_COMPANY_BANK_SYS_ID == id
      );
      if (existed_finance_company) {
        this.create_quotation.FINANCE_COMPANY =
          existed_finance_company.FINANCE_COMPANY_BANK_NAME;
      } else {
        this.create_quotation.FINANCE_COMPANY = "";
      }
    },
  },
};
</script>
<style scoped>
.loading_main_background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(42, 42, 43, 0.45);
  z-index: 9999;
  /* display: none; */
}
/* .preloder {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgb(0, 155, 254);
    z-index: 9999;
} */

.preloder img {
  width: auto;
  margin: 12% 38%;
  border-radius: 50%;
  opacity: 0.6;
}
.loading_main_background img {
  width: 5% !important;
  margin: 20% 50% !important;
  opacity: 1 !important;
  position: absolute;
}
img {
  vertical-align: middle;
  border-style: none;
}
li.nav-item {
  width: 10em !important;
  text-align: center !important;
}
</style>
