<template #createdAt="{ item }">
  <div>
    <div v-if="loading">
      <div class="preloder loading_main_background" id="upload_loader">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>
    <div class="floating-heading">
      <h4>Admin <span>Master Form</span></h4>
    </div>
    <div class="wrapper">
      <div class="animate__animated animate__slideInRight">
        <div class="content-body-wrapper mt-4">
          <div class="page-content pt-1">
            <div class="page-data">
              <ValidationObserver ref="vehicledetailsaddForm">
                <div class="row">
                  <div class="col-md-3 mt-4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Vehicle Status<span style="color: red">*</span></label
                      >
                      <select
                        name="Status"
                        v-model="addmasterdata.VEHICLE_STATUS"
                        @change="onChangeVehicleStatus()"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            addmasterdata.VEHICLE_STATUS != '',
                        }"
                      >
                        <option value="">Select</option>
                        <option value="Pre Order">Pre Order</option>
                        <option value="New Vehicle">New Vehicle</option>
                        <option value="Local">Local</option>
                        <option value="ETS">ETS</option>
                        <option value="Scrap">Scrap</option>
                        <option value="Trade-in">Trade-in</option>
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3 mt-5">
                    <input
                      type="checkbox"
                      v-model="addmasterdata.PRICE_LIST_STATUS"
                      class="me-2"
                    />
                    <label class="frm-label" style="display: inline">
                      Not show to pricelist
                    </label>
                  </div>
                  <div class="col-md-3 mt-5">
                    <input
                      type="checkbox"
                      @change="onChangeBulkVehicleDetails"
                      class="me-2"
                      v-model="bulkvehicle"
                    />
                    <label class="frm-label" style="display: inline">
                      Add Bulk Vehicle Details
                    </label>
                  </div>
                  <div class="col-md-3 mt-4">
                    <label class="frm-label">No. of Vehicle</label>
                    <input
                      v-model="addmasterdata.BULK_COUNT"
                      type="text"
                      class="form-control"
                      placeholder="Total Number Vehicle"
                      :disabled="isDisabled"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-3 mt-4">
                    <label class="frm-label">Vehicle No.</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Vehicle No."
                      v-model="addmasterdata.VEHICLE_NUMBER"
                      :disabled="!isDisabled_"
                    />
                  </div>
                  <div class="col-md-3 mt-4">
                    <label class="frm-label">Temporary Vehicle No.</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Temporary Vehicle No."
                      v-model="addmasterdata.TEMPORARY_VEHICLE_NUMBER"
                      :disabled="!isDisabled_temporary"
                    />
                  </div>
                  <div class="col-md-3 mt-4">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Make<span style="color: red"></span
                      ></label>
                      <select
                        name="Make"
                        v-model="addmasterdata.MAKE_SYS_ID"
                        @change="getmakeName"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            addmasterdata.MAKE_SYS_ID != '',
                        }"
                      >
                        <option value="">Select Manufacturer</option>
                        <option
                          v-for="makenm in Manufracturename"
                          :value="makenm.VEHICLE_MAKE_SYS_ID"
                          :key="makenm.VEHICLE_MAKE_SYS_ID"
                        >
                          {{ makenm.VEHICLE_MAKE }}
                        </option>
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3 mt-4">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Model<span style="color: red"></span
                      ></label>
                      <input
                        type="text"
                        name="Model"
                        class="form-control"
                        placeholder="Enter Vehicle Model"
                        v-model="addmasterdata.MODEL_NAME"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == '' && addmasterdata.MODEL_NAME != null,
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3 mt-4">
                    <label class="frm-label">Model Short Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Vehicle Model Short Name"
                      v-model="addmasterdata.MODEL_SHORT_NAME"
                    />
                  </div>
                  <div class="col-md-3 mt-4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Body Type<span style="color: red">*</span></label
                      >
                      <select
                        name="Body Type"
                        v-model="addmasterdata.BODY_TYPE_SYS_ID"
                        class="form-control"
                        @change="getbodyName"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            addmasterdata.BODY_TYPE_SYS_ID != '',
                        }"
                      >
                        <option value="">Select Body Type</option>
                        <option
                          v-for="body in Bodytype"
                          :value="body.VEHICLE_BODY_TYPE_SYS_ID"
                          :key="body.VEHICLE_BODY_TYPE_SYS_ID"
                        >
                          {{ body.VEHICLE_BODY_TYPE }}
                        </option>
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3 mt-4">
                    <label class="frm-label">Price List(plus)</label>
                    <input
                      name="Price list"
                      type="number"
                      v-model="addmasterdata.PRICE_LIST_PLUS"
                      class="form-control"
                      placeholder="Price List"
                    />
                  </div>
                  <div class="col-md-3 mt-4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Asking Price($)<span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="asking price"
                        class="form-control"
                        @keypress="validateNumber"
                        placeholder="Enter Asking Price"
                        v-model="addmasterdata.ASKING_PRICE"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            addmasterdata.ASKING_PRICE != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-3 mt-4">
                        <label class="frm-label">Chassis No.</label>
                        <input
                          name="Chassis No."
                          v-model="addmasterdata.CHASIS_NO"
                          :disabled="!isDisabled"
                          type="text"
                          class="form-control"
                          placeholder="Enter Chassis No."
                        />
                      </div>
                      <div class="col-md-3 mt-4">
                        <label class="frm-label">Engine No.</label>
                        <input
                          v-model="addmasterdata.ENGINE_NO"
                          :disabled="!isDisabled"
                          type="text"
                          name="Engine No."
                          class="form-control"
                          placeholder="Engine No."
                        />
                      </div>
                      <div class="col-md-3 mt-4">
                        <ValidationProvider v-slot="{ errors }">
                          <label class="frm-label"
                            >Propellant<span style="color: red"></span
                          ></label>
                          <select
                            name="Propellant"
                            v-model="addmasterdata.PROPELLANT"
                            class="form-control"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                addmasterdata.PROPELLANT != '',
                            }"
                          >
                            <option value="">Select</option>
                            <option value="Petrol">Petrol</option>
                            <option value="Diesel">Diesel</option>
                            <option value="Natural Gas">Natural Gas</option>
                            <option value="Electric">Electric</option>
                            <option value="Petrol-Electric">
                              Petrol-Electric
                            </option>
                            <option value="Diesel-Electric (Plug-In)">
                              Diesel-Electric (Plug-In)
                            </option>
                            <option value="Petrol-Electric (Plug-In)">
                              Petrol-Electric (Plug-In)
                            </option>
                            <option value="Diesel-CNG">
                              Diesel-CNG (Plug-In)
                            </option>
                            <option value="Diesel- Electric">
                              Diesel- Electric
                            </option>
                          </select>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-3 mt-4">
                        <label class="frm-label">Colour</label>
                        <select
                          v-model="addmasterdata.COLOR_SYS_ID"
                          :disabled="!isDisabled"
                          class="form-control"
                          @change="getcolorName"
                        >
                          <option value="">Select Colour</option>
                          <option
                            v-for="colournm in Coloursname"
                            :value="colournm.VEHICLE_COLOR_SYS_ID"
                            :key="colournm.VEHICLE_COLOR_SYS_ID"
                          >
                            {{ colournm.VEHICLE_COLOR }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 mt-4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Laden<span style="color: red">*</span></label
                      >
                      <input
                        name="Laden"
                        @keyup="onKeyUpCalculateTon"
                        v-model="addmasterdata.LADEN"
                        type="number"
                        class="form-control"
                        placeholder="Laden"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && addmasterdata.LADEN != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3 mt-4">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Unladen<span style="color: red">*</span></label
                      >
                      <input
                        @keyup="onKeyUpCalculateTon"
                        v-model="addmasterdata.UNLADEN"
                        type="number"
                        name="unladen"
                        class="form-control"
                        placeholder="Unladen"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && addmasterdata.UNLADEN != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3 mt-4">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label">Ton</label>
                      <input
                        name="Engine Cap"
                        v-model="addmasterdata.ENGINE_TON"
                        type="text"
                        class="form-control"
                        placeholder=""
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && addmasterdata.ENGINE_TON != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-3 mt-4">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Engine Cap<span style="color: red"></span
                      ></label>
                      <input
                        name="Engine Cap"
                        v-model="addmasterdata.ENGINE_CAP"
                        type="text"
                        class="form-control"
                        placeholder="Engine Cap"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && addmasterdata.ENGINE_CAP != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-4">
                    <label class="frm-label">PAX</label>
                    <input
                      name="Pax"
                      v-model="addmasterdata.PAX"
                      type="number"
                      class="form-control"
                      placeholder="PAX"
                      :disabled="isDisabledETS"
                    />
                  </div>
                  <div class="col-md-4 mt-4">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Year of Manufacture<span style="color: red"></span
                      ></label>
                      <input
                        name="Year"
                        v-model="addmasterdata.MANUFACTURE_YEAR"
                        type="text"
                        class="form-control"
                        maxlength="4"
                        placeholder="Year Of Manufacture"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            addmasterdata.MANUFACTURE_YEAR != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-4">
                    <label class="frm-label">Original Registration Date</label>
                    <input
                      v-model="addmasterdata.ORIGINAL_REGISTRATION_DATE"
                      type="text"
                      class="form-control"
                      :disabled="!isDisabled"
                    />
                  </div>
                  <div class="col-md-4 mt-4">
                    <label class="frm-label">Number Of TRF</label>
                    <input
                      v-model="addmasterdata.TSF_NUMBER"
                      type="text"
                      class="form-control"
                      placeholder="Number Of TRF"
                      :disabled="isDisabledTRF"
                    />
                  </div>
                  <div class="col-md-4 mt-4">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Location<span style="color: red"></span
                      ></label>
                      <select
                        name="Location"
                        v-model="addmasterdata.VEHICLE_LOCATION_SYS_ID"
                        class="form-control"
                        @change="getLocationName"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            addmasterdata.VEHICLE_LOCATION_SYS_ID != '',
                        }"
                        :disabled="isDisabledETS"
                      >
                        <option value="">Select location</option>
                        <option
                          v-for="Vehiclelocation in Vehiclelocations"
                          :value="Vehiclelocation.VEHICLE_LOCATION_SYS_ID"
                          :key="Vehiclelocation.VEHICLE_LOCATION_SYS_ID"
                        >
                          {{ Vehiclelocation.VEHICLE_LOCATION }}
                        </option>
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-4">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Incharge By<span style="color: red"></span
                      ></label>
                      <select
                        v-model="addmasterdata.INCHARGE_BY_BROKER_SYS_ID"
                        class="form-control"
                        name="Incharge By"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            addmasterdata.INCHARGE_BY_BROKER_SYS_ID != '',
                        }"
                      >
                        <option value="">Select Incharge</option>
                        <option
                          v-for="broker in brokerdetails"
                          :value="broker.USER_SYS_ID"
                          :key="broker.USER_SYS_ID"
                        >
                          {{ broker.PERSON_NAME }}
                        </option>
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="row">
                      <div class="col-md-6">
                        <label class="frm-label">Accessory</label>
                        <textarea
                          v-model="addmasterdata.ACCESSORY"
                          :disabled="!isDisabled"
                          class="custom-textarea form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-5">
                    <div class="row">
                      <div class="col-md-1">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          @click="nullify(), addVehicleDetails()"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="Reset"
                          class="custom-btn custom-danger-btn"
                          @click="emptyfun"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { admin_master } from "../user-management/admin_master";
export default admin_master;
</script>
<style scoped>
.loading_main_background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(42, 42, 43, 0.45);
  z-index: 9999;
  /* display: none; */
}
/* .preloder {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgb(0, 155, 254);
    z-index: 9999;
} */

.preloder img {
  width: auto;
  margin: 12% 38%;
  border-radius: 50%;
  opacity: 0.6;
}
.loading_main_background img {
  width: 5% !important;
  margin: 20% 50% !important;
  opacity: 1 !important;
  position: absolute;
}
img {
  vertical-align: middle;
  border-style: none;
}
li.nav-item {
  width: 10em !important;
  text-align: center !important;
}
.downld {
  background: transparent;
  outline: none;
  border: none;
  color: #112b3c;
  font-weight: 600;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  float: right !important;
  font-size: 13px;
  margin-top: 5px;
}
.animate__bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
</style>
