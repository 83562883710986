<template>
  <div>
    <!-- <div
                      class="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    > -->
    <div>
      <div
        class="card view_product_card tab-content product-card"
        style="padding: 0 !important; margin-top: 0"
      >
        <div class="card-header filter-panel-wrap pt-2">Change Password</div>
        <div class="card-body mt-3">
          <div class="forgot-passsword-form mb-4">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <ul class="styled-li">
                    <li id="length">
                      Password length should be withnin 8 to 15 characters.
                    </li>
                    <li id="case_low" class="mt-2">
                      At least one lowercase character
                    </li>
                    <li id="case_up" class="mt-2">
                      At least one uppercase character
                    </li>
                    <li id="special" class="mt-2">
                      At least one special character
                    </li>
                    <li id="num" class="mt-2">At least one number character</li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <label class="frm-label">Enter New Password</label>
                  <div class="d-flex">
                    <input
                      :type="show_password ? 'text' : 'password'"
                      maxLength="15"
                      v-model="OLD_PASSWORD"
                      class="form-control pe-4 password"
                      placeholder="Enter New Password"
                    />
                    <button
                      type="button"
                      @click="show_password = !show_password"
                      id="eye"
                      onclick="togglePassword(this)"
                      class="eye-btn border-0"
                    >
                      <i
                        v-if="!show_password"
                        class="fa fa-eye"
                        aria-hidden="true"
                        style="color: #000"
                      ></i>
                    </button>
                  </div>

                  <label class="frm-label">Repeat New Password</label>
                  <div class="d-flex">
                    <input
                      :type="show_passwor ? 'text' : 'password'"
                      maxLength="15"
                      id="new_pwd"
                      v-model="save_pass_details.NEW_PASSWORD"
                      @keyup="passwordcheck"
                      class="form-control pe-4 password"
                      placeholder="Enter Repeat Password"
                    />
                    <button
                      type="button"
                      id="eye"
                      @click="show_passwor = !show_passwor"
                      onclick="togglePassword(this)"
                      class="eye-btn border-"
                    >
                      <i
                        v-if="!show_passwor"
                        class="fa fa-eye"
                        aria-hidden="true"
                        style="color: #000"
                      ></i>
                    </button>
                  </div>

                  <button
                    type="button"
                    @click="savepass()"
                    id="btn_save_change_password"
                    class="custom-btn custom-success-btn mt-4"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      show_password: false,
      show_passwor: false,
      user_details: {},
      otp: "",
      save_pass_details: {
        ITEM: "FORGOT_PASSWORD",
        NEW_PASSWORD: "",
      },
      OLD_PASSWORD: "",
    };
  },
  mounted() {
    this.otpgenerate();

    $(document).ready(function () {
      $(document).on("keyup", "#new_pwd", function () {
        var lower = new RegExp("^(?=.*[a-z])");
        var upper = new RegExp("^(?=.*[A-Z])");
        var digit = new RegExp("^(?=.*[0-9])");
        var special_char = new RegExp("^(?=.*[@#$%^&+=])");
        var length = new RegExp("^.{8,15}");
        var pass = $("#new_pwd").val();
        var condition = [];
        var condition_count = 0;
        // var tmp = length.test(pass);
        if (length.test(pass)) {
          $("#length").css("color", "#008000");

          condition_count += 1;
        } else {
          $("#length").css("color", "#FF0000");
        }

        if (lower.test(pass)) {
          $("#case_low").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#case_low").css("color", "#FF0000");
        }
        if (upper.test(pass)) {
          $("#case_up").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#case_up").css("color", "#FF0000");
        }
        if (digit.test(pass)) {
          $("#num").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#num").css("color", "#FF0000");
        }
        if (special_char.test(pass)) {
          $("#special").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#special").css("color", "#FF0000");
        }

        if (condition_count >= 5) {
          $("#btn_save_change_password").show();
        } else {
          $("#btn_save_change_password").hide();
        }
      });
    });
  },

  methods: {
    //password method apply
    passwordcheck() {
      //const pass=this.NEW_PASS;
      if (this.OLD_PASSWORD === this.NEW_PASSWORD) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    //otp generate

    otpgenerate() {
      let GET_SYS_ID = JSON.parse(localStorage.getItem("GET_SYS_ID"));
      //console.log("sys", GET_SYS_ID);
      this.axios
        .get(apiEndPoints.otp_verification, {
          params: {
            ITEM: "MAIL_CONFIG",
            TEMPLATE_NAME: "FORGOT_PASSWORD",
            PARAMETER: GET_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("r", response);
          if (response.data.status == "true") {
            this.user_details = response.data.otp;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //password change
    savepass() {
      let GET_SYS_ID = JSON.parse(localStorage.getItem("GET_SYS_ID"));
      this.save_pass_details["USER_SYS_ID"] = GET_SYS_ID;
      this.save_pass_details["OTP"] = this.user_details;

      this.axios
        .post(apiEndPoints.otp_verify_save_password, this.save_pass_details)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(
              true,
              "success",
              "Password reset successfully"
            );
            this.$router.push({ name: "Login" });
            $cookies.set("_token", response.data.Token, 0);

            localStorage.setItem(
              "lek_user_details",
              JSON.stringify(response.data)
            );
            //this.$router.push({ name: "login" });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.Response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
