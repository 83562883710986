<template>
  <div>
    <div class="Invoice-description master-table box mt-4">
      <div class="row">
        <div class="col-md-12 offset-md-11">
          <button
            type="submit"
            value=""
            id=""
            class="custom-btn add-btn"
            name=""
            data-bs-toggle="modal"
            data-bs-target="#master_invoice_des_modal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-plus-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="8" x2="12" y2="16"></line>
              <line x1="8" y1="12" x2="16" y2="12"></line>
            </svg>
          </button>
        </div>
      </div>
      <table class="table table-bordered mt-3">
        <thead>
          <tr style="background: #ebd8c3">
            <th>SL. No.</th>
            <th scope="col">Description</th>
            <th scope="col">Code</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tax_invoice" :key="item.ITEM_LIST_SYS_ID">
            <td>{{ index + 1 }}</td>
            <td>{{ item.DESCRIPTION }}</td>
            <td>{{ item.CODE }}</td>
            <td class="table-btn">
              <button
                type="button"
                class="custom-btn p-0"
                @click="showModal(item, '#master_invoice_des_edit_modal')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit"
                >
                  <path
                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                  ></path>
                  <path
                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                  ></path>
                </svg>
              </button>
              <button
                type="button"
                class="custom-btn p-0"
                @click="showModal(item, '#master_invoice_des_delete_modal')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path
                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                  ></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- crate bank details modal -->

    <div
      class="modal modal-right fade"
      id="master_invoice_des_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Tax Invoice</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <ValidationObserver ref="invoiceform" v-slot="{ invalid }">
                  <form>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Description</label>
                        <input
                          type="text"
                          name="Description"
                          placeholder="Enter Description"
                          v-model="invoice_description_details.DESCRIPTION"
                          @change="handleInputOnKeyup"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              invoice_description_details.DESCRIPTION != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Code</label>
                        <input
                          type="text"
                          name="Code"
                          placeholder="Enter Code"
                          v-model="invoice_description_details.CODE"
                          @change="handleInputOnKeyup"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              invoice_description_details.CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <!-- <ValidationProvider
                                     rules="required"
                                    v-slot="{ errors }">
                                    <div class="col-md-12 mt-4">
                                        <label class="frm-label">GST Applicable</label>
                                        <select
                                            name="item"
                                          v-model="invoice_description_details.GST_APPLICABLE"
                                            class="form-control"                                                                                   
                                          >
                                           
                                            <option>Yes</option>
                                            <option>No</option>
                                             
                                           
                                          </select>
                                        <div class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                    </ValidationProvider>                                                             -->

                    <div class="modal-footer-2">
                      <div class="row mt-4">
                        <div class="col-md-5">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            :disabled="invalid"
                            @click="createItemDetails"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            @click="emptyfu"
                            class="custom-btn custom-danger-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- edit modal -->

    <div
      class="modal modal-right fade"
      id="master_invoice_des_edit_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Tax Invoice</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <ValidationObserver ref="invoiceeditform" v-slot="{ invalid }">
                  <form>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Description</label>
                        <input
                          type="text"
                          name="Description"
                          placeholder="Enter Description"
                          v-model="selected_row.DESCRIPTION"
                          @change="handleInputOnKeyupEdit"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              selected_row.DESCRIPTION != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-12 mt-4">
                        <label class="frm-label">Code</label>
                        <input
                          type="text"
                          name="Code"
                          placeholder="Enter Code"
                          v-model="selected_row.CODE"
                          @change="handleInputOnKeyupEdit"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == '' && selected_row.CODE != null,
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>

                    <div class="modal-footer-2">
                      <div class="row mt-4">
                        <div class="col-md-5">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            :disabled="invalid"
                            @click="updateItemDetails"
                          >
                            Update
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            class="custom-btn custom-danger-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--- delete modal -->

    <div class="modal" id="master_invoice_des_delete_modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete ?</p>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="deleteItemDetails"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      tax_invoice: [],

      invoice_description_details: {
        ITEM: "ADD",
        RECORD_SYS_ID: "0",
        DESCRIPTION: "",
        CODE: "",
        CREATED_BY: "",
      },
      selected_row: {},
      errors: [],
    };
  },

  mounted() {
    this.getTaxInvoiceDetails();
  },

  methods: {
    emptyfu() {
      this.invoice_description_details.DESCRIPTION = "";
      this.invoice_description_details.CODE = "";
    },
    getTaxInvoiceDetails() {
      //console.log("func call");
      this.axios
        .get(apiEndPoints.item_description, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("itemdescription", response);

          if (response.data.status == "true") {
            this.tax_invoice = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.tax_invoice = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    createItemDetails() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.invoice_description_details["CREATED_BY"] = user_details.UserId;

      this.$refs.invoiceform.validate().then((success) => {
        if (!success) {
          return;
        }
        this.axios
          .post(
            apiEndPoints.add_update_item_description,
            this.invoice_description_details
          )
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              $("#master_invoice_des_modal").modal("hide");
              this.getTaxInvoiceDetails();
              this.emptyfu();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })

          .catch((err) => {
            //console.log(err);
          });
      });
    },

    handleInputOnKeyup() {
      this.invoice_description_details.DESCRIPTION =
        this.invoice_description_details.DESCRIPTION.toUpperCase();
      this.invoice_description_details.CODE =
        this.invoice_description_details.CODE.toUpperCase();
    },

    handleInputOnKeyupEdit() {
      this.selected_row.DESCRIPTION =
        this.selected_row.DESCRIPTION.toUpperCase();
      this.selected_row.CODE = this.selected_row.CODE.toUpperCase();
    },

    showModal(row, id) {
      this.selected_row = row;
      $(id).modal("show");
    },

    updateItemDetails() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let update_payload = {
        ITEM: "UPDATE",
        RECORD_SYS_ID: this.selected_row.ITEM_LIST_SYS_ID,
        DESCRIPTION: this.selected_row.DESCRIPTION,
        CODE: this.selected_row.CODE,
        CREATED_BY: user_details.UserId,
      };

      this.$refs.invoiceeditform.validate().then((success) => {
        if (!success) {
          return;
        }
        this.axios
          .post(apiEndPoints.add_update_item_description, update_payload)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              $("#master_invoice_des_edit_modal").modal("hide");
              this.getTaxInvoiceDetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      });
    },

    deleteItemDetails() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let delete_payload = {
        ITEM: "DELETE",
        RECORD_SYS_ID: this.selected_row.ITEM_LIST_SYS_ID,
        CREATED_BY: user_details.UserId,
      };
      this.axios
        .post(apiEndPoints.add_update_item_description, delete_payload)
        .then((response) => {
          $("#master_invoice_des_delete_modal").modal("hide");
          this.getTaxInvoiceDetails();
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
