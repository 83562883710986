<template>
  <div>
    <div>
      <h4><b>Module</b></h4>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data">
          <div class="Sytem-role master-table box mt-4">
            <div class="row">
              <div class="col-md-12 offset-md-11">
                <button
                  type="submit"
                  value=""
                  id=""
                  class="custom-btn add-btn"
                  name=""
                  data-bs-toggle="modal"
                  data-bs-target="#master_system_role_modal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-plus-circle"
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="8" x2="12" y2="16"></line>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                  </svg>
                </button>
              </div>
            </div>

            <table class="table table-bordered mt-3">
              <thead>
                <tr>
                  <th>SL. No.</th>
                  <th scope="col">Module Name</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody
                v-for="(module_master_detail, index) in module_master_details"
                :key="module_master_detail.MODULE_SYS_ID"
              >
                <tr>
                  <td>{{ index + 1 }}</td>
                  <td>{{ module_master_detail.MODULE_NAME }}</td>
                  <td class="table-btn" colspan="2">
                    <button
                      type="button"
                      class="custom-btn p-0"
                      @click="
                        showModal(
                          module_master_detail,
                          '#master_system_role_edit_modal'
                        )
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-edit"
                      >
                        <path
                          d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                        ></path>
                        <path
                          d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                        ></path>
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="custom-btn p-0"
                      @click="
                        showModal(
                          module_master_detail,
                          '#module_master_detail_delete_modal'
                        )
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-trash-2"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path
                          d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                        ></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--create  modal -->

    <div
      class="modal modal-right fade"
      id="master_system_role_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Module</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <ValidationObserver ref="bankform" v-slot="{ invalid }">
                  <form>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="col-md-8 mt-4">
                        <label class="frm-label">Module Name</label>
                        <input
                          type="text"
                          name="Module Name"
                          placeholder="Enter Module Name"
                          v-model="create_master_mod.MODULE_NAME"
                          class="form-control"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_master_mod.MODULE_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label">Is Sub-module Present? </label>
                      <select
                        name="select"
                        v-model="create_master_mod.IS_SUBMODULE_PRESENT"
                        @change="showUrlBox"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_master_mod.IS_SUBMODULE_PRESENT != '',
                        }"
                      >
                        <option value="">Select</option>
                        <option value="1">YES</option>
                        <option value="0">NO</option>
                      </select>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>

                    <div v-show="showurl" class="col-md-8 mt-4">
                      <label class="frm-label">View URL</label>
                      <input
                        type="text"
                        name="View URL"
                        placeholder="Enter URL"
                        v-model="create_master_mod.VIEW_URL"
                        class="form-control"
                      />
                    </div>

                    <div class="modal-footer-2">
                      <div class="row mt-4">
                        <div class="col-md-5">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            :disabled="invalid"
                            @click="createSystemRole"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="custom-btn custom-danger-btn"
                            data-bs-dismiss="modal"
                            @click="emptyfu"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!--edit  modal -->

    <div
      class="modal modal-right fade"
      id="master_system_role_edit_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Edit Module Name</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <ValidationObserver ref="bankform2" v-slot="{ invalid }">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="col-md-8 mt-4">
                      <label class="frm-label">Module Name</label>
                      <input
                        type="text"
                        name="Module Name"
                        placeholder="Enter Module Name"
                        v-model="selected_row.MODULE_NAME"
                        class="form-control"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && selected_row.MODULE_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }">
                    <label class="frm-label">Is Sub-module Present? </label>
                    <select
                      name="select"
                      @change="showUrlBoxEdit"
                      v-model="selected_row.IS_SUBMODULE_PRESENT"
                      class="form-control"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null &&
                          selected_row.IS_SUBMODULE_PRESENT != '',
                      }"
                    >
                      <option value="">Select</option>
                      <option value="1">YES</option>
                      <option value="0">NO</option>
                    </select>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>

                  <div v-show="showurledit" class="col-md-8 mt-4">
                    <label class="frm-label">View URL</label>
                    <input
                      type="text"
                      name="View URL"
                      placeholder="Enter URL"
                      v-model="selected_row.VIEW_URL"
                      class="form-control"
                    />
                  </div>
                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-5">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          :disabled="invalid"
                          @click="updateSystemRole"
                        >
                          update
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--- delete modal-->
    <div class="modal" id="module_master_detail_delete_modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Delete</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete ?</p>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="deleteSystemRole2"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";

import { Global } from "@/global.js";
export default {
  data() {
    return {
      module_master_details: [],

      create_master_mod: {
        ITEM: "ADD",
        RECORD_SYS_ID: "0",
        MODULE_NAME: "",
        IS_SUBMODULE_PRESENT: "",
        VIEW_URL: "",
        CREATED_BY: "",
      },
      selected_row: {},
      showurl: false,
      showurledit: false,
    };
  },

  mounted() {
    this.getSystemRoleDetails();
  },

  methods: {
    emptyfu() {
      $("#master_system_role_modal").modal("hide");
      this.create_master_mod.MODULE_NAME = "";
    },

    getSystemRoleDetails() {
      this.axios
        .get(apiEndPoints.view_module, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.module_master_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    createSystemRole() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_master_mod["CREATED_BY"] = user_details.UserId;
      //console.log("rj", JSON.stringify(this.create_master_mod));

      this.axios
        .post(apiEndPoints.create_update_module, this.create_master_mod)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#master_system_role_modal").modal("hide");

            this.getSystemRoleDetails();
            this.emptyfu();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {
          //console.log(err);
        });
    },

    showUrlBox() {
      var yn = this.create_master_mod.IS_SUBMODULE_PRESENT;
      if (yn === "0") {
        this.showurl = true;
      } else {
        this.showurl = false;
      }
    },

    showUrlBoxEdit() {
      var yne = this.selected_row.IS_SUBMODULE_PRESENT;
      if (yne === "0") {
        this.showurledit = true;
      } else {
        this.showurledit = false;
      }
    },
    showModal(row, id) {
      this.selected_row = row;
      $(id).modal("show");
    },

    updateSystemRole() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let update_payload = {
        ITEM: "UPDATE",
        RECORD_SYS_ID: this.selected_row.MODULE_SYS_ID,
        MODULE_NAME: this.selected_row.MODULE_NAME,
        IS_SUBMODULE_PRESENT: this.selected_row.IS_SUBMODULE_PRESENT,
        VIEW_URL: this.selected_row.VIEW_URL,
        CREATED_BY: user_details.UserId,
      };

      this.axios
        .post(apiEndPoints.create_update_module, update_payload)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#master_system_role_edit_modal").modal("hide");
            this.getSystemRoleDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {
          //console.log(err);
        });
    },

    deleteSystemRole2() {
      //console.log("json");
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let delete_payload = {
        ITEM: "DELETE",
        RECORD_SYS_ID: this.selected_row.MODULE_SYS_ID,
        CREATED_BY: user_details.UserId,
      };
      this.axios
        .post(apiEndPoints.create_update_module, delete_payload)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#module_master_detail_delete_modal").modal("hide");
            this.getSystemRoleDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
