<template>
  <div
    style="
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 234, 220, 1) 100%
      );
      height: 100vh;
    "
  >
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 sm-pad" style="padding-right: 0">
            <div class="log-flex-wrap animate__animated animate__zoomIn">
              <div class="login-form-wrap">
                <img
                  src="../../assets/images/logo.png"
                  class="img-fluid mb-4 animate__animated animate__rubberBand"
                  alt="logo"
                  style="animation-delay: 0.2s"
                />
                <div class="form-label">
                  <label class="frm-label">Enter OTP</label>
                  <input
                    type="text"
                    v-model="user_verification.OTP"
                    class="form-control"
                    placeholder="Enter OTP"
                  />
                </div>
                <div>
                  <div class="form-label">
                    <a
                      class="login-btn"
                      @click="verifyEmai()"
                      style="cursor: pointer"
                      >Submit</a
                    >
                  </div>
                </div>
                <div>
                  <div class="form-label">
                    <a
                      class="login-btn"
                      @click="otpgenerate()"
                      style="cursor: pointer"
                      >Resend OTP</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      user_verification: {
        ITEM: "OTP_VERIFICATION",

        OTP: "",
      },
      user_name_details: {
        USER_NAME: "TemporaryToken",
        AUTH_KEY:
          "6481f8e1a060d56eeb7c10ac7809d316800dce013713c412e1d22076505b11a8",
      },
    };
  },
  mounted() {
    //this.otpgenerate()
  },
  methods: {
    forget() {
      this.axios
        .post(apiEndPoints.temporary_token, this.user_name_details)
        .then((response) => {
          //console.log(response);
          if (response.data.Status == null) {
            $cookies.set("temp_token", response.data.Token, 0);
            //this.$router.push({ name: "forget-pass" });
            localStorage.setItem(
              "lek_user_detai",
              JSON.stringify(response.data)
            );
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.Response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    otpgenerate() {
      let GET_SYS_ID = JSON.parse(localStorage.getItem("GET_SYS_ID"));
      //console.log("sys2", GET_SYS_ID);
      this.axios
        .get(apiEndPoints.otp_verification, {
          params: {
            ITEM: "MAIL_CONFIG",
            TEMPLATE_NAME: "FORGOT_PASSWORD",
            PARAMETER: GET_SYS_ID,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(
              true,
              "success",
              "Rsend otp sent succesfully"
            );
            //this.$router.push({ name: "otp-check" });
          }
          this.system_role_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //   otpgenerate() {

    //     this.axios.get(apiEndPoints.otp_verification, {

    //         params : {

    //           ITEM:"MAIL_CONFIG",
    //           TEMPLATE_NAME:"FORGOT_PASSWORD",
    //           PARAMETER:  this.otp_details

    //         }
    //         })
    //         .then(response => {

    //             //console.log("r",response);
    //              if (response.data.status == "true")
    //      {
    //       Global.showSuccessAlert(true, "success","otp sent succesfully");
    //     this.$router.push({ name: "otp-check" });
    //      }
    //             this.system_role_details = response.data.response;
    //         })
    //         .catch(err => {
    //             //console.log(err)
    //         });
    // },
    verifyEmai() {
      //console.log("hello");
      let customer_detaills = JSON.parse(localStorage.getItem("lek_user_deta"));
      let get_email_id = JSON.parse(localStorage.getItem("GET_EMAIL_ID"));
      this.user_verification["EMAIL_ID"] = get_email_id;
      ////console.log("riju2",get_email_id);
      //console.log("ms", JSON.stringify(this.user_verification));

      this.axios
        .post(apiEndPoints.verify_email, this.user_verification)
        .then((response) => {
          //console.log(response.data);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.$router.push({ name: "setnewpass" });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.data.status == false || err.data.status == "false") {
            Global.showErrorAlert(true, "error", err.data.response);
          }
          if (err.response.status != 401 || err.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    //     otpgenerate() {

    //       this.axios.get(apiEndPoints.otp_verification, {

    //           params : {

    //             ITEM:"MAIL_CONFIG",
    //             TEMPLATE_NAME:"FORGOT_PASSWORD",
    //             PARAMETER:216

    //           }
    //           })
    //           .then(response => {

    //               //console.log("r",response);
    //                if (response.data.status == "true")
    //        {
    //         Global.showSuccessAlert(true, "success", response.data.response);
    //         $cookies.set("_token", response.data.Token, 0);

    //        }
    //               this.system_role_details = response.data.response;
    //           })
    //           .catch(err => {
    //               //console.log(err)
    //           });
    //   },

    //   cancel() {
    // this.$router.push({ name: "Login" });
    // },
  },
};
</script>
