<template>
  <div>
    <div class="content-body-wrapper mt-4">
      <div>
        <h4><b>My Commission</b></h4>
      </div>
      <div class="page-content">
        <div class="row mb-3">
          <div class="col-md-4">
            <div class="">
              <label class="frm-label-2">Search Vehicle </label>
              <input
                type="text"
                class="form-control"
                v-model="VEHICLE_ID"
                @keyup.enter="searchvechicle(), newFunction()"
                placeholder="Enter Vehicle No."
              />
            </div>
          </div>
          <div class="col-md-2">
            <button
              style="
                margin-top: 18px;
                font-size: 13px;
                color: #fff;
                background-color: #754115;
              "
              type="button"
              @click="searchvechicle(), newFunction()"
              class="custom-btn btn btn-success"
            >
              <i class="fa fa-search" aria-hidden="true"></i> Search
            </button>
          </div>
        </div>
        <div class="d-flex">
          <label class="frm-label-2 align-right" v-show="truec"
            >Vehicle No:
            <span style="color: #000; margin-right: 1rem"
              >{{ this.vehicle_details.VEHICLE_NUMBER }}
            </span></label
          >
          <label class="frm-label-2 align-right" v-show="trueg"
            >Status:
            <span style="color: #000; margin-right: 1rem"
              >{{ this.vehicle_details.VEHICLE_STATUS }}
            </span></label
          >
          <label class="frm-label-2 align-right" v-show="truea"
            >Type:
            <span style="color: #000; margin-right: 1rem">{{
              this.vehicle_details.VEHICLE_TYPE
            }}</span></label
          >
          <label class="frm-label-2 align-right" v-show="truec"
            >Interim Status:
            <span style="color: #000; margin-right: 1rem">{{
              this.vehicle_details.INTERIM_STATUS
            }}</span></label
          >
          <br />
          <div>
            <table class="table table-bordered" v-show="truefalse">
              <thead>
                <tr style="background: #d4d4d4">
                  <th scope="col">Commison Amount($)</th>
                  <th scope="col">Commison Head</th>
                  <th scope="col">Commison To</th>
                  <th scope="col">Created Date</th>
                  <th scope="col">Handling</th>
                </tr>
              </thead>
              <tbody
                v-for="user_detail in user_deta"
                :key="user_detail.RECORD_SYS_ID"
              >
                <tr>
                  <td>{{ user_detail.COMMISSION_AMOUNT }}</td>
                  <td>{{ user_detail.COMMISSION_HEAD }}</td>
                  <td>{{ user_detail.COMMISSION_TO }}</td>
                  <td>{{ user_detail.CREATED_DATE }}</td>
                  <td>{{ user_detail.HANDLING }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      VEHICLE_NUMBER: "",
      truefalse: false,
      trueg: false,
      truea: false,
      trueb: false,
      truec: false,
      vehicle_details: {},
      VEHICLE_SYS_ID: "",
      VEHICLE_ID: "",
      user_deta: {},
      UserId: "",
      USER_SYS_ID: "",
      user_details: {},
    };
  },
  mounted() {
    this.getBrokerDetails();
  },
  methods: {
    searchvechicle() {
      this.axios
        .get(apiEndPoints.view_vehicle_number, {
          params: {
            ITEM: "SPECIFIC",

            //RECORD_SYS_ID:0,

            VEHICLE_NUMBER: this.VEHICLE_ID,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            this.trueg = true;
            this.truea = true;
            this.trueb = true;
            this.truec = true;
            this.vehicle_details = response.data.response[0];
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.truefalse = false;
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          this.truefalse = false;
          //console.log(err);
        });
    },
    //broker sys id generate
    getBrokerDetails() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            ITEM: "SPECIFIC",
            NAME: this.NAME,
            CONTACT_NO: this.CONTACT_NO,
            BROKER_TYPE: this.BROKER_TYPE,
            USER_TYPE: "EXTERNAL",
            PAGE_SIZE: 12,
          },
        })
        .then((response) => {
          //console.log("b", response.data.response);
          if (response.data.status == "true") {
            this.user_details = response.data.response[0];
            localStorage.setItem(
              "GET_SYS",
              JSON.stringify(response.data.USER_SYS_ID)
            );
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    newFunction() {
      let GET_SYS_ID = JSON.parse(localStorage.getItem("ets_user_details"));
      this.axios
        .get(apiEndPoints.my_commission_summarydetails, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.vehicle_details.VEHICLE_SYS_ID,
            USER_SYS_ID: GET_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("riju", response.data.response);
          if (response.data.status == "true") {
            this.truefalse = true;

            this.user_deta = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.user_deta = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
