import apiEndPoints from "../../endpoints.js";
import NProgress from "nprogress";
import { Global } from "@/global.js";

export const addincurance = {
  data() {
    return {
      truez: true,
      VEHICLE_NUM: "",
      AllCustomerArray: [],
      DisplayCustomerType: "",
      DisplayCustomerName: "",
      DisplayCustomerAddress: "",
      DisplayIdNumber: "",

      vehicle_details: {
        ENGINE_NO: "",
        CHASIS_NO: "",
        BILL_TO: "",
        ORIGINAL_REGISTRATION_DATE: "",
        MANUFACTURE_YEAR: "",
        CAPACITY: "",
        LADEN: "",
        UNLADEN: "",
        PAX: "",
        VEHICLE_MAKE: "",
        VEHICLE_MODEL: "",
        VEHICLE_MAKE_SYS_ID: "",
        VEHICLE_MODEL_SYS_ID: "",
        VEHICLE_REG_NO: "",
        VEHICLE_REG_NO: "",
        INSURANCE_TYPE: "",
        CUSTOMER_SYS_ID: "",
        VEHICLE_REG_NO: "",
        DEBIT_NOTE_NO: "",
        POLICY_NO: "",
        COVERAGE: "",
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        CREATED_BY: "",
        VEHICLE_TYPE: "",
      },
      insurance_details: [],

      vendorname: false,
      vendoraddress: false,
      customername: false,
      customeraddress: false,
      updat_user: {},
      create_company: {
        ITEM: "ADD",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        PERSON_INCHARGE: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "",
        COUNTRY_SYS_ID: "1",
        RECORD_SYS_ID: "0",
        OFFICE_NUMBER: "",
        CREATED_BY: "",
        ID_NUMBER: "",
        CUSTOMER_EMAIL: "",
      },
      update_user: {},
      create_customer: {
        ITEM: "ADD",
        NRIC_NUMBER: "",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        LAST_NAME: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "Singapore",
        COUNTRY_SYS_ID: "1",
        ID_NUMBER: "",
        CUSTOMER_EMAIL: "",
      },
      loading: false,
      driver_details: {
        DRIVER_NAME: "",
        OCCUPATION: "",
        DRIVING_PASS_DATE: "",
        RELATIONSHIP: "",
        I_C_NUMBER: "",
        DOB: "",
        CREATED_BY: "",
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        INSURANCE_ID: 35,
      },
      Manufracturename: [],
      Modeltype: "",
      user_details: "",
      CUSTOMER_SYS_ID: "",
      CUSTOMER_NAME: "",

      insurance_company_details: [],
      tax_info_details: [],

      ID: "",
      ADDRESS: "",
      DEBIT_NOTE_NO: "",
      POLICY_NO: "",
      insurance_details: [],

      selected_row: {},
      errors: [],
      show: false,

      disable: true,
      //for ID NO.
      vendorcode: "",
      vendordetails: {},
      ID_NUMBER: "",
      customerdetails: {},
      sellertype: "",
      customertype: "",
      all_vehicle_details: [],
      vehicle_number: "",
      is_disabled: false,
      particular_vehicle_details: {
        VEHICLE_STATUS: "",
        VEHICLE_MAKE_SYS_ID: "",
        MAKE_SYS_ID: "",
        MODEL_NAME: "",
        ENGINE_NO: "",
        CHASIS_NO: "",
        ORIGINAL_REGISTRATION_DATE: "",
        MANUFACTURE_YEAR: "",
        LADEN: "",
        UNLADEN: "",
        PAX: "",
      },
    };
  },

  mounted() {
    this.viewInsuranceDetails();

    this.getVehicledetails();
    this.autoPopulatetoAddInsuranceForm();
    this.viewInsuranceDetails();
    this.GetAllCustomerInfo();
    this.getVehicledetails();
    //this.viewInsuranceDetails()
    this.getManufracturename();
    this.getCustomer();
    // this.getTaxInfo();
    this.getInsuranceCompanyDetails();
    this.getvendordetails();
    this.getcustomerdetails();

    let yes = document.querySelector(".yes");
    let no = document.querySelector(".no");
    let calculatnbar = document.querySelector(".calculatnbar");

    yes.onclick = function () {
      calculatnbar.classList.add("active");
    };
    no.onclick = function () {
      calculatnbar.classList.remove("active");
    };

    let yes2 = document.querySelector(".yes2");
    let no2 = document.querySelector(".no2");
    let calculatnbar2 = document.querySelector(".calculatnbar2");

    yes2.onclick = function () {
      calculatnbar2.classList.add("active");
    };
    no2.onclick = function () {
      calculatnbar2.classList.remove("active");
    };

    let yes3 = document.querySelector(".yes3");
    let no3 = document.querySelector(".no3");
    let calculatnbar3 = document.querySelector(".calculatnbar3");

    yes3.onclick = function () {
      calculatnbar3.classList.add("active");
    };
    no3.onclick = function () {
      calculatnbar3.classList.remove("active");
    };

    let yes4 = document.querySelector(".yes4");
    let no4 = document.querySelector(".no4");
    let calculatnbar4 = document.querySelector(".calculatnbar4");

    yes4.onclick = function () {
      calculatnbar4.classList.add("active");
    };
    no4.onclick = function () {
      calculatnbar4.classList.remove("active");
    };

    let yes5 = document.querySelector(".yes5");
    let no5 = document.querySelector(".no5");
    let calculatnbar5 = document.querySelector(".calculatnbar5");

    yes5.onclick = function () {
      calculatnbar5.classList.add("active");
    };
    no5.onclick = function () {
      calculatnbar5.classList.remove("active");
    };
    $(document).ready(function () {
      $(".box").hide();
      $("#customertype")
        .change(function () {
          $(this)
            .find("option:selected")
            .each(function () {
              var optionValue = $(this).attr("value");
              if (optionValue) {
                $(".box")
                  .not("." + optionValue)
                  .hide();
                $("." + optionValue).show();
              } else {
                $(".box").hide();
              }
            });
        })
        .change();
    });
    $(".box2").hide();
    $("#customertypeupdate")
      .change(function () {
        $(this)
          .find("option:selected")
          .each(function () {
            var optionValue = $(this).attr("value");
            if (optionValue) {
              $(".box2")
                .not("." + optionValue)
                .hide();
              $("." + optionValue).show();
            } else {
              $(".box2").hide();
            }
          });
      })
      .change();

    $(document).ready(function () {
      $(".aliasadd").click(function (e) {
        e.preventDefault();
        $("#add_item").prepend(
          '<div class="row"><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">Driver Name</label></div><div class="col-md-8"><input type="text" class="form-control" placeholder=""></div></div></div><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">Occupation</label></div><div class="col-md-8"><input type="text" class="form-control" placeholder=""></div></div></div> <div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">Driving Pass Dt</label></div><div class="col-md-8"><input type="date" class="form-control"></div></div></div><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">Relationship</label></div><div class="col-md-8"><input type="text" class="form-control" placeholder=""></div></div></div><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">I/C Number</label></div><div class="col-md-8"><input type="text" class="form-control" placeholder=""></div></div></div><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">DOB</label></div><div class="col-md-8"><input type="date" class="form-control" placeholder=""></div></div></div><div class="col-md-1 mt-3"> <button type="button" class="custom-btn custom-danger-btn remove-itm-btn" id="aliasadd"><i class="fa fa-trash" aria-hidden="true"></i></button> </div></div>'
        );
      });
      $(document).on("click", ".remove-itm-btn", function (e) {
        e.preventDefault();
        let row_item = $(this).parent().parent();
        $(row_item).remove();
      });
    });
  },
  watch: {
    insurance_details: {
      handler: function (newValue, oldValue) {
        const insurance_id = this.$route.query.INSURANCE_DETAILS_SYS_ID;

        if (insurance_id) {
          let existInsuranceobj = newValue.find(
            (item) => item.INSURANCE_DETAILS_SYS_ID == insurance_id
          );

          if (existInsuranceobj) {
            this.autoPopulatetoAddInsuranceForm();
          }
        }
      },
    },
  },

  methods: {
    //Customer Info onKeyUp Function
    onKeyUpCustomerInfo() {
      if (this.sellertype == "") {
        this.DisplayCustomerType = "";
        this.DisplayIdNumber = "";
        this.DisplayCustomerName = "";
        this.DisplayCustomerAddress = "";
        this.vehicle_details.CUSTOMER_SYS_ID = "";
      }
    },
    //Customer Info onChange Function
    onChnageCustomerInfo() {
      let CustomerIdNumber = this.sellertype;
      if (CustomerIdNumber) {
        let cusObj = this.AllCustomerArray.find(
          (items) => items.CUSTOMER_NAME == CustomerIdNumber
        );
        this.DisplayCustomerType = cusObj.CUSTOMER_TYPE;
        this.DisplayIdNumber = cusObj.ID_NUMBER;
        this.DisplayCustomerName = cusObj.CUSTOMER_NAME;
        let address =
          cusObj.ADDRESS_LINE_1 +
          " ," +
          cusObj.COUNTRY_NAME +
          "-" +
          cusObj.PIN_CODE;
        this.DisplayCustomerAddress = address;
        this.vehicle_details.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
      }
    },
    //Get All Customer Info
    GetAllCustomerInfo() {
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 20,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.AllCustomerArray = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getVehicledetails() {
      this.axios
        .get(apiEndPoints.view_vehicle_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            VEHICLE_STATUS: "",
            VEHICLE_NUMBER: "",
            VEHICLE_MAKE: "",
            VEHICLE_MODEL: "",
            PAGE_SIZE: 1000,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.all_vehicle_details = response.data.response;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    createInsuranceDetails() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;

      let output_data = {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        COVERAGE: this.vehicle_details.COVERAGE,
        BILL_TO: this.vehicle_details.BILL_TO,
        CREATED_BY: user_details.UserId,
        POLICY_NO: this.vehicle_details.POLICY_NO,
        DEBIT_NOTE_NO: this.vehicle_details.DEBIT_NOTE_NO,

        CUSTOMER_SYS_ID: this.vehicle_details.CUSTOMER_SYS_ID,
        INSURANCE_TYPE: this.vehicle_details.INSURANCE_TYPE,
        VEHICLE_STATUS: this.particular_vehicle_details.VEHICLE_STATUS,
        MAKE_SYS_ID: this.particular_vehicle_details.VEHICLE_MAKE_SYS_ID,
        VEHICLE_MAKE: this.vehicle_details.VEHICLE_MAKE,
        VEHICLE_MODEL: this.particular_vehicle_details.MODEL_NAME,
        ENGINE_NO: this.particular_vehicle_details.ENGINE_NO,
        CHASIS_NO: this.particular_vehicle_details.CHASIS_NO,
        ORIGINAL_REGISTRATION_DATE:
          this.particular_vehicle_details.ORIGINAL_REGISTRATION_DATE,
        MANUFACTURE_YEAR: this.particular_vehicle_details.MANUFACTURE_YEAR,
        LADEN: this.particular_vehicle_details.LADEN,
        UNLADEN: this.particular_vehicle_details.UNLADEN,
        PAX: this.particular_vehicle_details.PAX,
        VEHICLE_REG_NO: this.vehicle_number,
      };
      this.axios
        .post(apiEndPoints.add_insurance_details, output_data)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);

            this.$router.push({ name: "viewinsurance" });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    getManufracturename() {
      this.axios
        .get(apiEndPoints.view_vehicle_manufacture_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          this.Manufracturename = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getCustomer() {
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 20,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.user_details = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getcustomerName(event) {
      let customer = event.target.value.split("-");
      this.vehicle_details.CUSTOMER_NAME = customer[0];
      this.vehicle_details.BILL_TO = parseInt(customer[1]);
    },

    getTaxInfo() {
      this.axios
        .get(apiEndPoints.tax_info, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.tax_info_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.tax_info_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getgstName() {
      this.vehicle_details.TAX_PERCENTAGE = this.tax_info_details.find(
        (o) => o.TAX_SYS_ID === this.vehicle_details.TAX_SYS_ID
      ).TAX_PERCENTAGE;
      this.tax_calcu();
      this.grosspremium_calcu();
    },

    add_driver() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.driver_details["CREATED_BY"] = user_details.UserId;
      this.axios
        .post(apiEndPoints.add_update_driver_details, this.driver_details)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {
          //console.log(err);
        });
    },

    showhide() {
      var ddlval = this.vehicle_details.INSURANCE_TYPE;
      if (ddlval == "Lek Auto INS") {
        this.show = true;
      } else {
        this.show = false;
      }
    },

    saferdisc_calcu() {
      var basicp = this.vehicle_details.BASE_PREMIUM;
      var perval = this.SAFER_DISC_PERCENTAGE;
      var saferdisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.vehicle_details.SAFER_DISC_VALUE = saferdisc;
    },

    loyaltydisc_calcu() {
      var basicp = this.vehicle_details.BASE_PREMIUM;
      var perval = this.LOYALITY_DISC_PERCENTAGE;
      var loyaltydisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.vehicle_details.LOYALITY_DISC_VALUE = loyaltydisc;
    },

    fleetdisc_calcu() {
      var basicp = this.vehicle_details.BASE_PREMIUM;
      var perval = this.FLEET_DISC_PERCENTAGE;
      var fleetdisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.vehicle_details.FLEET_DISC_VALUE = fleetdisc;
    },

    directdisc_calcu() {
      var basicp = this.vehicle_details.BASE_PREMIUM;
      var perval = this.DIRECT_DISC_PERCENTAGE;
      var directdisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.vehicle_details.DIRECT_DISC_VALUE = directdisc;
    },

    insurancedisc_calcu() {
      var basicp = this.vehicle_details.BASE_PREMIUM;
      var perval = this.INSURANCE_DISC_PERCENTAGE;
      var insurancedisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.vehicle_details.INSURANCE_DISC_VALUE = insurancedisc;
    },

    tax_calcu() {
      var basicp = this.vehicle_details.BASE_PREMIUM;
      var perval = this.vehicle_details.TAX_PERCENTAGE;
      var tax_amount = (parseInt(basicp) * parseInt(perval)) / 100;
      this.vehicle_details.TAX_AMOUNT = tax_amount;
    },

    grosspremium_calcu() {
      var basicp = this.vehicle_details.BASE_PREMIUM;
      var grosspremium =
        parseInt(basicp) +
        parseInt(this.vehicle_details.TAX_AMOUNT) -
        (parseInt(this.vehicle_details.SAFER_DISC_VALUE) +
          parseInt(this.vehicle_details.LOYALITY_DISC_VALUE) +
          parseInt(this.vehicle_details.FLEET_DISC_VALUE) +
          parseInt(this.vehicle_details.DIRECT_DISC_VALUE) +
          parseInt(this.vehicle_details.INSURANCE_DISC_VALUE) +
          parseInt(this.vehicle_details.NO_CLAIM_DISC));

      if (grosspremium != "NaN") {
        this.vehicle_details.FINAL_PREMIUM = grosspremium;
      }
    },

    getInsuranceCompanyDetails() {
      this.axios
        .get(apiEndPoints.insurance_company_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.insurance_company_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.insurance_company_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getInsuranceCompanyName() {
      this.vehicle_details.INSURANCE_COMPANY =
        this.insurance_company_details.find(
          (o) =>
            o.INSURANCE_COMPANY_SYS_ID ===
            this.vehicle_details.INSURANCE_COMPANY_SYS_ID
        ).INSURANCE_COMPANY;
    },

    getvendordetails() {
      this.axios
        .get(apiEndPoints.view_vendor, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            VENDOR_CODE: this.vendorcode,
            PAGE_SIZE: 10,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.vendordetails = response.data.response[0];
            this.vendorname = true;
            this.vendoraddress = true;
            this.vehicle_details.CUSTOMER_SYS_ID =
              response.data.response[0].VENDOR_SYS_ID;
          } else {
            this.vendordetails = "";
          }
        })
        .catch((err) => {
          //console.log(err);
          this.vendordetails = "";
        });
    },

    getcustomerdetails() {
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            ID_NUMBER: this.ID_NUMBER,
            PAGE_SIZE: 10,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.customerdetails = response.data.response[0];
            this.customername = true;
            this.customeraddress = true;
            this.vehicle_details.CUSTOMER_SYS_ID =
              response.data.response[0].CUSTOMER_SYS_ID;
          } else {
            this.customerdetails = "";
          }
        })
        .catch((err) => {
          //console.log(err);
          this.customerdetails = "";
        });
    },
    onchnagefun() {
      this.customerdetails = "";
      this.vendordetails = "";
      this.ID_NUMBER = "";
      this.vendorcode = "";
    },

    emptyfu() {
      $("#employee_modal").modal("hide");
      this.create_company.ADDRESS_LINE_1 = "";
      this.create_company.ADDRESS_LINE_2 = "";
      this.create_company.PERSON_INCHARGE = "";
      this.create_company.LAST_NAME = "";
      this.create_company.CUSTOMER_CONTACT = "";
      this.create_company.COUNTRY_NAME = "";
      this.create_company.PIN_CODE = "";
      this.create_company.ID_NUMBER = "";
      this.create_company.CUSTOMER_TYPE = "";
      this.create_company.COMPANY_NAME = "";
    },

    Addcustomer() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_customer["CREATED_BY"] = user_details.UserId;
      this.$refs.cus.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_customer)
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.GetAllCustomerInfo();
                this.getCustomer();
                this.emptyfu();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },

    Addcompany() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_company["CREATED_BY"] = user_details.UserId;
      this.create_company["CUSTOMER_TYPE"] = this.create_customer.CUSTOMER_TYPE;
      this.$refs.bankfo.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_company)
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getCustomer();
                this.emptyfu();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },

    onKeyUpVehicleInfo() {
      if (this.vehicle_number == "") {
        this.particular_vehicle_details = {
          VEHICLE_STATUS: "",
          VEHICLE_MAKE_SYS_ID: "",
          MAKE_SYS_ID: "",
          MODEL_NAME: "",
          ENGINE_NO: "",
          CHASIS_NO: "",
          ORIGINAL_REGISTRATION_DATE: "",
          MANUFACTURE_YEAR: "",
          LADEN: "",
          UNLADEN: "",
          PAX: "",
        };
        this.is_disabled = false;
      }
    },

    onChnageVehicleInfo() {
      let is_existed = this.all_vehicle_details.find(
        (items) => items.VEHICLE_NUMBER == this.vehicle_number
      );
      if (is_existed) {
        this.particular_vehicle_details = is_existed;
        this.getVehicleMakeName(is_existed.MAKE_SYS_ID);
        this.is_disabled = true;
      } else {
        this.is_disabled = false;
      }
    },

    //insurance get api call
    viewInsuranceDetails() {
      this.axios
        .get(apiEndPoints.view_insurance_details, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 100,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          this.insurance_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //function to autopopulate add insurance form
    autoPopulatetoAddInsuranceForm() {
      const insurance_id = this.$route.query.INSURANCE_DETAILS_SYS_ID;
      let existInsuranceobj = this.insurance_details.find(
        (item) => item.INSURANCE_DETAILS_SYS_ID == insurance_id
      );
      //console.log(existInsuranceobj, "existInsuranceobj");
      if (existInsuranceobj) {
        this.vehicle_details.INSURANCE_TYPE = existInsuranceobj.INSURANCE_TYPE;
        this.DisplayCustomerType = existInsuranceobj.CUSTOMER_NAME;
        this.sellertype = existInsuranceobj.CUSTOMER_NAME;
        this.DisplayIdNumber = existInsuranceobj.ID_NUMBER;
        this.DisplayCustomerName = existInsuranceobj.CUSTOMER_NAME;
        this.DisplayCustomerAddress = existInsuranceobj.ADDRESS_LINE_1;
        this.vehicle_details.DEBIT_NOTE_NO = existInsuranceobj.DEBIT_NOTE_NO;
        this.vehicle_details.POLICY_NO = existInsuranceobj.POLICY_NO;
        this.vehicle_details.COVERAGE = existInsuranceobj.COVERAGE;
        this.vehicle_details.BILL_TO = existInsuranceobj.BILL_TO;
        this.vehicle_number = existInsuranceobj.VEHICLE_REG_NO;

        this.particular_vehicle_details = this.all_vehicle_details.find(
          (items) => items.VEHICLE_NUMBER == this.vehicle_number
        );
      }
    },

    getVehicleMakeName(id) {
      let is_existed = this.Manufracturename.find(
        (items) => items.VEHICLE_MAKE_SYS_ID == id
      );
      if (is_existed) {
        this.vehicle_details.VEHICLE_MAKE = is_existed.VEHICLE_MAKE;
      }
    },
  },
  computed: {},
};
