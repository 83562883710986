<template>
  <div>
    <div v-if="loading">
      <div class="preloder loading_main_background" id="upload_loader">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>
    <div class="floating-heading">
      <h4><span>Customer</span></h4>
    </div>
    <div class="floating-search-btn-container">
      <button
        id="srch_btn"
        data-bs-toggle="modal"
        data-bs-target="#left_modal_sm"
      >
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
    </div>
    <div class="page-content">
      <div class="page-data">
        <div class="row mb-3">
          <div class="col-md-12 mb-1">
            <label class="frm-label-2" style="color: #a36626"
              ><i class="fa-solid fa-filter"></i> Filter</label
            >
          </div>

          <div class="row mb-3">
            <div class="row mb-3">
              <div class="col-md-2" v-if="statusvalue">
                <label class="frm-label-2" style="font-size: 15px"
                  >Customer Name:
                  <span class="filter-lbl">{{ statusvalue }}</span></label
                >
              </div>
              <div class="col-md-3" v-if="numbervalue">
                <label class="frm-label-2" style="font-size: 15px">
                  Mobile Number:
                  <span class="filter-lbl">{{ numbervalue }}</span></label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="floating-add-btn-container animate__animated animate__lightSpeedInRight"
    >
      <button
        id="add_btn"
        data-bs-toggle="modal"
        data-bs-target="#employee_modal"
      >
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
    <div class="animate__animated animate__slideInRight">
      <div class="content-body-wrapper mt-4">
        <div class="page-content">
          <div class="page-data">
            <div class="row">
              <div class="col-md-12 animate__animated animate__zoomIn">
                <div class="card-wrap shadow-main">
                  <div class="card-wrap-body">
                    <div class="data-table master-table">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>SL. No.</th>
                            <th>Customer Name</th>
                            <th>Customer Contact</th>
                            <th>ID Number</th>
                            <th>Customer Type</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(user_detail, index) in user_details"
                          :key="user_detail.RECORD_SYS_ID"
                        >
                          <tr>
                            <td>{{ index + 1 }}</td>
                            <td
                              @click="
                                editcustomer(
                                  user_detail,
                                  '#Updatecustomer_modal'
                                )
                              "
                            >
                              {{ user_detail.CUSTOMER_NAME }}
                            </td>
                            <td
                              @click="
                                editcustomer(
                                  user_detail,
                                  '#Updatecustomer_modal'
                                )
                              "
                            >
                              {{ user_detail.CUSTOMER_CONTACT }}
                            </td>
                            <td
                              @click="
                                editcustomer(
                                  user_detail,
                                  '#Updatecustomer_modal'
                                )
                              "
                            >
                              {{ user_detail.ID_NUMBER }}
                            </td>
                            <td
                              @click="
                                editcustomer(
                                  user_detail,
                                  '#Updatecustomer_modal'
                                )
                              "
                            >
                              {{ user_detail.CUSTOMER_TYPE }}
                            </td>
                            <td class="table-btn">
                              <button
                                type="button"
                                class="custom-btn me-1 p-0"
                                data-bs-toggle="modal"
                                data-bs-target="#view_frm"
                                @click="
                                  showDetails(
                                    user_detail,
                                    '#view_customer_modal'
                                  )
                                "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-eye"
                                >
                                  <path
                                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                  ></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              </button>
                              <button
                                type="button"
                                class="custom-btn p-0"
                                @click="
                                  editcustomer(
                                    user_detail,
                                    '#Updatecustomer_modal'
                                  )
                                "
                                v-if="menuPermissions.UPDATE_ACCESS"
                              ></button>
                              <button
                                type="button"
                                class="custom-btn p-0"
                                data-bs-toggle="modal"
                                data-bs-target="#delete_frm"
                                @click="Delete(user_detail)"
                                v-if="menuPermissions.DELETE_ACCESS"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                  ></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Customer Modal Start  -->
    <div
      class="modal modal-right fade"
      id="employee_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Customer</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6 mt-2">
                <label class="frm-label"
                  >Customer Type <span style="color: red">*</span></label
                >
                <select
                  id="customertype"
                  v-model="create_user.CUSTOMER_TYPE"
                  class="form-control"
                >
                  <option value="">Select</option>
                  <option value="individual">Individual</option>
                  <option value="Company">Company</option>
                </select>
              </div>
              <div class="individual box">
                <ValidationObserver ref="cus">
                  <div class="row">
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >NRIC Number <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="NRIC Number"
                          v-model.trim="create_user.ID_NUMBER"
                          class="form-control"
                          placeholder="Enter NRIC Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null && create_user.ID_NUMBER != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Customer Name
                          <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Customer Name"
                          v-model.trim="create_user.CUSTOMER_NAME"
                          class="form-control"
                          placeholder="Enter Customer Name"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_user.CUSTOMER_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Mobile Number</label>
                      <input
                        type="name"
                        name="Mobile.NO"
                        v-model.trim="create_user.CUSTOMER_CONTACT"
                        class="form-control"
                        placeholder="Enter Mobile Number"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Email ID</label>
                      <input
                        type="text"
                        name="Email Id"
                        v-model.trim="create_user.CUSTOMER_EMAIL"
                        class="form-control"
                        placeholder="Enter Email ID"
                      />
                    </div>

                    <div class="col-md-8 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Address <span style="color: red">*</span></label
                        >
                        <textarea
                          type="name"
                          style="height: 5em"
                          name="Address "
                          v-model.trim="create_user.ADDRESS_LINE_1"
                          class="form-control"
                          placeholder="Enter Address"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_user.ADDRESS_LINE_1 != '',
                          }"
                        >
                        </textarea>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >post Code <span style="color: red">*</span></label
                        >
                        <input
                          type="number"
                          name="Post code"
                          v-model.trim="create_user.PIN_CODE"
                          class="form-control"
                          placeholder="Enter Post Code"
                          maxlength="6"
                          minlength="6"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null && create_user.PIN_CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider v-slot="{ errors }">
                        <label class="frm-label"
                          >Country <span style="color: red">*</span></label
                        >
                        <b-form-select
                          name="Country"
                          v-model="create_user.COUNTRY_SYS_ID"
                          @change="getcountrysysid"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.COUNTRY_SYS_ID != '',
                          }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >-- Select --</b-form-select-option
                            >
                          </template>
                          <option value="" selected>Select</option>
                          <option
                            v-for="cost_type_info_detail in cost_type_info_details"
                            :value="cost_type_info_detail.COUNTRY_SYS_ID"
                            :key="cost_type_info_detail.COUNTRY_SYS_ID"
                          >
                            {{ cost_type_info_detail.COUNTRY_NAME }}
                          </option>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="modal-footer">
                      <div class="row mt-4">
                        <div class="col-md-3">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            @click="addIndividualTypeCustomer"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="custom-btn custom-danger-btn"
                            data-bs-dismiss="modal"
                            @click="resetAddCustomerForm"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>

              <div class="Company box">
                <ValidationObserver ref="bankfo">
                  <div class="row">
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >UEN Number <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="UEN Number"
                          v-model.trim="create_company.ID_NUMBER"
                          class="form-control"
                          placeholder="Enter UEN Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.ID_NUMBER != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Customer Name
                          <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Customer Name"
                          v-model.trim="create_company.CUSTOMER_NAME"
                          class="form-control"
                          placeholder="Enter Customer Name"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.CUSTOMER_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Person Incharge </label>
                      <input
                        type="name"
                        name="person incharge"
                        v-model.trim="create_company.PERSON_INCHARGE"
                        class="form-control"
                        placeholder="Enter Person incharge"
                      />
                    </div>

                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Mobile Number</label>
                      <input
                        type="name"
                        name="Mobile Number"
                        v-model.trim="create_company.CUSTOMER_CONTACT"
                        class="form-control"
                        placeholder="Enter Mobile Number"
                      />
                    </div>

                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Email ID</label>
                      <input
                        type="text"
                        name="gmail"
                        v-model.trim="create_company.CUSTOMER_EMAIL"
                        class="form-control"
                        placeholder="Enter Email ID"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >post Code <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Post code"
                          v-model.trim="create_company.PIN_CODE"
                          class="form-control"
                          placeholder="Enter Post Code"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.PIN_CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-8 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Address <span style="color: red">*</span></label
                        >
                        <textarea
                          type="name"
                          name="address "
                          v-model.trim="create_company.ADDRESS_LINE_1"
                          class="form-control"
                          placeholder="Enter Address"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.ADDRESS_LINE_1 != '',
                          }"
                        ></textarea>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider v-slot="{ errors }">
                        <label class="frm-label"
                          >Country <span style="color: red">*</span></label
                        >
                        <b-form-select
                          name="Country"
                          v-model="create_company.COUNTRY_SYS_ID"
                          @change="getcountrysyside"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              create_company.COUNTRY_SYS_ID != '',
                          }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >-- Select --</b-form-select-option
                            >
                          </template>
                          <option value="" selected>Singapore</option>
                          <option
                            v-for="cost_type_info_detail in cost_type_info_details"
                            :value="cost_type_info_detail.COUNTRY_SYS_ID"
                            :key="cost_type_info_detail.COUNTRY_SYS_ID"
                          >
                            {{ cost_type_info_detail.COUNTRY_NAME }}
                          </option>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="modal-footer">
                      <div class="row mt-4">
                        <div class="col-md-3">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            @click="addCompanyTypeCustomer"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="custom-btn custom-danger-btn"
                            data-bs-dismiss="modal"
                            @click="resetAddCustomerForm"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Customer Modal End  -->

    <!-- Update Customer Modal Start  -->
    <div
      class="modal modal-right fade"
      id="Updatecustomer_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Customer</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div
                class="col-md-6 mt-2"
                v-if="update_customer.CUSTOMER_TYPE != ''"
              >
                <label class="frm-label"
                  >Customer Type <span style="color: red">*</span></label
                >
                <select
                  id="customertypeupdate"
                  v-model="update_customer.CUSTOMER_TYPE"
                  class="form-control"
                  :disabled="true"
                >
                  <option value="">Select</option>
                  <option value="individual">Individual</option>
                  <option value="Company">Company</option>
                </select>
              </div>
              <div
                class="col-md-6 mt-2"
                v-if="update_company.CUSTOMER_TYPE != ''"
              >
                <label class="frm-label"
                  >Customer Type <span style="color: red">*</span></label
                >
                <select
                  :disabled="true"
                  id="customertypeupdate"
                  v-model="update_company.CUSTOMER_TYPE"
                  class="form-control"
                >
                  <option value="">Select</option>
                  <option value="individual">Individual</option>
                  <option value="Company">Company</option>
                </select>
              </div>

              <div
                class="individual box2"
                v-if="update_customer.CUSTOMER_TYPE == 'individual'"
              >
                <ValidationObserver ref="bankform2" v-slot="{ invalid }">
                  <div class="row">
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >NRIC Number <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="Vendor Name"
                          v-model.trim="update_customer.ID_NUMBER"
                          class="form-control"
                          placeholder="Enter NRIC Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_customer.ID_NUMBER != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Customer Name
                          <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Customer Name"
                          v-model="update_customer.CUSTOMER_NAME"
                          class="form-control"
                          placeholder="Enter Customer Name"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_customer.CUSTOMER_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Mobile Number </label>
                      <input
                        type="number"
                        name="Mobile Number"
                        v-model.trim="update_customer.CUSTOMER_CONTACT"
                        maxlength="8"
                        class="form-control"
                        placeholder="Enter Contact Number"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <label class="frm-label"> Email ID </label>
                      <input
                        type="text"
                        name="Email Id"
                        v-model.trim="update_customer.CUSTOMER_EMAIL"
                        class="form-control"
                        placeholder="Enter Email ID"
                      />
                    </div>
                    <div class="col-md-8 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Address <span style="color: red">*</span></label
                        >
                        <textarea
                          type="name"
                          style="height: 5em"
                          name="Address Line 1"
                          v-model.trim="update_customer.ADDRESS_LINE_1"
                          class="form-control"
                          placeholder="Enter Contact Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_customer.ADDRESS_LINE_1 != '',
                          }"
                        >
                        </textarea>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >post Code <span style="color: red">*</span></label
                        >
                        <input
                          type="number"
                          name="Pin code"
                          v-model.trim="update_customer.PIN_CODE"
                          class="form-control"
                          maxlength="6"
                          placeholder="Enter Contact Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_customer.PIN_CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Country <span style="color: red">*</span></label
                        >
                        <b-form-select
                          name="Country"
                          v-model="update_customer.COUNTRY_SYS_ID"
                          :class="{
                            'form-control': true,
                            required,
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_customer.COUNTRY_NAME != '',
                          }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >-- Select --</b-form-select-option
                            >
                          </template>
                          <option value="" selected>Singapore</option>
                          <option
                            v-for="cost_type_info_detail in cost_type_info_details"
                            :value="cost_type_info_detail.COUNTRY_SYS_ID"
                            :key="cost_type_info_detail.COUNTRY_SYS_ID"
                          >
                            {{ cost_type_info_detail.COUNTRY_NAME }}
                          </option>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="modal-footer-2">
                      <div class="row mt-4">
                        <div class="col-md-3 me-3">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            :disabled="invalid"
                            @click="updateIndividualTypeCustomer"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            class="custom-btn custom-danger-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>

              <div
                class="Company box2"
                v-if="update_company.CUSTOMER_TYPE == 'Company'"
              >
                <ValidationObserver ref="bankform2" v-slot="{ invalid }">
                  <div class="row">
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >UEN Number <span style="color: red">*</span></label
                        >
                        <input
                          type="text"
                          name="Vendor Name"
                          v-model.trim="update_company.ID_NUMBER"
                          class="form-control"
                          placeholder="Enter Nric Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_company.ID_NUMBER != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Customer Name
                          <span style="color: red">*</span></label
                        >
                        <input
                          type="name"
                          name="Customer Name"
                          v-model.trim="update_company.CUSTOMER_NAME"
                          class="form-control"
                          placeholder="Enter Customer Name"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_company.CUSTOMER_NAME != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Person Incharge </label>
                      <input
                        type="name"
                        name="Person Incharge"
                        v-model.trim="update_company.PERSON_INCHARGE"
                        class="form-control"
                        placeholder="Enter Person Incharge"
                      />
                    </div>

                    <div class="col-md-4 mt-3">
                      <label class="frm-label">Mobile Number</label>
                      <input
                        type="number"
                        name="Mobile.No"
                        v-model.trim="update_company.CUSTOMER_CONTACT"
                        class="form-control"
                        placeholder="Enter Mobile Number"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <label class="frm-label"> Email ID </label>
                      <input
                        type="text"
                        name="Email Id"
                        v-model.trim="update_company.CUSTOMER_EMAIL"
                        class="form-control"
                        placeholder="Enter Email ID"
                      />
                    </div>
                    <div class="col-md-4 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >post Code <span style="color: red">*</span></label
                        >
                        <input
                          type="number"
                          name="Post code"
                          maxlength="6"
                          v-model.trim="update_company.PIN_CODE"
                          class="form-control"
                          placeholder="Enter Contact Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_company.PIN_CODE != '',
                          }"
                        />
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-8 mt-3">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <label class="frm-label"
                          >Address <span style="color: red">*</span></label
                        >
                        <textarea
                          type="name"
                          style="height: 5em"
                          name="Address "
                          v-model.trim="update_company.ADDRESS_LINE_1"
                          class="form-control"
                          placeholder="Enter Contact Number"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_company.ADDRESS_LINE_1 != '',
                          }"
                        ></textarea>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>

                    <div class="col-md-4 mt-3">
                      <ValidationProvider v-slot="{ errors }">
                        <label class="frm-label"
                          >Country <span style="color: red">*</span></label
                        >
                        <b-form-select
                          name="Country"
                          v-model="update_company.COUNTRY_SYS_ID"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null &&
                              update_company.COUNTRY_NAME != '',
                          }"
                        >
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >-- Select --</b-form-select-option
                            >
                          </template>
                          <option value="" selected>Singapore</option>
                          <option
                            v-for="cost_type_info_detail in cost_type_info_details"
                            :value="cost_type_info_detail.COUNTRY_SYS_ID"
                            :key="cost_type_info_detail.COUNTRY_SYS_ID"
                          >
                            {{ cost_type_info_detail.COUNTRY_NAME }}
                          </option>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                        <div class="invalid-feedback">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                    <div class="modal-footer">
                      <div class="row mt-4">
                        <div class="col-md-3 me-3">
                          <button
                            type="button"
                            class="custom-btn custom-success-btn"
                            :disabled="invalid"
                            @click="updateCompanyTypeCustomer"
                          >
                            Save
                          </button>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            data-bs-dismiss="modal"
                            class="custom-btn custom-danger-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
    <!-- Update Customer Modal End  -->

    <!-- View Customer Modal Start  -->
    <div
      class="modal modal-right fade"
      id="view_customer_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 55% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">View Customer</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 mt-3">
                <label class="frm-label">Customer Type </label>
                <label>{{ view_customer.CUSTOMER_TYPE }}</label>
              </div>
              <div class="col-md-4 mt-3">
                <label class="frm-label">ID Number</label>
                <label>{{ view_customer.ID_NUMBER }}</label>
              </div>

              <div class="col-md-4 mt-3">
                <label class="frm-label">Customer Name </label>
                <label>{{ view_customer.CUSTOMER_NAME }}</label>
              </div>

              <div class="col-md-4 mt-3">
                <label class="frm-label">Person Incharge </label>
                <label>{{ view_customer.PERSON_INCHARGE }}</label>
              </div>

              <div class="col-md-4 mt-3">
                <label class="frm-label">Mobile Number </label>
                <label>{{ view_customer.CUSTOMER_CONTACT }}</label>
              </div>
              <div class="col-md-4 mt-3">
                <label class="frm-label">Post Code </label>
                <label>{{ view_customer.PIN_CODE }}</label>
              </div>

              <div
                class="col-md-12 mt-3"
                v-if="
                  view_customer.ADDRESS_LINE_1 ||
                  view_customer.ADDRESS_LINE_2 ||
                  view_customer.PIN_CODE
                "
              >
                <label class="frm-label">Address</label>
                <label>
                  <span v-if="view_customer.ADDRESS_LINE_1"
                    >{{ view_customer.ADDRESS_LINE_1 }} </span
                  >,
                  <span v-if="view_customer.COUNTRY_NAME"
                    >{{ view_customer.COUNTRY_NAME }} -
                  </span>
                  <span v-if="view_customer.PIN_CODE">{{
                    view_customer.PIN_CODE
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- View Customer Modal End  -->
    <div
      class="modal modal-left fade"
      id="left_modal_sm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="left_modal_sm"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search By</h5>
          </div>
          <div class="modal-body search-panel">
            <div class="row">
              <div class="col-md-12">
                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="all"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">All Combinations</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="status"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Customer Name</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="number"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Customer Number</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="make"
                    class="rad-input"
                    v-model="search_option"
                  />
                </label>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'status'"
              >
                <label class="frm-label">Customer name</label>
                <input
                  type="text"
                  v-model="INSURANCE_TYPE"
                  class="form-control"
                  placeholder="Enter Customer Name"
                />
              </div>
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'number'"
              >
                <label class="frm-label">Mobile Number</label>
                <input
                  type="text"
                  v-model="VEHICLENUMBER"
                  class="form-control"
                  placeholder="Enter Mobile No."
                />
              </div>

              <div class="col-md-12 mt-2" v-if="search_option">
                <div class="btn-conainer">
                  <button
                    type="button"
                    @click="getCustomer()"
                    id="srch-btn"
                    class="custom-btn custom-success-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NProgress from "nprogress";
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
export default {
  data() {
    return {
      search_option: "",
      loading: false,
      user_details: [],
      cost_type_info_details: [],
      NAME: null,
      create_user: {
        ITEM: "ADD",
        NRIC_NUMBER: "",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        PIN_CODE: "",
        COUNTRY_NAME: "Singapore",
        COUNTRY_SYS_ID: "1",
        ID_NUMBER: "",
        CUSTOMER_EMAIL: "",
      },
      create_company: {
        ITEM: "ADD",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        PERSON_INCHARGE: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        PIN_CODE: "",
        COUNTRY_NAME: "Singapore",
        COUNTRY_SYS_ID: "1",
        RECORD_SYS_ID: "0",
        OFFICE_NUMBER: "",
        CREATED_BY: "",
        ID_NUMBER: "",
        CUSTOMER_EMAIL: "",
      },
      search_option: "",
      statusvalue: false,
      vehicletype: "",
      INSURANCE_TYPE: "",
      view_customer: [],
      selected_row: {},
      update_customer: {},
      update_company: {},
      vehicledetails: {},
      vehicledetails_page_no: 1,
      CUSTOMER_NAME: "",
      CUSTOMER_CONTACT: "",
    };
  },
  mounted() {
    $(document).ready(function () {
      $(".box").hide();
      $("#customertype")
        .change(function () {
          $(this)
            .find("option:selected")
            .each(function () {
              var optionValue = $(this).attr("value");
              if (optionValue) {
                $(".box")
                  .not("." + optionValue)
                  .hide();
                $("." + optionValue).show();
              } else {
                $(".box").hide();
              }
            });
        })
        .change();
    });
    $(".box2").hide();
    $("#customertypeupdate")
      .change(function () {
        $(this)
          .find("option:selected")
          .each(function () {
            var optionValue = $(this).attr("value");
            if (optionValue) {
              $(".box2")
                .not("." + optionValue)
                .hide();
              $("." + optionValue).show();
            } else {
              $(".box2").hide();
            }
          });
      })
      .change();

    this.getCustomer();
    this.getCountryInfo();
  },
  methods: {
    //Reset Add Customer Form
    resetAddCustomerForm() {
      $("#employee_modal").modal("hide");
      this.create_user.ADDRESS_LINE_1 = "";
      this.create_user.CUSTOMER_CONTACT = "";
      this.create_user.COUNTRY_NAME = "";
      this.create_user.CUSTOMER_NAME = "";
      this.create_user.PIN_CODE = "";
      this.create_user.ID_NUMBER = "";
      this.create_user.CUSTOMER_TYPE = "";
      this.create_user.EMAIL_ID = "";

      this.create_company.ADDRESS_LINE_1 = "";
      this.create_company.PERSON_INCHARGE = "";
      this.create_company.CUSTOMER_CONTACT = "";
      this.create_company.PIN_CODE = "";
      this.create_company.ID_NUMBER = "";
      this.create_company.CUSTOMER_TYPE = "";
      this.create_company.CUSTOMER_NAME = "";
      this.create_company.EMAIL_ID = "";
    },

    //Delete Customer Data
    async Delete(data) {
      const result = await Global.showConfirmationAlert(
        `Delete Customer ${data.CUSTOMER_NAME}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        NProgress.start();
        NProgress.set(0.1);
        this.loading = true;
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.CUSTOMER_SYS_ID,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.create_update_customer, postarrydata)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getCustomer();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          })
          .finally(() => {
            NProgress.done();
            this.loading = false;
          });
      }
    },

    filtershowhide() {
      if (this.NAME != "") {
        this.makevalue = true;
      } else {
        this.makevalue = false;
      }
      if (this.CONTACT_NO != "") {
        this.modelvalue = true;
      } else {
        this.modelvalue = false;
      }
    },

    onlynumber($event) {
      let keycode = $event.keycode ? $event.keycode : $event.which;
      if ((keycode < 48 || keycode > 57) && keycode !== 46) {
        $event.preventDefault();
      }
    },

    //Add Individual Type Customer
    addIndividualTypeCustomer() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_user["CREATED_BY"] = user_details.UserId;
      // //console.log(
      //   "addIndividualTypeCustomer obj-",
      //   JSON.stringify(this.create_user)
      // );
      this.$refs.cus.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          NProgress.start();
          NProgress.set(0.1);
          this.loading = true;
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_user)
            .then((response) => {
              //console.log("addIndividualTypeCustomer Response -", response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getCustomer();
                this.resetAddCustomerForm();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .catch((err) => {
              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    //Add Company Type Customer
    addCompanyTypeCustomer() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_company["CREATED_BY"] = user_details.UserId;
      this.create_company["CUSTOMER_TYPE"] = this.create_user.CUSTOMER_TYPE;
      //console.log(JSON.stringify(this.create_company));
      this.$refs.bankfo.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          NProgress.start();
          NProgress.set(0.1);
          this.loading = true;
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_company)
            .then((response) => {
              //console.log(response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getCustomer();
                this.resetAddCustomerForm();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .catch((err) => {
              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    getcountrysysid() {
      //console.log("riju");
      this.create_user.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.create_user.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
      this.update_customer.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.update_user.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
    },

    getcountrysyside() {
      //console.log("riju");
      this.create_company.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.create_company.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
      this.update_company.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.update_user.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
    },

    //Update Individual Type Customer
    updateIndividualTypeCustomer() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.update_customer["CREATED_BY"] = user_details.UserId;
      this.update_customer["ITEM"] = "UPDATE";
      this.update_customer["RECORD_SYS_ID"] =
        this.update_customer.CUSTOMER_SYS_ID;
      this.axios
        .post(apiEndPoints.create_update_customer, this.update_customer)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getCustomer();
            $("#Updatecustomer_modal").modal("hide");
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    ////Update Company Type Customer
    updateCompanyTypeCustomer() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.update_company["CREATED_BY"] = user_details.UserId;
      this.update_company["ITEM"] = "UPDATE";
      this.update_company["RECORD_SYS_ID"] =
        this.update_company.CUSTOMER_SYS_ID;
      this.axios
        .post(apiEndPoints.create_update_customer, this.update_company)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getCustomer();
            $("#Updatecustomer_modal").modal("hide");
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    //Get Customer List
    getCustomer() {
      this.filtershowhide();
      if (this.search_option == "all") {
        this.statusvalue = this.INSURANCE_TYPE;
        this.numbervalue = this.VEHICLENUMBER;
      } else {
        if (this.search_option == "status")
          this.statusvalue = this.INSURANCE_TYPE;
        if (this.search_option == "number")
          this.numbervalue = this.VEHICLENUMBER;
      }
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "SPECIFIC",
            CUSTOMER_NAME: this.INSURANCE_TYPE,
            CUSTOMER_CONTACT: this.VEHICLENUMBER,
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 100,
            PAGE_NO: 10,
          },
        })
        .then((response) => {
          //console.log("getCustomer response-", response);
          this.user_details = response.data.response;
          localStorage.setItem(
            "Customer_sys_id",
            JSON.stringify(response.data)
          );
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    showDetails(row, id) {
      this.view_customer = row;
      //console.log(row);
      $(id).modal("show");
    },

    //Edit Customer onClick Row
    editcustomer(row, id) {
      if (row.CUSTOMER_TYPE == "Company") {
        this.update_company = row;
        this.update_customer = {};
        this.update_customer.CUSTOMER_TYPE = "";
      } else {
        this.update_customer = row;
        this.update_company = {};
        this.update_company.CUSTOMER_TYPE = "";
      }
      $(id).modal("show");
    },
  },

  computed: {
    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
    getCountryInfo() {
      this.axios
        .get(apiEndPoints.view_country, {
          params: { ITEM: "VIEW_ALL", RECORD_SYS_ID: 0 },
        })
        .then((response) => {
          //console.log(response);
          //this.cost_type_info_details = response.data.response;
          if (response.data.status == "true") {
            this.cost_type_info_details = response.data.response;
            this.$nextTick(() => {
              this.create_user.COUNTRY_SYS_ID =
                this.cost_type_info_details[0].COUNTRY_SYS_ID;
              this.create_company.COUNTRY_SYS_ID =
                this.cost_type_info_details[0].COUNTRY_SYS_ID;

              this.getcountrysysid();
              this.getcountrysyside();
            });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.cost_type_info_details = [];
            this.create_user.COUNTRY_SYS_ID = null;
            this.create_company.COUNTRY_SYS_ID = null;
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.loading_main_background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(42, 42, 43, 0.45);
  z-index: 9999;
  /* display: none; */
}
/* .preloder {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgb(0, 155, 254);
    z-index: 9999;
} */

.preloder img {
  width: auto;
  margin: 12% 38%;
  border-radius: 50%;
  opacity: 0.6;
}
.loading_main_background img {
  width: 5% !important;
  margin: 20% 50% !important;
  opacity: 1 !important;
  position: absolute;
}
img {
  vertical-align: middle;
  border-style: none;
}
li.nav-item {
  width: 10em !important;
  text-align: center !important;
}
.downld {
  background: transparent;
  outline: none;
  border: none;
  color: #112b3c;
  font-weight: 600;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  float: right !important;
  font-size: 13px;
  margin-top: 5px;
}
.animate__bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
</style>
