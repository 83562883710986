<template>
  <div class="wrapper">
    <div class="container-fluid d-flex">
      <div class="content-body-wrapper mt-4">
        <!-- <div class="page-content p-0"> -->
        <div class="page-data m-0">
          <div class="profile-page-cont animate__animated animate__zoomIn">
            <div class="card-wrap">
              <div class="row card-wrap-body">
                <div class="col-md-4">
                  <!-- <div class="profile-pic-sec">
                      <div class="picture">
                        <img
                          src="../../assets/images/profile.png"
                          class="img-fluid"
                          alt="profile-picture"
                        />
                      </div>
                      <form>
                        <button
                          type="button"
                          class="custom-btn custom-secondary-btn-4"
                        >
                          <i class="fa-solid fa-user-pen"></i> Change Profile
                          Picture
                        </button>
                      </form>
                    </div> -->

                  <div class="card-profile mt-2">
                    <div class="shadow-main profile-tabs">
                      <div
                        class="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <button
                          class="nav-link active"
                          id="v-pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                          @click="viewpersonalinfo"
                        >
                          Personal Info
                        </button>

                        <button
                          class="nav-link"
                          id="v-pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                        >
                          Change Password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div
                        class="card view_product_card tab-content product-card"
                        style="padding: 0 !important; margin-top: 0"
                      >
                        <div class="card-header filter-panel-wrap pt-2">
                          Personal Info
                        </div>
                        <div class="card-body mt-3">
                          <div class="row mb-4">
                            <div class="col-md-4">
                              <label class="frm-label">first name</label>
                              <label class="frm-label-val">{{
                                personal_info.FIRST_NAME
                              }}</label>
                            </div>

                            <div class="col-md-4">
                              <label class="frm-label">last name</label>
                              <label class="frm-label-val">{{
                                personal_info.LAST_NAME
                              }}</label>
                            </div>
                            <div class="col-md-4">
                              <label class="frm-label">gender</label>
                              <label class="frm-label-val">{{
                                personal_info.GENDER
                              }}</label>
                            </div>
                            <div class="col-md-4 mt-3">
                              <label class="frm-label">email</label>
                              <label class="frm-label-val">{{
                                personal_info.EMAIL_ID
                              }}</label>
                            </div>
                            <div class="col-md-4 mt-3">
                              <label class="frm-label">phone</label>
                              <label class="frm-label-val">{{
                                personal_info.MOBILE_NO
                              }}</label>
                            </div>
                            <div class="col-md-4 mt-3">
                              <label class="frm-label">country</label>
                              <label class="frm-label-val">{{
                                personal_info.COUNTRY_NAME
                              }}</label>
                            </div>
                            <div class="col-md-4 mt-3">
                              <label class="frm-label">post code</label>
                              <label class="frm-label-val">{{
                                personal_info.PIN_CODE
                              }}</label>
                            </div>
                            <div class="col-md-8 mt-3">
                              <label class="frm-label">address</label>
                              <label class="frm-label-val"
                                >{{ personal_info.ADDRESS_LINE_1 }}
                                {{ personal_info.ADDRESS_LINE_2 }}</label
                              >
                            </div>
                          </div>
                          <!-- <div class="row mb-3 mt-5">
                                                                                  <div class="col-md-2">
                                                                                        <button type="button" class="custom-btn custom-success-btn">Save</button>
                                                                                    </div>
                                                                                    <div class="col-md-2">
                                                                                        <button type="button" class="custom-btn custom-danger-btn">Cancel</button>
                                                                                    </div>
                                                                                </div> -->
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <div
                        class="card view_product_card tab-content product-card"
                        style="padding: 0 !important; margin-top: 0"
                      >
                        <div class="card-header filter-panel-wrap pt-2">
                          Change Password
                        </div>
                        <div class="card-body mt-3">
                          <div class="forgot-passsword-form mb-4">
                            <div class="container-fluid">
                              <div class="row">
                                <div class="col-md-6">
                                  <ul class="styled-li">
                                    <li id="length">
                                      Password length should be withnin 8 to 15
                                      characters.
                                    </li>
                                    <li id="case_low" class="mt-2">
                                      At least one lowercase character
                                    </li>
                                    <li id="case_up" class="mt-2">
                                      At least one uppercase character
                                    </li>
                                    <li id="special" class="mt-2">
                                      At least one special character
                                    </li>
                                    <li id="num" class="mt-2">
                                      At least one number character
                                    </li>
                                  </ul>
                                </div>
                                <div class="col-md-6">
                                  <label class="frm-label">old password</label>
                                  <div class="d-flex">
                                    <input
                                      :type="show_passwo ? 'text' : 'password'"
                                      maxLength="15"
                                      v-model="passwordata.OLD_PASSWORD"
                                      class="form-control pe-4 password"
                                      placeholder="Enter Old Password"
                                    />
                                    <button
                                      type="button"
                                      @click="show_passwo = !show_passwo"
                                      id="eye"
                                      onclick="togglePassword(this)"
                                      class="eye-btn border-0"
                                    >
                                      <i
                                        class="fa fa-eye"
                                        v-if="!show_passwo"
                                        aria-hidden="true"
                                        style="color: #000"
                                      ></i>
                                    </button>
                                  </div>

                                  <label class="frm-label">new password</label>
                                  <div class="d-flex">
                                    <input
                                      :type="show_passwor ? 'text' : 'password'"
                                      maxLength="15"
                                      id="new_pwd"
                                      v-model="passwordata.NEW_PASSWORD"
                                      @keyup="passwordcheck"
                                      class="form-control pe-4 password"
                                      placeholder="Enter New Password"
                                    />
                                    <button
                                      type="button"
                                      @click="show_passwor = !show_passwor"
                                      id="eye"
                                      onclick="togglePassword(this)"
                                      class="eye-btn border-0"
                                    >
                                      <i
                                        class="fa fa-eye"
                                        v-if="!show_passwor"
                                        aria-hidden="true"
                                        style="color: #000"
                                      ></i>
                                    </button>
                                  </div>
                                  <label class="frm-label mt-4"
                                    >repeat password</label
                                  >
                                  <div class="d-flex">
                                    <input
                                      :type="
                                        show_password ? 'text' : 'password'
                                      "
                                      maxLength="15"
                                      v-model="passwordata.REPEAT_PASS"
                                      @keyup="passwordcheck"
                                      class="form-control pe-4 password"
                                      placeholder="Repeat Password"
                                    />
                                    <button
                                      type="button"
                                      @click="show_password = !show_password"
                                      id=""
                                      onclick="togglePassword(this)"
                                      class="eye-btn eye border-0"
                                    >
                                      <i
                                        class="fa fa-eye"
                                        v-if="!show_password"
                                        aria-hidden="true"
                                        style="color: #000"
                                      ></i>
                                    </button>
                                  </div>
                                  <button
                                    type="button"
                                    @click="changePass"
                                    :disabled="invalid"
                                    id="btn_save_change_password"
                                    class="custom-btn custom-success-btn mt-4"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ends here  -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- right modal from here  -->
    <div
      class="modal modal-right fade"
      id="user1modal6"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 30%">
        <div class="modal-content">
          <div class="modal-header">
            <!-- <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> -->
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      passwordata: {
        OLD_PASSWORD: "",
        NEW_PASSWORD: "",
        REPEAT_PASS: "",
        USER_SYS_ID: "",
        ITEM: "USER_CHANGE_PASSWORD",
      },
      personal_info: {},

      // profile_info_details:{
      //   FIRST_NAME:"",
      //   LAST_NAME:"",
      //   GENDER:"",
      //   EMAIL_ID:"",
      //   COUNTRY_NAME:"",
      //   MOBILE_NO:"",
      //   PIN_CODE:"",
      //   ADDRESS_LINE_1:"",
      //   ADDRESS_LINE_2:"",
      // },

      invalid: true,
      show_passwo: false,
      show_passwor: false,
      show_password: false,
    };
  },

  mounted() {
    this.viewpersonalinfo();

    $(document).ready(function () {
      $(document).on("keyup", "#new_pwd", function () {
        var lower = new RegExp("^(?=.*[a-z])");
        var upper = new RegExp("^(?=.*[A-Z])");
        var digit = new RegExp("^(?=.*[0-9])");
        var special_char = new RegExp("^(?=.*[@#$%^&+=])");
        var length = new RegExp("^.{8,15}");
        var pass = $("#new_pwd").val();
        var condition = [];
        var condition_count = 0;
        // var tmp = length.test(pass);
        if (length.test(pass)) {
          $("#length").css("color", "#008000");

          condition_count += 1;
        } else {
          $("#length").css("color", "#FF0000");
        }

        if (lower.test(pass)) {
          $("#case_low").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#case_low").css("color", "#FF0000");
        }
        if (upper.test(pass)) {
          $("#case_up").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#case_up").css("color", "#FF0000");
        }
        if (digit.test(pass)) {
          $("#num").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#num").css("color", "#FF0000");
        }
        if (special_char.test(pass)) {
          $("#special").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#special").css("color", "#FF0000");
        }

        if (condition_count >= 5) {
          $("#btn_save_change_password").show();
        } else {
          $("#btn_save_change_password").hide();
        }
      });
    });
  },
  methods: {
    passwordcheck() {
      //const pass=this.NEW_PASS;
      if (this.NEW_PASS === this.REPEAT_PASS) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    changePass() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.passwordata.USER_SYS_ID = user_details.UserId;
      //console.log(this.passwordata);
      this.axios
        .post(apiEndPoints.change_password, this.passwordata)

        .then((response) => {
          //console.log(response.data);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.Response);

            this.$router.push({ name: "Login" });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.Response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.response.status != 401 || err.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    tokengenerate() {
      this.axios
        .post(apiEndPoints.login, {
          USER_NAME: this.passwordata.USER_NAME,
          PASSWORD: this.passwordata.PASSWORD,
        })
        .then((response) => {
          //console.log("sa", response);
          if (response.data.Status == "true") {
            this.user_details = response.data.response;
            //console.log("temp", this.user_details);

            $cookies.set("_token", response.data.Token, { expires: "23h" });

            localStorage.setItem(
              "lek_user_details",
              JSON.stringify(response.data)
            );
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            alert("hi");
            Global.showErrorAlert(true, "error", response.data.Response);
            this.$router.push({ name: "profile" });
          }
        })
        .catch((err) => {
          if (err.data.status == false || err.data.status == "false") {
            Global.showErrorAlert(true, "error", err.data.response);
            this.$router.push({ name: "profile" });
          }
          if (error.response.status != 401 || error.response.Status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    viewpersonalinfo() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));

      this.axios
        .get(apiEndPoints.user_profile_info, {
          params: {
            ITEM: "SPECIFIC",
            USER_SYS_ID: user_details.UserId,
          },
        })
        .then((response) => {
          //console.log("mon", response);

          this.personal_info = response.data.response[0];
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
