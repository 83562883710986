<template>
  <div>
    <div class="floating-heading">
      <h4><span>Employee</span></h4>
    </div>
    <div class="floating-search-btn-container">
      <button
        id="srch_btn"
        data-bs-toggle="modal"
        data-bs-target="#left_modal_sm"
      >
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
    </div>

    <div
      class="floating-add-btn-container animate__animated animate__lightSpeedInRight"
    >
      <button
        id="add_btn"
        data-bs-toggle="modal"
        data-bs-target="#employee_modal"
      >
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
    <div class="animate__animated animate__slideInRight">
      <!-- <div class="row show-hide-panel">
                     <div class="col-md-12 animate__animated animate__zoomIn">
                        <div class="card-wrap shadow-main">
                           <div class="card-wrap-body cus-body-wrap">
                              <div class="filter-panel-wrap"> -->
      <div class="content-body-wrapper mt-4">
        <div class="page-content">
          <div class="page-data">
            <div class="row">
              <div class="col-md-12 mb-1">
                <label class="frm-label-2" style="color: #a36626"
                  ><i class="fa-solid fa-filter"></i> Filter</label
                >
              </div>

              <div class="row mb-3">
                <div class="col-md-2" v-if="statusvalue">
                  <label class="frm-label-2" style="font-size: 15px"
                    >Person Name:
                    <span class="filter-lbl">{{ statusvalue }}</span></label
                  >
                </div>
                <div class="col-md-3" v-if="numbervalue">
                  <label class="frm-label-2" style="font-size: 15px">
                    Mobile Number:
                    <span class="filter-lbl">{{ numbervalue }}</span></label
                  >
                </div>
              </div>
              <div class="col-md-12 animate__animated animate__zoomIn">
                <div class="card-wrap shadow-main">
                  <div class="card-wrap-body">
                    <div class="data-table master-table">
                      <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>SL. No.</th>
                            <th>Person Name</th>
                            <th>Address</th>
                            <!-- <th>System Role</th> -->
                            <th>Mobile Number</th>
                            <th>Gender</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody
                          v-for="(user_detail, index) in employee_details"
                          :key="user_detail.RECORD_SYS_ID"
                        >
                          <tr>
                            <td>{{ index + 1 }}</td>
                            <td
                              @click="
                                editDetails(
                                  user_detail,
                                  '#updateemployee_modal'
                                )
                              "
                            >
                              {{ user_detail.PERSON_NAME }}
                            </td>
                            <td
                              @click="
                                editDetails(
                                  user_detail,
                                  '#updateemployee_modal'
                                )
                              "
                            >
                              {{ user_detail.ADDRESS_LINE_1 }}
                            </td>
                            <td
                              @click="
                                editDetails(
                                  user_detail,
                                  '#updateemployee_modal'
                                )
                              "
                            >
                              {{ user_detail.MOBILE_NO }}
                            </td>
                            <td
                              @click="
                                editDetails(
                                  user_detail,
                                  '#updateemployee_modal'
                                )
                              "
                            >
                              {{ user_detail.GENDER }}
                            </td>
                            <td class="table-btn">
                              <button
                                type="button"
                                class="custom-btn me-1 p-0"
                                data-bs-toggle="modal"
                                data-bs-target="#view_frm"
                                @click="
                                  showDetails(
                                    user_detail,
                                    '#view_employee_modal'
                                  )
                                "
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-eye"
                                >
                                  <path
                                    d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                  ></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              </button>
                              <button
                                type="button"
                                class="custom-btn p-0"
                                @click="
                                  editDetails(
                                    user_detail,
                                    '#updateemployee_modal'
                                  )
                                "
                                v-if="menuPermissions.UPDATE_ACCESS"
                              ></button>
                              <button
                                type="button"
                                class="custom-btn p-0"
                                data-bs-toggle="modal"
                                data-bs-target="#delete_frm"
                                @click="Delete(user_detail)"
                                v-if="menuPermissions.DELETE_ACCESS"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-trash-2"
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                  ></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div>
                                    </div>
                                 </div>
                              </div>
                           </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- right modal from here  -->
    <div
      class="modal modal-right fade"
      id="employee_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Add Employee Details
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
            <!-- <button type="button" class="btn-normal modal-close" data-bs-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                      </button> -->
          </div>

          <div class="modal-body">
            <ValidationObserver ref="bankform">
              <div class="row mt-2">
                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label">System Role</label>
                    <b-form-select
                      name="SYSTEM"
                      @change="getsysid"
                      v-model="create_employee.SYSTEM_ROLE_SYS_ID"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null &&
                          create_employee.SYSTEM_ROLE_SYS_ID != '',
                      }"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled
                          >-- Select --</b-form-select-option
                        >
                      </template>
                      <option value="" selected>Select</option>
                      <option
                        v-for="system_role_detail in system_role_details"
                        :value="system_role_detail.SYSTEM_ROLE_SYS_ID"
                        :key="system_role_detail.SYSTEM_ROLE_SYS_ID"
                      >
                        {{ system_role_detail.SYSTEM_ROLE }}
                      </option>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >First Name <span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      name="First Name"
                      v-model.trim="create_employee.FIRST_NAME"
                      class="form-control"
                      placeholder="Enter First Name"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_employee.FIRST_NAME != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Last Name <span style="color: red">*</span></label
                    >
                    <input
                      type="text"
                      name="Last Name"
                      v-model.trim="create_employee.LAST_NAME"
                      class="form-control"
                      placeholder="Enter Last Name"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_employee.LAST_NAME != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider
                    rules="required|mobileNumber"
                    v-slot="{ errors }"
                  >
                    <label class="frm-label"
                      >Mobile Number <span style="color: red">*</span></label
                    >
                    <input
                      type="number"
                      name="mobileNumber"
                      v-model.trim="create_employee.MOBILE_NO"
                      class="form-control"
                      placeholder="Enter Mobile no"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_employee.MOBILE_NO != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-md-4 mt-3">
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <label class="frm-label"
                      >Email <span style="color: red">*</span></label
                    >
                    <input
                      type="email"
                      name="Email"
                      v-model.trim="create_employee.EMAIL_ID"
                      class="form-control"
                      placeholder="Enter Email"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_employee.EMAIL_ID != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Gender <span style="color: red">*</span></label
                    >
                    <select
                      v-model="create_employee.GENDER"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_employee.GENDER != '',
                      }"
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Address <span style="color: red">*</span></label
                    >
                    <textarea
                      type="text"
                      name="address "
                      style="height: 5em"
                      v-model.trim="create_employee.ADDRESS_LINE_1"
                      class="form-control"
                      placeholder="Enter Address"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null &&
                          create_employee.ADDRESS_LINE_1 != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Post Code <span style="color: red">*</span></label
                    >
                    <input
                      type="number"
                      name="pincode"
                      v-model.trim="create_employee.PIN_CODE"
                      class="form-control"
                      placeholder="Enter Post Code"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_employee.PIN_CODE != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider v-slot="{ errors }">
                    <label class="frm-label"
                      >Country<span style="color: red">*</span></label
                    >
                    <b-form-select
                      v-model="create_employee.COUNTRY_SYS_ID"
                      @change="getcountrysysid"
                      :class="{
                        'form-control': true,
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null &&
                          create_employee.COUNTRY_SYS_ID != '',
                      }"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled
                          >-- Select --</b-form-select-option
                        >
                      </template>
                      <option value="" selected>Select</option>
                      <option
                        v-for="cost_type_info_detail in cost_type_info_details"
                        :value="cost_type_info_detail.COUNTRY_SYS_ID"
                        :key="cost_type_info_detail.COUNTRY_SYS_ID"
                      >
                        {{ cost_type_info_detail.COUNTRY_NAME }}
                      </option>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-md-4 mt-3">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <label class="frm-label"
                      >Password <span style="color: red">*</span></label
                    >
                    <input
                      type="password"
                      name="password"
                      v-model.trim="create_employee.PASSWORD"
                      class="form-control"
                      placeholder="Enter Password"
                      :class="{
                        'is-invalid': errors[0] != null,
                        'is-valid':
                          errors[0] == null && create_employee.PASSWORD != '',
                      }"
                    />
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>

                <div class="modal-footer-2">
                  <div class="row mt-4">
                    <div class="col-md-2">
                      <button
                        type="button"
                        class="custom-btn custom-success-btn"
                        @click="Addemployee"
                      >
                        Save
                      </button>
                    </div>
                    <div class="col-md-2">
                      <button
                        type="button"
                        class="custom-btn custom-danger-btn"
                        data-bs-dismiss="modal"
                        @click="emptyfu"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- row ends here  -->
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <!-- right modal from here for update -->
    <div
      class="modal modal-right fade"
      id="updateemployee_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update Employee Details
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
            <!-- <button type="button" class="btn-normal modal-close" data-bs-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                      </button> -->
          </div>
          <div class="modal-body">
            <ValidationObserver ref="bankform2" v-slot="{ invalid }">
              <form>
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label">System Role</label>
                      <b-form-select
                        name="System Role"
                        @change="getsysidedit"
                        v-model="update_user.SYSTEM_ROLE_SYS_ID"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.SYSTEM_ROLE_SYS_ID != '',
                        }"
                      >
                        <template #first>
                          <b-form-select-option :value="null"
                            >-- Select Role --</b-form-select-option
                          >
                        </template>
                        <option
                          v-for="system_role_detail in system_role_details"
                          :key="system_role_detail.SYSTEM_ROLE_SYS_ID"
                          :value="system_role_detail.SYSTEM_ROLE_SYS_ID"
                        >
                          {{ system_role_detail.SYSTEM_ROLE }}
                        </option>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >First Name <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="First Name"
                        v-model.trim="update_user.FIRST_NAME"
                        class="form-control"
                        placeholder="Enter First Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.FIRST_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Last Name <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="Last Name"
                        v-model.trim="update_user.LAST_NAME"
                        class="form-control"
                        placeholder="Enter Last Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.LAST_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Gender <span style="color: red">*</span></label
                      >
                      <select
                        name="Series"
                        v-model="update_user.GENDER"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.GENDER != '',
                        }"
                      >
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider
                      rules="required|mobileNumber"
                      v-slot="{ errors }"
                    >
                      <label class="frm-label"
                        >Mobile Number <span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="mobileNumber"
                        maxlength="8"
                        v-model.trim="update_user.MOBILE_NO"
                        class="form-control"
                        placeholder="Enter Last Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.MOBILE_NO != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <label class="frm-label"
                        >email <span style="color: red">*</span></label
                      >
                      <input
                        type="email"
                        name="email"
                        v-model.trim="update_user.EMAIL_ID"
                        class="form-control"
                        placeholder="Enter email"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.EMAIL_ID != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Address <span style="color: red">*</span></label
                      >
                      <textarea
                        type="text"
                        name="address "
                        v-model.trim="update_user.ADDRESS_LINE_1"
                        style="height: 5em"
                        class="form-control"
                        placeholder="Enter Address"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.ADDRESS_LINE_1 != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Post Code <span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="pincode"
                        maxlength="6"
                        v-model.trim="update_user.PIN_CODE"
                        class="form-control"
                        placeholder="Enter Pin Code"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.PIN_CODE != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Country <span style="color: red">*</span></label
                      >
                      <b-form-select
                        name="Series"
                        v-model="update_user.COUNTRY_SYS_ID"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.COUNTRY_NAME != null,
                        }"
                      >
                        <template>
                          <b-form-select-option :value="null" disabled
                            >-- Select --</b-form-select-option
                          >
                        </template>

                        <option
                          v-for="cost_type_info_detail in cost_type_info_details"
                          :value="cost_type_info_detail.COUNTRY_SYS_ID"
                          :key="cost_type_info_detail.COUNTRY_SYS_ID"
                        >
                          {{ cost_type_info_detail.COUNTRY_NAME }}
                        </option>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Password <span style="color: red">*</span></label
                      >
                      <div class="input-pass">
                        <input
                          type="password"
                          name="password"
                          v-model.trim="update_user.PASSWORD"
                          class="form-control"
                          placeholder="Enter Password"
                          :class="{
                            'is-invalid': errors[0] != null,
                            'is-valid':
                              errors[0] == null && update_user.PASSWORD != '',
                          }"
                        />
                        <!-- <button
                            type="button"
                            id="eye"
                            @click="show_password = !show_password"
                            class="eye-btn border-0"
                          >
                            <i
                              v-if="!show_password"
                              class="fa fa-eye"
                              aria-hidden="true"
                              style="color: #000"
                            ></i>
                            <i
                              v-else
                              class="fa fa-eye-slash"
                              aria-hidden="true"
                              style="color: #000"
                            ></i>
                          </button> -->
                      </div>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <!-- <div class="form-label">
                                    <a class="proposal-btn modal-dialog-centered" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">Submit Project Proposal</a>
                       
                                </div> -->

                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          :disabled="invalid"
                          data-bs-dismiss="modal"
                          @click="updateEmployee"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- right modal from here for view Employee -->
    <div
      class="modal modal-right fade"
      id="view_employee_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 55% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">View Employee</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 mt-3">
                <label class="frm-label">Name </label>
                <label
                  >{{ view_employee.FIRST_NAME }}
                  {{ view_employee.LAST_NAME }}</label
                >
              </div>

              <div class="col-md-4 mt-3">
                <label class="frm-label">Gender</label>
                <label>{{ view_employee.GENDER }}</label>
              </div>

              <div class="col-md-4 mt-3">
                <label class="frm-label">Mobile Number </label>
                <label>{{ view_employee.MOBILE_NO }}</label>
              </div>

              <div
                class="col-md-4 mt-3"
                v-if="
                  view_employee.ADDRESS_LINE_1 ||
                  view_employee.ADDRESS_LINE_2 ||
                  view_employee.PIN_CODE
                "
              >
                <label class="frm-label">Address</label>
                <label>
                  <span v-if="view_employee.ADDRESS_LINE_1"
                    >{{ view_employee.ADDRESS_LINE_1 }}<br
                  /></span>
                  <span v-if="view_employee.ADDRESS_LINE_2"
                    >{{ view_employee.ADDRESS_LINE_2 }}<br
                  /></span>
                  <span v-if="view_employee.COUNTRY_NAME"
                    >{{ view_employee.COUNTRY_NAME }} -
                  </span>
                  <span v-if="view_employee.PIN_CODE">{{
                    view_employee.PIN_CODE
                  }}</span>
                </label>
              </div>
              <!-- <div class="col-md-4 mt-3">
                                <label class="frm-label">Country </label>
                                <label>{{view_employee.COUNTRY_NAME}}</label>
                            </div> -->

              <div class="col-md-4 mt-3">
                <label class="frm-label">Email </label>
                <label>{{ view_employee.EMAIL_ID }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- search modal -->
    </div>

    <div
      class="modal modal-left fade"
      id="left_modal_sm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="left_modal_sm"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search By</h5>
          </div>
          <div class="modal-body search-panel">
            <div class="row">
              <div class="col-md-12">
                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="all"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">All Combinations</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="status"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Person Name</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="number"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Mobile Number</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="make"
                    class="rad-input"
                    v-model="search_option"
                  />
                </label>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'status'"
              >
                <label class="frm-label">person name</label>
                <input
                  type="text"
                  v-model="INSURANCE_TYPE"
                  class="form-control"
                  placeholder="Enter Person Name"
                />
              </div>
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'number'"
              >
                <label class="frm-label">Mobile Number</label>
                <input
                  type="text"
                  v-model="VEHICLENUMBER"
                  class="form-control"
                  placeholder="Enter Mobile No."
                />
              </div>

              <div class="col-md-12 mt-2" v-if="search_option">
                <div class="btn-conainer">
                  <button
                    type="button"
                    @click="getEmployee()"
                    id="srch-btn"
                    class="custom-btn custom-success-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
export default {
  // props: {
  //     type: {
  //         type: String,
  //         default: "text",
  //     },
  //     size: {
  //         type: String,
  //         default: "8",
  //     },
  //     characters: {
  //         type: String,
  //         default: "a-z,A-Z,0-9,#",
  //     },
  //     placeholder: {
  //         type: String,
  //         default: "Password",
  //     },
  //     auto: [String, Boolean],
  //     value: "",
  // },
  data() {
    return {
      system_role_details: [],
      cost_type_info_details: [],
      employee_details: [],
      create_employee: {
        ITEM: "ADD_USER",

        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        CUSTOMER_TYPE: "",
        //SYSTEM_ROLE:'',
        FIRST_NAME: "",
        LAST_NAME: "",
        DOB: "",
        EMAIL_ID: "",
        GENDER: "",
        MOBILE_NO: "",
        ORGANISATION_SYS_ID: "1",
        SYSTEM_ROLE_SYS_ID: "",
        SYSTEM_ROLE: "",
        USER_TYPE: "Internal",
        PASSWORD: "",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "",
        COUNTRY_SYS_ID: "",
      },
      adl: true,
      view_employee: [],
      selected_row: {},
      update_user: {},
      search_option: "",
      vehicletype: "",
      INSURANCE_TYPE: "",
      NAME: "",
      CONTACT_NO: "",
      STATUS: "",
      statusvalue: false,
      vehicledetails: {},
      vehicledetails_page_no: 1,
    };
  },
  mounted() {
    this.getEmployee();
    this.getSystemRoleDetails();
    this.getCountryInfo();
  },
  methods: {
    //  emptyfu(){
    //       this.create_employee.SYSTEM_ROLE="";
    //   },
    //          getSystemRoleDetails() {
    //   this.axios
    //     .get(apiEndPoints.view_module, {
    //       params: {
    //         ITEM: "VIEW_ALL",
    //         RECORD_SYS_ID: 0,
    //       },
    //     })
    //     .then((response) => {
    //       ////console.log(response);
    //       this.module_master_details = response.data.response;
    //     })
    //     .catch((err) => {
    //       ////console.log(err);
    //     });
    // },

    emptyfu() {
      $("#employee_modal").modal("hide");
      this.create_employee.ADDRESS_LINE_1 = "";
      this.create_employee.SYSTEM_ROLE = "";
      this.create_employee.ADDRESS_LINE_2 = "";
      this.create_employee.FIRST_NAME = "";
      this.create_employee.LAST_NAME = "";
      this.create_employee.MOBILE_NO = "";
      this.create_employee.COUNTRY_NAME = "";
      this.create_employee.PIN_CODE = "";
      this.create_employee.GENDER = "";
      this.create_employee.BROKER_TYPE = "";
      this.create_employee.EMAIL_ID = "";
      this.create_employee.COUNTRY_SYS_ID =
        this.cost_type_info_details[0].COUNTRY_SYS_ID;
    },
    async Delete(data) {
      const result = await Global.showConfirmationAlert(
        `Delete Employee ${data.PERSON_NAME}`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        ////console.log("tt", this.user_details);
        let postarrydata = {
          ITEM: "DELETE_USER",
          USER_SYS_ID: data.USER_SYS_ID,
          CREATED_BY: user_details.UserId,
        };

        ////console.log(postarrydata);
        this.axios
          .post(apiEndPoints.create_update_employee, postarrydata)
          .then((response) => {
            ////console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getEmployee();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            // this.isProductModalLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    getinsurancesearch() {
      this.numbervalue = "";

      if (this.search_option == "all") {
        this.statusvalue = this.INSURANCE_TYPE;
        this.numbervalue = this.VEHICLENUMBER;
      } else {
        if (this.search_option == "status")
          this.statusvalue = this.INSURANCE_TYPE;
        if (this.search_option == "number")
          this.numbervalue = this.VEHICLENUMBER;
      }
      this.axios
        .get(apiEndPoints.view_employee, {
          params: {
            ITEM: "SPECIFIC",
            NAME: this.VEHICLENUMBER || this.INSURANCE_TYPE,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.employee_details = response.data.response;
            this.FlagVeriableforSearch = "serchtrue";
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          ////console.log("err");
        });
    },
    filtershowhide() {
      if (this.STATUS != "") {
        this.statusvalue = true;
      } else {
        this.statusvalue = false;
      }

      if (this.NAME != "") {
        this.makevalue = true;
      } else {
        this.makevalue = false;
      }
      if (this.CONTACT_NO != "") {
        this.modelvalue = true;
      } else {
        this.modelvalue = false;
      }
    },

    // generate() {
    //     this.create_employee.PASSWORD = "";

    //     let charactersArray = this.characters.split(",");
    //     let CharacterSet = "";
    //     let password = "";

    //     if (charactersArray.indexOf("a-z") >= 0) {
    //         CharacterSet += "abcdefghijklmnopqrstuvwxyz";
    //     }
    //     if (charactersArray.indexOf("A-Z") >= 0) {
    //         CharacterSet += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    //     }
    //     if (charactersArray.indexOf("0-9") >= 0) {
    //         CharacterSet += "0123456789";
    //     }
    //     if (charactersArray.indexOf("#") >= 0) {
    //         CharacterSet += "![]{}()%&*$#^<>~@|";
    //     }

    //     for (let i = 0; i < this.size; i++) {
    //         password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length));
    //     }
    //     this.create_employee.PASSWORD = password;
    // },
    Addemployee() {
      //this.generate();
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      ////console.log("tr", this.user_details);
      this.create_employee["CREATED_BY"] = user_details.UserId;

      ////console.log(JSON.stringify(this.create_employee));
      this.$refs.bankform.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(
              apiEndPoints.create_update_employee,
              this.create_employee,
              this.system_role_details
            )
            .then((response) => {
              ////console.log(response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getEmployee();
                this.emptyfu();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },

    getsysid() {
      this.create_employee.SYSTEM_ROLE = this.system_role_details.find(
        (o) => o.SYSTEM_ROLE_SYS_ID === this.create_employee.SYSTEM_ROLE_SYS_ID
      ).SYSTEM_ROLE;
    },
    getsysidedit() {
      this.update_user.SYSTEM_ROLE = this.system_role_details.find(
        (o) => o.SYSTEM_ROLE_SYS_ID === this.update_user.SYSTEM_ROLE_SYS_ID
      ).SYSTEM_ROLE;
    },
    getcountrysysid() {
      ////console.log("riju");
      this.create_employee.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.create_employee.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
      this.update_user.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.update_user.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
    },
    getSystemRoleDetails() {
      this.axios
        .get(apiEndPoints.system_role_api, {
          params: {
            ITEM: "INTERNAL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          ////console.log(response);
          this.system_role_details = response.data.response;
        })
        .catch((err) => {
          ////console.log(err);
        });
    },

    updateEmployee() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.update_user["CREATED_BY"] = user_details.UserId;
      this.update_user["ITEM"] = "UPDATE_USER";
      this.update_user["RECORD_SYS_ID"] = this.update_user.USER_SYS_ID;

      this.update_user["SYSTEM_ROLE_SYS_ID"] =
        this.update_user.SYSTEM_ROLE_SYS_ID;
      this.update_user["ADDRESS_DETAILS_SYS_ID"] =
        this.update_user.ADDRESS_DETAILS_SYS_ID;
      this.update_user["NEW_PASSWORD"] = this.update_user.PASSWORD;
      ////console.log(JSON.stringify(this.update_user));
      this.axios
        .post(apiEndPoints.create_update_employee, this.update_user)
        .then((response) => {
          ////console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getEmployee();
            this.getSystemRoleDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          ////console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    getEmployee(page_no = 1) {
      this.filtershowhide();
      this.numbervalue = "";

      if (this.search_option == "all") {
        this.statusvalue = this.INSURANCE_TYPE;
        this.numbervalue = this.VEHICLENUMBER;
      } else {
        if (this.search_option == "status")
          this.statusvalue = this.INSURANCE_TYPE;
        if (this.search_option == "number")
          this.numbervalue = this.VEHICLENUMBER;
      }
      this.axios
        .get(apiEndPoints.view_employee, {
          params: {
            ITEM: "SPECIFIC",
            NAME: this.INSURANCE_TYPE,
            CONTACT_NO: this.VEHICLENUMBER,
            USER_TYPE: "INTERNAL",
            PAGE_SIZE: 35,
          },
        })
        .then((response) => {
          ////console.log(response);
          if (response.data.status == "true") {
            if (page_no == 1) {
              this.vehicledetails = [];
            }
            this.vehicledetails = [
              ...this.vehicledetails,
              ...response.data.response,
            ];
            this.vehicledetails_page_no += 1;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
          this.employee_details = response.data.response;
        })
        .catch((err) => {
          ////console.log(err);
        });
    },
    loadVehicleDetails(isVisible) {
      if (isVisible) {
        this.getEmployee(this.vehicledetails_page_no);
      }
    },
    showDetails(row, id) {
      this.view_employee = row;

      ////console.log("rt2", row);
      $(id).modal("show");
    },
    editDetails(row, id) {
      this.update_user = row;
      ////console.log("rt", this.update_user);
      $(id).modal("show");
    },

    getCountryInfo() {
      this.axios
        .get(apiEndPoints.view_country, {
          params: { ITEM: "VIEW_ALL", RECORD_SYS_ID: 0 },
        })
        .then((response) => {
          ////console.log(response);
          //this.cost_type_info_details = response.data.response;
          if (response.data.status == "true") {
            this.cost_type_info_details = response.data.response;
            this.$nextTick(() => {
              this.create_employee.COUNTRY_SYS_ID =
                this.cost_type_info_details[0].COUNTRY_SYS_ID;
              this.getcountrysysid();
            });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.cost_type_info_details = [];
            this.create_employee.COUNTRY_SYS_ID = null;
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          ////console.log(err);
        });
    },
  },
  computed: {
    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
  },
};
</script>
