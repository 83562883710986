import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
import NProgress from "nprogress";
export const admin_master = {
  data() {
    return {
      loading: false,
      isDisabledTRF: false,
      isDisabledETS: false,
      isDisabled: true,
      isDisabled_: true,
      isDisabled_temporary: true,
      bulkvehicle: false,
      addmasterdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: "0",
        VEHICLE_STATUS: "",
        VEHICLE_NUMBER: "",
        TEMPORARY_VEHICLE_NUMBER: "",
        MAKE_SYS_ID: "",
        MAKE_NAME: "",
        MODEL_SYS_ID: 0,
        MODEL_NAME: "",
        MODEL_SHORT_NAME: "",
        PRICE_LIST_STATUS: "",
        BODY_TYPE_SYS_ID: "",
        BODY_TYPE: "",
        PRICE_LIST_PLUS: "",
        ACCESSORY: "",
        ASKING_PRICE: "",
        CHASIS_NO: "",
        ENGINE_NO: "",
        PROPELLANT: "",
        COLOR_SYS_ID: "",
        ETS_FLAG: "",
        COLOR_NAME: "",
        LADEN: "",
        UNLADEN: "",
        ENGINE_CAP: "",
        ENGINE_TON: "",
        PAX: "",
        MANUFACTURE_YEAR: "",
        ORIGINAL_REGISTRATION_DATE: "",
        TSF_NUMBER: "",
        INCHARGE_BY_BROKER_SYS_ID: "",
        CREATED_BY: "",
        BULK_COUNT: "",
        VEHICLE_LOCATION_SYS_ID: "",
        Y: "",
      },
      Manufracturename: "",
      Coloursname: "",
      Bodytype: "",
      Modeltype: "",
      Vehiclelocations: "",
      brokerdetails: "",
    };
  },
  mounted() {
    this.getManufracturename();
    this.getcolour();
    this.getbodytype();
    // this.getmodeltype();
    this.getvehiclelocation();
    this.getbrokerdetails();
  },
  methods: {
    validateNumber: (event) => {
      let keyCode = event.keyCode;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {
        event.preventDefault();
      }
    },
    emptyfun() {
      this.addmasterdata.VEHICLE_STATUS = "";
      this.addmasterdata.VEHICLE_NUMBER = "";
      this.addmasterdata.TEMPORARY_VEHICLE_NO = "";
      this.addmasterdata.MAKE_SYS_ID = "";
      this.addmasterdata.MODEL_SYS_ID = "";
      this.addmasterdata.MODEL_SHORT_NAME = "";
      this.addmasterdata.PRICE_LIST_STATUS = "";
      this.addmasterdata.BODY_TYPE_SYS_ID = "";
      this.addmasterdata.PRICE_LIST_PLUS = "";
      this.addmasterdata.ACCESSORY = "";
      this.addmasterdata.CHASIS_NO = "";
      this.addmasterdata.ENGINE_NO = "";
      this.addmasterdata.PROPELLANT = "";
      this.addmasterdata.COLOR_SYS_ID = "";
      this.addmasterdata.LADEN = "";
      this.addmasterdata.PAX = "";
      this.addmasterdata.MANUFACTURE_YEAR = "";
      this.addmasterdata.ORIGINAL_REGISTRATION_DATE = "";
      this.addmasterdata.TSF_NUMBER = "";
      this.addmasterdata.INCHARGE_BY_BROKER_SYS_ID = "";
      this.addmasterdata.ASKING_PRICE = "";
      this.addmasterdata.VEHICLE_LOCATION_SYS_ID = "";
    },
    getManufracturename() {
      this.axios
        .get(apiEndPoints.view_vehicle_manufacture_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.Manufracturename = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getcolour() {
      this.axios
        .get(apiEndPoints.view_vehicle_color_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.Coloursname = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getbodytype() {
      this.axios
        .get(apiEndPoints.view_vehicle_body_type, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.Bodytype = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getmodeltype() {
      this.axios
        .get(apiEndPoints.view_vehicle_model_type, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.addmasterdata.MAKE_SYS_ID,
          },
        })
        .then((response) => {
          //console.log(response);
          this.Modeltype = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getvehiclelocation() {
      this.axios
        .get(apiEndPoints.view_vehicle_location, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.Vehiclelocations = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getbrokerdetails() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            // ITEM: "VIEW_ALL",
            // RECORD_SYS_ID: 0,
            ITEM: "SPECIFIC",

            USER_TYPE: "External",
            PAGE_SIZE: 100,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          //console.log(response);
          this.brokerdetails = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    nullify() {
      //console.log("debasish");
      let X = this.addmasterdata.PAX;
      if (X == "") {
        this.addmasterdata.PAX = 0;
      }
    },
    Addadminmaster() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.nullify();
      this.addmasterdata["CREATED_BY"] = user_details.UserId;

      //this.addmasterdata.ORIGINAL_REGISTRATION_DATE=this.formatDate(this.addmasterdata.ORIGINAL_REGISTRATION_DATE);

      //console.log(JSON.stringify(this.addmasterdata));
      this.axios
        .post(apiEndPoints.add_update_adminmaster, this.addmasterdata)
        .then((response) => {
          //console.log(response);
          //this.addmasterdata.ORIGINAL_REGISTRATION_DATE=this.parseDate(this.addmasterdata.ORIGINAL_REGISTRATION_DATE);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.emptyfun();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#region  get modelshort name
    getmodelshortName() {
      this.addmasterdata.MODEL_SHORT_NAME = this.Modeltype.find(
        (o) => o.VEHICLE_MODEL_SYS_ID === this.addmasterdata.MODEL_SYS_ID
      ).MODEL_SHORT_NAME;
      this.addmasterdata.MODEL_NAME = this.Modeltype.find(
        (o) => o.VEHICLE_MODEL_SYS_ID === this.addmasterdata.MODEL_SYS_ID
      ).VEHICLE_MODEL;
    }, //#endregion
    //#region  get make name
    getmakeName() {
      this.addmasterdata.MAKE_NAME = this.Manufracturename.find(
        (o) => o.VEHICLE_MAKE_SYS_ID === this.addmasterdata.MAKE_SYS_ID
      ).VEHICLE_MAKE;
      this.getmodeltype();
    }, //#endregion
    //#region  get body name
    getbodyName() {
      this.addmasterdata.BODY_TYPE = this.Bodytype.find(
        (o) =>
          o.VEHICLE_BODY_TYPE_SYS_ID === this.addmasterdata.BODY_TYPE_SYS_ID
      ).VEHICLE_BODY_TYPE;
    }, //#endregion
    //#region  get color name
    getcolorName() {
      this.addmasterdata.COLOR_NAME = this.Coloursname.find(
        (o) => o.VEHICLE_COLOR_SYS_ID === this.addmasterdata.COLOR_SYS_ID
      ).VEHICLE_COLOR;
    }, //#endregion
    //#region  get LOCATION name
    getLocationName() {
      this.addmasterdata.LOCATION = this.Vehiclelocations.find(
        (o) =>
          o.VEHICLE_LOCATION_SYS_ID ===
          this.addmasterdata.VEHICLE_LOCATION_SYS_ID
      ).VEHICLE_LOCATION;
    }, //#endregion
    //engin cap calculation
    engincap() {
      var laden = parseInt(this.addmasterdata.LADEN);
      var unladen = parseInt(this.addmasterdata.UNLADEN);
      this.addmasterdata.ENGINE_CAP_TON = laden - unladen;
    },

    //Onchange Bulk Vehicle Details
    onChangeBulkVehicleDetails() {
      //console.log("value", this.bulkvehicle);
      this.isDisabled = !this.bulkvehicle;
      this.isDisabled_ = !this.bulkvehicle;
      this.isDisabled_temporary = !this.bulkvehicle;
      let VehicleStatus = this.addmasterdata.VEHICLE_STATUS;
      if (!VehicleStatus) this.isDisabledTRF = this.bulkvehicle;
      else if (VehicleStatus == "ETS") this.isDisabledTRF = true;
      else if (VehicleStatus) this.isDisabledTRF = this.bulkvehicle;

      if (this.bulkvehicle) {
        this.addmasterdata.ITEM = "BULK_ADD";
      }
    },

    addVehicleDetails() {
      this.$refs.vehicledetailsaddForm.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All Fields Are Required!");
          return false;
        } else {
          let user_details = JSON.parse(
            localStorage.getItem("lek_user_details")
          );
          this.addmasterdata["CREATED_BY"] = user_details.UserId;
          if (this.addmasterdata.PRICE_LIST_STATUS == true)
            this.addmasterdata["PRICE_LIST_STATUS"] = "N";
          else if (this.addmasterdata.PRICE_LIST_STATUS == false)
            this.addmasterdata["PRICE_LIST_STATUS"] = "Y";
          //console.log("addVehicleDetails", this.addmasterdata);
          NProgress.start();
          NProgress.set(0.1);
          this.loading = true;
          this.axios
            .post(apiEndPoints.add_vehicle_details, this.addmasterdata)
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.$router.push({
                  name: "admin-master",
                  query: { VEHICLE_SYS_ID: response.data.VEHICLE_SYS_ID },
                });
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .catch((err) => {
              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    //Vehicle Status OnChange Function
    onChangeVehicleStatus() {
      let VehicleStatus = this.addmasterdata.VEHICLE_STATUS;
      if (VehicleStatus == "Pre Order" || VehicleStatus == "New Vehicle") {
        this.addmasterdata.TSF_NUMBER = 0;
        this.isDisabled_ = false;
        this.isDisabledETS = false;
        this.isDisabledTRF = this.bulkvehicle;
      } else {
        this.addmasterdata.TSF_NUMBER = "";
        this.isDisabled_ = true;
        if (VehicleStatus == "ETS") {
          this.addmasterdata.ETS_FLAG = "Y";
          this.isDisabledETS = true;
          this.isDisabledTRF = true;
        } else {
          this.addmasterdata.ETS_FLAG = "N";
          this.isDisabledETS = false;
          this.isDisabledTRF = this.bulkvehicle;
        }
      }
    },

    //Calculate Ton Value
    onKeyUpCalculateTon() {
      if (this.addmasterdata.LADEN && this.addmasterdata.UNLADEN) {
        let Ton = this.addmasterdata.LADEN - this.addmasterdata.UNLADEN;
        this.addmasterdata.ENGINE_TON = Ton;
      }
    },
  },
};
