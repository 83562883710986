import Swal from 'sweetalert2';
export const Global={
    // #region Error alert
    showErrorAlert(isToast, icon, text) {
        let content = "<strong><font color='white'>" + text + "</font></strong>";
          Swal.fire({
            toast: isToast,
            position: "top-end",
            icon: icon,
            html: content,
            iconColor: "white",
            showConfirmButton: false,
            timer: 3500,
            background: "red",
          });
        },
      // #endregion
  
      // #region Success alert
        showSuccessAlert(isToast, icon, text) {
          var content = "<strong><font color='white'>" + text + "</font></strong>";
          Swal.fire({
            toast: isToast,
            position: "top-end",
            icon: icon,
            html: content,
            iconColor: "white",
            showConfirmButton: false,
            timer: 3500,
            background: "green",
          });
        },
       // #endregion
  
  
        // #region Confirmation alert
        async showConfirmationAlert(title, text, icon) {
          return Swal.fire({
            title: title,
            text: text,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!",
          });
        },
        //#endregion
}