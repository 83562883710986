<template>
  <div>
    <div class="floating-heading">
      <h4><b>Admin Master</b></h4>
    </div>

    <!-- <div class="floating-search-btn-container">
      <button id="srch_btn">
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
    </div> -->
    <!-- <div
      class="
        floating-add-btn-container
        animate__animated animate__lightSpeedInRight
      "
    >
      <button
        id="add_btn"
        data-bs-toggle="modal"
        data-bs-target="#_modal"
        data-bs-dismiss="modal"
        @click="onLogIn"
        v-if="menuPermissions.FULL_ACCESS || menuPermissions.CREATE_ACCESS"
         
      >
        <i class="fa-solid fa-plus"></i>
      </button>
    </div> -->
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data">
          <div class="row mb-3">
            <!-- <div class="col-md-12 mb-1" style="display:flex; justify-content:space-between;">
              <label class="frm-label-2" style="color: #a36626"><i class="fa-solid fa-filter"></i> Filter</label>
              <div>
             
                
              </div>
            </div> -->
            <div class="col-md-2" v-if="statusvalue">
              <label class="frm-label-2" style="font-size: 15px"
                >Status: <span class="filter-lbl">{{ STATUS }}</span></label
              >
            </div>
            <div class="col-md-3" v-if="numbervalue">
              <label class="frm-label-2" style="font-size: 15px">
                Vehicle Number:
                <span class="filter-lbl">{{ VEHICLENUMBER }}</span></label
              >
            </div>
            <div class="col-md-2" v-if="makevalue">
              <label class="frm-label-2" style="font-size: 15px">
                Make: <span class="filter-lbl">{{ MAKENAME }}</span></label
              >
            </div>
            <div class="col-md-2" v-if="modelvalue">
              <label class="frm-label-2" style="font-size: 15px">
                Model: <span class="filter-lbl">{{ MODELNAME }}</span></label
              >
            </div>
          </div>

          <div class="data-table">
            <table class="table table-bordered table-hover">
              <thead>
                <tr style="background: #ebd8c3">
                  <th>SL. No.</th>
                  <th scope="col">Vehicle Status</th>
                  <th scope="col">Vehicle Number</th>
                  <th scope="col">Name And Model</th>
                  <th scope="col">Body Type</th>
                  <th scope="col">Propellant</th>
                  <th scope="col">Color</th>
                  <!-- <th scope="col">Action</th> -->
                </tr>
              </thead>
              <tbody
                v-for="(vehicle_detail, index) in vehicledetails"
                :key="vehicle_detail.RECORD_SYS_ID"
              >
                <tr>
                  <td
                    data-bs-toggle="modal"
                    @click="editfun(vehicle_detail)"
                    data-bs-target="#admin_edit_modal"
                  >
                    {{ index + 1 }}
                    <div
                      v-if="
                        index + 1 == vehicledetails.length &&
                        vehicledetails_page_no > 1
                      "
                    >
                      <span v-b-visible.once="loadVehicleDetails"></span>
                    </div>
                  </td>
                  <td
                    data-bs-toggle="modal"
                    @click="editfun(vehicle_detail)"
                    data-bs-target="#admin_edit_modal"
                  >
                    {{ vehicle_detail.VEHICLE_STATUS }}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    @click="editfun(vehicle_detail)"
                    data-bs-target="#admin_edit_modal"
                  >
                    {{ vehicle_detail.VEHICLE_NUMBER }}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    @click="editfun(vehicle_detail)"
                    data-bs-target="#admin_edit_modal"
                  >
                    {{ vehicle_detail.MAKE_NAME }}-{{
                      vehicle_detail.MODEL_NAME
                    }}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    @click="editfun(vehicle_detail)"
                    data-bs-target="#admin_edit_modal"
                  >
                    {{ vehicle_detail.BODY_TYPE }}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    @click="editfun(vehicle_detail)"
                    data-bs-target="#admin_edit_modal"
                  >
                    {{ vehicle_detail.PROPELLANT }}
                  </td>
                  <td
                    data-bs-toggle="modal"
                    @click="editfun(vehicle_detail)"
                    data-bs-target="#admin_edit_modal"
                  >
                    {{ vehicle_detail.COLOR_NAME }}
                  </td>

                  <td class="table-btn">
                    <!-- <button
                      class="custom-btn p-0"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#flush-collapseOne-${index}`"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      <i class="fa fa-plus-circle btn btn-secondary fa-2x" aria-hidden="true"></i>
                    </button> -->
                  </td>
                </tr>
                <!-- table under table  -->
                <tr class="table-under-table">
                  <td colspan="12" class="p-0" style="padding: 0 !important">
                    <div
                      :id="`flush-collapseOne-${index}`"
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body p-2 mt-1">
                        <form
                          class="d-flex"
                          style="justify-content: space-around"
                        >
                          <button
                            type="button"
                            class="custom-btn custom-secondary-btn-4"
                            @click="getDebitNoteDetails(vehicle_detail)"
                          >
                            DEBIT NOTE
                          </button>
                          <button
                            type="button"
                            class="custom-btn custom-secondary-btn-4"
                            @click="getCreditNoteDetails(vehicle_detail)"
                          >
                            CREDIT NOTE
                          </button>
                          <button
                            type="button"
                            class="custom-btn custom-secondary-btn-4"
                            @click="getSalesNoteDetails(vehicle_detail)"
                          >
                            SALES RECEIPT
                          </button>
                          <button
                            type="button"
                            class="custom-btn custom-secondary-btn-4"
                            @click="getTaxNoteDetails(vehicle_detail), gettax()"
                          >
                            TAX INVOICE
                          </button>
                          <button
                            type="button"
                            class="custom-btn custom-secondary-btn-4"
                            @click="
                              getPlStatmentDetails(vehicle_detail),
                                exportExcel()
                            "
                          >
                            P_L STATEMENT
                          </button>
                          <button
                            type="button"
                            class="custom-btn custom-secondary-btn-4"
                            @click="getPurchasesNoteDetails(vehicle_detail)"
                          >
                            PURCHASE SETTLEMENT
                          </button>
                          <div class="table-under-table-dlt">
                            <button
                              type="button"
                              class="custom-btn custom-danger-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_frm"
                              @click="Delete(vehicle_detail)"
                            >
                              <i class="fa-solid fa-trash-can"></i>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- modal left  -->
    <div
      class="modal modal-left fade"
      id="left_modal_sm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="left_modal_sm"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search By</h5>
          </div>
          <div class="modal-body search-panel">
            <div class="row">
              <div class="col-md-12">
                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="All"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">All Combinations</div>
                </label>

                <label class="rad-label">
                  <input type="radio" class="rad-input" name="rad" value="FY" />
                  <div class="rad-design"></div>
                  <div class="rad-text">Vehicle Status</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="program"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Vehicle Number</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="project"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Make</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="partner"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Model</div>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 div_1 mt-1">
                <label class="frm-label">Status</label>
                <select class="form-control" v-model="STATUS">
                  <option value="">Select</option>
                  <option value="Pre Order">Pre Order</option>
                  <option value="Ordered">Ordered</option>
                  <option value="New Vehicle">New Vehicle</option>
                  <option value="Pre Owned">Pre Owned</option>
                </select>
              </div>
              <div class="col-md-12 div_3 mt-1">
                <label class="frm-label">Vehicle Number</label>
                <input
                  type="text"
                  v-model="VEHICLENUMBER"
                  class="form-control"
                  placeholder="Enter Vehicle No."
                />
              </div>
              <div class="col-md-12 div_4 mt-1">
                <label class="frm-label"
                  >Make<span style="color: red">*</span></label
                >
                <select class="form-control" v-model="MAKENAME">
                  <option value="">Select Make</option>
                  <option
                    v-for="makenm in Manufracturename"
                    :value="makenm.VEHICLE_MAKE"
                    :key="makenm.VEHICLE_MAKE_SYS_ID"
                  >
                    {{ makenm.VEHICLE_MAKE }}
                  </option>
                </select>
              </div>
              <div class="col-md-12 div_5 mt-1">
                <label class="frm-label">Model</label>
                <select class="form-control" v-model="MODELNAME">
                  <option value="">Select Model</option>
                  <option
                    v-for="model in Modeltype"
                    :value="model.VEHICLE_MODEL"
                    :key="model.VEHICLE_MODEL_SYS_ID"
                  >
                    {{ model.VEHICLE_MODEL }}
                  </option>
                </select>
              </div>
              <div class="col-md-12 div_6 mt-2">
                <div class="btn-conainer">
                  <button
                    type="button"
                    @click="getvehicledetails"
                    id="srch-btn"
                    class="custom-btn custom-success-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal  -->
    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-2">
                <h5 class="modal-title" id="staticBackdropLabel">DEBIT NOTE</h5>
              </div>
              <div class="col-md-10">
                <button
                  class="downld animate__animated animate__bounceIn"
                  @click="generateReport"
                >
                  <i class="fa-solid fa-cloud-arrow-down"></i> Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <div class="row">
              <!-- <div class="col-md-12 offset-md-10">
                        
                     </div> -->
            </div>
            <div class="content">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-2">
                            <p>
                              2 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="heading">
                      <p>DEBIT NOTE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6">
                  <div class="content-header-2">
                    <ul>
                      <li><h5>To.</h5></li>
                      <li>
                        <h5>{{ this.pdf_details.FIRST_NAME }}</h5>
                      </li>
                      <li>
                        <div
                          class="col-md-4 mt-3"
                          v-if="
                            pdf_details.ADDRESS_LINE_1 ||
                            pdf_details.ADDRESS_LINE_2 ||
                            pdf_details.PIN_CODE
                          "
                        >
                          <span v-if="pdf_details.ADDRESS_LINE_1">{{
                            pdf_details.ADDRESS_LINE_1
                          }}</span
                          ><br />
                          <span v-if="pdf_details.ADDRESS_LINE_2">{{
                            pdf_details.ADDRESS_LINE_2
                          }}</span
                          ><br />
                          <span v-if="pdf_details.COUNTRY_NAME"
                            >{{ pdf_details.COUNTRY_NAME }} -
                          </span>
                          <span v-if="pdf_details.PIN_CODE">{{
                            pdf_details.PIN_CODE
                          }}</span>
                        </div>
                      </li>
                      <!-- <li>
                        <p>{{ this.pdf_details.ADDRESS_LINE_2 }}</p>
                      </li> -->
                      <!-- <li>
                        <p>{{ this.pdf_details.COUNTRY_NAME }} {{ this.pdf_details.PIN_CODE }}</p>
                      </li> -->
                      <!-- <li>
                        <p>{{ this.pdf_details.ADDRESS_LINE_2 }}</p>
                      </li> -->
                      <!-- <li>
                        <p>{{ this.pdf_details.COUNTRY_NAME }} {{ this.pdf_details.PIN_CODE }} </p>
                      </li> -->
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 mt-2" style="text-align: right">
                  <div class="content-header-2">
                    <!-- <h5>Debit Note</h5> -->
                    <ul>
                      <li>
                        <p>
                          CO. REGN NO:
                          <span
                            >{{ this.pdf_details.CO_REGISTRATION_NO }}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Debit Note No.:
                          <span>{{ this.pdf_details.DEBIT_NOTE_NO }} </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Date:
                          <span>{{ this.pdf_details.DEBIT_NOTE_DATE }}</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="content-body">
                    <ul>
                      <li><h5>Policy Details:</h5></li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Vehicle No.</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_details.VEHICLE_NUMBER }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Make & Model</p></div>
                          <div class="col-md-3">
                            <span
                              >:
                              {{
                                this.pdf_details.VEHICLE_MAKE +
                                "/" +
                                this.pdf_details.VEHICLE_MODEL
                              }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Period of Insurance</p></div>
                          <div class="col-md-3">
                            <span
                              >:{{ this.pdf_details.INSURANCE_START_DATE
                              }}<br />{{
                                this.pdf_details.INSURANCE_END_DATE
                              }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Class</p></div>
                          <div class="col-md-3">
                            <span>:{{ this.pdf_details.CLASS }}</span>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Coverage</p></div>
                          <div class="col-md-3">
                            <span>:{{ this.pdf_details.COVERAGE }} </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Insurer</p></div>
                          <div class="col-md-3">
                            <span>:{{ this.pdf_details.INSURER }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Policy No.</p></div>
                          <div class="col-md-3">
                            <span>:{{ this.pdf_details.POLICY_NO }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>NCD</p></div>
                          <div class="col-md-3">
                            <span
                              >:${{
                                this.pdf_details.NO_CLAIM_DISC_VALUE
                              }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Remarks</p></div>
                          <div class="col-md-3">
                            <span>:{{ this.pdf_details.NCD_REMARKS }} </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Premium</p></div>
                          <div class="col-md-3">
                            <span>:${{ this.pdf_details.PREMIUM }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>GST</p></div>
                          <div class="col-md-3">
                            <span>:${{ this.pdf_details.GST }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Premium Discount</p></div>
                          <div class="col-md-3">
                            <span
                              >:${{ this.pdf_details.PREMIUM_DISCOUNT }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li class="total-amnt">
                        <div class="row">
                          <div class="col-md-3"><p>Premium Payable</p></div>
                          <div class="col-md-3">
                            <span>${{ this.pdf_details.PREMIUM_PAYABLE }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <ul class="mt-4">
                      <li><h5>Note:</h5></li>
                      <li>
                        1) Subject to Premium Warranty as per your policy.
                      </li>
                      <li>
                        2) Please pay the premium with in the Premium Warranty
                        to ensure the continuity of your insurance cover.
                      </li>
                      <li>
                        3) This is a Debit Note and this GST amount is
                        non-claimable as Input-Tax.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="content-footer">
                    <div class="row">
                      <div class="col-md-9">
                        <ul>
                          <li><h5>Mode of Payment</h5></li>
                          <li>
                            <div class="row">
                              <div class="col-md-3"><p>Cheque</p></div>
                              <div class="col-md-9 p-0">
                                <span>
                                  : All Cheque must be in favour of
                                  <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-md-3"><p>Bank Transfer</p></div>
                              <div class="col-md-9 p-0">
                                <span>: OCBC671885168001</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-md-3"><p>Paynow</p></div>
                              <div class="col-md-9 p-0">
                                <span>: EnterUEN201929524C</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-3">
                        <ul>
                          <li>
                            <img
                              src="../../assets/images/QR.png"
                              class="img-fluid mt-2"
                              alt="qr code"
                            />
                          </li>
                          <li><p class="scan-msg">SCAN TO PAY</p></li>
                          <li>
                            <p
                              class="me-4"
                              style="color: #000; text-align: center"
                            >
                              Scan QR Code for Payment
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="debitnote"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdf"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li><img src="../../assets/images/logo-txt.png" /></li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="heading">
                  <p>DEBIT NOTE</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="row">
              <div class="col-md-6" style="margin-bottom: 1rem">
                <div class="content-header-2">
                  <ul>
                    <li><h5>To.</h5></li>
                    <li>
                      <h5>{{ this.pdf_details.FIRST_NAME }}</h5>
                    </li>
                    <li>
                      <p>{{ this.pdf_details.ADDRESS_LINE_1 }}</p>
                    </li>
                    <li>
                      <p>{{ this.pdf_details.ADDRESS_LINE_2 }}</p>
                    </li>
                    <li>
                      <p>{{ this.pdf_details.COUNTRY_NAME }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6 mt-2" style="text-align: right">
                <div class="content-header-2">
                  <!-- <h5>Debit Note</h5> -->
                  <ul>
                    <li>
                      <p>CO. REGN NO: <span>201926967N</span></p>
                    </li>
                    <li>
                      <p>Debit Note No.: <span>D21NB24597065</span></p>
                    </li>
                    <li>
                      <p>Date: <span>17/11/2021</span></p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="content-body">
                <ul>
                  <li><h5 style="font-weight: 500">Policy Details:</h5></li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Vehicle No.</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_details.VEHICLE_NUMBER }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Make & Model</p></div>
                      <div class="col-md-3">
                        <span
                          >:
                          {{
                            this.pdf_details.VEHICLE_MAKE +
                            "/" +
                            this.pdf_details.VEHICLE_MODEL
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Period of Insurance</p></div>
                      <div class="col-md-3">
                        <span
                          >{{ this.pdf_details.INSURANCE_START_DATE }}-{{
                            this.pdf_details.INSURANCE_END_DATE
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Class</p></div>
                      <div class="col-md-3"><span></span></div>
                    </div>
                  </li>

                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Asking Price</p></div>
                      <div class="col-md-3">
                        <span>{{ this.pdf_details.ASKING_PRICE }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Coverage</p></div>
                      <div class="col-md-3">
                        <span>{{ this.pdf_details.COVERAGE }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Insurer</p></div>
                      <div class="col-md-3">
                        <span>{{ this.pdf_details.INSURER }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Policy No.</p></div>
                      <div class="col-md-3">
                        <span>{{ this.pdf_details.POLICY_NO }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>NCD</p></div>
                      <div class="col-md-3">
                        <span> {{ this.pdf_details.NCD_PERCENTAGE }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Remarks</p></div>
                      <div class="col-md-3"><span>: </span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Premium</p></div>
                      <div class="col-md-3">
                        <span>{{ this.pdf_details.PREMIUM }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>GST</p></div>
                      <div class="col-md-3">
                        <span>{{ this.pdf_details.GST }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Premium Discount</p></div>
                      <div class="col-md-3">
                        <span> {{ this.pdf_details.PREMIUM_DISCOUNT }}</span>
                      </div>
                    </div>
                  </li>
                  <li class="total-amnt">
                    <div class="row">
                      <div class="col-md-3"><p>Premium Payable</p></div>
                      <div class="col-md-3">
                        <span>{{ this.pdf_details.PREMIUM_PAYABLE }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul class="mt-4">
                  <li><h5 style="font-weight: 500">Note:</h5></li>
                  <li>1) Subject to Premium Warranty as per your policy.</li>
                  <li>
                    2) Please pay the premium with in the Premium Warranty to
                    ensure the continuity of your insurance cover.
                  </li>
                  <li>
                    3) This is a Debit Note and this GST amount is non-claimable
                    as Input-Tax.
                  </li>
                </ul>
              </div>
            </div>
            <div
              style="
                margin-top: 1rem,
                margin-bottom: 1rem,
                border-bottom: 2px solid #000;
              "
            ></div>
            <div class="col-md-12">
              <div class="content-footer">
                <div class="row">
                  <div class="col-md-9">
                    <ul>
                      <li><h5 style="font-weight: 500">Mode of Payment</h5></li>
                      <li>
                        <div class="row">
                          <div class="col-md-3">
                            <p style="font-weight: 500; color: #ff5d5d">
                              Cheque
                            </p>
                          </div>
                          <div class="col-md-9 p-0">
                            <span>
                              : All Cheque must be in favour of
                              <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3">
                            <p style="font-weight: 500; color: #ff5d5d">
                              Bank Transfer
                            </p>
                          </div>
                          <div class="col-md-9 p-0">
                            <span>: OCBC671885168001</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3">
                            <p style="font-weight: 500; color: #ff5d5d">
                              Paynow
                            </p>
                          </div>
                          <div class="col-md-9 p-0">
                            <span>: EnterUEN201929524C</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-3">
                    <ul>
                      <li>
                        <img
                          src="../../assets/images/QR.png"
                          class="img-fluid mt-2"
                          alt="qr code"
                        />
                      </li>
                      <li>
                        <p
                          class="scan-msg"
                          style="
                            background-color: #ff5d5d;
                            color: #fff;
                            font-weight: 500;
                            text-align: center;
                            margin-top: 5px;
                          "
                        >
                          SCAN TO PAY
                        </p>
                      </li>
                      <li>
                        <p style="color: #000; text-align: center">
                          Scan QR Code for Payment
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-2">
                <h5
                  class="modal-title"
                  id="staticBackdropLabel"
                  style="width: 150px"
                >
                  CREDIT NOTE
                </h5>
              </div>
              <div class="col-md-10">
                <button
                  class="downld animate__animated animate__bounceIn"
                  @click="generateReportcredit"
                >
                  <i class="fa-solid fa-cloud-arrow-down"></i> Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <!-- <div class="close-btn-div">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> -->
            <div class="row">
              <!-- <div class="col-md-12 offset-md-10">
                        
                     </div> -->
            </div>

            <div class="content">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-2">
                            <p>
                              1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="heading">
                      <p>TAX CREDIT NOTE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 offset-md-7 mt-2">
                  <div class="content-header-2">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Invoice No.</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_detail.INVOICE_NO }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Date</p></div>
                          <div class="col-md-3">
                            <span>:{{ this.pdf_detail.CREDIT_NOTE_DATE }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Agreement No:</p></div>
                          <div class="col-md-3">
                            <span
                              >: {{ this.pdf_detail.AGREEMENT_NUMBER }}</span
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="content-header-2">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>To</p></div>
                          <div class="col-md-8">
                            :<span> {{ this.pdf_detail.FIRST_NAME }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4"><p>Address</p></div>
                          <div class="col-md-8">
                            :

                            <span class="ms-1">
                              {{
                                this.pdf_detail.ADDRESS_LINE_1 +
                                "/" +
                                this.pdf_detail.ADDRESS_LINE_2
                              }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4">
                            <p>Vehicle Registration No.</p>
                          </div>
                          <div class="col-md-8">
                            :<span>{{ this.pdf_detail.VEHICLE_REG_NO }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4"><p>Engine Number</p></div>
                          <div class="col-md-8">
                            :<span> {{ this.pdf_detail.ENGINE_NO }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row mt-1">
                          <div class="col-md-4"><p>Chassis Number</p></div>
                          <div class="col-md-8">
                            :<span>{{ this.pdf_detail.CHASIS_NO }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="content-body-2">
                    <table class="table table-borderless">
                      <thead
                        style="
                          border-top: 2px solid #212121;
                          border-bottom: 2px solid #212121;
                        "
                      >
                        <tr>
                          <th scope="col">Qty</th>
                          <th scope="col">Description of Goods</th>
                          <th scope="col">Unit Price</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">{{ this.pdf_detail.QTY }}</th>
                          <td>
                            {{
                              this.pdf_detail.VEHICLE_MAKE +
                              "/" +
                              this.pdf_detail.VEHICLE_MODEL
                            }}
                            <span class="ms-1">{{
                              this.pdf_detail.DESCRIPTION
                            }}</span>
                          </td>
                          <td>$ <span class="ms-1">6,000.00</span></td>
                          <td>
                            $
                            <span class="ms-1">{{
                              this.pdf_detail.AMOUNT
                            }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="content-footer" style="border: 1px solid #212121">
                    <table class="table table-borderless mb-0">
                      <tbody>
                        <tr>
                          <!-- <th scope="col">Qty</th> -->
                          <td></td>
                          <td style="border-left: 1px solid #212121">
                            Amount SGD$
                          </td>
                          <td style="border-left: 1px solid #212121">
                            $
                            <span class="ms-1">{{
                              this.pdf_detail.AMOUNT
                            }}</span>
                          </td>
                        </tr>

                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            Terms of Payment:
                            <span class="MS-2">{{
                              this.pdf_detail.TERMS_OF_PAYMENT
                            }}</span>
                          </td>
                          <td style="border-left: 1px solid #212121">
                            $
                            <span class="ms-1">{{
                              this.pdf_detail.GST_7_PERCENT
                            }}</span>
                          </td>
                          <td style="border-left: 1px solid #212121"></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            All cheques issued must be made in the favour of Lek
                            Auto Pte Ltd
                          </td>
                          <td style="border-left: 1px solid #212121">
                            $ <span class="ms-1">Total SGD $</span>
                          </td>
                          <td style="border-left: 1px solid #212121">
                            $
                            <span class="ms-1">{{
                              this.pdf_detail.TOTAL_SGD
                            }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="creditnote"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li><img src="../../assets/images/logo-txt.png" /></li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div
                  class="heading"
                  style="
                    border: 1px solid #000;
                    padding: 10px;
                    text-align: center;
                  "
                >
                  <p>CREDIT NOTE</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12 offset-md-7 mt-2">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Invoice No.</p></div>
                      <div class="col-md-3"><span>: TI/2021-11/1303</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Date</p></div>
                      <div class="col-md-3"><span>: 9/11/2021</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Agreement No:</p></div>
                      <div class="col-md-3"><span>: SA23554</span></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-12" style="margin-bottom: 1rem">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>To</p></div>
                      <div class="col-md-8">:<span> WHEELERS AVENUE</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4"><p>Address</p></div>
                      <div class="col-md-8">
                        :<span>
                          258D COMPASSVALE ROAD #07-595 COMPASSVALE GARDEN
                          <span class="ms-1">SINGAPORE 544258 </span></span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4">
                        <p>Vehicle Registration No.</p>
                      </div>
                      <div class="col-md-8">
                        :<span> {{ this.pdf_detail.VEHICLE_NUMBER }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4"><p>Engine Number</p></div>
                      <div class="col-md-8">:<span> HR16189457D</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row mt-1">
                      <div class="col-md-4"><p>Chassis Number</p></div>
                      <div class="col-md-8">
                        :<span> JN1YAAM20Z0002323</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-12">
              <div class="content-body-2">
                <table class="table table-borderless">
                  <thead
                    style="
                      border-top: 2px solid #212121;
                      border-bottom: 2px solid #212121;
                    "
                  >
                    <tr>
                      <th scope="col">Qty</th>
                      <th scope="col">Description of Goods</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>
                        {{
                          this.pdf_detail.MAKE_NAME +
                          "/" +
                          this.pdf_detail.MODEL_NAME
                        }}
                        <span class="ms-1">NV200</span>
                      </td>
                      <td>$ <span class="ms-1">6,000.00</span></td>
                      <td>$ <span class="ms-1">6,000.00</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-md-12">
              <div class="content-footer" style="border: 1px solid #212121">
                <table class="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <!-- <th scope="col">Qty</th> -->
                      <td></td>
                      <td style="border-left: 1px solid #212121">
                        Amount SGD$
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1">6,000.00</span>
                      </td>
                    </tr>

                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>Terms of Payment: <span class="MS-2">CASH</span></td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1">7% GST</span>
                      </td>
                      <td style="border-left: 1px solid #212121"></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        All cheques issued must be made in the favour of Lek
                        Auto Pte Ltd
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1">Total SGD $</span>
                      </td>
                      <td style="border-left: 1px solid #212121">
                        $ <span class="ms-1">6,000.00</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-3"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content" v-show="canShowSalesNote">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-2">
                <h5
                  class="modal-title"
                  id="staticBackdropLabel"
                  style="width: 150px"
                >
                  SALES RECEIPT
                </h5>
              </div>
              <div class="col-md-10">
                <button class="downld animate__animated animate__bounceIn">
                  <i
                    class="fa-solid fa-cloud-arrow-down"
                    @click="generateReportsales"
                  ></i>
                  Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <!-- <div class="close-btn-div">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> -->
            <div class="row">
              <!-- <div class="col-md-12 offset-md-10">
                        
                     </div> -->
            </div>
            <div class="content">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-2">
                            <p>
                              1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-0">
                    <div class="heading">
                      <p>SALES RECEIPT</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <!-- header  -->
                <div class="col-md-8">
                  <div class="content-header-3">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>To</p></div>
                          <div class="col-md-8">
                            <span>: {{ this.pdf_detai.FIRST_NAME }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Address</p></div>
                          <div class="col-md-8">
                            <span
                              >{{ this.pdf_detai.ADDRESS_LINE_1 }}
                              {{ this.pdf_detai.ADDRESS_LINE_2 }}
                              <span class="ms-1"
                                >{{ this.pdf_detai.COUNTRY_NAME }}
                                {{ this.pdf_detai.PIN_CODE }}</span
                              ></span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4">
                            <p>Vehicle Registration No.</p>
                          </div>
                          <div class="col-md-8">
                            <span
                              >: T00165 0
                              <span style="display: block">
                                {{
                                  this.pdf_detai.MAKE_NAME +
                                  "/" +
                                  this.pdf_detai.MODEL_NAME
                                }}</span
                              ></span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p></p></div>
                          <div class="col-md-8"><span> </span></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="content-header-3">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-5"><p>S-No.</p></div>
                          <div class="col-md-7">
                            <span>: {{ this.pdf_detai.S_NO }} </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-5"><p>Date</p></div>
                          <div class="col-md-7">
                            <span>: {{ this.pdf_detai.DATE }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-5"><p>Agreement No.</p></div>
                          <div class="col-md-7">
                            <span>: {{ this.pdf_detai.AGREE }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- content body  -->
                <div class="col-md-12">
                  <div class="content-body-3">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-9"><p>Selling Price.</p></div>
                          <div class="col-md-3 data">
                            $<span>{{ this.pdf_detai.SELLING_PRICE }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-9"><p>Transfer Fee</p></div>
                          <div class="col-md-3">
                            $<span>{{ this.pdf_detai.TRANSFER_FEE }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-9"><p>Road Tax (1/2 yr)</p></div>
                          <div class="col-md-3">
                            <span>{{ this.pdf_detai.ROAD_TAX }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-9">
                            <p>Insurance Premium (Comp/ TPFT/ TPO)- NTUC</p>
                          </div>
                          <div class="col-md-3 data">
                            $<span>{{ this.pdf_detai.FINAL_PREMIUM }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-9"><p>HP Facilities Fees</p></div>
                          <div class="col-md-3 data">
                            <span>
                              {{ this.pdf_detai.HP_FACILITIES_FEE }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-9">
                            <p>Loan Advance Payment</p>
                          </div>
                          <div class="col-md-3 data">
                            $<span>{{ this.pdf_detai.ADVANCE_PAYMENT }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Others</p></div>
                          <div class="col-md-6"><span> </span></div>
                          <div class="col-md-3 data"><span> </span></div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-9"><p>Admin Fee</p></div>
                          <div class="col-md-3 data">
                            <span>{{ this.pdf_detai.ADMIN_FEE }} </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-9"><p>TOTAL PAYABLE</p></div>
                          <div class="col-md-3 data">
                            $<span>{{ this.pdf_detai.TOTAL_PAYABLE }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-2">
                            <p><u>Less</u></p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-9"><p>Deposit paid</p></div>
                          <div class="col-md-3 data">
                            $<span>{{ this.pdf_detai.DEPOSIT_PAID }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-3">
                                <p>Trade in Vehicle No.</p>
                              </div>
                              <div class="col-md-3 data">
                                <span>{{
                                  this.pdf_detai.TRADE_IN_VEH_NO
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-1">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="row" style="margin-left: 2px">
                                  <div
                                    class="col-md-6 data"
                                    style="width: 118px !important"
                                  >
                                    $<span>-</span>
                                  </div>
                                  <div class="col-md-6 p-0">
                                    <p>(Trade in Price)</p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-5 ps-0">
                                <div class="row">
                                  <div class="col-md-2">
                                    <p>Less</p>
                                    <div class="col-md-3 data">
                                      <span>{{
                                        this.pdf_detai.TRADE_IN_AMOUNT
                                      }}</span>
                                    </div>
                                  </div>
                                  <div
                                    class="col-md-4 data"
                                    style="width: 104px !important"
                                  >
                                    <span>$</span>
                                  </div>
                                  <div class="col-md-6">
                                    <p>(Finance Due)</p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-3 data">$<span></span></div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-2"><p>Loan Amount</p></div>
                          <div class="col-md-7">
                            <div class="row">
                              <div class="col-md-7 data">
                                <span
                                  style="
                                    width: fit-content !important;
                                    float: left !important;
                                  "
                                >
                                  {{ this.pdf_detai.LOAN_AMOUNT }}</span
                                >
                              </div>
                              <div class="col-md-1">X</div>
                              <div
                                class="col-md-2 data"
                                style="width: 55px !important"
                              >
                                <span style="float: left !important">
                                  {{ this.pdf_detai.MONTH }}</span
                                >
                              </div>
                              <div class="col-md-2 p-0">
                                <p>Months</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3 data">
                            $<span> {{ this.pdf_detai.MTHS }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Finance Co.</p></div>
                          <div class="col-md-6">
                            <u
                              ><span>{{ this.pdf_detai.FINANCE_CO }}</span></u
                            >
                          </div>
                          <div class="col-md-3 data"><span></span></div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-6"><p>BALANCE DUE</p></div>
                          <div class="col-md-3">
                            <span style="float: right">Cash/ Cheque No. </span>
                          </div>
                          <div class="col-md-3 data"><span> </span></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- content body 2  -->
                <div class="col-md-12">
                  <div
                    class="content-body-2 p-1"
                    style="border: 1px solid #212121"
                  >
                    <p style="color: red"><b>Delivery Acknowledgement</b></p>
                    <span>
                      I / We confirm that I/we have examined the above said
                      vehicle and that the same is good order and proper
                      condition and suitable for the purpose for which I/We
                      require it and to my/ our satisfaction in every respect
                      and that I/We have to take delivery of vehicle on
                      (date)<span>________________</span>(time)<span>
                        ________________</span
                      >am/pm. The buyer henceforth on accepting delivery of the
                      vehicle on the time indicated above I/ We will be fully
                      responsible for the vehicle use including any traffic
                      offence and or illegal acts
                    </span>
                  </div>
                </div>
                <!-- content footer  -->
                <div class="col-md-12">
                  <div class="content-footer-2">
                    <div class="row mb-5">
                      <div class="col-md-6">
                        <p>Signed for & behalf of</p>
                      </div>
                      <div class="col-md-6">
                        <p style="float: right">Agreed & Aceepted by Buyer</p>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-7">
                        <ul>
                          <li>
                            <span style="border-top: 2px solid #000"
                              >Authorised Signature</span
                            >
                          </li>
                          <li>
                            <div class="row mt-2" style="text-align: left">
                              <div class="col-md-1 p-0"><p>Name</p></div>
                              <div class="col-md-8"><span>: </span></div>
                            </div>
                          </li>
                          <li>
                            <div class="row" style="text-align: left">
                              <div class="col-md-1 p-0"><p>HP</p></div>
                              <div class="col-md-8"><span>: </span></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-5">
                        <ul style="float: right; text-align: center">
                          <li>
                            <span
                              style="border-top: 2px solid #000; width: 100px"
                              >Authorised Signature of Buyer</span
                            >
                          </li>
                          <li><span>Company Stamp</span></li>
                          <li>
                            <div class="row mt-2" style="text-align: left">
                              <div class="col-md-4 p-0"><p>Name</p></div>
                              <div class="col-md-8"><span>: </span></div>
                            </div>
                          </li>
                          <li>
                            <div class="row" style="text-align: left">
                              <div class="col-md-4 p-0"><p>NRIC No.</p></div>
                              <div class="col-md-8"><span>: </span></div>
                            </div>
                          </li>
                          <li>
                            <div class="row" style="text-align: left">
                              <div class="col-md-4 p-0">
                                <p>Handphone No.</p>
                              </div>
                              <div class="col-md-8"><span>: </span></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Salesnote"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="80%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdf2"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3 p-0">
                <div class="heading">
                  <p>SALES RECEIPT</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <!-- header  -->
            <div class="col-md-8">
              <div class="content-header-3">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>To</p></div>
                      <div class="col-md-8">
                        <span>: CRAY VENTURES PRIVATE LIMITED</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Address</p></div>
                      <div class="col-md-8">
                        <span
                          >{{ this.pdf_detai.ADDRESS_LINE_1 }}
                          {{ this.pdf_detai.ADDRESS_LINE_2 }}
                          <span class="ms-1"
                            >{{ this.pdf_detai.COUNTRY_NAME }}
                            {{ this.pdf_detai.PIN_CODE }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4">
                        <p>Vehicle Registration No.</p>
                      </div>
                      <div class="col-md-8">
                        <span
                          >: T00165 0
                          <span style="display: block">
                            {{
                              this.pdf_detai.MAKE_NAME +
                              "/" +
                              this.pdf_detai.MODEL_NAME
                            }}</span
                          ></span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p></p></div>
                      <div class="col-md-8"><span> </span></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="content-header-3">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>S-No.</p></div>
                      <div class="col-md-7">
                        <span>: SR/2021-11/10305</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>Date</p></div>
                      <div class="col-md-7"><span>: 26/11/2021</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>Agreement No.</p></div>
                      <div class="col-md-7"><span>: SA23232</span></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- content body  -->
            <div class="col-md-12">
              <div class="content-body-3">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-9"><p>Selling Price.</p></div>
                      <div class="col-md-3 data">
                        $<span>{{ this.pdf_detai.SELLING_PRICE }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-9"><p>Transfer Fee</p></div>
                      <div class="col-md-3"></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-9"><p>Road Tax (1/2 yr)</p></div>
                      <div class="col-md-3">
                        <span>{{ this.pdf_detai.ROAD_TAX }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-9">
                        <p>Insurance Premium (Comp/ TPFT/ TPO)- NTUC</p>
                      </div>
                      <div class="col-md-3 data">
                        $<span>{{ this.pdf_detai.INSURANCE_PREMIUM }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-9"><p>HP Facilities Fees</p></div>
                      <div class="col-md-3 data"><span> </span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-9">
                        <p>Loan Advance Payment</p>
                      </div>
                      <div class="col-md-3 data">
                        $<span>{{ this.pdf_detai.ADVANCE_PAYMENT }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Others</p></div>
                      <div class="col-md-6"><span> </span></div>
                      <div class="col-md-3 data"><span> </span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-9"><p>Admin Fee</p></div>
                      <div class="col-md-3 data"><span> </span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-9"><p>TOTOL PAYABLE</p></div>
                      <div class="col-md-3 data">
                        $<span>{{ this.pdf_detai.TOTAL_PAYABLE }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-2">
                        <p><u>Less</u></p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-9"><p>Deposit paid</p></div>
                      <div class="col-md-3 data">
                        $<span>{{ this.pdf_detai.DEPOSIT_PAID }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-3">
                            <p>Trade in Vehicle No.</p>
                          </div>
                          <div class="col-md-3 data">
                            <span>{{ this.pdf_detai.TRADE_IN_VEH_NO }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mt-1">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="row" style="margin-left: 2px">
                              <div
                                class="col-md-6 data"
                                style="width: 118px !important"
                              >
                                $<span>-</span>
                              </div>
                              <div class="col-md-6 p-0">
                                <p>(Trade in Price)</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-5 ps-0">
                            <div class="row">
                              <div class="col-md-2">
                                <p>Less</p>
                                <div class="col-md-3 data">
                                  <span>{{
                                    this.pdf_detai.TRADE_IN_AMOUNT
                                  }}</span>
                                </div>
                              </div>
                              <div
                                class="col-md-4 data"
                                style="width: 104px !important"
                              >
                                <span>$</span>
                              </div>
                              <div class="col-md-6">
                                <p>(Finance Due)</p>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3 data">$<span></span></div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-2"><p>Loan Amount</p></div>
                      <div class="col-md-7">
                        <div class="row">
                          <div class="col-md-7 data">
                            <span
                              style="
                                width: fit-content !important;
                                float: left !important;
                              "
                            >
                              {{ this.pdf_detai.LOAN_AMOUNT }}</span
                            >
                          </div>
                          <div class="col-md-1">X</div>
                          <div
                            class="col-md-2 data"
                            style="width: 55px !important"
                          >
                            <span style="float: left !important">
                              {{ this.pdf_detai.MONTH }}</span
                            >
                          </div>
                          <div class="col-md-2 p-0">
                            <p>Mths</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3 data">
                        $<span> {{ this.pdf_detai.MTHS }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Finance Co.</p></div>
                      <div class="col-md-6">
                        <u><span>NITED OVERSEAS BANK LIMITED</span></u>
                      </div>
                      <div class="col-md-3 data"><span></span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-6"><p>BALANCE DUE</p></div>
                      <div class="col-md-3">
                        <span style="float: right">Cash/ Cheque No. </span>
                      </div>
                      <div class="col-md-3 data"><span> </span></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- content body 2  -->
            <div class="col-md-12">
              <div class="content-body-2 p-1" style="border: 1px solid #212121">
                <p style="color: red"><b>Delivery Acknowledgement</b></p>
                <span>
                  I / We confirm that I/we have examined the above said vehicle
                  and that the same is good order and proper condition and
                  suitable for the purpose for which I/We require it and to my/
                  our satisfaction in every respect and that I/We have to take
                  delivery of vehicle on
                  (date)<span>________________</span>(time)<span>
                    ________________</span
                  >am/pm. The buyer henceforth on accepting delivery of the
                  vehicle on the time indicated above I/ We will be fully
                  responsible for the vehicle use including any traffic offence
                  and or illegal acts
                </span>
              </div>
            </div>
            <!-- content footer  -->
            <div class="col-md-12">
              <div class="content-footer-2">
                <div class="row mb-5">
                  <div class="col-md-6">
                    <p>Signed for & behalf of</p>
                  </div>
                  <div class="col-md-6">
                    <p style="float: right">Agreed & Aceepted by Buyer</p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-7">
                    <ul>
                      <li>
                        <span style="border-top: 2px solid #000"
                          >Authorised Signature</span
                        >
                      </li>
                      <li>
                        <div class="row mt-2" style="text-align: left">
                          <div class="col-md-1 p-0"><p>Name</p></div>
                          <div class="col-md-8"><span>: </span></div>
                        </div>
                      </li>
                      <li>
                        <div class="row" style="text-align: left">
                          <div class="col-md-1 p-0"><p>HP</p></div>
                          <div class="col-md-8"><span>: </span></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-5">
                    <ul style="float: right; text-align: center">
                      <li>
                        <span style="border-top: 2px solid #000; width: 100px"
                          >Authorised Signature of Buyer</span
                        >
                      </li>
                      <li><span>Company Stamp</span></li>
                      <li>
                        <div class="row mt-2" style="text-align: left">
                          <div class="col-md-4 p-0"><p>Name</p></div>
                          <div class="col-md-8"><span>: </span></div>
                        </div>
                      </li>
                      <li>
                        <div class="row" style="text-align: left">
                          <div class="col-md-4 p-0"><p>NRIC No.</p></div>
                          <div class="col-md-8"><span>: </span></div>
                        </div>
                      </li>
                      <li>
                        <div class="row" style="text-align: left">
                          <div class="col-md-4 p-0">
                            <p>Handphone No.</p>
                          </div>
                          <div class="col-md-8"><span>: </span></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-4"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" v-show="canShowTaxNote">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-2">
                <h5
                  class="modal-title"
                  id="staticBackdropLabel"
                  style="width: 150px"
                >
                  TAX INVOICE
                </h5>
              </div>
              <div class="col-md-10">
                <button
                  class="downld animate__animated animate__bounceIn"
                  @click="generateReportTax"
                >
                  <i class="fa-solid fa-cloud-arrow-down"></i> Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <!-- <div class="close-btn-div">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> -->
            <div class="row">
              <!-- <div class="col-md-12 offset-md-10">
                        
                     </div> -->
            </div>
            <div class="content" v-show="canShowTaxNote">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-2">
                            <p>
                              1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 p-0">
                    <div class="heading">
                      <p>TAX INVOICE</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <!-- header  -->
                <div class="col-md-8">
                  <div class="content-header-2">
                    <ul>
                      <li><p>Bill To,</p></li>
                      <li>
                        <p>{{ this.pdf_deta.FIRST_NAME }}</p>
                      </li>
                      <li>
                        <p>
                          {{ this.pdf_deta.ADDRESS_LINE_1 }}
                          {{ this.pdf_deta.ADDRESS_LINE_2 }}
                        </p>
                      </li>
                      <li>
                        <p>
                          {{ this.pdf_deta.COUNTRY_NAME }}
                          {{ this.pdf_deta.PIN_CODE }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="content-header-2">
                    <ul>
                      <li>
                        <div class="row">
                          <div class="col-md-5"><p>Invoice No.</p></div>
                          <div class="col-md-7">
                            <span>{{ this.pdf_deta.INVOICE_NO }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-5"><p>Date</p></div>
                          <div class="col-md-7">
                            <span>{{ this.pdf_deta.DATE }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-5"><p>VSA No.</p></div>
                          <div class="col-md-7">
                            <span>{{ this.pdf_deta.VSA_NO }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- after heading sec  -->
                <div class="col-md-12">
                  <div
                    class="content-body-2 p-1"
                    style="border: 1px solid #212121"
                  >
                    <Div class="row">
                      <div class="col-md-7">
                        <div class="content-header-3">
                          <ul>
                            <li>
                              <div class="row">
                                <div class="col-md-6">
                                  <p>Vehicle Registration No.</p>
                                </div>
                                <div class="col-md-6">
                                  <span
                                    >: {{ this.pdf_deta.VEHICLE_NUMBER }}</span
                                  >
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-6"><p>Vehicle Make</p></div>
                                <div class="col-md-6">
                                  <span>: {{ this.pdf_deta.MAKE_NAME }}</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-6"><p>VehicleModel</p></div>
                                <div class="col-md-6">
                                  <span>: {{ this.pdf_deta.MODEL_NAME }}</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-6">
                                  <p>Original Registration Date</p>
                                </div>
                                <div class="col-md-6">
                                  <span
                                    >:
                                    {{ this.pdf_deta.REGISTRATION_DATE }}</span
                                  >
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="content-header-3">
                          <ul>
                            <li>
                              <div class="row">
                                <div class="col-md-5"><p>Engine No.</p></div>
                                <div class="col-md-7">
                                  <span
                                    >:{{ this.pdf_deta.VEHICLE_NUMBER }}</span
                                  >
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-5"><p>Chassis No.</p></div>
                                <div class="col-md-7">
                                  <span>:{{ this.pdf_deta.CHASIS_NO }}</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div class="row">
                                <div class="col-md-5"><p>Sales Person</p></div>
                                <div class="col-md-7">
                                  <span
                                    >: {{ this.pdf_deta.SALES_PERSON }}</span
                                  >
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="content-footer" style="border: 1px solid #212121">
                    <table class="table table-borderless mb-0 bordered-table">
                      <thead style="border-bottom: 1px solid #212121">
                        <tr></tr>
                        <tr>
                          <th scope="col">Item Description</th>
                          <th scope="col" class="border-td">Rate</th>
                          <th scope="col" class="border-td">
                            Quantity <span class="ms-1"></span>
                          </th>
                          <th scope="col" class="border-td">
                            Grossary Amount($)
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="user_detail in taxapdetails"
                        :key="user_detail.RECORD_SYS_ID"
                      >
                        <tr>
                          <td>{{ user_detail.DESCRIPTION }}</td>
                          <td class="border-td">
                            {{ user_detail.UNIT_PRICE }}
                          </td>
                          <td class="border-td">
                            {{ user_detail.DISCOUNT_VALUE }}
                          </td>
                          <td class="border-td">
                            {{ user_detail.TOTAL_PRICE }}
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <td></td>
                        <td class="border-td"><span class="ms-1"></span></td>
                        <td class="border-td">
                          <span class="ms-1">{{
                            this.pdf_deta.ADVANCE_PAYMENT
                          }}</span>
                        </td>

                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td style="border-bottom: 1px solid #212121"></td>
                          <td class="border-td border-td-2"></td>
                          <td class="border-td border-td-2"></td>
                          <td class="border-td border-td-3"></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>Payment Details:</td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>Sales Deposit</td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td">
                            <span class="ms-1">{{
                              this.pdf_deta.SALES_DEPOSIT
                            }}</span>
                          </td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            Less: Trade In
                            <span class="ms-3">{{
                              this.pdf_deta.TRADE_IN_AMOUNT
                            }}</span>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1">-</span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>Less: PAYMENT PREVIOUSLY MADE</td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td">
                            $
                            <span class="ms-1">{{
                              this.pdf_deta.PREVIOUSLY_PAYMENT
                            }}</span>
                          </td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-6">
                                <p>{{ this.pdf_deta.FINANCE_COMPANY }}</p>
                              </div>
                              <div class="col-md-6 p-0">
                                <span>{{ this.pdf_deta.FINANCE_COMPANY }}</span>
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td">
                            $ <span class="ms-1">{{ this.pdf_det }}</span>
                          </td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Finance Compnay:</p>
                              </div>
                              <div class="col-md-7">
                                :
                                <span class="ms-1">{{
                                  this.pdf_deta.FINANCE_COMPANY
                                }}</span>
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Loan Amount:</p>
                              </div>
                              <div class="col-md-7">
                                <span class="ms-1">{{
                                  this.pdf_deta.LOAN_AMOUNT
                                }}</span>
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Loan Tenure</p>
                              </div>
                              <div class="col-md-7">
                                <span class="ms-1">{{
                                  this.pdf_deta.LOAN_TENURE
                                }}</span>
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Interest Rate</p>
                              </div>
                              <div class="col-md-7">
                                :
                                <span class="ms-1">{{
                                  this.pdf_deta.INTEREST_RATE
                                }}</span>
                              </div>
                            </div>
                          </td>
                          <td class="border-td"><span class="ms-1"></span></td>
                          <td class="border-td"><span class="ms-1"></span></td>
                        </tr>
                        <tr>
                          <!-- <th scope="row">1</th> -->
                          <td class="border-td-2">Amount Due From Customer</td>
                          <td class="border-td border-td-2">
                            <span class="ms-1"></span>
                          </td>
                          <td class="border-td border-td-2">
                            <span class="ms-1">-</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- content footer  -->
                <div class="content-footer">
                  <p class="mt-2">
                    *GST is computed at 7% of 50% of the taxable supply
                  </p>
                  <div class="row">
                    <div class="col-md-9">
                      <ul>
                        <li><h5>Mode of Payment</h5></li>
                        <li>
                          <div class="row">
                            <div class="col-md-3"><p>Cheque</p></div>
                            <div class="col-md-9 p-0">
                              <span>
                                : All Cheque must be in favour of
                                <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                              </span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-3"><p>Bank Transfer</p></div>
                            <div class="col-md-9 p-0">
                              <span>: OCBC671885168001</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-3"><p>Paynow</p></div>
                            <div class="col-md-9 p-0">
                              <span>: EnterUEN201929524C</span>
                            </div>
                          </div>
                        </li>
                        <li class="mt-4">
                          <p>Memo:</p>
                          <span
                            >This is a computer generated document,no signature
                            is required.</span
                          >
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-3">
                      <ul>
                        <li>
                          <img
                            src="../../assets/images/QR.png"
                            class="img-fluid mt-2"
                            alt="qr code"
                          />
                        </li>
                        <li><p class="scan-msg">SCAN TO PAY</p></li>
                        <li>
                          <p
                            class="me-4"
                            style="color: #000; text-align: center"
                          >
                            Scan QR Code for Payment
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      style="display: none; padding: 20px"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Tax Invoice"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdf3"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content" v-show="canShowTaxNote">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3 p-0">
                <div class="heading">
                  <p>TAX INVOICE</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <!-- header  -->
            <div class="col-md-8">
              <div class="content-header-2">
                <ul>
                  <li><p>Bill To,</p></li>
                  <li>
                    <p>{{ this.pdf_deta.FIRST_NAME }}</p>
                  </li>
                  <li>
                    <p>
                      {{ this.pdf_deta.ADDRESS_LINE_1 }}
                      {{ this.pdf_deta.ADDRESS_LINE_2 }}
                    </p>
                  </li>
                  <li>
                    <p>
                      {{ this.pdf_deta.COUNTRY_NAME }}
                      {{ this.pdf_deta.PIN_CODE }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-4 mt-2">
              <div class="content-header-2">
                <ul>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>Invoice No.</p></div>
                      <div class="col-md-7">
                        <span>{{ this.pdf_deta.INVOICE_NO }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>Date</p></div>
                      <div class="col-md-7">
                        <span>{{ this.pdf_deta.DATE }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-5"><p>VSA No.</p></div>
                      <div class="col-md-7">
                        <span>{{ this.pdf_deta.VSA_NO }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- after heading sec  -->
            <div class="col-md-12">
              <div class="content-body-2 p-1" style="border: 1px solid #212121">
                <Div class="row">
                  <div class="col-md-7">
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>Vehicle Registration No.</p>
                            </div>
                            <div class="col-md-6">
                              <span>: {{ this.pdf_deta.VEHICLE_NUMBER }}</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-6"><p>Vehicle Make</p></div>
                            <div class="col-md-6">
                              <span>: {{ this.pdf_deta.MAKE_NAME }}</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-6"><p>VehicleModel</p></div>
                            <div class="col-md-6">
                              <span>: {{ this.pdf_deta.MODEL_NAME }}</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-6">
                              <p>Original Registration Date</p>
                            </div>
                            <div class="col-md-6">
                              <span
                                >: {{ this.pdf_deta.REGISTRATION_DATE }}</span
                              >
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="content-header-3">
                      <ul>
                        <li>
                          <div class="row">
                            <div class="col-md-5"><p>Engine No.</p></div>
                            <div class="col-md-7">
                              <span>:{{ this.pdf_deta.VEHICLE_NUMBER }}</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5"><p>Chassis No.</p></div>
                            <div class="col-md-7">
                              <span>:{{ this.pdf_deta.CHASIS_NO }}</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5"><p>Sales Person</p></div>
                            <div class="col-md-7">
                              <span>: {{ this.pdf_deta.SALES_PERSON }}</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Div>
              </div>
            </div>

            <div class="col-md-12">
              <div class="content-footer" style="border: 1px solid #212121">
                <table class="table table-borderless mb-0 bordered-table">
                  <thead style="border-bottom: 1px solid #212121">
                    <tr></tr>
                    <tr>
                      <th scope="col">Item Description</th>
                      <th scope="col" class="border-td">Rate</th>
                      <th scope="col" class="border-td">
                        Quantity <span class="ms-1"></span>
                      </th>
                      <th scope="col" class="border-td">Grossary Amount($)</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="user_detail in taxapdetails"
                    :key="user_detail.RECORD_SYS_ID"
                  >
                    <tr>
                      <td>{{ user_detail.DESCRIPTION }}</td>
                      <td class="border-td">
                        {{ user_detail.UNIT_PRICE }}
                      </td>
                      <td class="border-td">
                        {{ user_detail.DISCOUNT_VALUE }}
                      </td>
                      <td class="border-td">
                        {{ user_detail.TOTAL_PRICE }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <td></td>
                    <td class="border-td"><span class="ms-1"></span></td>
                    <td class="border-td">
                      <span class="ms-1">{{
                        this.pdf_deta.ADVANCE_PAYMENT
                      }}</span>
                    </td>

                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td style="border-bottom: 1px solid #212121"></td>
                      <td class="border-td border-td-2"></td>
                      <td class="border-td border-td-2"></td>
                      <td class="border-td border-td-3"></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>Payment Details:</td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td"><span class="ms-1"></span></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>Sales Deposit</td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td">
                        <span class="ms-1">{{
                          this.pdf_deta.SALES_DEPOSIT
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        Less: Trade In
                        <span class="ms-3">{{
                          this.pdf_deta.TRADE_IN_AMOUNT
                        }}</span>
                      </td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td"><span class="ms-1">-</span></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>Less: PAYMENT PREVIOUSLY MADE</td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td">
                        $
                        <span class="ms-1">{{
                          this.pdf_deta.PREVIOUSLY_PAYMENT
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td></td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td"><span class="ms-1"></span></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-6">
                            <p>{{ this.pdf_deta.FINANCE_COMPANY }}</p>
                          </div>
                          <div class="col-md-6 p-0">
                            <span>{{ this.pdf_deta.FINANCE_COMPANY }}</span>
                          </div>
                        </div>
                      </td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td">
                        $ <span class="ms-1">{{ this.pdf_det }}</span>
                      </td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-5">
                            <p>Finance Compnay:</p>
                          </div>
                          <div class="col-md-7">
                            :
                            <span class="ms-1">{{
                              this.pdf_deta.FINANCE_COMPANY
                            }}</span>
                          </div>
                        </div>
                      </td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td"><span class="ms-1"></span></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-5">
                            <p>Loan Amount:</p>
                          </div>
                          <div class="col-md-7">
                            <span class="ms-1">{{
                              this.pdf_deta.LOAN_AMOUNT
                            }}</span>
                          </div>
                        </div>
                      </td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td"><span class="ms-1"></span></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-5">
                            <p>Loan Tenure</p>
                          </div>
                          <div class="col-md-7">
                            <span class="ms-1">{{
                              this.pdf_deta.LOAN_TENURE
                            }}</span>
                          </div>
                        </div>
                      </td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td"><span class="ms-1"></span></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-5">
                            <p>Interest Rate</p>
                          </div>
                          <div class="col-md-7">
                            :
                            <span class="ms-1">{{
                              this.pdf_deta.INTEREST_RATE
                            }}</span>
                          </div>
                        </div>
                      </td>
                      <td class="border-td"><span class="ms-1"></span></td>
                      <td class="border-td"><span class="ms-1"></span></td>
                    </tr>
                    <tr>
                      <!-- <th scope="row">1</th> -->
                      <td class="border-td-2">Amount Due From Customer</td>
                      <td class="border-td border-td-2">
                        <span class="ms-1"></span>
                      </td>
                      <td class="border-td border-td-2">
                        <span class="ms-1">-</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- content footer  -->
            <div class="content-footer">
              <p class="mt-2">
                *GST is computed at 7% of 50% of the taxable supply
              </p>
              <div class="row">
                <div class="col-md-9">
                  <ul>
                    <li><h5>Mode of Payment</h5></li>
                    <li>
                      <div class="row">
                        <div class="col-md-3"><p>Cheque</p></div>
                        <div class="col-md-9 p-0">
                          <span>
                            : All Cheque must be in favour of
                            <span><b>"LEK AUTO PTE LTD"</b></span> Only.
                          </span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-3"><p>Bank Transfer</p></div>
                        <div class="col-md-9 p-0">
                          <span>: OCBC671885168001</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-3"><p>Paynow</p></div>
                        <div class="col-md-9 p-0">
                          <span>: EnterUEN201929524C</span>
                        </div>
                      </div>
                    </li>
                    <li class="mt-4">
                      <p>Memo:</p>
                      <span
                        >This is a computer generated document,no signature is
                        required.</span
                      >
                    </li>
                  </ul>
                </div>
                <div class="col-md-3">
                  <ul>
                    <li>
                      <img
                        src="../../assets/images/QR.png"
                        class="img-fluid mt-2"
                        alt="qr code"
                      />
                    </li>
                    <li><p class="scan-msg">SCAN TO PAY</p></li>
                    <li>
                      <p class="me-4" style="color: #000; text-align: center">
                        Scan QR Code for Payment
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-6"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" v-show="canShowPurchaseNote">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-4">
                <h5 class="modal-title" id="staticBackdropLabel">
                  PURCHASE SETTLEMENT
                </h5>
              </div>
              <div class="col-md-8">
                <button
                  class="downld animate__animated animate__bounceIn"
                  @click="generateReportPurchase"
                >
                  <i class="fa-solid fa-cloud-arrow-down"></i> Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <div class="row">
              <!-- <div class="col-md-12 offset-md-10">
                        
                     </div> -->
            </div>
            <div class="content">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-3">
                            <p>
                              1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-3">
                                            <div class="heading">
                                                <p>DEBIT NOTE</p>
                                            </div>
                                        </div> -->
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6">
                  <div class="content-header-2">
                    <ul>
                      <li>
                        <h5>{{ this.pdf_de.FIRST_NAME }}</h5>
                      </li>
                      <li>
                        <p>
                          {{ this.pdf_de.ADDRESS_LINE_1
                          }}{{ this.pdf_de.ADDRESS_LINE_2 }}
                        </p>
                      </li>
                      <li>
                        <p>
                          {{ this.pdf_de.COUNTRY_NAME
                          }}{{ this.pdf_de.PIN_CODE }}
                        </p>
                      </li>
                      <li>
                        <p>
                          Agree to sell the following vehicle to Lek Auto Pte
                          Ltd ("Buyer")
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 mt-2" style="text-align: right">
                  <div class="content-header-2">
                    <!-- <h5>Debit Note</h5> -->
                    <ul>
                      <li>
                        <p>Serial No: <span>PS/2021-6/10167</span></p>
                      </li>
                      <li>
                        <p>NRIC/ROC: <span>0</span></p>
                      </li>
                      <li>
                        <p>Date: <span>17/11/2021</span></p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="content-body">
                    <ul>
                      <li><h5>Description:</h5></li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Vehicle No.</p></div>
                          <div class="col-md-3">
                            <span>:{{ this.pdf_de.VEHICLE_NUMBER }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Make & Model</p></div>
                          <div class="col-md-3">
                            <span
                              >:{{ this.pdf_de.MAKE_NAME }}-{{
                                this.pdf_de.MODEL_NAME
                              }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Chassis No.</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_de.CHASIS_NO }}</span>
                          </div>
                        </div>
                      </li>

                      <li><h5>Vehicle Price:</h5></li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Less</p></div>
                          <div class="col-md-3">
                            <span>: ${{ this.pdf_de.VEHICLE_PRICE }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3">
                            <p>Deposit (HON LI HIN ENTERPRISE)</p>
                          </div>
                          <div class="col-md-3">
                            <span>: ${{ this.pdf_de.DEPOSIT }} </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3"><p>Finance Company</p></div>
                          <div class="col-md-3">
                            <span>: {{ this.pdf_de.FINANCE_COMPANY }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3">
                            <p>Full Settlement Amount</p>
                          </div>
                          <div class="col-md-3">
                            <span
                              >: $
                              {{ this.pdf_de.FULL_SETTLEMENT_AMOUNT }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3">
                            <p>Full Payment (HON LI HIN ENTERPRISE)</p>
                          </div>
                          <div class="col-md-3">
                            <span>: ${{ this.pdf_de.FULL_PAYMENT }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-3">
                            <p>Balance Issued to (HON LI HIN ENTERPRISE)</p>
                          </div>
                          <div class="col-md-3"><span>: $ 0</span></div>
                        </div>
                      </li>
                    </ul>
                    <ul class="mt-4">
                      <li><h5>Vehicle Delivery Acknowledgement:</h5></li>
                      <li>Handover on (date) 20/07/22 (time) 01.00 pm.</li>
                      <li>
                        <span style="color: red">*</span>Seller has agreed to
                        handover the mentioned vehicle to Buyer at the above
                        mentioned date & time and free from all encrumbrance or
                        whatever.
                      </li>
                      <li>
                        <span style="color: red">*</span>Seller shall be
                        responsible for ant traffic offences and illegal acts
                        arising fron the use thereof.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="content-footer-2">
                    <div class="row mb-5">
                      <div class="col-md-6">
                        <p>Signed for & behalf of</p>
                        <p>Lek Auto Pte Ltd</p>
                      </div>
                      <div class="col-md-6">
                        <p style="float: right">Agreed & Aceepted by Seller</p>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-6">
                        <ul>
                          <li>
                            <span style="border-top: 2px solid #000"
                              >Authorised Signature</span
                            >
                          </li>
                          <li>
                            <div class="row mt-2" style="text-align: left">
                              <div class="col-md-2 p-0"><p>Salesman</p></div>
                              <div class="col-md-8"><span>: </span></div>
                            </div>
                          </li>
                          <li>
                            <div class="row" style="text-align: left">
                              <div class="col-md-2 p-0"><p>HP</p></div>
                              <div class="col-md-8"><span>: </span></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-6">
                        <ul style="float: right; text-align: center">
                          <li>
                            <span
                              style="border-top: 2px solid #000; width: 100px"
                              >Authorised Signature &</span
                            >
                          </li>
                          <li><span>Company Stamp</span></li>
                          <li>
                            <div class="row mt-2" style="text-align: left">
                              <div class="col-md-4 p-0"><p>Name</p></div>
                              <div class="col-md-8"><span>: </span></div>
                            </div>
                          </li>
                          <li>
                            <div class="row" style="text-align: left">
                              <div class="col-md-4 p-0"><p>Contact No.</p></div>
                              <div class="col-md-8"><span>: </span></div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-html2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="purchasenote"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="htmlPdf6"
    >
      <section slot="pdf-content" class="p-5">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-3">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-3">
                                            <div class="heading">
                                                <p>DEBIT NOTE</p>
                                            </div>
                                        </div> -->
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6">
              <div class="content-header-2">
                <ul>
                  <li>
                    <h5>{{ this.pdf_de.FIRST_NAME }}</h5>
                  </li>
                  <li>
                    <p>
                      {{ this.pdf_de.ADDRESS_LINE_1
                      }}{{ this.pdf_de.ADDRESS_LINE_2 }}
                    </p>
                  </li>
                  <li>
                    <p>
                      {{ this.pdf_de.COUNTRY_NAME }}{{ this.pdf_de.PIN_CODE }}
                    </p>
                  </li>
                  <li>
                    <p>
                      Agree to sell the following vehicle to Lek Auto Pte Ltd
                      ("Buyer")
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 mt-2" style="text-align: right">
              <div class="content-header-2">
                <!-- <h5>Debit Note</h5> -->
                <ul>
                  <li>
                    <p>Serial No: <span>PS/2021-6/10167</span></p>
                  </li>
                  <li>
                    <p>NRIC/ROC: <span>0</span></p>
                  </li>
                  <li>
                    <p>Date: <span>17/11/2021</span></p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-12">
              <div class="content-body">
                <ul>
                  <li><h5>Description:</h5></li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Vehicle No.</p></div>
                      <div class="col-md-3">
                        <span>:{{ this.pdf_de.VEHICLE_NUMBER }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Make & Model</p></div>
                      <div class="col-md-3">
                        <span>:{{ this.pdf_de.MAKE_NAME }} </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Chassis No.</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_de.CHASIS_NO }}</span>
                      </div>
                    </div>
                  </li>

                  <li><h5>Vehicle Price:</h5></li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Less</p></div>
                      <div class="col-md-3">
                        <span>: ${{ this.pdf_de.Less }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3">
                        <p>Deposit (HON LI HIN ENTERPRISE)</p>
                      </div>
                      <div class="col-md-3"><span>: $ </span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3"><p>Finance Company</p></div>
                      <div class="col-md-3">
                        <span>: {{ this.pdf_de.FINANCE_COMPANY }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3">
                        <p>Full Settlement Amount</p>
                      </div>
                      <div class="col-md-3">
                        <span>: $ {{ this.pdf_de.DEPOSIT_AMOUNT }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3">
                        <p>Full Payment (HON LI HIN ENTERPRISE)</p>
                      </div>
                      <div class="col-md-3"><span>: $</span></div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-3">
                        <p>Balance Issued to (HON LI HIN ENTERPRISE)</p>
                      </div>
                      <div class="col-md-3"><span>: $ 0</span></div>
                    </div>
                  </li>
                </ul>
                <ul class="mt-4">
                  <li><h5>Vehicle Delivery Acknowledgement:</h5></li>
                  <li>Handover on (date) 20/07/22 (time) 01.00 pm.</li>
                  <li>
                    <span style="color: red">*</span>Seller has agreed to
                    handover the mentioned vehicle to Buyer at the above
                    mentioned date & time and free from all encrumbrance or
                    whatever.
                  </li>
                  <li>
                    <span style="color: red">*</span>Seller shall be responsible
                    for ant traffic offences and illegal acts arising fron the
                    use thereof.
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-12">
              <div class="content-footer-2">
                <div class="row mb-5">
                  <div class="col-md-6">
                    <p>Signed for & behalf of</p>
                    <p>Lek Auto Pte Ltd</p>
                  </div>
                  <div class="col-md-6">
                    <p style="float: right">Agreed & Aceepted by Seller</p>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <ul>
                      <li>
                        <span style="border-top: 2px solid #000"
                          >Authorised Signature</span
                        >
                      </li>
                      <li>
                        <div class="row mt-2" style="text-align: left">
                          <div class="col-md-2 p-0"><p>Salesman</p></div>
                          <div class="col-md-8"><span>: </span></div>
                        </div>
                      </li>
                      <li>
                        <div class="row" style="text-align: left">
                          <div class="col-md-2 p-0"><p>HP</p></div>
                          <div class="col-md-8"><span>: </span></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul style="float: right; text-align: center">
                      <li>
                        <span style="border-top: 2px solid #000; width: 100px"
                          >Authorised Signature &</span
                        >
                      </li>
                      <li><span>Company Stamp</span></li>
                      <li>
                        <div class="row mt-2" style="text-align: left">
                          <div class="col-md-4 p-0"><p>Name</p></div>
                          <div class="col-md-8"><span>: </span></div>
                        </div>
                      </li>
                      <li>
                        <div class="row" style="text-align: left">
                          <div class="col-md-4 p-0"><p>Contact No.</p></div>
                          <div class="col-md-8"><span>: </span></div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>

    <!-- DUMMY PL_statemebnt modal  -->

    <!-- right modal from here for edit  -->
    <!-- right modal from here  -->
    <div
      class="modal modal-right fade"
      id="admin_edit_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 95%">
        <!-- style="width: 55% !important;" -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Edit Admin Master
            </h5>
            <h5 class="modal-title" style="margin-left: 20px">
              {{ vehicletype }}
            </h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body admin-edt-frm">
            <div class="floating-btn-container">
              <!-- <button type="button" id="floatbtn" >
                            <i class="fa-solid fa-check" onclick="changeicon(this)" style="padding: 15px;"></i>
                        </button> -->
              <div class="wrapper-1">
                <ul>
                  <li style="background-color: chocolate">
                    <a href="#stock_entry">
                      <i class="fa-solid fa-arrow-trend-up"></i>
                    </a>
                    <div class="slider" style="background-color: chocolate">
                      <p>Stock Entry</p>
                    </div>
                  </li>

                  <li style="background-color: tomato">
                    <a href="#seller_det">
                      <i class="fa-solid fa-person-circle-check"></i>
                    </a>
                    <div class="slider" style="background-color: tomato">
                      <p>Seller Details</p>
                    </div>
                  </li>

                  <li style="background-color: orangered">
                    <a href="#buyer_det">
                      <i class="fa-solid fa-user"></i>
                    </a>
                    <div class="slider" style="background-color: orangered">
                      <p>Buyer Details</p>
                    </div>
                  </li>

                  <li style="background-color: brown">
                    <a href="#repair_det">
                      <i class="fa-solid fa-screwdriver-wrench"></i>
                    </a>
                    <div class="slider" style="background-color: brown">
                      <p>Repair Details</p>
                    </div>
                  </li>

                  <li style="background-color: tomato">
                    <a href="#miscellenous_det">
                      <i class="fa-solid fa-people-line"></i>
                    </a>
                    <div class="slider" style="background-color: tomato">
                      <p>Miscellenous Detail</p>
                    </div>
                  </li>

                  <li style="background-color: rgb(210, 72, 30)">
                    <a href="#cost_center_det">
                      <i class="fa-solid fa-sack-dollar"></i>
                    </a>
                    <div
                      class="slider"
                      style="background-color: rgb(210, 72, 30)"
                    >
                      <p>Cost Center Details</p>
                    </div>
                  </li>

                  <!-- <li>
                                            <a href="#insurance_det">
                                                <i class="fa-solid fa-car-burst"></i>
                                            </a>
                                            <div class="slider">
                                                <p>Insurance Details</p>
                                            </div>
                                        </li> -->
                </ul>
              </div>
            </div>
            <!-- <h5 class="mt-2">Stock Entrys</h5> -->
            <div class="row mt-3">
              <!-- SECTION 1  (header section)-->

              <!-- <div class="col-md-12">
                                    <div class="divider-2"></div>
                                    </div> -->
              <!-- SECTION 2  (stock entry) -->

              <!-- SECTION 3 (seller details)-->

              <div
                class="modal modal-right fade"
                id="rokerupdate_moda"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div
                  class="modal-dialog modal-xl"
                  style="width: 75% !important"
                >
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Update Payment
                      </h5>
                      <button
                        type="button"
                        class="btn-normal modal-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="fa fa-angle-right" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div class="accordion-body p-2 mt-2">
                      <form>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <!-- <label class="frm-label-2 align-right"
                                        >AP Payment</label
                                      > -->
                                  </div>
                                  <div class="col-md-8">
                                    <!-- <input
                                        v-model="
                                          updat_user.PAYMENT_DESC
                                        "
                                        type="text"
                                        class="form-control"
                                        placeholder="Ap payment."
                                      /> -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Bank</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <select
                                      v-model="updat_user.BANK_SYS_ID"
                                      @change="updatebankname()"
                                      class="form-control"
                                    >
                                      <option value="">Select</option>
                                      <option
                                        v-for="item in Banklist"
                                        :value="item.BANK_SYS_ID"
                                        :key="item.BANK_SYS_ID"
                                      >
                                        {{ item.BANK_NAME }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2" v-show="falsetrue">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Cheque Number</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="updat_user.CHEQUE_NO"
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter Cheque No."
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >To/From Who</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="updat_user.TO_FROM_WHOM"
                                      type="text"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >AP Balance($)<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      v-model="updat_user.BALANCE"
                                      class="form-control"
                                      placeholder="Enter AP Balance"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >P Settlement Remark</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      v-model="updat_user.SETTLEMENT_REMARKS"
                                      class="form-control"
                                      placeholder="Enter P Settlement Remark"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Amount($)<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="updat_user.AMOUNT"
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter Amount"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Payment Mode</label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <select
                                      v-model="updat_user.PAYMENT_MODE"
                                      class="form-control"
                                    >
                                      <option value="">Select</option>
                                      <option value="GIRO">GIRO</option>
                                      <option value="TT">TT</option>
                                      <option value="Cheque">Cheque</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div class="row">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Cheque Date<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      v-model="updat_user.CHEQUE_DATE"
                                      type="date"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12 mt-2">
                                <div
                                  class="accordion accordion-flush"
                                  id="accordionExample"
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <label
                                        class="accordion-header frm-label-2 align-right"
                                        id="headingTwo"
                                      >
                                        <a
                                          class="collapsed"
                                          type="button"
                                          data-bs-toggle="collapse"
                                          data-bs-target="#collapseTwo"
                                          aria-expanded="false"
                                          aria-controls="collapseTwo"
                                          style="color: #6e6b7b"
                                        >
                                          <i class="fa-solid fa-circle-plus"></i
                                          >&nbsp; Add Remarks
                                        </a>
                                      </label>
                                    </div>
                                    <div class="col-md-8">
                                      <div class="accordion-item">
                                        <div
                                          id="collapseTwo"
                                          class="collapse"
                                          aria-labelledby="headingTwo"
                                          data-bs-parent="#accordionExample"
                                        >
                                          <div
                                            class="accordion-body p-0"
                                            style="border: none; outline: none"
                                          >
                                            <textarea
                                              v-model="updat_user.REMARKS"
                                              class="custom-textarea form-control"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="row mt-4">
                              <div class="col-md-1">
                                <button
                                  @click="AddUpdatesellerap"
                                  type="button"
                                  class="custom-btn custom-success-btn"
                                >
                                  Update
                                </button>
                                <br />
                              </div>
                              <div class="col-md-1">
                                <button
                                  type="button"
                                  class="custom-btn custom-danger-btn"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal"
                id="master_cost_type_delete_modal"
                tabindex="-1"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Delete</h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <p>Are you sure you want to delete ?</p>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" class="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- SECTION 4 (buyer details)-->

              <!-- SECTION 5 -->
              <!-- <div class="col-md-12 mt-2" id="repair_det">
                                    
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h5>Repair Details</h5>
                                            <div class="row mt-2">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Vehicle No. </label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="Enter Vehicle No.">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Cost Type</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <select class="form-control">
                                                                <option>Select</option>
                                                                <option>Reg. Cost</option>
                                                                <option>Road Tax</option>
                                                                <option>COE Value</option>
                                                                <option>Miscellaneous</option>
                                                                <option>Conversion Fee</option>
                                                                <option>Extension Fee</option>
                                                                <option>Transfer Fee</option>
                                                                <option>Road Tax Refunded (-)</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Description</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                          <textarea class="form-control custom-textarea"></textarea>  
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">To/From </label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <input type="checkbox">
                                                    <label class="frm-label-2 ms-1">  Reimburse</label>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Payment Mode</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <select class="form-control">
                                                                <option>Select</option>
                                                                <option>Online</option>
                                                                <option>Cash</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Amt. Bef. GST</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">GST Amt.</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Total Amount</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h5>Record Section</h5>
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Entry Date</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="date" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Created By</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Last Modified By</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                            </div>

                                            
                                        </div>
                                        
                                       
                                     </div>
                                    <div class="row mt-4">
                                        <div class="col-md-1">
                                            <button type="button" class="custom-btn custom-success-btn">Save</button>
                                        </div>
                                        <div class="col-md-1">
                                            <button type="button" class="custom-btn custom-danger-btn">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="divider"></div>
                                </div> -->
              <!-- SECTION 6 -->
              <!-- <div class="col-md-12 mt-2" id="miscellenous_det">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h5>Miscellenous Detail</h5>
                                            <div class="row mt-2">
                                                <div class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Vehicle No. </label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="Enter Vehicle No.">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Cost Type</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <select class="form-control">
                                                                <option>Select</option>
                                                                <option>Reg. Cost</option>
                                                                <option>Road Tax</option>
                                                                <option>COE Value</option>
                                                                <option>Miscellaneous</option>
                                                                <option>Conversion Fee</option>
                                                                <option>Extension Fee</option>
                                                                <option>Transfer Fee</option>
                                                                <option>Road Tax Refunded (-)</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Description</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                          <textarea class="form-control custom-textarea"></textarea>  
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">To/From </label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <input type="text" class="form-control" placeholder="">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <input type="checkbox">
                                                    <label class="frm-label-2 ms-1">  Reimburse</label>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label class="frm-label-2">Payment Mode</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <select class="form-control">
                                                                <option>Select</option>
                                                                <option>Online</option>
                                                                <option>Cash</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 mt-2">
                                                    <Button type="button" class="custom-btn custom-secondary-btn-4">Print Payment Voucher(PV)</Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <h5>Record Section</h5>
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Entry Date</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="date" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Created By</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Last Modified By</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Detail</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <select class="form-control">
                                                            <option>Select</option>
                                                            <option>6 Months</option>
                                                            <option>1 Year</option>
                                                            <option>5 Years</option>
                                                            <option>10 Years</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Late Renewal Fee</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Amt. Bef. GST</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">GST Amt.</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <label class="frm-label-2">Total Amount</label>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <input type="text" class="form-control" placeholder="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-2">
                                            <label class="frm-label-2">Vehicle Name And Model</label>
                                            <input type="text" class="form-control" placeholder="Enter Vehicle Name And Model">
                                        </div>
                                        <div class="col-md-2">
                                            <label class="frm-label-2">Registration Number</label>
                                            <input type="text" class="form-control" placeholder="Enter Registration No.">
                                        </div>
                                        <div class="col-md-2">
                                            <label class="frm-label-2">Vendor Name</label>
                                            <input type="text" class="form-control" placeholder="Enter Vendor Name">
                                        </div>
                                        <div class="col-md-2">
                                            <label class="frm-label-2">Amount Spent</label>
                                            <input type="text" class="form-control" placeholder="">
                                        </div>
                                        <div class="col-md-2">
                                            <label class="frm-label-2">GST Charges</label>
                                            <input type="text" class="form-control" placeholder="">
                                        </div>
                                        <div class="col-md-2">
                                            <label class="frm-label-2">Total Spent Amount</label>
                                            <input type="text" class="form-control" placeholder="">
                                        </div>
                                        <div class="col-md-12 mt-2">
                                            <div class="row">
                                                <div class="col-md-6 mt-2">
                                                    <label class="frm-label-2">Description</label>
                                                    <textarea class="form-control custom-textarea"></textarea>
                                                </div>
                                            </div>
                                        </div> --
                                     </div>
                                     <div class="row mt-4">
                                        <div class="col-md-1">
                                            <button type="button" class="custom-btn custom-success-btn">Save</button>
                                        </div>
                                        <div class="col-md-1">
                                            <button type="button" class="custom-btn custom-danger-btn">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="divider"></div>
                                </div> -->
              <!-- SECTION 7 -->

              <!-- SECTION 8 -->
              <div class="col-md-12" id="loan_info_det">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="line-heading"><span>Loan Info</span></h5>
                  </div>

                  <div class="col-md-12">
                    <button
                      class="custom-btn add-btn"
                      name=""
                      v-show="!show_add_loan_info"
                      @click="show_add_loan_info = !show_add_loan_info"
                    >
                      <strong>Add Loan Info</strong>
                      <i data-feather="plus-circle"></i>
                    </button>
                  </div>

                  <div
                    aria-labelledby="flush-headingOne"
                    v-if="show_add_loan_info"
                  >
                    <div class="p-2 mt-1">
                      <div class="col-md-12">
                        <div class="row mt-3">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Fin. Company</label
                                >
                              </div>
                              <div class="col-md-8">
                                <select
                                  class="form-control roleddl"
                                  v-model="loan_info.FIN_COMP_SYS_ID"
                                  @change="onLoanInfoFinCompanyChange"
                                >
                                  <option value="">select</option>
                                  <option
                                    v-for="system_role_detail in system_role_deta"
                                    :key="
                                      system_role_detail.FINANCE_COMPANY_SYS_ID
                                    "
                                    :value="
                                      system_role_detail.FINANCE_COMPANY_SYS_ID
                                    "
                                  >
                                    {{ system_role_detail.FINANCE_COMPANY }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Loan Amount($)</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder=""
                                  v-model="loan_info.LOAN_AMOUNT"
                                  @keyup="loancalculator"
                                />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Loan Tenure</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder=""
                                  v-model="loan_info.LOAN_TENURE"
                                  @keyup="loancalculator"
                                />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Interest Rate(%)</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                  v-model="loan_info.INTEREST_RATE"
                                  @keyup="loancalculator"
                                />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Advance Payment($)</label
                                >
                              </div>
                              <div class="col-md-8">
                                <b-form-input
                                  type="number"
                                  class="form-control"
                                  placeholder=""
                                  v-model="loan_info.ADVANCE_PAYMENT"
                                  @change="loancalculator"
                                ></b-form-input>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Interest :</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                  v-model="loan_info.INTEREST_AMOUNT"
                                />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Subtotal :</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  disabled
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                  v-model="loan_info.SUBTOTAL"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >HP Facilties Fee</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder=""
                                  v-model="loan_info.HP_FACILITIES_FEE"
                                />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Loan Commission(%)</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  class="form-control"
                                  @keyup="getloancalculation()"
                                  placeholder=""
                                  v-model="loan_info.LOAN_COMMISSION_PERCENTAGE"
                                />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Loan Commission (Loan Amt x Loan Commission
                                  %)</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  disabled
                                  class="form-control"
                                  placeholder=""
                                  v-model="loan_info.LOAN_COMMISSION"
                                />
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Salesman Commission (For admin fee & loan
                                  commission)</label
                                >
                              </div>
                              <div class="col-md-8 mt-2">
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder=""
                                  v-model="loan_info.SALES_COMMISSION"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6 pt-1 pb-1">
                            <div class="row mt-4 mb-4">
                              <div class="col-md-3">
                                <button
                                  type="button"
                                  class="custom-btn custom-success-btn"
                                  @click="AddLoanInfo"
                                >
                                  Save
                                </button>
                              </div>
                              <div class="col-md-1">
                                <button
                                  type="button"
                                  class="custom-btn custom-danger-btn"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="divider-2"></div>
                      </div>

                      <div class="col-md-12 mb-3">
                        <button
                          class="custom-btn add-btn"
                          name=""
                          @click="AddExtraTradeInfo"
                        >
                          <strong>Add Trade In Info</strong>
                          <i data-feather="plus-circle"></i>
                        </button>
                      </div>

                      <div class="col-md-12">
                        <div
                          class="row mt-1"
                          v-for="(trade, index) in trade_info"
                          :key="index"
                        >
                          <div class="col-md-12">
                            <h5>Trade In Info {{ index + 1 }}</h5>
                          </div>
                          <div class="col-md-6 mt-3">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Trade In Veh No.</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                  v-model="trade_info[index].TRADE_IN_VEH_NO"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-3">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Trade In Amount (-)</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder=""
                                  v-model="trade_info[index].TRADE_IN_AMOUNT"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Payment Made (+)</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  class="form-control"
                                  placeholder=""
                                  v-model="trade_info[index].PAYMENT_MADE"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-2">
                            <div class="row">
                              <div class="col-md-4">
                                <label
                                  class="frm-label-2 align-right"
                                  style="text-align: end"
                                  >Full Settlement Reimbursement</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="number"
                                  class="form-control"
                                  v-model="
                                    trade_info[index]
                                      .FULL_SETTLEMENT_REIMBURSEMENT
                                  "
                                  placeholder=""
                                />
                              </div>
                            </div>
                            <div class="row mt-4 mb-4">
                              <div class="col-md-3">
                                <button
                                  type="button"
                                  class="custom-btn custom-success-btn"
                                  @click="AddTradeInfo(index)"
                                >
                                  Save
                                </button>
                              </div>
                              <div class="col-md-1">
                                <button
                                  type="button"
                                  class="custom-btn custom-danger-btn"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-12">
                        <div class="divider-2"></div>
                      </div>

                      <!-- <div class="col-md-12">
                        <div class="row mt-1">
                          <div class="col-md-12">
                            <h5>Multi Cost</h5>
                          </div>
                          <div class="col-md-12 mt-3">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                      />
                                      <label
                                        class="frm-label-2"
                                        for="defaultCheck1"
                                      >
                                        Please Tick Reimburse & Co. Share Case
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                                                            <input type="text" class="form-control" placeholder="">
                                                                        </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 mt-2">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="defaultCheck1"
                                      />
                                      <label
                                        class="frm-label-2"
                                        for="defaultCheck1"
                                      >
                                        Please Tick Reimburse Only For Reimburse
                                        Case
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                                                            <input type="text" class="form-control" placeholder="">
                                                                        </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <!-- <div class="col-md-12 mt-3 master-table box">
                        <table class="table table-bordered mt-3">
                          <thead>
                            <tr>
                              <th>SL. No.</th>
                              <th scope="col">Acc Code</th>
                              <th scope="col">Cost Type</th>
                              <th scope="col">Description</th>
                              <th scope="col">Amount</th>
                              <th scope="col">GST</th>
                              <th scope="col">GST Inclusive</th>
                              <th scope="col">Reimburse</th>
                              <th scope="col">Co. Share</th>
                            </tr>
                          </thead>
                          <tbody></tbody>
                        </table>
                      </div> -->

                      <div class="col-md-12 mt-2">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row"></div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-12 mt-3">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Payment</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <!-- <div class="col-md-12 mt-3">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >Balance Due</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <!-- <div class="col-md-12 mt-3">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="row">
                              <div class="col-md-4">
                                <label class="frm-label-2 align-right"
                                  >DA Remarks</label
                                >
                              </div>
                              <div class="col-md-8">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <!-- <div class="row mt-4 mb-4">
                      <div class="col-md-1">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-1">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <!--SECTION 9 -->
              <div class="col-md-12">
                <h5 class="line-heading">
                  <span>Finance Company Sales Receipt</span>
                </h5>
              </div>

              <div class="content-body-wrapper mt-4">
                <div class="row">
                  <div class="col-md-12 animate__animated animate__zoomIn">
                    <div class="card-wrap shadow-main">
                      <div class="card-wrap-body">
                        <div class="data-table master-table">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th>SL. No</th>
                                <th>Deposit Paid.($)</th>
                                <th>Final Premium.($)</th>
                                <th>Loan Amount.($)</th>
                                <th>Selling Price($)</th>
                                <th>Trade in Amount($)</th>
                              </tr>
                            </thead>
                            <tbody
                              v-for="(user_deta, index) in pdf_detai"
                              :key="user_deta.RECORD_SYS_ID"
                            >
                              <tr>
                                <td>{{ index + 1 }}</td>
                                <td>{{ user_deta.DEPOSIT_PAID }}</td>
                                <td>{{ user_deta.FINAL_PREMIUM }}</td>
                                <td>{{ user_deta.LOAN_AMOUNT }}</td>
                                <td>{{ user_deta.SELLING_PRICE }}</td>
                                <td>{{ user_deta.TRADE_IN_AMOUNT }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div>
</div> -->
              </div>

              <!-- section 10  -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- right modal from here  -->
    <div
      class="modal modal-right fade"
      id="vendor_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Vendor</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
            <!-- <button type="button" class="btn-normal modal-close" data-bs-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                      </button> -->
          </div>
          <div class="modal-body">
            <ValidationObserver ref="bankform2">
              <form>
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Vendor Code <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="Vendorcode"
                        v-model.trim="create_vendor.VENDOR_CODE"
                        class="form-control"
                        placeholder="Enter Vendor Code"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.VENDOR_CODE != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Vendor Name <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="Vendor Name"
                        v-model.trim="create_vendor.VENDOR_NAME"
                        class="form-control"
                        placeholder="Enter Vendor Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.VENDOR_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label">
                        Person Number <span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="Person Number"
                        v-model.trim="create_vendor.CONTACT_PERSON_NUMBER"
                        class="form-control"
                        placeholder="Enter Person Number"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.CONTACT_PERSON_NUMBER != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Person Name <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="Person Name"
                        v-model.trim="create_vendor.CONTACT_PERSON_NAME"
                        class="form-control"
                        placeholder="Enter Person Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.CONTACT_PERSON_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Address Line 1 <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="Address Line 1"
                        v-model.trim="create_vendor.ADDRESS_LINE_1"
                        class="form-control"
                        placeholder="Enter Address"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.ADDRESS_LINE_1 != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <label class="frm-label">Address Line 2 </label>
                    <input
                      type="text"
                      name="Address Line 2"
                      v-model.trim="create_vendor.ADDRESS_LINE_2"
                      class="form-control"
                      placeholder="Enter Address"
                    />
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Post Code <span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="pin Code"
                        v-model.trim="create_vendor.PIN_CODE"
                        class="form-control"
                        placeholder="Enter Post Code "
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && create_vendor.PIN_CODE != null,
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Country <span style="color: red">*</span></label
                      >
                      <b-form-select
                        name="Country"
                        v-model="create_vendor.COUNTRY_SYS_ID"
                        @change="getcountrysysid"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.COUNTRY_SYS_ID != '',
                        }"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- Select --</b-form-select-option
                          >
                        </template>
                        <option value="" selected>Select</option>
                        <option
                          v-for="cost_type_info_detail in cost_type_info_details"
                          :value="cost_type_info_detail.COUNTRY_SYS_ID"
                          :key="cost_type_info_detail.COUNTRY_SYS_ID"
                        >
                          {{ cost_type_info_detail.COUNTRY_NAME }}
                        </option>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          data-bs-dismiss="modal"
                          @click="Addvendor"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                          data-bs-dismiss="modal"
                          @click="emptyfu"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="modal modal-right fade"
        id="employee_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" style="width: 75% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Customer</h5>
              <button
                type="button"
                class="btn-normal modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
              <!-- <button type="button" class="btn-normal modal-close" data-bs-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                      </button> -->
            </div>
            <div class="modal-body">
              <div class="row">
                <!-- <div class="col-md-5 mt-2">
                            <label class="frm-label">user type</label>
                            <input type="text" class="form-control">
                        </div> -->
                <!-- <div class="col-md-6"> -->
                <!-- <div class="row"> -->
                <div class="col-md-6 mt-2">
                  <label class="frm-label"
                    >Customer Type <span style="color: red">*</span></label
                  >
                  <select
                    id="customertype"
                    v-model="create_customer.CUSTOMER_TYPE"
                    class="form-control"
                  >
                    <option value="">Select</option>
                    <option value="individual">Individual</option>
                    <option value="Company">Company</option>
                  </select>
                </div>
                <!-- </div>
                  </div> -->
                <div class="individual box">
                  <ValidationObserver ref="cus">
                    <div class="row">
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >NRIC Number
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                            name="NRIC Number"
                            v-model.trim="create_customer.ID_NUMBER"
                            class="form-control"
                            placeholder="Enter NRIC Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.ID_NUMBER != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Full Name <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Full Name"
                            v-model.trim="create_customer.FIRST_NAME"
                            class="form-control"
                            placeholder="Enter full Name"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.FIRST_NAME != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required|mobileNumber"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Mobile Number<span style="color: red"
                              >*</span
                            ></label
                          >
                          <input
                            type="name"
                            name="Mobile.NO"
                            v-model.trim="create_customer.CUSTOMER_CONTACT"
                            class="form-control"
                            placeholder="Enter Mobile Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.CUSTOMER_CONTACT != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Email ID <span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                            name="Email Id"
                            v-model.trim="create_customer.GMAIL_ID"
                            class="form-control"
                            placeholder="Enter Email ID"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.GMAIL_ID != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Address Line 1
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Address 1"
                            v-model.trim="create_customer.ADDRESS_LINE_1"
                            class="form-control"
                            placeholder="Enter Address"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.ADDRESS_LINE_1 != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <label class="frm-label">Address Line 2</label>
                        <input
                          type="text"
                          name="Address Line 2"
                          v-model.trim="create_customer.ADDRESS_LINE_2"
                          class="form-control"
                          placeholder="Enter Address"
                        />
                      </div>
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >post Code <span style="color: red">*</span></label
                          >
                          <input
                            type="number"
                            name="Post code"
                            v-model.trim="create_customer.PIN_CODE"
                            class="form-control"
                            placeholder="Enter Post Code"
                            maxlength="6"
                            minlength="6"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.PIN_CODE != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider v-slot="{ errors }">
                          <label class="frm-label"
                            >Country <span style="color: red">*</span></label
                          >
                          <b-form-select
                            name="Country"
                            v-model="create_customer.COUNTRY_NAME"
                            :class="{
                              'form-control': true,
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.COUNTRY_NAME != '',
                            }"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Select --</b-form-select-option
                              >
                            </template>
                            <option value="">Singapore</option>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="modal-footer">
                        <div class="row mt-4">
                          <div class="col-md-3">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="Addcustomer"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-2">
                            <button
                              type="button"
                              class="custom-btn custom-danger-btn"
                              data-bs-dismiss="modal"
                              @click="emptyfu"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>

                <div class="Company box">
                  <ValidationObserver ref="bankfo">
                    <div class="row">
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >UEN Number <span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                            name="UEN Number"
                            v-model.trim="create_company.ID_NUMBER"
                            class="form-control"
                            placeholder="Enter UEN Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.ID_NUMBER != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Company Name
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Company Name"
                            v-model.trim="create_company.COMPANY_NAME"
                            class="form-control"
                            placeholder="Enter Company Name"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.COMPANY_NAME != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Person Incharge
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="person incharge"
                            v-model.trim="create_company.PERSON_INCHARGE"
                            class="form-control"
                            placeholder="Enter Person incharge"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.PERSON_INCHARGE != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required|mobileNumber"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Mobile.NO <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Mobile Number"
                            v-model.trim="create_company.CUSTOMER_CONTACT"
                            class="form-control"
                            placeholder="Enter Mobile Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.CUSTOMER_CONTACT != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Email ID<span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                            name="gmail"
                            v-model.trim="create_company.GMAIL_ID"
                            class="form-control"
                            placeholder="Enter Email ID"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.GMAIL_ID != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Address Line 1
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="address line 1"
                            v-model.trim="create_company.ADDRESS_LINE_1"
                            class="form-control"
                            placeholder="Enter Address"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.ADDRESS_LINE_1 != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <label class="frm-label">Address Line 2 </label>
                        <input
                          type="name"
                          name="Address Line 2"
                          v-model.trim="create_company.ADDRESS_LINE_2"
                          class="form-control"
                          placeholder="Enter Address"
                        />
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >post Code <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Post code"
                            v-model.trim="create_company.PIN_CODE"
                            class="form-control"
                            placeholder="Enter Post Code"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.PIN_CODE != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <ValidationProvider v-slot="{ errors }">
                          <label class="frm-label"
                            >Country <span style="color: red">*</span></label
                          >
                          <b-form-select
                            name="Country"
                            v-model="create_company.COUNTRY_NAME"
                            :class="{
                              'form-control': true,
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.COUNTRY_NAME != '',
                            }"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Select --</b-form-select-option
                              >
                            </template>
                            <option value="">Singapore</option>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="modal-footer">
                        <div class="row mt-4">
                          <div class="col-md-3">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="Addcompany"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-2">
                            <button
                              type="button"
                              class="custom-btn custom-danger-btn"
                              data-bs-dismiss="modal"
                              @click="emptyfu"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="modal modal-right fade"
        id="employee_moda"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" style="width: 75% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Customer</h5>
              <button
                type="button"
                class="btn-normal modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
              <!-- <button type="button" class="btn-normal modal-close" data-bs-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                      </button> -->
            </div>
            <div class="modal-body">
              <div class="row">
                <!-- <div class="col-md-5 mt-2">
                            <label class="frm-label">user type</label>
                            <input type="text" class="form-control">
                        </div> -->
                <!-- <div class="col-md-6"> -->
                <!-- <div class="row"> -->
                <div class="col-md-6 mt-2">
                  <label class="frm-label"
                    >Customer Type <span style="color: red">*</span></label
                  >
                  <select
                    id="customertype"
                    v-model="create_customer.CUSTOMER_TYPE"
                    class="form-control"
                  >
                    <option value="">Select</option>
                    <option value="individual">Individual</option>
                    <option value="Company">Company</option>
                  </select>
                </div>
                <!-- </div>
                  </div> -->
                <div class="individual box">
                  <ValidationObserver ref="cus">
                    <div class="row">
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >NRIC Number
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                            name="NRIC Number"
                            v-model.trim="create_customer.ID_NUMBER"
                            class="form-control"
                            placeholder="Enter NRIC Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.ID_NUMBER != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Full Name <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Full Name"
                            v-model.trim="create_customer.FIRST_NAME"
                            class="form-control"
                            placeholder="Enter full Name"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.FIRST_NAME != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required|mobileNumber"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Mobile Number<span style="color: red"
                              >*</span
                            ></label
                          >
                          <input
                            type="name"
                            name="Mobile.NO"
                            v-model.trim="create_customer.CUSTOMER_CONTACT"
                            class="form-control"
                            placeholder="Enter Mobile Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.CUSTOMER_CONTACT != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Email ID <span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                            name="Email Id"
                            v-model.trim="create_customer.GMAIL_ID"
                            class="form-control"
                            placeholder="Enter Email ID"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.GMAIL_ID != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Address Line 1
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Address 1"
                            v-model.trim="create_customer.ADDRESS_LINE_1"
                            class="form-control"
                            placeholder="Enter Address"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.ADDRESS_LINE_1 != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <label class="frm-label">Address Line 2</label>
                        <input
                          type="text"
                          name="Address Line 2"
                          v-model.trim="create_customer.ADDRESS_LINE_2"
                          class="form-control"
                          placeholder="Enter Address"
                        />
                      </div>
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >post Code <span style="color: red">*</span></label
                          >
                          <input
                            type="number"
                            name="Post code"
                            v-model.trim="create_customer.PIN_CODE"
                            class="form-control"
                            placeholder="Enter Post Code"
                            maxlength="6"
                            minlength="6"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_customer.PIN_CODE != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider v-slot="{ errors }">
                          <label class="frm-label"
                            >Country <span style="color: red">*</span></label
                          >
                          <b-form-select
                            name="Country"
                            v-model="create_customer.COUNTRY_NAME"
                            :class="{
                              'form-control': true,
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.COUNTRY_NAME != '',
                            }"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Select --</b-form-select-option
                              >
                            </template>
                            <option value="">Singapore</option>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="modal-footer">
                        <div class="row mt-4">
                          <div class="col-md-3">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="Addcustomer"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-2">
                            <button
                              type="button"
                              class="custom-btn custom-danger-btn"
                              data-bs-dismiss="modal"
                              @click="emptyfu"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>

                <div class="Company box">
                  <ValidationObserver ref="bankfo">
                    <div class="row">
                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >UEN Number <span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                            name="UEN Number"
                            v-model.trim="create_company.ID_NUMBER"
                            class="form-control"
                            placeholder="Enter UEN Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.ID_NUMBER != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Company Name
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Company Name"
                            v-model.trim="create_company.COMPANY_NAME"
                            class="form-control"
                            placeholder="Enter Company Name"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.COMPANY_NAME != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Person Incharge
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="person incharge"
                            v-model.trim="create_company.PERSON_INCHARGE"
                            class="form-control"
                            placeholder="Enter Person incharge"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.PERSON_INCHARGE != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required|mobileNumber"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Mobile.NO <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Mobile Number"
                            v-model.trim="create_company.CUSTOMER_CONTACT"
                            class="form-control"
                            placeholder="Enter Mobile Number"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.CUSTOMER_CONTACT != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required|email"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Email ID<span style="color: red">*</span></label
                          >
                          <input
                            type="text"
                            name="gmail"
                            v-model.trim="create_company.GMAIL_ID"
                            class="form-control"
                            placeholder="Enter Email ID"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.GMAIL_ID != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >Address Line 1
                            <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="address line 1"
                            v-model.trim="create_company.ADDRESS_LINE_1"
                            class="form-control"
                            placeholder="Enter Address"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.ADDRESS_LINE_1 != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <label class="frm-label">Address Line 2 </label>
                        <input
                          type="name"
                          name="Address Line 2"
                          v-model.trim="create_company.ADDRESS_LINE_2"
                          class="form-control"
                          placeholder="Enter Address"
                        />
                      </div>

                      <div class="col-md-4 mt-3">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <label class="frm-label"
                            >post Code <span style="color: red">*</span></label
                          >
                          <input
                            type="name"
                            name="Post code"
                            v-model.trim="create_company.PIN_CODE"
                            class="form-control"
                            placeholder="Enter Post Code"
                            :class="{
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.PIN_CODE != '',
                            }"
                          />
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>
                      <div class="col-md-4 mt-3">
                        <ValidationProvider v-slot="{ errors }">
                          <label class="frm-label"
                            >Country <span style="color: red">*</span></label
                          >
                          <b-form-select
                            name="Country"
                            v-model="create_company.COUNTRY_NAME"
                            :class="{
                              'form-control': true,
                              'is-invalid': errors[0] != null,
                              'is-valid':
                                errors[0] == null &&
                                create_company.COUNTRY_NAME != '',
                            }"
                          >
                            <template #first>
                              <b-form-select-option :value="null" disabled
                                >-- Select --</b-form-select-option
                              >
                            </template>
                            <option value="">Singapore</option>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </div>

                      <div class="modal-footer">
                        <div class="row mt-4">
                          <div class="col-md-3">
                            <button
                              type="button"
                              class="custom-btn custom-success-btn"
                              @click="Addcompany"
                            >
                              Save
                            </button>
                          </div>
                          <div class="col-md-2">
                            <button
                              type="button"
                              class="custom-btn custom-danger-btn"
                              data-bs-dismiss="modal"
                              @click="emptyfu"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { view_vehicledetails } from "../admin-master/Loanindex";
export default view_vehicledetails;
</script>
