<template>
  <div>
    <div class="animate__animated animate__slideInRight">
      <div class="content-body-wrapper mt-4">
        <div class="page-content">
          <div class="page-data">
            <div class="content-header">
              <div class="row mt-4">
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-md-3">
                      <img
                        style="margin-left: 5em"
                        src="../../assets/images/icon.png"
                        class="img-fluid header-img"
                        alt="#"
                      />
                    </div>
                    <div class="col-md-7 p-0">
                      <ul style="margin-left: 7em">
                        <li>
                          <img src="../../assets/images/logo-txt.png" />
                        </li>
                        <li class="mt-2" style="width: 30em">
                          <p>
                            1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                            627615
                          </p>
                        </li>
                        <li>
                          <p>ROC No./ GST Reg No:. 201929524C</p>
                        </li>
                        <li>
                          <p>Tel: 62395191 Fax: 62395190</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3 ml-2">
                <div class="col-md-12" style="text-align: end">
                  <h4 class="fw-bold">
                    <span
                      style="
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        padding: 4px 10px;
                      "
                    >
                      INVOICE</span
                    >
                  </h4>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <!-- header  -->
              <div class="col-md-4">
                <div class="content-header-2" style="margin-left: 2em">
                  <ul>
                    <li><p>Bill To,</p></li>
                    <li>
                      <p>
                        <span style="font-weight: bold">{{
                          this.finance_company_tax_invoice.FINANCE_COMPANY
                        }}</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style="font-weight: bold">{{
                          this.finance_company_tax_invoice.ADDRESS_LINE_1
                        }}</span>
                      </p>
                    </li>
                    <li>
                      <p>
                        <span style="font-weight: bold">
                          {{ this.finance_company_tax_invoice.COUNTRY_NAME }}
                          {{ this.finance_company_tax_invoice.PIN_CODE }}
                        </span>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4">
                <ul>
                  <li></li>
                  <li>
                    <p></p>
                  </li>
                  <li>
                    <p></p>
                  </li>

                  <li>
                    <p></p>
                  </li>
                </ul>
              </div>
              <div class="col-md-4">
                <div class="content-header-2">
                  <ul>
                    <li>
                      <div class="row">
                        <div class="col-md-5">
                          <p>Invoice No</p>
                        </div>
                        <div class="col-md-7">
                          <span style="font-weight: bold"
                            >:
                            {{
                              this.finance_company_tax_invoice.INVOICE_NO
                            }}</span
                          >
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-5"><p>Term</p></div>
                        <div class="col-md-7">
                          <span style="font-weight: bold">COD</span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-5"><p>Date</p></div>
                        <div class="col-md-7">
                          <span style="font-weight: bold"
                            >:{{
                              this.finance_company_tax_invoice.TAX_INVOICE_DATE
                            }}
                          </span>
                          <!-- <span style="font-weight:bold">{{this.pdf_deta.TAX_INVOICE_DATE}} </span> -->
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="row">
                        <div class="col-md-5"><p>VSA No.</p></div>
                        <div class="col-md-7">
                          <span style="font-weight: bold"
                            >:{{ this.finance_company_tax_invoice.VSA_NO }}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- after heading sec  -->
              <div class="col-md-12">
                <div
                  class="content-body-2 ps-1 pe-1"
                  style="border: 1px solid #212121"
                >
                  <div class="row">
                    <div class="col-md-7 pt-1 pb-1">
                      <div class="content-header-3">
                        <ul>
                          <li>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Vehicle Registration No.</p>
                              </div>
                              <div class="col-md-6">
                                <span style="font-weight: bold"
                                  >:{{
                                    this.finance_company_tax_invoice
                                      .VEHICLE_NUMBER
                                  }}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Vehicle Make</p>
                              </div>
                              <div class="col-md-6">
                                <span style="font-weight: bold"
                                  >:{{
                                    this.finance_company_tax_invoice.MAKE_NAME
                                  }}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-md-6">
                                <p>VehicleModel</p>
                              </div>
                              <div class="col-md-6">
                                <span style="font-weight: bold"
                                  >:{{
                                    this.finance_company_tax_invoice.MODEL_NAME
                                  }}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-md-6">
                                <p>Original Registration Date</p>
                              </div>
                              <div class="col-md-6">
                                <span style="font-weight: bold"
                                  >:{{
                                    this.finance_company_tax_invoice
                                      .ORIGINAL_REGISTRATION_DATE
                                  }}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      class="col-md-5 pt-1 pb-1"
                      style="border-left: 1px solid rgba(0, 0, 0, 0.3)"
                    >
                      <div class="content-header-3">
                        <ul>
                          <li>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Engine No.</p>
                              </div>
                              <div class="col-md-7">
                                <span style="font-weight: bold">{{
                                  this.finance_company_tax_invoice.ENGINE_NO
                                }}</span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Chassis No.</p>
                              </div>
                              <div class="col-md-7">
                                <span style="font-weight: bold"
                                  >:{{
                                    this.finance_company_tax_invoice.CHASIS_NO
                                  }}</span
                                >
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-md-5">
                                <p>Sales Person</p>
                              </div>
                              <div class="col-md-7">
                                <span style="font-weight: bold"
                                  >:{{
                                    this.finance_company_tax_invoice
                                      .INCHARGE_NAME
                                  }}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div class="row">
                              <div class="col-md-5">
                                <p>GST Method</p>
                              </div>
                              <div class="col-md-7">
                                <span style="font-weight: bold"
                                  >: Gross Margin</span
                                >
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-3">
                <div class="content-footer" style="border: 1px solid #212121">
                  <table class="table table-bordered">
                    <thead style="border-bottom: 1px solid #212121">
                      <tr></tr>
                      <tr>
                        <th scope="col">
                          <span style="font-weight: bold">Description</span>
                        </th>
                        <th scope="col">
                          <span style="font-weight: bold"></span>
                        </th>
                        <th
                          scope="col"
                          class="border-td"
                          style="text-align: center"
                        >
                          <span style="font-weight: bold">Amount&nbsp;($)</span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <!-- <th scope="row">1</th> -->

                        <td
                          class="border-td-2"
                          style="border-bottom: 1px solid #212121"
                        >
                          <span style="font-weight: bold">
                            {{
                              this.finance_company_tax_invoice.VEHICLE_NUMBER
                            }}
                          </span>
                        </td>
                        <td
                          class="border-td border-td-2"
                          style="border-bottom: 1px solid #212121"
                        >
                          <span style="font-weight: bold"> </span>
                        </td>
                        <td
                          class="border-td border-td-2"
                          style="border-bottom: 1px solid #212121"
                        >
                          <div class="row">
                            <div class="col-md-4">
                              <span class="fw-bold"></span>
                            </div>
                            <div class="col-md-4">
                              <span class="fw-bold"
                                >${{
                                  this.finance_company_tax_invoice
                                    .TOTAL_VEHICLE_PRICE
                                }}</span
                              >
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <!-- <th scope="row">1</th> -->
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Fees received on behalfof oter agency and
                                  company</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"><span class="ms-1"> </span></td>
                        <td class="border-td">
                          <span class="ms-1" style="font-weight: bold"
                            >$ 1440.00</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <!-- <th scope="row">1</th> -->
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Loan Advanced Payment</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"><span class="ms-1"> </span></td>
                        <td class="border-td">
                          <span class="ms-1" style="font-weight: bold"
                            >$
                            {{
                              this.finance_company_tax_invoice.LOAN_ADVANCE
                            }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >HP Charges</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"><span class="ms-1"> </span></td>
                        <td class="border-td">
                          <span class="ms-1" style="font-weight: bold"
                            >$ 1987.56</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Insuarce Premium</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"><span class="ms-1"> </span></td>
                        <td class="border-td">
                          <span class="ms-1" style="font-weight: bold"
                            >$
                            {{
                              this.finance_company_tax_invoice.INSURANCE_PREMIUM
                            }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold">Admin Fee</span>
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"><span class="ms-1"> </span></td>
                        <td class="border-td">
                          <span class="ms-1" style="font-weight: bold"
                            >$
                            {{
                              this.finance_company_tax_invoice
                                .ADMIN_FEE_TAX_VALUE
                            }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="border-td"
                          style="border-bottom: 1px solid #212121"
                        >
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Total Invoice Amount</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td
                          class="border-td"
                          style="border-bottom: 1px solid #212121"
                        >
                          <span class="ms-1"> </span>
                        </td>
                        <td
                          class="border-td"
                          style="
                            border-bottom: 1px solid #212121;
                            border-top: 1px solid #212121;
                          "
                        >
                          <span class="ms-1" style="font-weight: bold"
                            >$ 59852.86</span
                          >
                        </td>
                      </tr>

                      <tr>
                        <!-- <th scope="row">1</th> -->
                        <td>
                          <span style="font-weight: bold"
                            >Payment Details :</span
                          >
                        </td>
                        <td class="border-td"></td>
                      </tr>
                      <tr></tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Sales Deposit</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"><span class="ms-1"> </span></td>
                        <td class="border-td">
                          <span class="ms-1" style="font-weight: bold"
                            >$
                            {{
                              this.finance_company_tax_invoice.DEPOSIT_AMOUNT
                            }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Down/full payment</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"><span class="ms-1"> </span></td>
                        <td class="border-td">
                          <span class="ms-1" style="font-weight: bold">$ </span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold">Trade In</span>
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                        0
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"><span class="ms-1"> </span></td>
                        <td class="border-td">
                          <span class="ms-1" style="font-weight: bold"
                            >$
                            {{
                              this.finance_company_tax_invoice
                                .TRADE_IN_REIMBURSEMENT
                            }}</span
                          >
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Excess Payment</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                        {{
                                          this.finance_company_tax_invoice
                                            .EXCESS_AMOUNT
                                        }}
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6"></div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"><span class="ms-1"> </span></td>
                        <td class="border-td">
                          <span class="ms-1" style="font-weight: bold"
                            >$ -</span
                          >
                        </td>
                      </tr>
                      <!-- <th scope="row">1</th> -->
                      <td>
                        <div class="row">
                          <div class="col-md-3">
                            <p>
                              <span style="font-weight: bold"></span>
                            </p>
                          </div>
                          <div class="col-md-9">
                            <div class="row" style="text-align: center">
                              <div class="col-md-4">
                                <span class="fw-bold"
                                  >MAXAUTOWAY CREDIT PTE.LTD</span
                                >
                              </div>
                              <div class="col-md-7">
                                <span class="fw-bold">Hire Purchase</span>
                              </div>
                            </div>
                            <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                          </div>
                        </div>
                      </td>
                      <td class="border-td"></td>

                      <tr>
                        <!-- <th scope="row">1</th> -->
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Loan Amont</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                        ${{
                                          this.finance_company_tax_invoice
                                            .LOAN_AMOUNT
                                        }}
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                        $
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"></td>
                      </tr>
                      <tr>
                        <!-- <th scope="row">1</th> -->
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Loan Tenure</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                        {{
                                          this.finance_company_tax_invoice
                                            .LOAN_TENURE
                                        }}
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                        $
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"></td>
                      </tr>
                      <tr>
                        <!-- <th scope="row">1</th> -->
                        <td>
                          <div class="row">
                            <div class="col-md-3">
                              <p>
                                <span style="font-weight: bold"
                                  >Interest Rate</span
                                >
                              </p>
                            </div>
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                        {{
                                          this.finance_company_tax_invoice
                                            .INTEREST_RATE
                                        }}%
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="row">
                                    <div class="col-md-3">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      >
                                        $
                                      </span>
                                    </div>
                                    <div class="col-md-9">
                                      <span
                                        class="ms-1"
                                        style="font-weight: bold"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                            </div>
                          </div>
                        </td>
                        <td class="border-td"></td>
                      </tr>
                      <tr style="border-top: 1px solid #212529">
                        <!-- <th scope="row">1</th> -->
                        <td class="border-td-2">
                          <span style="font-weight: bold"
                            >Amount Due from customer</span
                          >
                        </td>
                        <td class="border-td border-td-2">
                          <span class="ms-1"></span>
                        </td>
                        <td class="border-td border-td-2">
                          <div class="row">
                            <div class="col-md-4">
                              <span class="fw-bold"></span>
                            </div>
                            <div class="col-md-4">
                              <span class="fw-bold"
                                >${{
                                  this.finance_company_tax_invoice.BALANCE_DUE
                                }}</span
                              >
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- content footer  -->
              <div class="content-footer">
                <p class="mt-2">
                  Please cross and make cheque payable to Lek Auto Pte Ltd (Bank
                  Account OCBC 671885168001)
                </p>
                <div class="row mt-3">
                  <div class="col-md-12">
                    <ul>
                      <li>
                        <div class="row">
                          <h6>
                            THIS IS A COMPUTER GENERATED INVOICE. NO SIGNATURE
                            REQUIRED
                          </h6>
                        </div>
                      </li>
                    </ul>
                    <div class="row">
                      <div class="col-md-7"></div>
                      <div class="col-md-5">
                        <div class="text-center">
                          <img
                            src="../../assets/images/QRCodeForTaxInvoice.png"
                            class="w-100 mt-2"
                            alt="qr code"
                          />
                          <p class="scan-msg mt-1">SCAN TO PAY</p>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <ul>
                          <li class="mt-4">
                            <p>Memo:</p>
                            <span
                              >This is a computer generated document,no
                              signature is required.</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <vue-html2pdf
            style="display: none"
            :show-layout="true"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="purchasenote"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="100%"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
            ref="htmlPdf6"
          >
            <section slot="pdf-content" class="p-5">
              <div class="page-data">
                <div class="content-header">
                  <div class="row mt-4">
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-3">
                          <img
                            style="margin-left: 5em"
                            src="../../assets/images/icon.png"
                            class="img-fluid header-img"
                            alt="#"
                          />
                        </div>
                        <div class="col-md-7 p-0">
                          <ul style="margin-left: 7em">
                            <li>
                              <img src="../../assets/images/logo-txt.png" />
                            </li>
                            <li class="mt-2" style="width: 30em">
                              <p>
                                1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                                627615
                              </p>
                            </li>
                            <li>
                              <p>ROC No./ GST Reg No:. 201929524C</p>
                            </li>
                            <li>
                              <p>Tel: 62395191 Fax: 62395190</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3 ml-2">
                    <div class="col-md-12" style="text-align: end">
                      <h4 class="fw-bold">
                        <span
                          style="
                            border: 1px solid rgba(0, 0, 0, 0.3);
                            padding: 4px 10px;
                          "
                        >
                          INVOICE</span
                        >
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <!-- header  -->
                  <div class="col-md-4">
                    <div class="content-header-2" style="margin-left: 2em">
                      <ul>
                        <li><p>Bill To,</p></li>
                        <li>
                          <p>
                            <span style="font-weight: bold">{{
                              this.finance_company_tax_invoice.FINANCE_COMPANY
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span style="font-weight: bold">{{
                              this.finance_company_tax_invoice.ADDRESS_LINE_1
                            }}</span>
                          </p>
                        </li>
                        <li>
                          <p>
                            <span style="font-weight: bold">
                              {{
                                this.finance_company_tax_invoice.COUNTRY_NAME
                              }}
                              {{ this.finance_company_tax_invoice.PIN_CODE }}
                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <ul>
                      <li></li>
                      <li>
                        <p></p>
                      </li>
                      <li>
                        <p></p>
                      </li>

                      <li>
                        <p></p>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4">
                    <div class="content-header-2">
                      <ul>
                        <li>
                          <div class="row">
                            <div class="col-md-5">
                              <p>Invoice No</p>
                            </div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:
                                {{
                                  this.finance_company_tax_invoice.INVOICE_NO
                                }}</span
                              >
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5"><p>Term</p></div>
                            <div class="col-md-7">
                              <span style="font-weight: bold">COD</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5"><p>Date</p></div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:{{
                                  this.finance_company_tax_invoice
                                    .TAX_INVOICE_DATE
                                }}
                              </span>
                              <!-- <span style="font-weight:bold">{{this.pdf_deta.TAX_INVOICE_DATE}} </span> -->
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="row">
                            <div class="col-md-5"><p>VSA No.</p></div>
                            <div class="col-md-7">
                              <span style="font-weight: bold"
                                >:{{ this.finance_company_tax_invoice.VSA_NO }}
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- after heading sec  -->
                  <div class="col-md-12">
                    <div
                      class="content-body-2 ps-1 pe-1"
                      style="border: 1px solid #212121"
                    >
                      <div class="row">
                        <div class="col-md-7 pt-1 pb-1">
                          <div class="content-header-3">
                            <ul>
                              <li>
                                <div class="row">
                                  <div class="col-md-6">
                                    <p>Vehicle Registration No.</p>
                                  </div>
                                  <div class="col-md-6">
                                    <span style="font-weight: bold"
                                      >:{{
                                        this.finance_company_tax_invoice
                                          .VEHICLE_NUMBER
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-md-6">
                                    <p>Vehicle Make</p>
                                  </div>
                                  <div class="col-md-6">
                                    <span style="font-weight: bold"
                                      >:{{
                                        this.finance_company_tax_invoice
                                          .MAKE_NAME
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-md-6">
                                    <p>VehicleModel</p>
                                  </div>
                                  <div class="col-md-6">
                                    <span style="font-weight: bold"
                                      >:{{
                                        this.finance_company_tax_invoice
                                          .MODEL_NAME
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-md-6">
                                    <p>Original Registration Date</p>
                                  </div>
                                  <div class="col-md-6">
                                    <span style="font-weight: bold"
                                      >:{{
                                        this.finance_company_tax_invoice
                                          .ORIGINAL_REGISTRATION_DATE
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="col-md-5 pt-1 pb-1"
                          style="border-left: 1px solid rgba(0, 0, 0, 0.3)"
                        >
                          <div class="content-header-3">
                            <ul>
                              <li>
                                <div class="row">
                                  <div class="col-md-5">
                                    <p>Engine No.</p>
                                  </div>
                                  <div class="col-md-7">
                                    <span style="font-weight: bold">{{
                                      this.finance_company_tax_invoice.ENGINE_NO
                                    }}</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-md-5">
                                    <p>Chassis No.</p>
                                  </div>
                                  <div class="col-md-7">
                                    <span style="font-weight: bold"
                                      >:{{
                                        this.finance_company_tax_invoice
                                          .CHASIS_NO
                                      }}</span
                                    >
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-md-5">
                                    <p>Sales Person</p>
                                  </div>
                                  <div class="col-md-7">
                                    <span style="font-weight: bold"
                                      >:{{
                                        this.finance_company_tax_invoice
                                          .INCHARGE_NAME
                                      }}
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div class="row">
                                  <div class="col-md-5">
                                    <p>GST Method</p>
                                  </div>
                                  <div class="col-md-7">
                                    <span style="font-weight: bold"
                                      >: Gross Margin</span
                                    >
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mt-3">
                    <div
                      class="content-footer"
                      style="border: 1px solid #212121"
                    >
                      <table class="table table-borderless">
                        <thead style="border-bottom: 1px solid #212121">
                          <tr></tr>
                          <tr>
                            <th scope="col">
                              <span style="font-weight: bold">Description</span>
                            </th>
                            <th scope="col">
                              <span style="font-weight: bold"></span>
                            </th>
                            <th
                              scope="col"
                              class="border-td"
                              style="text-align: center"
                            >
                              <span style="font-weight: bold"
                                >Amount&nbsp;($)</span
                              >
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <!-- <th scope="row">1</th> -->

                            <td
                              class="border-td-2"
                              style="border-bottom: 1px solid #212121"
                            >
                              <span style="font-weight: bold">
                                {{
                                  this.finance_company_tax_invoice
                                    .VEHICLE_NUMBER
                                }}
                              </span>
                            </td>
                            <td
                              class="border-td border-td-2"
                              style="border-bottom: 1px solid #212121"
                            >
                              <span style="font-weight: bold"> </span>
                            </td>
                            <td
                              class="border-td border-td-2"
                              style="border-bottom: 1px solid #212121"
                            >
                              <div class="row">
                                <div class="col-md-4">
                                  <span class="fw-bold"></span>
                                </div>
                                <div class="col-md-4">
                                  <span class="fw-bold"
                                    >${{
                                      this.finance_company_tax_invoice
                                        .TOTAL_VEHICLE_PRICE
                                    }}</span
                                  >
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <!-- <th scope="row">1</th> -->
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Fees received on behalfof oter agency and
                                      company</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span class="ms-1" style="font-weight: bold"
                                >$ 1440.00</span
                              >
                            </td>
                          </tr>
                          <tr>
                            <!-- <th scope="row">1</th> -->
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Loan Advanced Payment</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span class="ms-1" style="font-weight: bold"
                                >$
                                {{
                                  this.finance_company_tax_invoice.LOAN_ADVANCE
                                }}</span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >HP Charges</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span class="ms-1" style="font-weight: bold"
                                >$ 1987.56</span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Insuarce Premium</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span class="ms-1" style="font-weight: bold"
                                >$
                                {{
                                  this.finance_company_tax_invoice
                                    .INSURANCE_PREMIUM
                                }}</span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Admin Fee</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span class="ms-1" style="font-weight: bold"
                                >$
                                {{
                                  this.finance_company_tax_invoice
                                    .ADMIN_FEE_TAX_VALUE
                                }}</span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Total Invoice Amount</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span
                                class="ms-1"
                                style="font-weight: bold"
                              ></span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div
                                  class="col-md-3"
                                  style="border-bottom: 1px solid #212121"
                                >
                                  <p>
                                    <span style="font-weight: bold"></span>
                                  </p>
                                </div>
                                <div
                                  class="col-md-9"
                                  style="border-bottom: 1px solid #212121"
                                >
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                        <div
                                          class="col-md-9"
                                          style="
                                            border-bottom: 1px solid #212121;
                                          "
                                        >
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="col-md-6"
                                      style="border-bottom: 1px solid #212121"
                                    ></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td
                              class="border-td"
                              style="border-bottom: 1px solid #212121"
                            >
                              <span class="ms-1"> </span>
                            </td>
                            <td
                              class="border-td"
                              style="border-bottom: 1px solid #212121"
                            >
                              <span
                                class="ms-1"
                                style="font-weight: bold"
                              ></span>
                            </td>
                          </tr>

                          <tr>
                            <!-- <th scope="row">1</th> -->
                            <td>
                              <span style="font-weight: bold"
                                >Payment Details :</span
                              >
                            </td>
                            <td class="border-td"></td>
                          </tr>
                          <tr></tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Sales Deposit</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span class="ms-1" style="font-weight: bold"
                                >$
                                {{
                                  this.finance_company_tax_invoice
                                    .DEPOSIT_AMOUNT
                                }}</span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Down/full payment</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span class="ms-1" style="font-weight: bold"
                                >$
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Trade In</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                            0
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span class="ms-1" style="font-weight: bold"
                                >$
                                {{
                                  this.finance_company_tax_invoice
                                    .TRADE_IN_REIMBURSEMENT
                                }}</span
                              >
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Excess Payment</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                            {{
                                              this.finance_company_tax_invoice
                                                .EXCESS_AMOUNT
                                            }}
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td">
                              <span class="ms-1"> </span>
                            </td>
                            <td class="border-td">
                              <span class="ms-1" style="font-weight: bold"
                                >$ -</span
                              >
                            </td>
                          </tr>
                          <!-- <th scope="row">1</th> -->
                          <td>
                            <div class="row">
                              <div class="col-md-3">
                                <p>
                                  <span style="font-weight: bold"></span>
                                </p>
                              </div>
                              <div class="col-md-9">
                                <div class="row" style="text-align: center">
                                  <div class="col-md-4">
                                    <span class="fw-bold"
                                      >MAXAUTOWAY CREDIT PTE.LTD</span
                                    >
                                  </div>
                                  <div class="col-md-7">
                                    <span class="fw-bold">Hire Purchase</span>
                                  </div>
                                </div>
                                <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                              </div>
                            </div>
                          </td>
                          <td class="border-td"></td>

                          <tr>
                            <!-- <th scope="row">1</th> -->
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Loan Amont</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                            ${{
                                              this.finance_company_tax_invoice
                                                .LOAN_AMOUNT
                                            }}
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                            $
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td"></td>
                          </tr>
                          <tr>
                            <!-- <th scope="row">1</th> -->
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Loan Tenure</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                            {{
                                              this.finance_company_tax_invoice
                                                .LOAN_TENURE
                                            }}
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                            $
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td"></td>
                          </tr>
                          <tr>
                            <!-- <th scope="row">1</th> -->
                            <td>
                              <div class="row">
                                <div class="col-md-3">
                                  <p>
                                    <span style="font-weight: bold"
                                      >Interest Rate</span
                                    >
                                  </p>
                                </div>
                                <div class="col-md-9">
                                  <div class="row">
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                            {{
                                              this.finance_company_tax_invoice
                                                .INTEREST_RATE
                                            }}%
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="row">
                                        <div class="col-md-3">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          >
                                            $
                                          </span>
                                        </div>
                                        <div class="col-md-9">
                                          <span
                                            class="ms-1"
                                            style="font-weight: bold"
                                          ></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <span class="ms-1" style="font-weight:bold">  $ &nbsp;&nbsp;77,500.00</span> -->
                                </div>
                              </div>
                            </td>
                            <td class="border-td"></td>
                          </tr>
                          <tr style="border-top: 1px solid #212529">
                            <!-- <th scope="row">1</th> -->
                            <td class="border-td-2">
                              <span style="font-weight: bold"
                                >Amount Due from customer</span
                              >
                            </td>
                            <td class="border-td border-td-2">
                              <span class="ms-1"></span>
                            </td>
                            <td class="border-td border-td-2">
                              <div class="row">
                                <div class="col-md-4">
                                  <span class="fw-bold"></span>
                                </div>
                                <div class="col-md-4">
                                  <span class="fw-bold"
                                    >${{
                                      this.finance_company_tax_invoice
                                        .BALANCE_DUE
                                    }}</span
                                  >
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- content footer  -->
                  <div class="content-footer">
                    <p class="mt-2">
                      Please cross and make cheque payable to Lek Auto Pte Ltd
                      (Bank Account OCBC 671885168001)
                    </p>
                    <div class="row mt-3">
                      <div class="col-md-9">
                        <ul>
                          <li>
                            <div class="row">
                              <h6>
                                THIS IS A COMPUTER GENERATED INVOICE. NO
                                SIGNATURE REQUIRED
                              </h6>
                            </div>
                          </li>
                        </ul>
                        <div class="row">
                          <div class="col-md-9"></div>
                          <div class="col-md-3">
                            <div class="text-center">
                              <img
                                src="../../assets/images/QRCodeForTaxInvoice.png"
                                class="img-fluid mt-2"
                                alt="qr code"
                              />
                              <p class="scan-msg mt-1">SCAN TO PAY</p>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <ul>
                              <li class="mt-4">
                                <p>Memo:</p>
                                <span
                                  >This is a computer generated document,no
                                  signature is required.</span
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </vue-html2pdf>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiEndPoints from "../../endpoints.js";
import VueHtml2pdf from "vue-html2pdf";
export default {
  data() {
    return {
      finance_company_tax_invoice: {},
    };
  },
  mounted() {
    this.Financecompanytaxinvoice();
    //this.getfinancecompanyinvice()
  },
  methods: {
    //finance company tax invoice api call
    Financecompanytaxinvoice() {
      this.axios
        .get(apiEndPoints.view_tax_note_info, {
          params: {
            ITEM: "WITHOUT_TAX_INVOICE",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: 29,
          },
        })
        .then((response) => {
          //console.log("Financecompanytaxinvoice", response);
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          } else {
            this.finance_company_tax_invoice = response.data.response[0];
          }
        })
        .catch((err) => {
          //console.log("axiosErr", err);
        });
    },
  },
};
</script>
