<template>
  <div>
    <!-- <div
                      class="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    > -->
    <div>
      <div
        class="card view_product_card tab-content product-card"
        style="padding: 0 !important; margin-top: 0"
      >
        <div class="card-header filter-panel-wrap pt-2">Change Password</div>
        <div class="card-body mt-3">
          <div class="forgot-passsword-form mb-4">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-6">
                  <ul class="styled-li">
                    <li id="length">
                      Password length should be withnin 8 to 15 characters.
                    </li>
                    <li id="case_low" class="mt-2">
                      At least one lowercase character
                    </li>
                    <li id="case_up" class="mt-2">
                      At least one uppercase character
                    </li>
                    <li id="special" class="mt-2">
                      At least one special character
                    </li>
                    <li id="num" class="mt-2">At least one number character</li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <label class="frm-label">old password</label>
                  <div class="d-flex">
                    <input
                      type="password"
                      v-model="passwordata.OLD_PASSWORD"
                      class="form-control pe-4 password"
                      placeholder="Enter Old Password"
                    />
                    <button
                      type="button"
                      id="eye"
                      onclick="togglePassword(this)"
                      class="eye-btn border-0"
                    >
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        style="color: #000"
                      ></i>
                    </button>
                  </div>

                  <label class="frm-label">new password</label>
                  <div class="d-flex">
                    <input
                      type="password"
                      maxLength="15"
                      id="new_pwd"
                      v-model="passwordata.NEW_PASSWORD"
                      @keyup="passwordcheck"
                      class="form-control pe-4 password"
                      placeholder="Enter New Password"
                    />
                    <button
                      type="button"
                      id="eye"
                      onclick="togglePassword(this)"
                      class="eye-btn border-0"
                    >
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        style="color: #000"
                      ></i>
                    </button>
                  </div>
                  <label class="frm-label mt-4">repeat password</label>
                  <div class="d-flex">
                    <input
                      type="password"
                      maxLength="15"
                      v-model="passwordata.REPEAT_PASS"
                      @keyup="passwordcheck"
                      class="form-control pe-4 password"
                      placeholder="Repeat Password"
                    />
                    <button
                      type="button"
                      id=""
                      onclick="togglePassword(this)"
                      class="eye-btn eye border-0"
                    >
                      <i
                        class="fa fa-eye"
                        aria-hidden="true"
                        style="color: #000"
                      ></i>
                    </button>
                  </div>
                  <button
                    type="button"
                    @click="changePass"
                    :disabled="invalid"
                    id="btn_save_change_password"
                    class="custom-btn custom-success-btn mt-4"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      passwordata: {
        OLD_PASSWORD: "",
        NEW_PASSWORD: "",
        // REPEAT_PASS: "",
        USER_SYS_ID: "",
        ITEM: "USER_CHANGE_PASSWORD",
      },

      invalid: true,
    };
  },

  mounted() {
    $(document).ready(function () {
      $(document).on("keyup", "#new_pwd", function () {
        var lower = new RegExp("^(?=.*[a-z])");
        var upper = new RegExp("^(?=.*[A-Z])");
        var digit = new RegExp("^(?=.*[0-9])");
        var special_char = new RegExp("^(?=.*[@#$%^&+=])");
        var length = new RegExp("^.{8,15}");
        var pass = $("#new_pwd").val();
        var condition = [];
        var condition_count = 0;
        // var tmp = length.test(pass);
        if (length.test(pass)) {
          $("#length").css("color", "#008000");

          condition_count += 1;
        } else {
          $("#length").css("color", "#FF0000");
        }

        if (lower.test(pass)) {
          $("#case_low").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#case_low").css("color", "#FF0000");
        }
        if (upper.test(pass)) {
          $("#case_up").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#case_up").css("color", "#FF0000");
        }
        if (digit.test(pass)) {
          $("#num").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#num").css("color", "#FF0000");
        }
        if (special_char.test(pass)) {
          $("#special").css("color", "#008000");
          condition_count += 1;
        } else {
          $("#special").css("color", "#FF0000");
        }

        if (condition_count >= 5) {
          $("#btn_save_change_password").show();
        } else {
          $("#btn_save_change_password").hide();
        }
      });
    });
  },

  methods: {
    passwordcheck() {
      //const pass=this.NEW_PASS;
      if (this.NEW_PASS === this.REPEAT_PASS) {
        this.invalid = false;
      } else {
        this.invalid = true;
      }
    },
    changePass() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_detai"));

      this.passwordata.USER_SYS_ID = user_details.UserId;
      //console.log(this.passwordata);
      this.axios
        .post(apiEndPoints.change_password, this.passwordata)

        .then((response) => {
          //console.log(response.data);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.Response);

            this.$router.push({ name: "login" });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.Response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.response.status != 401 || err.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
  },
};
</script>
