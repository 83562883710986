import axios from "axios";
import Vue from "vue";
import router from "./router/index";

export default {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  }
};
axios.interceptors.request.use(
  config => {
    if ($cookies.isKey("_token")) {
      let token = $cookies.get("_token");
      config.headers.common["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    const self = new Vue();
    //console.log(error)
    if (error.response.status) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("lek_user_details")
          $cookies.remove("_token");
          router.replace({ name: "Login" });
          break;

        case 500:
       
          break;
      }
      return Promise.reject(error.response);
    }
  }
);
