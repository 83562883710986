<template>
  <div>
    <div v-if="loading">
      <div class="preloder loading_main_background" id="upload_loader">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>
    <div class="floating-heading">
      <h4><span>Vendors</span></h4>
    </div>
    <div class="page-content">
      <div class="page-data">
        <div class="row mb-3">
          <div class="col-md-12 mb-1">
            <label class="frm-label-2" style="color: #a36626"
              ><i class="fa-solid fa-filter"></i> Filter</label
            >
          </div>

          <div class="row mb-3">
            <div class="col-md-2" v-if="statusvalue">
              <label class="frm-label-2" style="font-size: 15px"
                >Vendor Name:
                <span class="filter-lbl">{{ statusvalue }}</span></label
              >
            </div>
            <div class="col-md-3" v-if="numbervalue">
              <label class="frm-label-2" style="font-size: 15px">
                Vendor Code:
                <span class="filter-lbl">{{ numbervalue }}</span></label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="floating-search-btn-container">
      <button
        id="srch_btn"
        data-bs-toggle="modal"
        data-bs-target="#left_modal_sm"
      >
        <i class="fa fa-search" aria-hidden="true"></i>
      </button>
    </div>
    <div
      class="floating-add-btn-container animate__animated animate__lightSpeedInRight"
    >
      <button
        id="add_btn"
        data-bs-toggle="modal"
        data-bs-target="#employee_modal"
        v-if="menuPermissions.FULL_ACCESS || menuPermissions.CREATE_ACCESS"
      >
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data">
          <div class="row">
            <div class="col-md-12 animate__animated animate__slideInRight">
              <div class="card-wrap shadow-main">
                <div class="card-wrap-body">
                  <div class="data-table master-table">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>SL. No.</th>
                          <th>Vendor Name</th>
                          <th>Vendor Code</th>
                          <th>UEN</th>
                          <th>Address</th>
                          <th>Person Name</th>
                          <th>Person Number</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(user_detail, index) in user_details"
                        :key="user_detail.RECORD_SYS_ID"
                      >
                        <tr>
                          <td>{{ index + 1 }}</td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.VENDOR_NAME }}
                          </td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.VENDOR_CODE }}
                          </td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.UEN }}
                          </td>
                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.ADDRESS_LINE_1 }}
                            {{ user_detail.ADDRESS_LINE_2 }}
                          </td>

                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.CONTACT_PERSON_NAME }}
                          </td>

                          <td
                            @click="
                              showModal(user_detail, '#rokerupdate_modal')
                            "
                          >
                            {{ user_detail.CONTACT_PERSON_NUMBER }}
                          </td>
                          <td class="table-btn">
                            <button
                              type="button"
                              class="custom-btn me-1 p-0"
                              data-bs-toggle="modal"
                              data-bs-target="#view_frm"
                              @click="
                                showDetails(user_detail, '#view_broker_modal')
                              "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-eye"
                              >
                                <path
                                  d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                ></path>
                                <circle cx="12" cy="12" r="3"></circle>
                              </svg>
                            </button>
                            <button
                              type="button"
                              class="custom-btn p-0"
                              @click="
                                showModal(user_detail, '#rokerupdate_modal')
                              "
                              v-if="menuPermissions.UPDATE_ACCESS"
                            ></button>
                            <button
                              type="button"
                              class="custom-btn p-0"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_frm"
                              @click="Delete(user_detail)"
                              v-if="menuPermissions.DELETE_ACCESS"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trash-2"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path
                                  d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                ></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Vendor Modal Start  -->
    <div
      class="modal modal-right fade"
      id="employee_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Vendor</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="bankform2">
              <form>
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >UEN <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="UEN"
                        v-model.trim="create_vendor.UEN"
                        class="form-control"
                        placeholder="Enter UEN"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && create_vendor.UEN != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Vendor Code <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="Vendorcode"
                        v-model.trim="create_vendor.VENDOR_CODE"
                        class="form-control"
                        placeholder="Enter Vendor Code"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.VENDOR_CODE != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Vendor Name <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="Vendor Name"
                        v-model.trim="create_vendor.VENDOR_NAME"
                        class="form-control"
                        placeholder="Enter Vendor Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.VENDOR_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label">
                        Person Number <span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="Person Number"
                        v-model.trim="create_vendor.CONTACT_PERSON_NUMBER"
                        class="form-control"
                        placeholder="Enter Person Number"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.CONTACT_PERSON_NUMBER != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Person Name <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="Person Name"
                        v-model.trim="create_vendor.CONTACT_PERSON_NAME"
                        class="form-control"
                        placeholder="Enter Person Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.CONTACT_PERSON_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-8 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Address <span style="color: red">*</span></label
                      >
                      <textarea
                        type="text"
                        style="height: 5em"
                        name="Address Line 1"
                        v-model.trim="create_vendor.ADDRESS_LINE_1"
                        class="form-control"
                        placeholder="Enter Address"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.ADDRESS_LINE_1 != '',
                        }"
                      ></textarea>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Post Code <span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="pin Code"
                        v-model.trim="create_vendor.PIN_CODE"
                        class="form-control"
                        placeholder="Enter Post Code "
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && create_vendor.PIN_CODE != null,
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-3">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Country <span style="color: red">*</span></label
                      >
                      <b-form-select
                        name="Country"
                        v-model="create_vendor.COUNTRY_SYS_ID"
                        @change="getcountrysysid"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            create_vendor.COUNTRY_SYS_ID != '',
                        }"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- Select --</b-form-select-option
                          >
                        </template>
                        <option value="" selected>Select</option>
                        <option
                          v-for="cost_type_info_detail in cost_type_info_details"
                          :value="cost_type_info_detail.COUNTRY_SYS_ID"
                          :key="cost_type_info_detail.COUNTRY_SYS_ID"
                        >
                          {{ cost_type_info_detail.COUNTRY_NAME }}
                        </option>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          data-bs-dismiss="modal"
                          @click="Addvendor"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-danger-btn"
                          data-bs-dismiss="modal"
                          @click="emptyfu"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- Add Vendor Modal End  -->

    <!-- Edit Vendor Modal Start  -->
    <div
      class="modal modal-right fade"
      id="rokerupdate_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 75% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Vendor</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <ValidationObserver ref="bankform" v-slot="{ invalid }">
              <form>
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >UEN <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="UEN"
                        v-model.trim="update_user.UEN"
                        class="form-control"
                        placeholder="Enter UEN"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.UEN != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Vendor Code <span style="color: red">*</span></label
                      >
                      <input
                        type="text"
                        name="Vendor Code"
                        v-model.trim="update_user.VENDOR_CODE"
                        class="form-control"
                        placeholder="Enter Vendor Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.VENDOR_CODE != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-2">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Vendor Name <span style="color: red">*</span></label
                      >
                      <input
                        type="name"
                        name="Vendor Name"
                        v-model.trim="update_user.VENDOR_NAME"
                        class="form-control"
                        placeholder="Enter Vendor Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.VENDOR_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-4 mt-2">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Person Name <span style="color: red">*</span></label
                      >
                      <input
                        type="name"
                        name="Person Name"
                        v-model.trim="update_user.CONTACT_PERSON_NAME"
                        class="form-control"
                        placeholder="Enter Person Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.CONTACT_PERSON_NAME != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-2">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Person Number<span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="Person Number"
                        v-model.trim="update_user.CONTACT_PERSON_NUMBER"
                        class="form-control"
                        placeholder="Enter Person Number"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.CONTACT_PERSON_NUMBER != '',
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-8 mt-2">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Address <span style="color: red">*</span></label
                      >
                      <textarea
                        style="height: 5em"
                        type="name"
                        name="Address line 1"
                        v-model.trim="update_user.ADDRESS_LINE_1"
                        class="form-control"
                        placeholder="Enter Address Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.ADDRESS_LINE_1 != '',
                        }"
                      ></textarea>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider v-slot="{ errors }">
                      <label class="frm-label"
                        >Country <span style="color: red">*</span></label
                      >
                      <b-form-select
                        name="Series"
                        v-model="update_user.COUNTRY_SYS_ID"
                        :class="{
                          'form-control': true,
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null &&
                            update_user.COUNTRY_NAME != null,
                        }"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >-- Select --</b-form-select-option
                          >
                        </template>
                        <option
                          v-for="cost_type_info_detail in cost_type_info_details"
                          :value="cost_type_info_detail.COUNTRY_SYS_ID"
                          :key="cost_type_info_detail.COUNTRY_SYS_ID"
                        >
                          {{ cost_type_info_detail.COUNTRY_NAME }}
                        </option>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-4 mt-3">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <label class="frm-label"
                        >Post Code <span style="color: red">*</span></label
                      >
                      <input
                        type="number"
                        name="Post Code"
                        v-model.trim="update_user.PIN_CODE"
                        class="form-control"
                        placeholder="Enter Vendor Name"
                        :class="{
                          'is-invalid': errors[0] != null,
                          'is-valid':
                            errors[0] == null && update_user.PIN_CODE != null,
                        }"
                      />
                      <div class="invalid-feedback">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </div>

                  <div class="modal-footer-2">
                    <div class="row mt-4">
                      <div class="col-md-2">
                        <button
                          type="button"
                          class="custom-btn custom-success-btn"
                          :disabled="invalid"
                          @click="updateVendor"
                        >
                          Save
                        </button>
                      </div>
                      <div class="col-md-2">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          class="custom-btn custom-danger-btn"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <!-- Edit Vendor Modal End  -->

    <!-- View Vendor Modal Start  -->
    <div
      class="modal modal-right fade"
      id="view_broker_modal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" style="width: 55% !important">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">View Vendor</h5>
            <button
              type="button"
              class="btn-normal modal-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-4 mt-3">
                <label class="frm-label">Vendor Name </label>
                <label>{{ view_brokers.VENDOR_NAME }}</label>
              </div>

              <div class="col-md-4 mt-3">
                <label class="frm-label">Vendor Code</label>
                <label>{{ view_brokers.VENDOR_CODE }}</label>
              </div>

              <div class="col-md-4 mt-3">
                <label class="frm-label">Person Name </label>
                <label>{{ view_brokers.CONTACT_PERSON_NAME }}</label>
              </div>

              <div
                class="col-md-12 mt-3"
                v-if="
                  view_brokers.ADDRESS_LINE_1 ||
                  view_brokers.ADDRESS_LINE_2 ||
                  view_brokers.PIN_CODE
                "
              >
                <label class="frm-label">Address </label>
                <label>
                  <span v-if="view_brokers.ADDRESS_LINE_1">{{
                    view_brokers.ADDRESS_LINE_1
                  }}</span
                  >,
                  <span v-if="view_brokers.COUNTRY_NAME"
                    >{{ view_brokers.COUNTRY_NAME }} -
                  </span>
                  <span v-if="view_brokers.PIN_CODE">{{
                    view_brokers.PIN_CODE
                  }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- View Vendor Modal Start  -->

    <div
      class="modal modal-left fade"
      id="left_modal_sm"
      tabindex="-1"
      role="dialog"
      aria-labelledby="left_modal_sm"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search By</h5>
          </div>
          <div class="modal-body search-panel">
            <div class="row">
              <div class="col-md-12">
                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="all"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">All Combinations</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    class="rad-input"
                    name="rad"
                    value="status"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Vendor Name</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="number"
                    class="rad-input"
                    v-model="search_option"
                  />
                  <div class="rad-design"></div>
                  <div class="rad-text">Vendor Code</div>
                </label>

                <label class="rad-label">
                  <input
                    type="radio"
                    name="rad"
                    value="make"
                    class="rad-input"
                    v-model="search_option"
                  />
                </label>
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'status'"
              >
                <label class="frm-label">Vendor name</label>
                <input
                  type="text"
                  v-model="INSURANCE_TYPE"
                  class="form-control"
                  placeholder="Enter Person Name"
                />
              </div>
              <div
                class="col-md-12 mt-1"
                v-show="search_option == 'all' || search_option == 'number'"
              >
                <label class="frm-label">Vendor Code</label>
                <input
                  type="text"
                  v-model="VEHICLENUMBER"
                  class="form-control"
                  placeholder="Enter Vendor Code"
                />
              </div>

              <div class="col-md-12 mt-2" v-if="search_option">
                <div class="btn-conainer">
                  <button
                    type="button"
                    @click="getvendor()"
                    id="srch-btn"
                    class="custom-btn custom-success-btn"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NProgress from "nprogress";
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
export default {
  data() {
    return {
      loading: false,
      user_details: [],
      cost_type_info_details: [],
      create_vendor: {
        ITEM: "ADD",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        VENDOR_CODE: "",
        VENDOR_NAME: "",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        POST_CODE: "",
        COUNTRY_NAME: "",
        COUNTRY_SYS_ID: "",
        UEN: "",
      },
      search_option: "",
      vehicletype: "",
      INSURANCE_TYPE: "",
      view_brokers: [],
      selected_row: {},
      update_user: {},
      CONTACT_PERSON_NUMBER: "",
      VENDOR_NAME: "",
      VENDOR_CODE: "",
      vehicledetails: {},
      vehicledetails_page_no: 1,
    };
  },
  mounted() {
    this.getvendordetails();
    this.getCountryInfo();
  },
  methods: {
    emptyfu() {
      $("#employee_modal").modal("hide");
      this.create_vendor.ADDRESS_LINE_1 = "";
      this.create_vendor.ADDRESS_LINE_2 = "";
      this.create_vendor.VENDOR_NAME = "";
      this.create_vendor.VENDOR_CODE = "";
      this.create_vendor.MOBILE_NO = "";
      this.create_vendor.POST_CODE = "";
      this.create_vendor.GENDER = "";
      this.create_vendor.EMAIL_ID = "";
      this.create_vendor.COUNTRY_SYS_ID =
        this.cost_type_info_details[0].COUNTRY_SYS_ID;
      this.getcountrysysid();
    },

    async Delete(data) {
      const result = await Global.showConfirmationAlert(
        `Delete Vendor ${data.VENDOR_NAME}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        NProgress.start();
        NProgress.set(0.1);
        this.loading = true;
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.VENDOR_SYS_ID,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.create_update_customer, postarrydata)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getvendordetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          })
          .finally(() => {
            NProgress.done();
            this.loading = false;
          });
      }
    },

    filtershowhide() {
      if (this.NAME != "") {
        this.makevalue = true;
      } else {
        this.makevalue = false;
      }
      if (this.CONTACT_NO != "") {
        this.modelvalue = true;
      } else {
        this.modelvalue = false;
      }
    },

    Addvendor() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_vendor["CREATED_BY"] = user_details.UserId;
      this.$refs.bankform2.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          NProgress.start();
          NProgress.set(0.1);
          this.loading = true;
          this.axios
            .post(apiEndPoints.create_update_vendor, this.create_vendor)
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getvendordetails();
                this.emptyfu();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .catch((err) => {
              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    updateVendor() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.update_user["CREATED_BY"] = user_details.UserId;
      this.update_user["ITEM"] = "UPDATE";
      this.update_user["RECORD_SYS_ID"] = this.update_user.VENDOR_SYS_ID;
      this.update_user["VENDOR_ADDRESS_SYS_ID"] =
        this.update_user.VENDOR_ADDRESS_SYS_ID;
      this.axios
        .post(apiEndPoints.create_update_vendor, this.update_user)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            $("#rokerupdate_modal").modal("hide");
            this.getvendordetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    getvendor() {
      this.filtershowhide();
      if (this.search_option == "all") {
        this.statusvalue = this.INSURANCE_TYPE;
        this.numbervalue = this.VEHICLENUMBER;
      } else {
        if (this.search_option == "status")
          this.statusvalue = this.INSURANCE_TYPE;
        if (this.search_option == "number")
          this.numbervalue = this.VEHICLENUMBER;
      }
      this.axios
        .get(apiEndPoints.view_vendor, {
          params: {
            ITEM: "SPECIFIC",

            RECORD_SYS_ID: 0,
            PAGE_SIZE: 100,
            VENDOR_NAME: this.INSURANCE_TYPE,
            VENDOR_CODE: this.VEHICLENUMBER,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
          this.user_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    loadVehicleDetails(isVisible) {
      if (isVisible) {
        this.getvendor(this.vehicledetails_page_no);
      }
    },

    getcountrysysid() {
      this.create_vendor.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.create_vendor.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
      this.update_user.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.update_user.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
    },

    getvendordetails() {
      this.axios
        .get(apiEndPoints.view_vendor, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 100,
            VENDOR_NAME: "this.user_detail.VENDOR_NAME",
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          //console.log(response);
          this.user_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    showDetails(row, id) {
      this.view_brokers = row;
      //console.log(row);
      $(id).modal("show");
      // this.getaddressDetails();
    },

    showModal(row, id) {
      this.update_user = row;
      //console.log(this.update_user);
      $(id).modal("show");
    },

    getCountryInfo() {
      this.axios
        .get(apiEndPoints.view_country, {
          params: { ITEM: "VIEW_ALL", RECORD_SYS_ID: 0 },
        })
        .then((response) => {
          //console.log(response);
          //this.cost_type_info_details = response.data.response;
          if (response.data.status == "true") {
            this.cost_type_info_details = response.data.response;
            this.$nextTick(() => {
              this.create_vendor.COUNTRY_SYS_ID =
                this.cost_type_info_details[0].COUNTRY_SYS_ID;
              this.getcountrysysid();
            });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.cost_type_info_details = [];
            this.create_vendor.COUNTRY_SYS_ID = null;
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  computed: {
    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
  },
};
</script>

<style scoped>
.loading_main_background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(42, 42, 43, 0.45);
  z-index: 9999;
}
.preloder img {
  width: auto;
  margin: 12% 38%;
  border-radius: 50%;
  opacity: 0.6;
}
.loading_main_background img {
  width: 5% !important;
  margin: 20% 50% !important;
  opacity: 1 !important;
  position: absolute;
}
img {
  vertical-align: middle;
  border-style: none;
}
li.nav-item {
  width: 10em !important;
  text-align: center !important;
}
.downld {
  background: transparent;
  outline: none;
  border: none;
  color: #112b3c;
  font-weight: 600;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  float: right !important;
  font-size: 13px;
  margin-top: 5px;
}
.animate__bounceIn {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: calc(var(--animate-duration) * 0.75);
  animation-duration: calc(var(--animate-duration) * 0.75);
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
</style>
