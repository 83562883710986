<template>
  <div
    style="
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 234, 220, 1) 100%
      );
      height: 100vh;
    "
  >
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 sm-pad" style="padding-right: 0">
            <div class="log-flex-wrap animate__animated animate__zoomIn">
              <div class="login-form-wrap">
                <img
                  src="../../assets/images/logo.png"
                  class="img-fluid mb-4 animate__animated animate__rubberBand"
                  alt="logo"
                  style="animation-delay: 0.2s"
                />
                <p>
                  Please collect OTP from your registered email id to reset the
                  password
                </p>
                <div class="form-label">
                  <label class="frm-label">Enter Registered Email Id</label>
                  <input
                    type="text"
                    v-model="user_verification.EMAIL_ID"
                    class="form-control"
                    placeholder="enter email id"
                  />
                </div>

                <!-- <button type="button" @click="verifyEmail">Next</button> -->
                <!-- <div class="form-label">
                  <a class="login-btn" @click="verifyEmail" style="cursor: pointer">NEXT</a></div>
            </div> -->
                <div class="form-label">
                  <a
                    class="login-btn"
                    @click="verifyEmail(), forget()"
                    style="cursor: pointer"
                    >Get OTP</a
                  >
                </div>

                <div>
                  <button
                    type="button"
                    @click="cancel"
                    class="custom-btn custom-danger-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      otp_details: {},
      USER_SYS_ID: "",
      user_verification: {
        ITEM: "EMAIL_VERIFICATION",
        EMAIL_ID: "",
      },
      user_name_details: {
        USER_NAME: "TemporaryToken",
        AUTH_KEY:
          "6481f8e1a060d56eeb7c10ac7809d316800dce013713c412e1d22076505b11a8",
      },
    };
  },
  mounted() {
    this.getBrokerDetails();
  },
  methods: {
    getBrokerDetails() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            ITEM: "SPECIFIC",
            NAME: this.NAME,
            CONTACT_NO: this.CONTACT_NO,
            BROKER_TYPE: this.BROKER_TYPE,
            USER_TYPE: "EXTERNAL",
            PAGE_SIZE: 12,
          },
        })
        .then((response) => {
          //console.log("b", response.data.response);
          if (response.data.status == "true") {
            this.user_details = response.data.response[0];
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    forget() {
      // this.$router.push({ name: "otp-check" });
      this.axios
        .post(apiEndPoints.temporary_token, this.user_name_details)
        .then((response) => {
          //console.log(response);
          if (response.data.Status == null) {
            $cookies.set("_token", response.data.Token, 0);
            //console.log("ri", response.data);
            localStorage.setItem(
              "lek_user_detai",
              JSON.stringify(response.data)
            );
            //this.$router.push({ name: "forget-pass" });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.Response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    verifyEmail() {
      //console.log("hello");
      let customer_details = JSON.parse(localStorage.getItem("lek_user_detai"));

      //console.log("z", this.customer_details);
      //console.log("ms", JSON.stringify(this.user_verification));

      this.axios
        .post(apiEndPoints.verify_email, this.user_verification)
        .then((response) => {
          //console.log("otp generate", response.data);

          if (response.data.status == "true") {
            this.otp_details = response.data.USER_SYS_ID;
            localStorage.setItem(
              "GET_EMAIL_ID",
              JSON.stringify(response.data.EMAIL_ID)
            );
            localStorage.setItem(
              "GET_SYS_ID",
              JSON.stringify(response.data.USER_SYS_ID)
            );
            //console.log("riju", response.data.USER_SYS_ID);
            this.otpgenerate();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (err.data.status == false || err.data.status == "false") {
            Global.showErrorAlert(true, "error", err.data.response);
          }
          if (err.response.status != 401 || err.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    otpgenerate() {
      this.axios
        .get(apiEndPoints.otp_verification, {
          params: {
            ITEM: "MAIL_CONFIG",
            TEMPLATE_NAME: "FORGOT_PASSWORD",
            PARAMETER: this.otp_details,
          },
        })
        .then((response) => {
          //console.log("r", response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", "otp sent succesfully");
            this.$router.push({ name: "otp-check" });
          }
          this.system_role_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    cancel() {
      this.$router.push({ name: "Login" });
    },
    forget() {
      this.$router.push({ name: "otp-check" });
      this.axios
        .post(apiEndPoints.temporary_token, this.user_name_details)
        .then((response) => {
          //console.log(response);
          if (response.data.Status == null) {
            $cookies.set("_token", response.data.Token, 0);
            //console.log("ri", response.data);
            localStorage.setItem(
              "lek_user_detai",
              JSON.stringify(response.data)
            );
            //this.$router.push({ name: "forget-pass" });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.Response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
