import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import AdminMaster from "../views/admin-master/Index.vue";
import MasterData from "../views/master-data/Index.vue";
import SystemRole from "../components/access-management/SystemRole.vue"
import SystemModule from "../components/access-management/SystemModule.vue"
import SystemSubModule from "../components/access-management/SystemSubModule.vue"
import Privillge from "../components/access-management/Privillge.vue"
import Menu from "../components/access-management/Menu.vue"
import Broker from "../components/user-management/Broker.vue";
import Customer from "../components/user-management/Customer.vue";
import Employee from "../components/user-management/Employee.vue";
import VendorList from "../components/user-management/VendorList.vue";
import admin_master from "../components/user-management/adminmaster.vue";
import Profile from "../components/Profile/Profile.vue";
import ChangePass from "../components/Profile/ChangePass.vue";
import ChangePassTemp from "../components/Profile/ChangePassTemp.vue";
import AddInsurance from "../components/insurance/AddInsurance.vue";
import AddQuotation from "../components/insurance/AddQuotation.vue";
import ViewQuotation from "../components/insurance/ViewQuotation.vue";
import ViewInsurance from "../components/insurance/ViewInsurance.vue";
import PriceList from "../components/Price-list/Pricelist.vue";
import PriceListOld from "../components/Price-list/PricelistOld.vue";
import EtspriceList from "../components/Price-list/etspricelist.vue";
import Askingprice from "../components/Price-list/Askingprice.vue";
import ForgetPassword from "../views/auth/ForgetPassword.vue";
import OtpCheck from "../views/auth/OtpCheck.vue";
import SetNewPassword from "../views/auth/SetNewPassword.vue";
import Commission from "../components/commission/VechicleCommission.vue";
import MyCommission from "../components/commission/MyCommission.vue";
import Bookingreport from "../components/Ereport/Bookingreport.vue";
import EInsurance from "../components/Ereport/EInsurance.vue";
import Etransfer from "../components/Ereport/Etransfer.vue";
import Pendingreport from "../components/Ereport/Pendingreport.vue";
import EtransferOut from "../components/Ereport/EtransferOut.vue";
import Loanindex  from "../views/admin-master/Loanindex.vue";
import PLReport from "../components/Ereport/PLReport.vue";
import testing from "../components/Ereport/testing.vue";
import invoice from "../components/Ereport/invoice.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: "/login"
  },
  {
    path: "/",
    component: () => import("../views/layout/Layout1.vue"),
    children: [
    {
      path: "",
      redirect: "/login"
    },
  {
    path: "/dashboard",
    name: "dashboard",
    component:  Dashboard,
  },
  {
    path: "/admin-master",
    name: "admin-master",
    component:  AdminMaster,
  },
  {
    path: "/loan-master",
    name: "loan-master",
    component:  Loanindex,
  },
  {
    path: "/master-data",
    name: "master-data",
    component:  MasterData,
  },
  {
    path: "/system-role",
    name: "system-role",
    component:  SystemRole,
  },
  {
    path: "/system-module",
    name: "system-module",
    component:  SystemModule,
  },
  {
    path: "/system-sub-module",
    name: "system-sub-module",
    component:  SystemSubModule,
  },
  {
    path: "/privillge-data",
    name: "privillge-data",
    component:  Privillge,
  },
  {
    path: "/menu",
    name: "menu",
    component:  Menu,
  },
  {
    path: "/broker-data",
    name: "broker-data",
    component:  Broker,
  },
  {
    path: "/customer-data",
    name: "customer-data",
    component:  Customer,
  },
  {
    path: "/employee-data",
    name: "employee-data",
    component:  Employee,
  },
  {
    path: "/vendorlist-data",
    name: "vendorlist-data",
    component:  VendorList,
  },
  {
    path: "/adminmasteradd-data",
    name: "addadminmaster-data",
    component: admin_master,
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/changepass",
    name: "changepass",
    component: ChangePass,
  },
  
  {
    path: "/addinsurance",
    name: "addinsurance",
    component: AddInsurance,
  },
  {
    path: "/addquotation",
    name: "addquotation",
    component: AddQuotation,
  },
  {
    path: "/viewquotation",
    name: "viewquotation",
    component: ViewQuotation,
  },
  {
    path: "/viewinsurance",
    name: "viewinsurance",
    component: ViewInsurance,
  },
  {
    path: "/price-list-new",
    name: "price-list",
    component: PriceList,
  },
  {
    path: "/price-list",
    name: "price-list",
    component: PriceListOld,
  },
  {
    path: "/ets-price-list",
    name: "ets-price-list",
    component: EtspriceList,
  },
  {
    path: "/asking-price",
    name: "asking-price",
    component: Askingprice,
  },
  {
    path:"/commission",
    name: "commission",
    component: Commission,
},
{
  path:"/bookingreport",
  name: "bookingreport",
  component: Bookingreport,
},
{
  path:"/einsurance",
  name: "einsurance",
  component: EInsurance,
},
{
  path:"/testing",
  name: "testing",
  component: testing,
},
{
  path:"/invoice",
  name: "invoice",
  component: invoice,
},
{
  path:"/etransfer",
  name: "etransfer",
  component: Etransfer,
},
{
  path:"/pendingreport",
  name: "pendingreport",
  component: Pendingreport,
},
{
  path:"/eout",
  name: "eout",
  component: EtransferOut,
},
{
  path:"/pandl",
  name: "pandl",
  component: PLReport,
},


{
  path:"/mycommission",
  name: "mycommission",
  component: MyCommission,
},


]
  },
  {
    path: "/changepasstemp",
    name: "changepasstemp",
    component: ChangePassTemp,
  },
  {
      path:"/forget-pass",
      name: "forget-pass",
      component: ForgetPassword,
  },
  {
    path:"/otp-check",
    name: "otp-check",
    component: OtpCheck,
},
{
  path:"/setnewpass",
  name: "setnewpass",
  component: SetNewPassword,
},
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue")
  },
 
  
  
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
