<template>
  <div>
    <div class="floating-heading">
      <h4><b>E-Transfer Out Report</b></h4>
    </div>
    <div class="col-md-12 mt-1 text-success">
      <div class="form-outline text-primary">
        <span class="inline">
          <div class="row mt-4">
            <div class="col-md-2">
              <label class="form-label" for="form1" display="inline"
                >Transfer Out Start Date</label
              >
              <input
                style="height: 3em;"
                type="date"
                id="form1"
                placeholder="enter dd/mm/yyyy"
                class="form-control form-icon-trailing"
                v-model="E_TRANSFER_OUT_START_DATE"
              />
            </div>
            <div class="col-md-2">
               <label class="form-label" for="form1"
                >Transfer Out End Date</label
              >
              <input
                style="height: 3em;"
                type="date"
                id="form1"
                placeholder="enter dd/mm/yyyy"
                class="form-control form-icon-trailing"
                v-model="E_TRANSFER_OUT_END_DATE"
              />
            </div>
            <div class="col-md-2">
              <label class="form-label" for="form1" display="inline">
                P/L Start date</label
              >
              <input
                style="height: 3em;"
                type="date"
                placeholder="enter dd/mm/yyyy"
                id="form1"
                class="form-control form-icon-trailing"
                v-model="P_L_START_DATE"
              />
            </div>
            <div class="col-md-2">
              <label class="form-label" for="form1">P/L End date</label>
              <input
                style="height: 3em;"
                type="date"
                placeholder="enter dd/mm/yyyy"
                id="form1"
                class="form-control form-icon-trailing"
                v-model="P_L_END_DATE"
              />
            </div>
            <div class="col-md-3">
              <label class="form-label" for="form1" display="inline"
                >Sales Agreement Number</label
              >
              <input
                style="height: 3em;"
                type="text"
                id="form1"
                class="form-control form-icon-trailing"
                v-model="SALES_AGREEMENT_NUMBER"
              />
            </div>
            <div class="col-md-1">
              <button
                type="submit"
                style="margin-top: 27px;font-size: 0.9rem !important;"
                class="btn btn-primary"
                @click="getPriceDetails"
              >
                Search
              </button>
            </div>
            <div class="col-md-12 offset-md-11 p-0 mt-3">
              <button
                type="button"
                class="custom-btn custom-excel-btn"
                @click="exportExcel()"
              >
                <i class="fa-solid fa-file-excel"></i> Excel
              </button>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div class="content-body-wrapper mt-2">
      <div class="page-content">
        <div class="page-data">
          <div class="data-table master-table">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Serial Number</th>
                  <th>Sales Agreement Number.</th>
                  <th>P/L Date</th>
                  <th>Car Plate</th>
                  <th>Make and Model</th>
                  <th>E-Transfer Out Date</th>
                  <th>Broker</th>
                  <th>Salesman Code</th>
                  <th>Selling Amount</th>
                </tr>
              </thead>
              <tbody
                v-for="user_detail in system_role_details"
                :key="user_detail.RECORD_SYS_ID"
              >
                <tr style="text-align: center">
                  <td>{{ user_detail.SL_NO }}</td>
                  <td>{{ user_detail.SALES_AGREEMENT_NUMBER }}</td>
                  <td>{{ user_detail.P_L_DATE }}</td>
                  <td>{{ user_detail.VEHICLE_NUMBER }}</td>
                  <td>
                    {{ user_detail.MAKE_NAME }}-{{ user_detail.MODEL_NAME }}
                  </td>
                  <td>{{ user_detail.E_TRANSFER_OUT_DATE }}</td>
                  <td>{{ user_detail.BROKER_1 }}</td>
                  <td>{{ user_detail.SELL_CODE }}</td>
                  <td>{{ user_detail.TOTAL_SELLING_PRICE }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
export default {
  data() {
    return {
      system_role_details: [],
      E_TRANSFER_OUT_START_DATE: "",
      E_TRANSFER_OUT_END_DATE: "",
      P_L_START_DATE: "",
      P_L_END_DATE: "",
      SALES_AGREEMENT_NUMBER: "",
    };
  },
  computed: {
    system_role_groups() {
      const gr = [];
      this.system_role_details.forEach((g) => {
        if (!gr.includes(g.BODY_TYPE)) gr.push(g.BODY_TYPE);
      });
      return gr;
    },
  },

  methods: {
    getGroupDetails(group) {
      return this.system_role_details.filter((role) => role.BODY_TYPE == group);
    },
    generateRandomColor() {
      let maxVal = 0xffffff;
      let randomNumber = Math.random() * maxVal;
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`;
    },
    getGroupStyles() {
      let color = this.generateRandomColor();
      return {
        "background-color": color,
        padding: "5px 6px !important",
        "border-width": "3px",
        "border-color": "#fff",
      };
    },
    getPriceDetails() {
      let params = {
        ITEM: "SPECIFIC",
        E_TRANSFER_OUT_START_DATE: this.E_TRANSFER_OUT_START_DATE
          ? this.formatDate(this.E_TRANSFER_OUT_START_DATE)
          : "",
        E_TRANSFER_OUT_END_DATE: this.E_TRANSFER_OUT_END_DATE
          ? this.formatDate(this.E_TRANSFER_OUT_END_DATE)
          : "",
        P_L_START_DATE: this.P_L_START_DATE
          ? this.formatDate(this.P_L_START_DATE)
          : "",
        P_L_END_DATE: this.P_L_END_DATE
          ? this.formatDate(this.P_L_END_DATE)
          : "",
        SALES_AGREEMENT_NUMBER: this.SALES_AGREEMENT_NUMBER,
      };
      this.axios
        .get(apiEndPoints.e_trasnfer_out, { params: params })
        .then((response) => {
          if (response.data.status == "true") {
            this.system_role_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.system_role_details = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    exportExcel() {
      const tHeader = [
        "SL. No.",
        "Sales Agreement Number",
        "P/L Date",
        "Car Plate",
        "Make and Model",
        "E-Transfer Out Date",
        "Broker",
        "Salesman Code",
        "Selling  Amount",
      ];

      const filterVal = [
        "SL_NO",
        "SALES_AGREEMENT_NUMBER",
        ".P_L_DATE",
        "VEHICLE_NUMBER",
        "MAKE_NAME",
        "E_TRANSFER_OUT_DATE",
        "BROKER_1",
        "SELL_CODE",
        "TOTAL_SELLING_PRICE",
      ];
      let system_role_groups = [];
      this.system_role_groups.forEach((role) => {
        this.getGroupDetails(role).forEach((item, index) => {
          if (index > 0) {
            item.BODY_TYPE = "";
          }
          system_role_groups.push(item);
        });
      });
      const data = this.formatJson(filterVal, system_role_groups);
      this.generateXlsx(tHeader, data, "E trasnfer out report");
    },
  },
};
</script>
