import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import NProgress from "nprogress";
import VueHtml2pdf from "vue-html2pdf";
export const viewinsurance = {
  components: {
    DatePicker,
    VueHtml2pdf,
  },
  data() {
    return {
      fields: [
        { key: "index", thStyle: "background:#ebd8c3" },

        {
          key: "VEHICLE_REG_NO",
          label: "Vehicle No.",
          thStyle: "background:#ebd8c3",
        },
        {
          key: "INSURANCE_START_DATE",
          label: "Start Date",
          thStyle: "background:#ebd8c3",
        },
        {
          key: "INSURANCE_END_DATE",
          label: "End Date",
          thStyle: "background:#ebd8c3",
        },
        {
          key: "INSURANCE_TYPE",
          label: "Insurance Type",
          thStyle: "background:#ebd8c3",
        },
        {
          key: "INSURANCE_COMPANY",
          label: "Company Name",
          thStyle: "background:#ebd8c3",
        },
        { key: "ACTIONS", label: "Actions", thStyle: "background:#ebd8c3" },
      ],

      headVariant: "#ebd8c3",
      loading: false,
      numbervalue: "",
      insurance_details: [],
      Banklist: {},
      //insurance_details: {},
      vehicle_details: [],
      VEHICLENUMBER: "",
      INSURANCE_TYPE: "",
      VEHICLE_MODEL: "",
      FlagVeriableforSearch: "",
      update_user: {
        BASE_PREMIUM: "",
        TAX_PERCENTAGE: "",
        TAX_AMOUNT: "",
        FINAL_PREMIUM: "",

        ENGINE_NO: "",
        CHASIS_NO: "",
        BILL_TO: "",
        ORIGINAL_REGISTRATION_DATE: "",
        MANUFACTURE_YEAR: "",
        TO_AGENT: "",
        NO_CLAIM_DISC: "",
        SAFER_DISC_VALUE: "",
        LOYALITY_DISC_VALUE: "",
        FLEET_DISC_VALUE: "",
        DIRECT_DISC_VALUE: "",
        INSURANCE_DISC_VALUE: "",
        CAPACITY: "",
        LADEN: "",
        UNLADEN: "",
        PAX: "",
        VEHICLE_REG_NO: "",

        INSURANCE_START_DATE: "",
        INSURANCE_END_DATE: "",

        SAFER_DISC_PERCENTAGE: "",
        LOYALITY_DISC_PERCENTAGE: "",
        FLEET_DISC_PERCENTAGE: "",
        DIRECT_DISC_PERCENTAGE: "",
        INSURANCE_DISC_PERCENTAGE: "",
      },
      // tax_info_details: [
      //   {tax_per:'7', tax_name:'GST-7%'},
      //   {tax_per:'0', tax_name:'EXE-0%'}
      // ],
      tax_info_details: [],
      updatevehicle_details: {
        ENGINE_NO: "",
        CHASIS_NO: "",
        BILL_TO: "",
        ORIGINAL_REGISTRATION_DATE: "",
        MANUFACTURE_YEAR: "",
        CAPACITY: "",
        LADEN: "",
        UNLADEN: "",
        PAX: "",
        VEHICLE_MAKE: "",
        VEHICLE_MODEL: "",
        VEHICLE_MAKE_SYS_ID: "",
        VEHICLE_MODEL_SYS_ID: "",
        VEHICLE_REG_NO: "",
        INSURANCE_TYPE: "",
        CUSTOMER_SYS_ID: "",
        CO_REGISTRATION_NO: "",
        DEBIT_NOTE_NO: "",
        POLICY_NO: "",
        COVERAGE: "",
        ITEM: "UPDATE",
        RECORD_SYS_ID: 0,
        CREATED_BY: "",
        ID_NUMBER: "",
        CUSTOMER_TYPE: "",
        CUSTOMER_NAME: "",
        CUSTOMER_ADDRESS: "",
      },
      show: false,
      NCD_DETAILSUPDATE: {
        NCD_EXPIRY_DATE: "",
        NCD_PERCENTAGE: "",
        NCD_REMARKS: "",
        NCD_TRANSFER_FROM: "",
        PREVIOUS_INSURER: "",
        RECORD_SYS_ID: "",
      },
      Paymentsdetails: {
        PAYMENT_AR: "",
        PAYMENT_ENTRY_DATE: "",
        PAYMENT_VOUCHER_OC: "",
        RECORD_SYS_ID: "",
        C_N: "",
        D_N: "",
      },
      brokerdetails: {},
      Receivabledetails: {
        RECEIVABLE_PREMIUM: "",

        RECEIVABLE_TAX_PERCENTAGE_ID: "",
        RECEIVABLE_TAX_PERCENTAGE: "",
        RECEIVABLE_TAX_AMOUNT: "",
        TOTAL_RECEIVABLE_PREMIUM: "",
        DEALER_COMMISSION_AMOUNT: "",
        DEALER_COMMISSION_PERCENTAGE: "",
        RECORD_SYS_ID: "",
      },
      Payabledetails: {
        SALESMAN: "",
        SALES_TYPE_PERCENTAGE: "0",
        SALES_TYPE_AMOUNT: "0",
        PAYABLE_PREMIUM: "",
        SALES_COMMISSION_PERCENTAGE: "",
        SALES_COMMISSION_AMOUNT: "",
        PAYABLE_TAX_AMOUNT: "",
        PAYABLE_TAX_PERCENTAGE: "",

        PAYABLE_TAX_PERCENTAGE_ID: "",

        TOTAL_PAYABLE_PREMIUM_PERCENTAGE: "",
        PREMIUM: "0",
        PAYABLE_COMPANY: "",
        RECORD_SYS_ID: "",
      },
      truea: false,
      Premiumdetails: {
        INSURANCE_COMPANY: "",
        DEALER_NAME: "",
        TO_AGENT: "",
        INSURANCE_START_DATE: "",
        INSURANCE_END_DATE: "",
        SUM_INSURED: "",
        BASE_PREMIUM: "",
        PAYABLE_PREMIUM: "",
        TAX_PERCENTAGE_ID: "",
        NO_CLAIM_DISC_PERCENTAGE: "0",
        NO_CLAIM_DISC_VALUE: "0",
        SAFER_DISC_VALUE: "0",
        LOYALITY_DISC_VALUE: "0",
        SAFER_DISC_PERCENTAGE: "0",
        LOYALITY_DISC_PERCENTAGE: "0",
        FLEET_DISC_PERCENTAGE: "0",
        INSURANCE_COMPANY_SYS_ID: "",
        FLEET_DISC_VALUE: "0",
        FLEET_DISC: "0",
        LOYALITY_DISC: "N",
        SAFER_DISC: "N",
        NO_CLAIM_DISC: "N",
        DIRECT_DISC: "N",
        CALCULATED_PREMIUM: "",
        DIRECT_DISC_PERCENTAGE: "0",
        DIRECT_DISC_VALUE: "0",
        INSURANCE_PROVIDER_SYS_ID: "",
        PERSON_NAME: "",
        CONTACT_NO: "",

        TAX_PERCENTAGE: "",
        TAX_AMOUNT: "",
        FINAL_PREMIUM: "",
        RECORD_SYS_ID: "",
      },
      Excessdriverdetails: {
        EXCESS_DRIVER_NAME: "",
        EXCESS_DRIVER_AMOUNT: "",

        EXCESS_WINDSCREEN: "",
        EXTRA_BENEFIT: "",
        EXCESS_UNNAMED_DRIVER_AMOUNT: "",
        EXCESS_YOUNG_DRIVER: "",
        WORKSHOP_SCHEME: "",
        RECORD_SYS_ID: "",
        INSURANCE_SYS_ID: "",
      },
      Driverdetails: {
        RECORD_SYS_ID: 0,
        DRIVER_NAME: "",
        OCCUPATION: "",
        DRIVING_PASS_DATE: "",
        RELATIONSHIP: "",
        I_C_NUMBER: "",
        DOB: "",
        INSURANCE_SYS_ID: "",
        ITEM: "ADD",
      },
      addupdatebuyerapdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 4,
        MODULE: "BUYER",
        PAYMENT_DESC: "",
        AMOUNT: "",
        BALANCE: 0,
        ID_NUMBER1: "",
        ID_NUMBER: "",
        VENDORCODE: "",
        CUSTOMERTYPE: "",
        PAYMENT_MODE: "",
        BANK_NAME: "",
        BANK_SYS_ID: "",
        ADMIN_FEE: "",
        CHEQUE_DATE: "",
        CHEQUE_NO: "",
        TO_FROM_WHOM: "",
        SETTLEMENT_REMARKS: "",
        REMARKS: "",
        CREATED_BY: "",
        REDUCED_RECEIVABLE_AMOUNT: "",
        PAYMENT_DESC_OTHER: "",
      },
      updat_user: {},
      buyerapdetails: {},
      show: false,
      insurancevalue: false,
      vehiclevalue: false,
      INSURANCE_SYS_ID: "",
      Getdriverdetails: {},

      Manufracturename: {},
      Modeltype: {},
      tax_inf: {},

      //for ID NO.
      vendorcode: "",
      vendordetails: {},
      ID_NUMBER: "",
      customerdetails: {},
      sellertype: "",
      customertype: "",

      INSURANCE_DETAILS_SYS_ID: "",

      insurense_page_no: 1,
      vehicledetails: {},
      VEHICLE_NUMBER: "",
      pdf_details: {},
      pdf_detail: {},
      insurance_company_details: [],
      ITEM: "",
      hidereg: true,
      statusvalue: false,
      driver_info: [],
      search_option: "",
      vehicletype: "",
      INSURANCE_TYPE: "",
      insurance_provider_details: [],
    };
  },
  mounted() {
    this.getTaxIn();
    this.viewInsuranceDetails();

    this.getManufracturename();
    this.getCustomer();
    this.getInsuranceCompanyDetails();
    this.getInsuranceProviderInfo();
    this.getbrokerdetails();
    $(document).ready(function () {
      $("#dark-mode").click(function () {
        $("body").addClass("darkmodeactivated");
        $("#dark-mode").css("display", "none");
        $("#light-mode").css("display", "block");
        $(".light-mood-logo").css("display", "none");
        $(".dark-mood-logo").css("display", "block");
      });

      $("#light-mode").click(function () {
        $("body").removeClass("darkmodeactivated");
        $("#dark-mode").css("display", "block");
        $("#light-mode").css("display", "none");
        $(".light-mood-logo").css("display", "block");
        $(".dark-mood-logo").css("display", "none");
      });

      $("#collapse_btn").click(function () {
        $(".left-card-wrap").toggleClass("shrink");
        $(".right-card-wrap").toggleClass("expand");
        $(".ver-tab-txt").toggleClass("ver-tab-txt-d-none");
        $(".fa-angle-left").toggleClass("rotate");
      });

      $("#srch_btn").click(function () {
        $(".fixed-search-panel").toggleClass("active-search-panel");
        $("#left_modal_sm").modal("show");
      });

      $(".add_btn").click(function () {
        $("#right_modal_xl").modal("show");
      });

      $("#srch-btn").click(function () {
        $(".fixed-search-panel").removeClass("active-search-panel");
        $(".nav-transparent-bg").removeClass("nav-transparent-bg-active");
        $(".fixed-search-panel").removeClass("animate__animated");
        $("#srch_btn").css("display", "block");
        $(".show-hide-panel").addClass("panel-active");
        $("#left_modal_sm").modal("hide");
      });

      $(".show-single-panel-btn").click(function () {
        $(".tab-panel").slideToggle("slow");
        $(".show-single-panel-btn").toggleClass("rotate");
      });
    });
    $(document).ready(function () {
      $(".box").hide();
      $("#customertype")
        .change(function () {
          $(this)
            .find("option:selected")
            .each(function () {
              var optionValue = $(this).attr("value");
              if (optionValue) {
                $(".box")
                  .not("." + optionValue)
                  .hide();
                $("." + optionValue).show();
              } else {
                $(".box").hide();
              }
            });
        })
        .change();
    });
    $(".box2").hide();
    $("#customertypeupdate")
      .change(function () {
        $(this)
          .find("option:selected")
          .each(function () {
            var optionValue = $(this).attr("value");
            if (optionValue) {
              $(".box2")
                .not("." + optionValue)
                .hide();
              $("." + optionValue).show();
            } else {
              $(".box2").hide();
            }
          });
      })
      .change();

    $(document).ready(function () {
      $(".aliasadd").click(function (e) {
        e.preventDefault();
        $("#add_item").prepend(
          '<div class="row"><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">Driver Name</label></div><div class="col-md-8"><input type="text" class="form-control" placeholder=""></div></div></div><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">Occupation</label></div><div class="col-md-8"><input type="text" class="form-control" placeholder=""></div></div></div> <div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">Driving Pass Dt</label></div><div class="col-md-8"><input type="date" class="form-control"></div></div></div><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">Relationship</label></div><div class="col-md-8"><input type="text" class="form-control" placeholder=""></div></div></div><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">I/C Number</label></div><div class="col-md-8"><input type="text" class="form-control" placeholder=""></div></div></div><div class="col-md-4 mt-3"><div class="row"><div class="col-md-4"><label class="frm-label">DOB</label></div><div class="col-md-8"><input type="date" class="form-control" placeholder=""></div></div></div><div class="col-md-1 mt-3"> <button type="button" class="custom-btn custom-danger-btn remove-itm-btn" id="aliasadd"><i class="fa fa-trash" aria-hidden="true"></i></button> </div></div>'
        );
      });
      $(document).on("click", ".remove-itm-btn", function (e) {
        e.preventDefault();
        let row_item = $(this).parent().parent();
        $(row_item).remove();
      });
    });
  },

  methods: {
    filtershowhide() {
      if (this.STATUS != "") {
        this.statusvalue = true;
      } else {
        this.statusvalue = false;
      }

      if (this.INSURANCE_TYPE != "") {
        this.insurancevalue = true;
      } else {
        this.insurancevalue = false;
      }

      if (this.VEHICLE_NUMBER != "") {
        this.vehiclevalue = true;
      } else {
        this.vehiclevalue = false;
      }
    },

    getTaxIn() {
      this.axios
        .get(apiEndPoints.tax_info, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.tax_info_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.tax_info_details = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //Edit Insurance Function
    Editinsurance(vehicle_detail) {
      $("#rokerupdate_modal").modal("show");
      this.getBanklist();
      this.INSURANCE_SYS_ID = vehicle_detail.INSURANCE_DETAILS_SYS_ID;
      this.Driverdetails.INSURANCE_SYS_ID =
        vehicle_detail.INSURANCE_DETAILS_SYS_ID;
      this.Excessdriverdetails.INSURANCE_SYS_ID =
        vehicle_detail.INSURANCE_DETAILS_SYS_ID;
      this.Excessdriverdetails.EXCESS_DRIVER_NAME =
        vehicle_detail.EXCESS_DRIVER_NAME;
      this.Excessdriverdetails.EXCESS_DRIVER_AMOUNT =
        vehicle_detail.EXCESS_DRIVER_AMOUNT;

      this.Excessdriverdetails.EXCESS_WINDSCREEN =
        vehicle_detail.EXCESS_WINDSCREEN;
      this.Excessdriverdetails.EXTRA_BENEFIT = vehicle_detail.EXTRA_BENEFIT;
      this.Excessdriverdetails.EXCESS_UNNAMED_DRIVER_AMOUNT =
        vehicle_detail.EXCESS_UNNAMED_DRIVER_AMOUNT;
      this.Excessdriverdetails.EXCESS_YOUNG_DRIVER =
        vehicle_detail.EXCESS_YOUNG_DRIVER;
      this.Excessdriverdetails.WORKSHOP_SCHEME = vehicle_detail.WORKSHOP_SCHEME;
      //General details
      this.updatevehicle_details.ID_NUMBER = vehicle_detail.ID_NUMBER;
      this.updatevehicle_details.CUSTOMER_TYPE = vehicle_detail.CUSTOMER_TYPE;
      this.updatevehicle_details.CUSTOMER_NAME = vehicle_detail.CUSTOMER_NAME;
      this.updatevehicle_details.CUSTOMER_ADDRESS =
        vehicle_detail.ADDRESS_LINE_1 +
        "," +
        vehicle_detail.ADDRESS_LINE_2 +
        "," +
        vehicle_detail.COUNTRY_NAME +
        "-" +
        vehicle_detail.PIN_CODE;

      this.updatevehicle_details.RECORD_SYS_ID =
        vehicle_detail.INSURANCE_DETAILS_SYS_ID;
      this.updatevehicle_details.ENGINE_NO = vehicle_detail.ENGINE_NO;
      this.updatevehicle_details.CHASIS_NO = vehicle_detail.CHASIS_NO;
      this.updatevehicle_details.BILL_TO = vehicle_detail.BILL_TO;
      this.updatevehicle_details.ORIGINAL_REGISTRATION_DATE =
        vehicle_detail.ORIGINAL_REGISTRATION_DATE;
      this.updatevehicle_details.MANUFACTURE_YEAR =
        vehicle_detail.MANUFACTURE_YEAR;
      this.updatevehicle_details.CAPACITY = vehicle_detail.CAPACITY;
      this.updatevehicle_details.LADEN = vehicle_detail.LADEN;
      this.updatevehicle_details.UNLADEN = vehicle_detail.UNLADEN;
      this.updatevehicle_details.PAX = vehicle_detail.PAX;
      this.updatevehicle_details.VEHICLE_MAKE = vehicle_detail.VEHICLE_MAKE;
      this.updatevehicle_details.VEHICLE_MODEL = vehicle_detail.VEHICLE_MODEL;
      this.updatevehicle_details.VEHICLE_MAKE_SYS_ID =
        vehicle_detail.VEHICLE_MAKE_SYS_ID;
      this.updatevehicle_details.VEHICLE_MODEL_SYS_ID =
        vehicle_detail.VEHICLE_MODEL_SYS_ID;
      this.updatevehicle_details.VEHICLE_REG_NO = vehicle_detail.VEHICLE_REG_NO;
      this.updatevehicle_details.INSURANCE_TYPE = vehicle_detail.INSURANCE_TYPE;
      this.updatevehicle_details.CUSTOMER_SYS_ID =
        vehicle_detail.CUSTOMER_SYS_ID;
      this.updatevehicle_details.CO_REGISTRATION_NO =
        vehicle_detail.VEHICLE_REG_NO;
      this.updatevehicle_details.DEBIT_NOTE_NO = vehicle_detail.DEBIT_NOTE_NO;
      this.updatevehicle_details.POLICY_NO = vehicle_detail.POLICY_NO;
      this.updatevehicle_details.COVERAGE = vehicle_detail.COVERAGE;
      this.updatevehicle_details.ORIGINAL_REGISTRATION_DATE = this.parseDate(
        this.updatevehicle_details.ORIGINAL_REGISTRATION_DATE
      );
      this.updatevehicle_details.VEHICLE_STATUS = vehicle_detail.VEHICLE_STATUS;
      //NCD
      //this.NCD_DETAILSUPDATE.NCD_EXPIRY_DATE=this.formatDate(this.NCD_DETAILSUPDATE.NCD_EXPIRY_DATE);

      this.NCD_DETAILSUPDATE.NCD_EXPIRY_DATE = vehicle_detail.NCD_EXPIRY_DATE;
      this.NCD_DETAILSUPDATE.NCD_PERCENTAGE = vehicle_detail.NCD_PERCENTAGE;
      this.NCD_DETAILSUPDATE.NCD_REMARKS = vehicle_detail.NCD_REMARKS;
      this.NCD_DETAILSUPDATE.NCD_TRANSFER_FROM =
        vehicle_detail.NCD_TRANSFER_FROM;
      this.NCD_DETAILSUPDATE.PREVIOUS_INSURER = vehicle_detail.PREVIOUS_INSURER;
      this.NCD_DETAILSUPDATE.RECORD_SYS_ID =
        vehicle_detail.INSURANCE_DETAILS_SYS_ID;
      //END
      //payment
      if (
        vehicle_detail.PAYMENT_AR == null ||
        vehicle_detail.PAYMENT_AR == "null" ||
        vehicle_detail.PAYMENT_AR == ""
      )
        this.Paymentsdetails.PAYMENT_AR = vehicle_detail.PAYABLE_PREMIUM;
      else this.Paymentsdetails.PAYMENT_AR = vehicle_detail.PAYMENT_AR;
      //this.Paymentsdetails.PAYMENT_ENTRY_DATE =this.formatDate(this.Paymentsdetails.PAYMENT_ENTRY_DATE);
      this.Paymentsdetails.PAYMENT_ENTRY_DATE =
        vehicle_detail.PAYMENT_ENTRY_DATE;
      this.Paymentsdetails.PAYMENT_VOUCHER_OC =
        vehicle_detail.PAYMENT_VOUCHER_OC;
      this.Paymentsdetails.C_N = vehicle_detail.C_N;
      if (
        vehicle_detail.D_N == null ||
        vehicle_detail.D_N == "null" ||
        vehicle_detail.D_N == ""
      )
        this.Paymentsdetails.D_N = vehicle_detail.DEBIT_NOTE_NO;
      else this.Paymentsdetails.D_N = vehicle_detail.D_N;

      this.Paymentsdetails.RECORD_SYS_ID =
        vehicle_detail.INSURANCE_DETAILS_SYS_ID;
      //end
      //receivable
      this.Receivabledetails.RECEIVABLE_PREMIUM =
        vehicle_detail.RECEIVABLE_PREMIUM;

      this.Receivabledetails.RECEIVABLE_TAX_PERCENTAGE_ID =
        vehicle_detail.RECEIVABLE_TAX_PERCENTAGE_ID;

      this.Receivabledetails.RECEIVABLE_TAX_AMOUNT =
        vehicle_detail.RECEIVABLE_TAX_AMOUNT;
      this.Receivabledetails.DEALER_COMMISSION_PERCENTAGE =
        vehicle_detail.DEALER_COMMISSION_PERCENTAGE;
      this.Receivabledetails.TOTAL_RECEIVABLE_PREMIUM =
        vehicle_detail.TOTAL_RECEIVABLE_PREMIUM;
      this.Receivabledetails.DEALER_COMMISSION_AMOUNT =
        vehicle_detail.DEALER_COMMISSION_AMOUNT;

      this.Receivabledetails.RECORD_SYS_ID =
        vehicle_detail.INSURANCE_DETAILS_SYS_ID;
      //END
      //PAYABLE
      this.Payabledetails.SALESMAN = vehicle_detail.SALESMAN;
      this.Payabledetails.SALES_TYPE_PERCENTAGE =
        vehicle_detail.SALES_TYPE_PERCENTAGE;
      this.Payabledetails.SALES_TYPE_AMOUNT = vehicle_detail.SALES_TYPE_AMOUNT;
      this.Payabledetails.PAYABLE_PREMIUM = vehicle_detail.PAYABLE_PREMIUM;
      this.Payabledetails.SALES_COMMISSION_PERCENTAGE =
        vehicle_detail.SALES_COMMISSION_PERCENTAGE;
      this.Payabledetails.SALES_COMMISSION_AMOUNT =
        vehicle_detail.SALES_COMMISSION_AMOUNT;
      this.Payabledetails.PAYABLE_TAX_PERCENTAGE =
        vehicle_detail.PAYABLE_TAX_PERCENTAGE;

      this.Payabledetails.PAYABLE_TAX_AMOUNT =
        vehicle_detail.PAYABLE_TAX_AMOUNT;
      this.Payabledetails.PAYABLE_TAX_PERCENTAGE_ID =
        vehicle_detail.PAYABLE_TAX_PERCENTAGE_ID;

      this.Payabledetails.TOTAL_PAYABLE_PREMIUM_PERCENTAGE =
        vehicle_detail.TOTAL_PAYABLE_PREMIUM_PERCENTAGE;

      this.Payabledetails.PAYABLE_COMPANY = vehicle_detail.PAYABLE_COMPANY;
      this.Payabledetails.RECORD_SYS_ID =
        vehicle_detail.INSURANCE_DETAILS_SYS_ID;

      //PREMIUM
      this.Premiumdetails.RECORD_SYS_ID =
        vehicle_detail.INSURANCE_DETAILS_SYS_ID;
      this.Premiumdetails.INSURANCE_COMPANY = vehicle_detail.INSURANCE_COMPANY;
      this.Premiumdetails.INSURANCE_COMPANY_SYS_ID =
        vehicle_detail.INSURANCE_COMPANY_SYS_ID;
      this.Premiumdetails.DEALER_NAME = vehicle_detail.DEALER_NAME;
      this.Premiumdetails.TO_AGENT = vehicle_detail.TO_AGENT;
      this.Premiumdetails.PAYABLE_PREMIUM = vehicle_detail.PAYABLE_PREMIUM;
      this.Premiumdetails.INSURANCE_START_DATE =
        vehicle_detail.INSURANCE_START_DATE;
      this.Premiumdetails.INSURANCE_END_DATE =
        vehicle_detail.INSURANCE_END_DATE;
      this.Premiumdetails.SUM_INSURED = vehicle_detail.SUM_INSURED;
      this.Premiumdetails.BASE_PREMIUM = vehicle_detail.BASE_PREMIUM;
      this.Premiumdetails.TAX_PERCENTAGE_ID = vehicle_detail.TAX_PERCENTAGE_ID;

      this.Premiumdetails.NO_CLAIM_DISC = vehicle_detail.NO_CLAIM_DISC
        ? vehicle_detail.NO_CLAIM_DISC
        : "N";
      this.Premiumdetails.NO_CLAIM_DISC_PERCENTAGE =
        vehicle_detail.NO_CLAIM_DISC_PERCENTAGE;
      this.Premiumdetails.NO_CLAIM_DISC_VALUE =
        vehicle_detail.NO_CLAIM_DISC_VALUE;

      this.Premiumdetails.DIRECT_DISC = vehicle_detail.DIRECT_DISC
        ? vehicle_detail.DIRECT_DISC
        : "N";
      this.Premiumdetails.DIRECT_DISC_PERCENTAGE =
        vehicle_detail.DIRECT_DISC_PERCENTAGE;
      this.Premiumdetails.DIRECT_DISC_VALUE = vehicle_detail.DIRECT_DISC_VALUE;

      this.Premiumdetails.LOYALITY_DISC = vehicle_detail.LOYALITY_DISC
        ? vehicle_detail.LOYALITY_DISC
        : "N";
      this.Premiumdetails.LOYALITY_DISC_PERCENTAGE =
        vehicle_detail.LOYALITY_DISC_PERCENTAGE;
      this.Premiumdetails.LOYALITY_DISC_VALUE =
        vehicle_detail.LOYALITY_DISC_VALUE;

      this.Premiumdetails.SAFER_DISC = vehicle_detail.SAFER_DISC
        ? vehicle_detail.SAFER_DISC
        : "N";
      this.Premiumdetails.SAFER_DISC_VALUE = vehicle_detail.SAFER_DISC_VALUE;
      this.Premiumdetails.SAFER_DISC_PERCENTAGE =
        vehicle_detail.SAFER_DISC_PERCENTAGE;

      this.Premiumdetails.FLEET_DISC = vehicle_detail.FLEET_DISC
        ? vehicle_detail.FLEET_DISC
        : "N";
      this.Premiumdetails.FLEET_DISC_PERCENTAGE =
        vehicle_detail.FLEET_DISC_PERCENTAGE;
      this.Premiumdetails.FLEET_DISC_VALUE = vehicle_detail.FLEET_DISC_VALUE;

      this.Premiumdetails.CALCULATED_PREMIUM =
        vehicle_detail.CALCULATED_PREMIUM;

      this.Premiumdetails.TAX_PERCENTAGE = vehicle_detail.TAX_PERCENTAGE;
      this.Premiumdetails.CONTACT_NO = vehicle_detail.CONTACT_NO;
      this.Premiumdetails.PERSON_NAME = vehicle_detail.PERSON_NAME;

      this.getBuyerAPdetails(vehicle_detail.RECORD_SYS_ID);
      this.Premiumdetails.TAX_AMOUNT = vehicle_detail.TAX_AMOUNT;
      this.Premiumdetails.FINAL_PREMIUM = vehicle_detail.FINAL_PREMIUM;
      let TotalPremiumDiscValue =
        vehicle_detail.NO_CLAIM_DISC_VALUE +
        vehicle_detail.SAFER_DISC_VALUE +
        vehicle_detail.LOYALITY_DISC_VALUE +
        vehicle_detail.FLEET_DISC_VALUE +
        vehicle_detail.DIRECT_DISC_VALUE;
      let TotalPremiumValue =
        vehicle_detail.BASE_PREMIUM - TotalPremiumDiscValue;
      if (
        vehicle_detail.PAYABLE_PREMIUM == "" ||
        vehicle_detail.PAYABLE_PREMIUM == null ||
        vehicle_detail.PAYABLE_PREMIUM == "null"
      ) {
        this.Payabledetails.PAYABLE_PREMIUM = TotalPremiumValue;
      }
      if (
        vehicle_detail.TOTAL_PAYABLE_PREMIUM_PERCENTAGE == "" ||
        vehicle_detail.TOTAL_PAYABLE_PREMIUM_PERCENTAGE == null ||
        vehicle_detail.TOTAL_PAYABLE_PREMIUM_PERCENTAGE == "null"
      ) {
        this.Payabledetails.TOTAL_PAYABLE_PREMIUM_PERCENTAGE =
          vehicle_detail.FINAL_PREMIUM;
      }
      if (
        vehicle_detail.PAYABLE_TAX_AMOUNT == "" ||
        vehicle_detail.PAYABLE_TAX_AMOUNT == null ||
        vehicle_detail.PAYABLE_TAX_AMOUNT == "null"
      ) {
        this.Payabledetails.PAYABLE_TAX_AMOUNT = vehicle_detail.TAX_AMOUNT;
      }

      this.getmodeltype();
      this.getDriverDetails();
    },

    onchg() {
      var Premium = this.Payabledetails.PAYABLE_PREMIUM;
      if (vs == "Individual") {
        this.truez = false;
      } else {
        this.vehicle_details.CO_REGISTRATION_NO = Enabled;
      }
    },

    updateDriverdetails() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.Driverdetails["CREATED_BY"] = user_details.UserId;

      this.axios
        .post(apiEndPoints.add_update_driver_details, this.Driverdetails)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getDriverDetails();
            this.Driverdetails.RECORD_SYS_ID = 0;
            this.Driverdetails.DRIVER_NAME = "";
            this.Driverdetails.OCCUPATION = "";
            this.Driverdetails.DRIVING_PASS_DATE = "";
            this.Driverdetails.RELATIONSHIP = "";
            this.Driverdetails.I_C_NUMBER = "";
            this.Driverdetails.DOB = "";
            this.Driverdetails.ITEM = "ADD";
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })

        .catch((err) => {
          //console.log(err);
        });
    },

    async Deletedriver(data) {
      const result = await Global.showConfirmationAlert(
        `Delete Driver ${data.DRIVER_NAME}`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.DRIVER_SYS_ID,
          CREATED_BY: user_details.UserId,
        };

        this.axios
          .post(apiEndPoints.add_update_driver_details, postarrydata)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);

              this.getDriverDetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    // debit note populate function
    getDebitNOteDetails(vehicle_detail) {
      this.axios
        .get(apiEndPoints.view_debit_note_info, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: vehicle_detail.VEHICLE_REG_NO,
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
            $("#staticBackdrop-2").modal("hide");
          } else {
            $("#staticBackdrop-2").modal("show");
            this.pdf_details = response.data.response[0];
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    },

    generateReport() {
      this.$refs.htmlPdf.generatePdf();
    },

    //Credit note
    getCreditNoteDetails(vehicle_detail) {
      this.axios
        .get(apiEndPoints.view_credit_note_info, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: vehicle_detail.VEHICLE_NUMBER || "",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          this.pdf_detail = response.data.response[0];
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    generateReportcredit() {
      this.$refs.html2Pdf.generatePdf();
    },

    onLogIn() {
      this.$router.replace("/addinsurance");
    },

    Editdriverdetails(data) {
      this.Driverdetails.RECORD_SYS_ID = data.DRIVER_SYS_ID;
      this.Driverdetails.DRIVER_NAME = data.DRIVER_NAME;
      this.Driverdetails.OCCUPATION = data.OCCUPATION;
      this.Driverdetails.DRIVING_PASS_DATE = data.DRIVING_PASS_DATE;
      this.Driverdetails.RELATIONSHIP = data.RELATIONSHIP;
      this.Driverdetails.I_C_NUMBER = data.I_C_NUMBER;
      this.Driverdetails.DOB = data.DOB;
      this.Driverdetails.INSURANCE_SYS_ID = data.INSURANCE_SYS_ID;
      this.Driverdetails.ITEM = "UPDATE";
    },

    //Get Driver Details
    getDriverDetails() {
      this.axios
        .get(apiEndPoints.view_driver_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.INSURANCE_SYS_ID,
            PAGE_SIZE: 12,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true" || response.data.status == true) {
            this.driver_info = response.data.response;
          } else {
            this.driver_info = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    loadInsurenceDetails(isVisible) {
      if (isVisible) {
        this.viewInsuranceDetails(this.insurense_page_no);
      }
    },

    updateExcessdetails() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.Excessdriverdetails["CREATED_BY"] = user_details.UserId;
      this.Excessdriverdetails["ITEM"] = "ADD";
      this.Excessdriverdetails["SUB_ITEM"] = "EXCESS_DRIVER";
      this.Excessdriverdetails["RECORD_SYS_ID"] = "0";

      this.$refs.bankform6.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(
              apiEndPoints.add_update_driver_details,
              this.Excessdriverdetails
            )
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })

            .catch((err) => {
              //console.log(err);
            });
        }
      });
    },
    //insurance search
    getinsurancesearch() {
      this.numbervalue = "";

      if (this.search_option == "all") {
        this.statusvalue = this.INSURANCE_TYPE;
        this.numbervalue = this.VEHICLENUMBER;
      } else {
        if (this.search_option == "status")
          this.statusvalue = this.INSURANCE_TYPE;
        if (this.search_option == "number")
          this.numbervalue = this.VEHICLENUMBER;
      }
      this.axios
        .get(apiEndPoints.insurance_search, {
          params: {
            ITEM: "VIEW_ALL",
            SEARCH_TEXT: this.VEHICLENUMBER || this.INSURANCE_TYPE,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.insurance_details = response.data.response;
            this.FlagVeriableforSearch = "serchtrue";
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log("err");
        });
    },
    gettax() {
      var aount = this.Receivabledetails.RECEIVABLE_PREMIUM;
      var gstpercentage = this.Receivabledetails.DEALER_COMMISSION_PERCENTAGE;
      var gst = (parseInt(aount) * parseInt(gstpercentage)) / 100;
      this.Receivabledetails.DEALER_COMMISSION_AMOUNT = gst;
    },

    getgstName() {
      this.Receivabledetails.RECEIVABLE_TAX_PERCENTAGE_ID =
        this.tax_info_details.find(
          (o) =>
            o.TAX_PERCENTAGE ===
            this.Receivabledetails.RECEIVABLE_TAX_PERCENTAGE
        ).TAX_SYS_ID;

      this.gstcalculati();
    },

    gstcalculati() {
      var aount = this.Receivabledetails.RECEIVABLE_PREMIUM;
      var gstpercent = this.Receivabledetails.RECEIVABLE_TAX_PERCENTAGE;
      var gs = (parseInt(aount) * parseInt(gstpercent)) / 100;
      this.Receivabledetails.RECEIVABLE_TAX_AMOUNT = gs;
      var aount = this.Receivabledetails.RECEIVABLE_PREMIUM;
      var gstpercentage = this.Receivabledetails.DEALER_COMMISSION_PERCENTAGE;
      var gst = (parseInt(aount) * parseInt(gstpercentage)) / 100;
      this.Receivabledetails.DEALER_COMMISSION_AMOUNT = gst;
      var gstt = parseInt(aount) + parseInt(gst) + parseInt(gs);
      this.Receivabledetails.TOTAL_RECEIVABLE_PREMIUM = gstt;
    },

    commison() {
      var aount = this.Payabledetails.PAYABLE_PREMIUM;
      var gstpercentage = this.Payabledetails.SALES_COMMISSION_PERCENTAGE;

      var gst = (parseInt(aount) * parseInt(gstpercentage)) / 100;

      this.Payabledetails.SALES_COMMISSION_AMOUNT = gst;
    },

    getbrokerdetails() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            ITEM: "SPECIFIC",

            USER_TYPE: "External",
            PAGE_SIZE: 1000,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          this.brokerdetails = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getbankName_buyer() {
      this.addupdatebuyerapdata.BANK_NAME = this.Banklist.find(
        (o) => o.BANK_SYS_ID === this.addupdatebuyerapdata.BANK_SYS_ID
      ).BANK_NAME;
    },

    updatePremiumdetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.Premiumdetails["CREATED_BY"] = user_details.UserId;
      this.Premiumdetails["ITEM"] = "UPDATE";
      this.Premiumdetails["SUB_ITEM"] = "PREMIUM";
      if (
        this.Premiumdetails.NO_CLAIM_DISC_PERCENTAGE == null ||
        this.Premiumdetails.NO_CLAIM_DISC_PERCENTAGE == "null"
      )
        this.Premiumdetails["NO_CLAIM_DISC_PERCENTAGE"] = "0";

      if (
        this.Premiumdetails.NO_CLAIM_DISC_VALUE == null ||
        this.Premiumdetails.NO_CLAIM_DISC_VALUE == "null"
      )
        this.Premiumdetails["NO_CLAIM_DISC_VALUE"] = "0";

      if (
        this.Premiumdetails.SAFER_DISC_VALUE == null ||
        this.Premiumdetails.SAFER_DISC_VALUE == "null"
      )
        this.Premiumdetails["SAFER_DISC_VALUE"] = "0";

      if (
        this.Premiumdetails.LOYALITY_DISC_VALUE == null ||
        this.Premiumdetails.LOYALITY_DISC_VALUE == "null"
      )
        this.Premiumdetails["LOYALITY_DISC_VALUE"] = "0";

      if (
        this.Premiumdetails.SAFER_DISC_PERCENTAGE == null ||
        this.Premiumdetails.SAFER_DISC_PERCENTAGE == "null"
      )
        this.Premiumdetails["SAFER_DISC_PERCENTAGE"] = "0";

      if (
        this.Premiumdetails.LOYALITY_DISC_PERCENTAGE == null ||
        this.Premiumdetails.LOYALITY_DISC_PERCENTAGE == "null"
      )
        this.Premiumdetails["LOYALITY_DISC_PERCENTAGE"] = "0";

      if (
        this.Premiumdetails.FLEET_DISC_PERCENTAGE == null ||
        this.Premiumdetails.FLEET_DISC_PERCENTAGE == "null"
      )
        this.Premiumdetails["FLEET_DISC_PERCENTAGE"] = "0";

      if (
        this.Premiumdetails.FLEET_DISC_VALUE == null ||
        this.Premiumdetails.FLEET_DISC_VALUE == "null"
      )
        this.Premiumdetails["FLEET_DISC_VALUE"] = "0";

      if (
        this.Premiumdetails.DIRECT_DISC_PERCENTAGE == null ||
        this.Premiumdetails.DIRECT_DISC_PERCENTAGE == "null"
      )
        this.Premiumdetails["DIRECT_DISC_PERCENTAGE"] = "0";

      if (
        this.Premiumdetails.DIRECT_DISC_VALUE == null ||
        this.Premiumdetails.DIRECT_DISC_VALUE == "null"
      )
        this.Premiumdetails["DIRECT_DISC_VALUE"] = "0";
      if(this.Premiumdetails.INSURANCE_COMPANY_SYS_ID != ""){
      this.Premiumdetails["COMPANY_NAME"] =  this.insurance_company_details.find((o) => o.INSURANCE_COMPANY_SYS_ID === this.Premiumdetails.INSURANCE_COMPANY_SYS_ID).INSURANCE_COMPANY;
      }
      this.$refs.bankform5.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.add_insurance_details, this.Premiumdetails)
            .then((response) => {
              if (response.data.status == "true") {
                this.Payabledetails.TOTAL_PAYABLE_PREMIUM_PERCENTAGE =
                  this.Premiumdetails.FINAL_PREMIUM;
                this.Payabledetails.PAYABLE_TAX_AMOUNT =
                  this.Premiumdetails.TAX_AMOUNT;
                this.Payabledetails.PAYABLE_PREMIUM =
                  this.Payabledetails.PAYABLE_PREMIUM;
                this.viewInsuranceDetails();
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .catch((err) => {
              //console.log(err);
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    updateGeneralvehicledetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updatevehicle_details["CREATED_BY"] = user_details.UserId;
      this.updatevehicle_details["ITEM"] = "UPDATE";
      this.updatevehicle_details["SUB_ITEM"] = "GENERAL_INFO";
      this.updatevehicle_details.ORIGINAL_REGISTRATION_DATE = this.formatDate(
        this.updatevehicle_details.ORIGINAL_REGISTRATION_DATE
      );

      this.axios
        .post(apiEndPoints.add_insurance_details, this.updatevehicle_details)
        .then((response) => {
          this.updatevehicle_details.ORIGINAL_REGISTRATION_DATE =
            this.parseDate(
              this.updatevehicle_details.ORIGINAL_REGISTRATION_DATE
            );
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    updateNCDDETAILS() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.NCD_DETAILSUPDATE["CREATED_BY"] = user_details.UserId;
      this.NCD_DETAILSUPDATE["ITEM"] = "UPDATE";
      this.NCD_DETAILSUPDATE["SUB_ITEM"] = "NCD_TRANSFER";

      this.$refs.bankfor.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(
            true,
            "error",
            "Red markes fields Are Required!"
          );
          return;
        } else {
          this.axios
            .post(apiEndPoints.add_insurance_details, this.NCD_DETAILSUPDATE)
            .then((response) => {
              if (response.data.status == "true") {
                this.viewInsuranceDetails();
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })

            .catch((err) => {
              //console.log(err);
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },
    //Update Payment details
    updatePaymentdetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.Paymentsdetails["CREATED_BY"] = user_details.UserId;
      this.Paymentsdetails["ITEM"] = "UPDATE";
      this.Paymentsdetails["SUB_ITEM"] = "PAYMENT";

      this.$refs.bankform2.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(
            true,
            "error",
            "Red markes fields Are Required!"
          );
          NProgress.done();
          this.loading = false;
          return;
        } else {
          this.axios
            .post(apiEndPoints.add_insurance_details, this.Paymentsdetails)
            .then((response) => {
              if (response.data.status == "true") {
                this.viewInsuranceDetails();
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })

            .catch((err) => {
              //console.log(err);
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    //Add Dealer Commision Details
    updateReceivabledetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.Receivabledetails["CREATED_BY"] = user_details.UserId;
      this.Receivabledetails["ITEM"] = "UPDATE";
      this.Receivabledetails["SUB_ITEM"] = "RECEIVABLE";

      this.$refs.taxeditform.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(
            true,
            "error",
            "Red Markes fields Are Required!"
          );
          return;
        } else {
          this.axios
            .post(apiEndPoints.add_insurance_details, this.Receivabledetails)
            .then((response) => {
              if (response.data.status == "true") {
                this.viewInsuranceDetails();
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })

            .catch((err) => {
              //console.log(err);
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    updatePayabledetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.Payabledetails["CREATED_BY"] = user_details.UserId;
      this.Payabledetails["ITEM"] = "UPDATE";
      this.Payabledetails["SUB_ITEM"] = "PAYABLE";
      this.$refs.bankform3.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(
            true,
            "error",
            "Red Marks fields Are Required!"
          );
          return;
        } else {
          this.axios
            .post(apiEndPoints.add_insurance_details, this.Payabledetails)
            .then((response) => {
              if (response.data.status == "true") {
                this.viewInsuranceDetails();
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })

            .catch((err) => {
              //console.log(err);
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    getgstNa() {
      this.Payabledetails.PAYABLE_TAX_PERCENTAGE_ID =
        this.tax_info_details.find(
          (o) => o.TAX_PERCENTAGE === this.Payabledetails.PAYABLE_TAX_PERCENTAGE
        ).TAX_SYS_ID;
      // this.Payabledetails.PAYABLE_TAX_PERCENTAGE = this.tax_info_details.find(
      //   (o) => o.TAX_SYS_ID === this.Payabledetails.TAX_SYS_ID
      // ).TAX_PERCENTAGE;

      this.gstcalculatio();
    },

    gstcalculatio() {
      var aount = this.Payabledetails.PAYABLE_PREMIUM;
      var gstpercent = this.Payabledetails.PAYABLE_TAX_PERCENTAGE;
      var gs = (parseInt(aount) * parseInt(gstpercent)) / 100;
      this.Payabledetails.PAYABLE_TAX_AMOUNT = gs;
      var aount = this.Payabledetails.PAYABLE_PREMIUM;
      var gstpercentage = this.Payabledetails.SALES_COMMISSION_PERCENTAGE;

      var gst = (parseInt(aount) * parseInt(gstpercentage)) / 100;

      this.Payabledetails.SALES_COMMISSION_AMOUNT = gst;
      var gstt = parseInt(aount) + parseInt(gst) + parseInt(gs);
      this.Payabledetails.TOTAL_PAYABLE_PREMIUM_PERCENTAGE = gstt;
    },

    showhide() {
      var ddlval = this.updatevehicle_details.INSURANCE_TYPE;
      if (ddlval == "Lek Auto INS") {
        this.show = true;
      } else {
        this.show = false;
      }
    },

    getInsuranceCompanyDetails() {
      this.axios
        .get(apiEndPoints.insurance_company_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //this.insurance_company_details = response.data.response;
          if (response.data.status == "true") {
            this.insurance_company_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.insurance_company_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    vehicletypelebels() {
      if (
        this.updatevehicle_details.VEHICLE_STATUS == "Old Vehicle" ||
        this.updatevehicle_details.VEHICLE_STATUS == "Pre Owned"
      ) {
        this.vehicletype = "Old Vehicle";
      } else if (
        this.updatevehicle_details.VEHICLE_STATUS == "New Vehicle" ||
        this.updatevehicle_details.VEHICLE_STATUS == "Pre Order"
      ) {
        this.vehicletype = "New Vehicle";
      }
      if (this.updatevehicle_details.VEHICLE_STATUS == "ETS") {
        this.etsfromdisable = true;
      }
    },
    //view table for insurance
    viewInsuranceDetails(page_no = 1) {
      this.filtershowhide();
      this.statusvalue = "";
      this.numbervalue = "";
      this.makevalue = "";
      this.modelvalue = "";
      if (this.search_option == "all") {
        this.statusvalue = this.STATUS;
        this.numbervalue = this.VEHICLENUMBER;
        this.makevalue = this.MAKENAME;
        this.modelvalue = this.MODELNAME;
      } else {
        if (this.search_option == "status") this.statusvalue = this.STATUS;
        if (this.search_option == "number")
          this.numbervalue = this.VEHICLENUMBER;
        if (this.search_option == "make") this.makevalue = this.MAKENAME;
        if (this.search_option == "model") this.modelvalue = this.MODELNAME;
      }
      this.axios
        .get(apiEndPoints.view_insurance_details, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 10,
            PAGE_NO: 8,
            PAGE_NO: page_no,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.CUSTOMER_TYPE = response.data.response[0].CUSTOMER_TYPE;
            this.ID_NUMBER = response.data.response[0].ID_NUMBER;
            if (page_no == 1) {
              this.insurance_details = [];
            }
            this.insurance_details = [
              ...this.insurance_details,
              ...response.data.response,
            ];
            this.insurense_page_no += 1;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            if (page_no == 1) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    calculation() {
      let a = this.addupdatebuyerapdata.AMOUNT;
      let b = this.updat_user.REDUCED_RECEIVABLE_AMOUNT;
      let c = b - a;
      this.updat_user.REDUCED_RECEIVABLE_AMOUNT = c;
    },
    AddUpdatebuyerapdetails() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatebuyerapdata["CREATED_BY"] = user_details.UserId;
      this.addupdatebuyerapdata["ITEM"] = "ADD";
      if (this.addupdatebuyerapdata.PAYMENT_DESC == "Others") {
        this.addupdatebuyerapdata["PAYMENT_DESC"] =
          this.addupdatebuyerapdata.PAYMENT_DESC_OTHER;
      }
      this.axios
        .post(
          apiEndPoints.add_update_ap_payment_details,
          this.addupdatebuyerapdata
        )
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getBuyerAPdetails(this.addupdatebuyerapdata.RECORD_SYS_ID);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    getemptydata() {
      this.addupdatebuyerapdata.PAYMENT_DESC = "";
      this.addupdatebuyerapdata.BANK_SYS_ID = "";
      this.addupdatebuyerapdata.CHEQUE_NO = "";
      this.addupdatebuyerapdata.TO_FROM_WHOM = "";
      this.addupdatebuyerapdata.AMOUNT = "";
      this.addupdatebuyerapdata.PAYMENT_MODE = "";
      this.addupdatebuyerapdata.CHEQUE_DATE = "";
      this.addupdatebuyerapdata.REMARKS = "";
      this.addupdatesellerapdata.PAYMENT_DESC = "";
      this.addupdatesellerapdata.BANK_SYS_ID = "";
      this.addupdatesellerapdata.CHEQUE_NO = "";
      this.addupdatesellerapdata.CHEQUE_DATE = "";
      this.addupdatesellerapdata.PAYMENT_MODE = "";
      this.addupdatesellerapdata.REMARKS = "";
      this.addupdatesellerapdata.TO_FROM_WHOM = "";
      this.addupdatesellerapdata.AMOUNT = "";
      this.addupdatesellerapdata.SETTLEMENT_REMARKS = "";
      this.addupdatebuyerapdata.PAYMENT_DESC_OTHER = "";
      this.addupdatesellerapdata.PAYMENT_DESC_OTHER = "";
    },
    getBuyerAPdetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.view_ap_payment_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
            P_MODULE: "BUYER",
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.buyerapdetails = response.data.response;
            this.updat_user.REDUCED_RECEIVABLE_AMOUNT =
              response.data.response[0].REDUCED_RECEIVABLE_AMOUNT;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    UpdateInsurance() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.update_user["CREATED_BY"] = user_details.UserId;
      this.update_user["ITEM"] = "UPDATE";
      this.update_user["RECORD_SYS_ID"] =
        this.update_user.INSURANCE_DETAILS_SYS_ID;

      this.axios
        .post(apiEndPoints.add_insurance_details, this.update_user)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.viewInsuranceDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    showModal(row, id) {
      this.update_user = row;
      $(id).modal("show");
    },

    getInsuranceCompanyName() {
      this.Premiumdetails.INSURANCE_COMPANY =
        this.insurance_company_details.find(
          (o) =>
            o.INSURANCE_COMPANY_SYS_ID ===
            this.Premiumdetails.INSURANCE_COMPANY_SYS_ID
        ).INSURANCE_COMPANY;
    },

    //insurance provider
    getInsuranceProviderInfo() {
      this.axios
        .get(apiEndPoints.insurance_provider_details, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.insurance_provider_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.insurance_provider_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getInsurenseProvider(insurence_provider) {
      let existed_insurence_provider = this.insurance_provider_details.find(
        (data) => data.INSURANCE_PROVIDER_SYS_ID == insurence_provider
      );
      if (existed_insurence_provider) {
        this.Premiumdetails.CONTACT_NO = existed_insurence_provider.CONTACT_NO;
        this.Premiumdetails.PERSON_NAME =
          existed_insurence_provider.PERSON_NAME;
      }
    },

    async Delete(data) {
      const result = await Global.showConfirmationAlert(
        `DELETE  THIS ENTRY?`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.INSURANCE_DETAILS_SYS_ID,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.add_insurance_details, postarrydata)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.viewInsuranceDetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            if (err.response.status != 401 || err.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },

    getManufracturename() {
      this.axios
        .get(apiEndPoints.view_vehicle_manufacture_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          this.Manufracturename = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getmakeName() {
      this.updatevehicle_details.VEHICLE_MAKE = this.Manufracturename.find(
        (o) =>
          o.VEHICLE_MAKE_SYS_ID ===
          this.updatevehicle_details.VEHICLE_MAKE_SYS_ID
      ).VEHICLE_MAKE;
      this.getmodeltype();
    },

    getmodelshortName() {
      //   this.addmasterdata.MODEL_SHORT_NAME = this.Modeltype.find(o => o.VEHICLE_MODEL_SYS_ID === this.addmasterdata.MODEL_SYS_ID).MODEL_SHORT_NAME;
      this.updatevehicle_details.VEHICLE_MODEL = this.Modeltype.find(
        (o) =>
          o.VEHICLE_MODEL_SYS_ID ===
          this.updatevehicle_details.VEHICLE_MODEL_SYS_ID
      ).VEHICLE_MODEL;
    },

    getmodeltype() {
      this.axios
        .get(apiEndPoints.view_vehicle_model_type, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.updatevehicle_details.VEHICLE_MAKE_SYS_ID,
          },
        })
        .then((response) => {
          this.Modeltype = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getCustomer() {
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 20,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          this.user_details = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    AddUpdatesellerap() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updat_user["CREATED_BY"] = user_details.UserId;
      this.updat_user["ITEM"] = "UPDATE";
      this.updat_user["RECORD_SYS_ID"] = this.updat_user.VEHICLE_SYS_ID;
      this.axios
        .post(apiEndPoints.add_update_ap_payment_details, this.updat_user)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    getTaxInfo() {
      this.axios
        .get(apiEndPoints.tax_info, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.tax_info_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.tax_info_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    saferdi_calcu() {
      var basicp = this.Premiumdetails.BASE_PREMIUM;
      var perval = this.Premiumdetails.NO_CLAIM_DISC_PERCENTAGE;
      var safer = (parseInt(basicp) * parseInt(perval)) / 100;
      this.Premiumdetails.NO_CLAIM_DISC_VALUE = safer;
      this.grosspremium_calcu();
    },

    saferdisc_calcu() {
      var basicp = this.Premiumdetails.BASE_PREMIUM;
      var perval = this.Premiumdetails.SAFER_DISC_PERCENTAGE;
      var saferdisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.Premiumdetails.SAFER_DISC_VALUE = saferdisc;
      this.grosspremium_calcu();
    },

    loyaltydisc_calcu() {
      var basicp = this.Premiumdetails.BASE_PREMIUM;
      var perval = this.Premiumdetails.LOYALITY_DISC_PERCENTAGE;
      var loyaltydisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.Premiumdetails.LOYALITY_DISC_VALUE = loyaltydisc;
      this.grosspremium_calcu();
    },

    fleetdisc_calcu() {
      var basicp = this.Premiumdetails.BASE_PREMIUM;
      var perval = this.Premiumdetails.FLEET_DISC_PERCENTAGE;
      var fleetdisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.Premiumdetails.FLEET_DISC_VALUE = fleetdisc;
      this.grosspremium_calcu();
    },

    directdisc_calcu() {
      var basicp = this.Premiumdetails.BASE_PREMIUM;
      var perval = this.Premiumdetails.DIRECT_DISC_PERCENTAGE;
      var directdisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.Premiumdetails.DIRECT_DISC_VALUE = directdisc;
      this.grosspremium_calcu();
    },

    insurancedisc_calcu() {
      var basicp = this.vehicle_details.BASE_PREMIUM;
      var perval = this.INSURANCE_DISC_PERCENTAGE;
      var insurancedisc = (parseInt(basicp) * parseInt(perval)) / 100;
      this.vehicle_details.INSURANCE_DISC_VALUE = insurancedisc;
    },

    tax_calcu() {
      var basicp = this.vehicle_details.BASE_PREMIUM;
      var perval = this.vehicle_details.TAX_PERCENTAGE;
      var tax_amount = (parseInt(basicp) * parseInt(perval)) / 100;
      this.vehicle_details.TAX_AMOUNT = tax_amount;
    },

    premium_calcu() {
      this.Payabledetails.PAYABLE_PREMIUM =
        this.Premiumdetails.BASE_PREMIUM -
        (this.Premiumdetails.NO_CLAIM_DISC_VALUE +
          this.Premiumdetails.SAFER_DISC_VALUE +
          this.Premiumdetails.LOYALITY_DISC_VALUE +
          this.Premiumdetails.FLEET_DISC_VALUE +
          this.Premiumdetails.DIRECT_DISC_VALUE);
    },

    grosspremium_calcu() {
      this.Premiumdetails.CALCULATED_PREMIUM =
        this.Premiumdetails.PAYABLE_PREMIUM;
      if (this.Premiumdetails.SAFER_DISC == "Y") {
        this.Premiumdetails.CALCULATED_PREMIUM -= parseInt(
          this.Premiumdetails.SAFER_DISC_VALUE
        )
          ? parseInt(this.Premiumdetails.SAFER_DISC_VALUE)
          : 0;
      }
      if (this.Premiumdetails.NO_CLAIM_DISC == "Y") {
        this.Premiumdetails.CALCULATED_PREMIUM -= parseInt(
          this.Premiumdetails.NO_CLAIM_DISC_VALUE
        )
          ? parseInt(this.Premiumdetails.NO_CLAIM_DISC_VALUE)
          : 0;
      }
      if (this.Premiumdetails.LOYALITY_DISC == "Y") {
        this.Premiumdetails.CALCULATED_PREMIUM -= parseInt(
          this.Premiumdetails.LOYALITY_DISC_VALUE
        )
          ? parseInt(this.Premiumdetails.LOYALITY_DISC_VALUE)
          : 0;
      }
      if (this.Premiumdetails.FLEET_DISC == "Y") {
        this.Premiumdetails.CALCULATED_PREMIUM -= parseInt(
          this.Premiumdetails.FLEET_DISC_VALUE
        )
          ? parseInt(this.Premiumdetails.FLEET_DISC_VALUE)
          : 0;
      }
      if (this.Premiumdetails.DIRECT_DISC == "Y") {
        this.Premiumdetails.CALCULATED_PREMIUM -= parseInt(
          this.Premiumdetails.DIRECT_DISC_VALUE
        )
          ? parseInt(this.Premiumdetails.DIRECT_DISC_VALUE)
          : 0;
      }
    },

    getgstr() {
      let existed_tax = this.tax_info_details.find(
        (o) => o.TAX_NAME === this.Premiumdetails.TAX_PERCENTAGE
      );
      if (existed_tax) {
        this.gstc(existed_tax);
      }
    },

    gstc(existed_tax) {
      var aount = this.Payabledetails.PAYABLE_PREMIUM;
      var gstpercent = existed_tax.TAX_PERCENTAGE;
      var gs = (parseFloat(aount) * parseFloat(gstpercent)) / 100;
      this.Premiumdetails.TAX_AMOUNT = gs.toFixed(2);
      this.Payabledetails.PAYABLE_TAX_AMOUNT = gs.toFixed(2);
      var ge = parseFloat(aount) + parseFloat(gs);
      this.Premiumdetails.FINAL_PREMIUM = ge.toFixed(2);
      this.Payabledetails.TOTAL_PAYABLE_PREMIUM_PERCENTAGE = ge.toFixed(2);
    },

    hideRegNo() {
      var id_no = this.updatevehicle_details.CUSTOMER_SYS_ID;
      var nric = this.ID_NUMBER;
      var ctype = this.CUSTOMER_TYPE;
      if (id_no == nric && ctype == "individual") {
        this.hidereg = false;
      } else {
        this.hidereg = true;
      }
    },

    getInsuranceCompanyDetails() {
      this.axios
        .get(apiEndPoints.insurance_company_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.insurance_company_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.insurance_company_details = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    
    //Bind Extra Driver Form
    AddExtraDriver() {
      this.driver_info.push({
        EXCESS_DRIVER_NAME: "",
        EXCESS_DRIVER_AMOUNT: "",
        EXCESS_UNNAMED_DRIVER_AMOUNT: "",
        EXCESS_YOUNG_DRIVER: "",
        EXCESS_WINDSCREEN: "",
        WORKSHOP_SCHEME: null,
        EXTRA_BENEFIT: null,
      });
    },

    //Add Driver
    AddDriver(index, record_sys_id) {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let driver_info = this.driver_info[index];
      driver_info["CREATED_BY"] = user_details.UserId;
      driver_info["SUB_ITEM"] = "EXCESS_DRIVER";
      driver_info["INSURANCE_SYS_ID"] = this.INSURANCE_SYS_ID;
      if (
        record_sys_id == 0 ||
        record_sys_id == "0" ||
        record_sys_id == undefined ||
        record_sys_id == "undefined"
      ) {
        driver_info["RECORD_SYS_ID"] = 0;
        driver_info["ITEM"] = "ADD";
      } else {
        driver_info["RECORD_SYS_ID"] = record_sys_id;
        driver_info["ITEM"] = "UPDATE";
      }
      this.$refs.bankform6.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.add_update_driver_details, driver_info)
            .then((response) => {
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            })
            .finally(() => {
              NProgress.done();
              this.loading = false;
            });
        }
      });
    },

    //Bank list
    getBanklist() {
      this.axios
        .get(apiEndPoints.bank_name_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.Banklist = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.Banklist = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    // dealer commison calculation
    commisonamount() {
      let PAYABLE_PREMIUM = this.Payabledetails.PAYABLE_PREMIUM;

      let dealerpercentage =
        this.Receivabledetails.DEALER_COMMISSION_PERCENTAGE;
      let aount = (PAYABLE_PREMIUM * dealerpercentage) / 100;
      this.Receivabledetails.DEALER_COMMISSION_AMOUNT = aount;
    },
    //function to redirect for another page
    RedirectToaddinsurance(insurance_detail) {
      this.$router.push({
        name: "addinsurance",
        query: {
          INSURANCE_DETAILS_SYS_ID: insurance_detail.INSURANCE_DETAILS_SYS_ID,
        },
      });
    },
  },
  computed: {
    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
  },
};
