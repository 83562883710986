<template>
  <div>
    <div v-if="loading">
      <div class="preloder loading_main_background" id="upload_loader">
        <img src="../../assets/images/loader.gif" />
      </div>
    </div>
    <div class="floating-heading">
      <h4><b>View Quotation</b></h4>
    </div>
    <div
      class="floating-add-btn-container animate__animated animate__lightSpeedInRight"
    >
      <button
        id="add_btn"
        data-bs-toggle="modal"
        data-bs-target="#_modal"
        data-bs-dismiss="modal"
        @click="onLogIn"
        v-if="menuPermissions.FULL_ACCESS || menuPermissions.CREATE_ACCESS"
      >
        <i class="fa-solid fa-plus"></i>
      </button>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="floating-search-btn-container">
        <button
          id="srch_btn"
          data-bs-toggle="modal"
          data-bs-target="#left_modal_sm"
        >
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>
      <div class="page-content">
        <div class="page-data">
          <div class="row">
            <!-- <h5>View Quotation</h5> -->
            <div class="col-md-12 animate__animated animate__zoomIn">
              <div class="card-wrap shadow-main">
                <div class="card-wrap-body">
                  <div class="data-table master-table">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>SL. No.</th>
                          <th>Model Name</th>
                          <th>Vehicle Number</th>
                          <th>Type Of Cover</th>
                          <th>Status</th>

                          <!-- <th>ID Number</th> -->
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="(user_detai, index) in user_detail"
                        :key="user_detai.RECORD_SYS_ID"
                      >
                        <tr>
                          <td>{{ index + 1 }}</td>
                          <td
                            @click="showModal(user_detai, '#rokerupdate_modal')"
                          >
                            {{ user_detai.MODEL_NAME }}
                          </td>
                          <td
                            @click="showModal(user_detai, '#rokerupdate_modal')"
                          >
                            {{ user_detai.VEHICLE_NO }}
                          </td>
                          <td
                            @click="showModal(user_detai, '#rokerupdate_modal')"
                          >
                            {{ user_detai.COVER_TYPE }}
                          </td>
                          <th
                            @click="showModal(user_detai, '#rokerupdate_modal')"
                          >
                            {{ user_detai.STATUS }}
                          </th>

                          <!-- <td>NIRIC-Individual</td> -->
                          <td class="table-btn">
                            <button
                              type="button"
                              class="custom-btn me-1 p-0"
                              data-bs-toggle="modal"
                              data-bs-target="#view_frm"
                              @click="
                                showDetails(user_detai, '#view_broker_modal')
                              "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-eye"
                              >
                                <path
                                  d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                ></path>
                                <circle cx="12" cy="12" r="3"></circle>
                              </svg>
                            </button>
                            <button
                              type="button"
                              class="custom-btn p-0"
                              @click="
                                showModal(user_detai, '#rokerupdate_modal')
                              "
                              v-if="menuPermissions.UPDATE_ACCESS"
                            ></button>
                            <button
                              type="button"
                              class="custom-btn p-0"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_frm"
                              @click="Dele(user_detai)"
                              v-if="menuPermissions.DELETE_ACCESS"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trash-2"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path
                                  d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                                ></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <!-- Update Quotation -->
            <div
              class="modal modal-right fade"
              id="rokerupdate_modal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-xl" style="width: 80% !important">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Update Quotation
                    </h5>

                    <button
                      type="button"
                      class="btn-normal modal-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      @click="closeModal('#rokerupdate_modal')"
                    >
                      <i class="fa fa-angle-right" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="modal-body">
                    <ValidationObserver ref="bankform" v-slot="{ invalid }">
                      <form>
                        <div class="row admin-edt-frm mt-3">
                          <!-- left sec  -->
                          <div class="col-md-6 left-side-frm">
                            <h5 class="line-heading">
                              <span>Customer Details</span>
                            </h5>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Customer Info<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <b-input-group>
                                      <b-form-input
                                        id="CustomerInfo"
                                        name="CustomerInfo"
                                        v-model="customer_name"
                                        class="form-control"
                                        placeholder="Enter customer"
                                        list="datalistOptions"
                                        @keyup="onKeyUpCustomerInfo"
                                        @change="onChnageCustomerInfo"
                                      ></b-form-input>
                                      <b-input-group-append>
                                        <!-- <b-button
                                        variant="info"
                                        
                                      >
                                        <i class="fa-solid fa-plus"></i>
                                      </b-button> -->
                                      </b-input-group-append>
                                    </b-input-group>
                                    <datalist id="datalistOptions">
                                      <option
                                        v-for="(
                                          customer_info, index
                                        ) in all_customer_array"
                                        :key="index"
                                      >
                                        {{ customer_info.CUSTOMER_NAME }}
                                      </option>
                                    </datalist>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-12"
                                v-if="DisplayCustomerType != ''"
                              >
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Customer Type<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      id="CustomerType"
                                      name="CustomerType"
                                      class="form-control"
                                      v-model="DisplayCustomerType"
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-12"
                                v-if="
                                  DisplayCustomerType == 'individual' &&
                                  DisplayIdNumber != ''
                                "
                              >
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >NRIC Number<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      id="NRIC Number"
                                      name="NRIC Number"
                                      class="form-control"
                                      v-model="DisplayIdNumber"
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-12"
                                v-if="
                                  DisplayCustomerType == 'Company' &&
                                  DisplayIdNumber != ''
                                "
                              >
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >UEN Number<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      id="UEN Number"
                                      name="UEN Number"
                                      class="form-control"
                                      v-model="DisplayIdNumber"
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-12"
                                v-if="DisplayCustomerName != ''"
                              >
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Customer Name<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <input
                                      type="text"
                                      id="Customer Name"
                                      name="Customer Name"
                                      class="form-control"
                                      v-model="DisplayCustomerName"
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-12"
                                v-if="DisplayCustomerAddress != ''"
                              >
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Customer Address<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <textarea
                                      style="height: 4em"
                                      id="Customer Address"
                                      name="Customer Address"
                                      class="form-control"
                                      v-model="DisplayCustomerAddress"
                                      disabled
                                    >
                                    </textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- right sec -->
                          <div class="col-md-6 left-side-frm">
                            <h5 class="line-heading">
                              <span>Vehicle Details</span>
                            </h5>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Vehicle No<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <ValidationProvider
                                      rules="required"
                                      v-slot="{ errors }"
                                    >
                                      <b-form-input
                                        id="VehicleNo"
                                        name="VehicleNo"
                                        class="form-control"
                                        placeholder="Enter Vehicle No"
                                        v-model="vehicledetails.VEHICLE_NO"
                                        :disabled="
                                          vehicledetails.VEHICLE_NO != ''
                                        "
                                        :class="{
                                          'is-invalid': errors[0] != null,
                                          'is-valid':
                                            errors[0] == null &&
                                            vehicledetails.VEHICLE_NO != '',
                                        }"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </ValidationProvider>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-12"
                                v-if="vehicledetails.VEHICLE_NO"
                              >
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Make<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <ValidationProvider
                                      rules="required"
                                      v-slot="{ errors }"
                                    >
                                      <select
                                        name="Make"
                                        v-model="vehicledetails.MAKE_SYS_ID"
                                        class="form-control"
                                        :class="{
                                          'is-invalid': errors[0] != null,
                                          'is-valid':
                                            errors[0] == null &&
                                            vehicledetails.MAKE_SYS_ID != '',
                                        }"
                                      >
                                        <option value="">
                                          Select Manufracturer
                                        </option>
                                        <option
                                          v-for="makenm in Manufracturename"
                                          :value="makenm.VEHICLE_MAKE_SYS_ID"
                                          :key="makenm.VEHICLE_MAKE_SYS_ID"
                                        >
                                          {{ makenm.VEHICLE_MAKE }}
                                        </option>
                                      </select>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </ValidationProvider>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-12"
                                v-if="vehicledetails.VEHICLE_NO"
                              >
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Model<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <ValidationProvider
                                      rules="required"
                                      v-slot="{ errors }"
                                    >
                                      <input
                                        type="name"
                                        name="Existing Insurance"
                                        v-model.trim="vehicledetails.MODEL_NAME"
                                        class="form-control"
                                        placeholder="Enter Existing Insurance"
                                        :class="{
                                          'is-invalid': errors[0] != null,
                                          'is-valid':
                                            errors[0] == null &&
                                            vehicledetails.MODEL_NAME != '',
                                        }"
                                      />
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </ValidationProvider>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-12"
                                v-if="vehicledetails.VEHICLE_NO"
                              >
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Manufacture Year<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <ValidationProvider
                                      rules="required"
                                      v-slot="{ errors }"
                                    >
                                      <input
                                        type="number"
                                        name="Manufactory Year"
                                        v-model="
                                          vehicledetails.MANUFACTURE_YEAR
                                        "
                                        class="form-control"
                                        placeholder="Enter Manufacture Year"
                                        :class="{
                                          'is-invalid': errors[0] != null,
                                          'is-valid':
                                            errors[0] == null &&
                                            vehicledetails.MANUFACTURE_YEAR !=
                                              '',
                                        }"
                                      />
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </ValidationProvider>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-12"
                                v-if="vehicledetails.VEHICLE_NO"
                              >
                                <div class="row mt-2">
                                  <div class="col-md-4">
                                    <label class="frm-label-2 align-right"
                                      >Orginal Reg. Date<span style="color: red"
                                        >*</span
                                      ></label
                                    >
                                  </div>
                                  <div class="col-md-8">
                                    <ValidationProvider
                                      rules="required"
                                      v-slot="{ errors }"
                                    >
                                      <input
                                        type="text"
                                        name="Orginal reg date"
                                        v-model.trim="
                                          vehicledetails.ORIGINAL_REGISTRATION_DATE
                                        "
                                        class="form-control"
                                        placeholder="Enter Orginal Reg no"
                                        :class="{
                                          'is-invalid': errors[0] != null,
                                          'is-valid':
                                            errors[0] == null &&
                                            vehicledetails.ORIGINAL_REGISTRATION_DATE !=
                                              '',
                                        }"
                                      />
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </ValidationProvider>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <br />
                            <h5 class="line-heading">
                              <span>Motor Insurance Quotation</span>
                            </h5>
                            <div class="col-md-12">
                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Renewal NCD<span style="color: red"
                                      >*</span
                                    ></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <ValidationProvider
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      type="name"
                                      name="Renwal NCD"
                                      v-model.trim="
                                        motor_quotaion_details.RENEWAL_NCD
                                      "
                                      class="form-control"
                                      placeholder="Enter Renewal Ncd"
                                      :class="{
                                        'is-invalid': errors[0] != null,
                                        'is-valid':
                                          errors[0] == null &&
                                          motor_quotaion_details.RENEWAL_NCD !=
                                            '',
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Existing Insurance<span style="color: red"
                                      >*</span
                                    ></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <ValidationProvider
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <input
                                      type="name"
                                      name="Existing Insurance"
                                      v-model.trim="
                                        motor_quotaion_details.EXISITING_INSURER
                                      "
                                      class="form-control"
                                      placeholder="Enter Existing Insurance"
                                      :class="{
                                        'is-invalid': errors[0] != null,
                                        'is-valid':
                                          errors[0] == null &&
                                          motor_quotaion_details.EXISITING_INSURER !=
                                            '',
                                      }"
                                    />
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Previous Registration Number
                                  </label>
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="text"
                                    name="Previous Registration Number"
                                    v-model.trim="
                                      motor_quotaion_details.PREVIOUS_REGISTRATION_NO
                                    "
                                    class="form-control"
                                    placeholder="Enter Previous Registration Number"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Type Of Cover<span style="color: red"
                                      >*</span
                                    ></label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <ValidationProvider
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <select
                                      name="type of cover"
                                      v-model="
                                        motor_quotaion_details.COVER_TYPE
                                      "
                                      class="form-control"
                                      :class="{
                                        'is-invalid': errors[0] != null,
                                        'is-valid':
                                          errors[0] == null &&
                                          motor_quotaion_details.COVER_TYPE !=
                                            '',
                                      }"
                                    >
                                      <option value="">Select</option>
                                      <option>COMPREHENSIVE</option>
                                      <option>TPFT</option>
                                      <option>TPO</option>
                                    </select>
                                    <div class="invalid-feedback">
                                      {{ errors[0] }}
                                    </div>
                                  </ValidationProvider>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right">
                                    Finance Company
                                  </label>
                                </div>
                                <div class="col-md-8">
                                  <select
                                    name="type of cover"
                                    v-model="
                                      motor_quotaion_details.FINANCE_COMPANY_SYS_ID
                                    "
                                    @change="
                                      getFinanceCompanyName(
                                        motor_quotaion_details.FINANCE_COMPANY_SYS_ID
                                      )
                                    "
                                    class="form-control"
                                  >
                                    <option :value="null">Select</option>
                                    <option
                                      v-for="(
                                        finace_company, finace_index
                                      ) in finace_company_list"
                                      :key="finace_index"
                                      :value="
                                        finace_company.FINANCE_COMPANY_BANK_SYS_ID
                                      "
                                    >
                                      {{
                                        finace_company.FINANCE_COMPANY_BANK_NAME
                                      }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Remarks</label
                                  >
                                </div>
                                <div class="col-md-8">
                                  <input
                                    type="name"
                                    name="Remarks"
                                    v-model.trim="
                                      motor_quotaion_details.QUOTATION_REMARKS
                                    "
                                    class="form-control"
                                    placeholder="Enter Remarks"
                                  />
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="row mt-2">
                                <div class="col-md-4">
                                  <label class="frm-label-2 align-right"
                                    >Quotation File</label
                                  >
                                </div>
                                <div class="col-md-6">
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    id="customQuotationFile"
                                    accept="application/pdf"
                                    ref="QuotationFile"
                                    @change="handleQuotationFileObject()"
                                  />
                                  
                                  
                                </div>
                                <div
                                  class="col-md-2"
                                  style="text-align: right;"
                                  v-if="isDisabledQuotatinFile == true"
                                >
                                  <button
                                    style="margin-left: 27px"
                                    type="button"
                                    class="custom-btn me-1 p-0"
                                    v-b-modal.quotation_preview_modal
                                    @click="previewLogCardPdfFile()"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-eye"
                                    >
                                      <path
                                        d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
                                      ></path>
                                      <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <br />
                            <b>Note</b>
                            <p>
                              1. Once fillup the info and quotation pricing
                              please use save button to get download and
                              approval action.
                            </p>
                            <p>
                              2. To generate the quotation with pricing for
                              customer select the checkbox. Without pricing info
                              will not visible.
                            </p>
                            <p>
                              3. Select the radio button to choose which premium
                              is approved by customer. Select that and click on
                              approve button.
                            </p>
                          </div>
                        </div>
                        <br />
                        <div class="master-table">
                          <table class="mt-3 table table-bordered">
                            <thead>
                              <tr style="border: none">
                                <th
                                  colspan="2"
                                  class="borderless heading-td"
                                  style="border: none; visibility: hidden"
                                ></th>
                                <th
                                  scope="col"
                                  style="
                                    border-color: #dee2e6;
                                    background: #fff !important;
                                    border-top: 1px solid #dee2e6;
                                  "
                                >
                                  <b-form-checkbox
                                    v-model="selected_workshop_pdf"
                                    name="workshop-radios"
                                    value="DISTRIBUTOR_WORKSHOP"
                                  ></b-form-checkbox>
                                </th>
                                <th
                                  scope="col"
                                  style="
                                    border-color: #dee2e6;
                                    background: #fff !important;
                                    border-top: 1px solid #dee2e6;
                                  "
                                >
                                  <b-form-checkbox
                                    v-model="selected_workshop_pdf"
                                    name="workshop-radios"
                                    value="ANY_WORKSHOP"
                                  ></b-form-checkbox>
                                </th>
                                <th
                                  scope="col"
                                  style="
                                    border-color: #dee2e6;
                                    background: #fff !important;
                                    border-top: 1px solid #dee2e6;
                                  "
                                >
                                  <b-form-checkbox
                                    v-model="selected_workshop_pdf"
                                    name="workshop-radios"
                                    value="AUTHORISED_WORKSHOP"
                                  ></b-form-checkbox>
                                </th>
                                <th
                                  scope="col"
                                  style="
                                    border-color: #dee2e6;
                                    background: #fff !important;
                                    border-top: 1px solid #dee2e6;
                                  "
                                ></th>
                              </tr>
                              <tr style="border: none">
                                <th
                                  colspan="2"
                                  class="borderless heading-td"
                                  style="border: none; visibility: hidden"
                                ></th>
                                <th scope="col" style="border-color: #dee2e6">
                                  <b-form-radio
                                    v-model="selected_workshop"
                                    name="workshop-radios"
                                    value="DISTRIBUTOR_WORKSHOP"
                                    >Distributor’s Workshop</b-form-radio
                                  >
                                </th>
                                <th scope="col" style="border-color: #dee2e6">
                                  <b-form-radio
                                    v-model="selected_workshop"
                                    name="workshop-radios"
                                    value="ANY_WORKSHOP"
                                    >Any Workshop</b-form-radio
                                  >
                                </th>
                                <th scope="col" style="border-color: #dee2e6">
                                  <b-form-radio
                                    v-model="selected_workshop"
                                    name="workshop-radios"
                                    value="AUTHORISED_WORKSHOP"
                                    >Authorised Workshop</b-form-radio
                                  >
                                </th>
                                <th
                                  scope="col"
                                  colspan="2"
                                  style="border-color: #dee2e6"
                                >
                                  Remarks
                                </th>
                              </tr>
                            </thead>
                          </table>
                          <table class="table table-bordered">
                            <tbody>
                              <tr>
                                <td rowspan="2">
                                  <b-form-checkbox
                                    v-model="selected_company_pdf"
                                    name="TAIPING"
                                    value="TAIPING"
                                  ></b-form-checkbox>
                                </td>
                                <td class="heading-td">
                                  China Taiping<b-form-radio
                                    v-model="selected_company"
                                    name="company-radios"
                                    style="float: right"
                                    value="TAIPING"
                                  ></b-form-radio>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .TAIPING
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP.TAIPING
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP
                                        .TAIPING
                                    "
                                  />
                                </td>
                                <td rowspan="2" colspan="2">
                                  <textarea
                                    class="form-control input-txtarea"
                                    v-model="REMARKS.TAIPING_EXCESS_REMARKS"
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <td class="heading-td">Excess</td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .TAIPING_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP
                                        .TAIPING_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP
                                        .TAIPING_EXCESS
                                    "
                                  />
                                </td>
                                <!-- <td></td> -->
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered mt-3">
                            <tbody>
                              <tr>
                                <td rowspan="2">
                                  <b-form-checkbox
                                    v-model="selected_company_pdf"
                                    name="NTUC"
                                    value="NTUC"
                                  ></b-form-checkbox>
                                </td>
                                <td class="heading-td">
                                  Income<b-form-radio
                                    v-model="selected_company"
                                    name="company-radios"
                                    value="NTUC"
                                    style="float: right"
                                  ></b-form-radio>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP.NTUC
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="workshop_details.ANY_WORKSHOP.NTUC"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP.NTUC
                                    "
                                  />
                                </td>
                                <td rowspan="2" colspan="2">
                                  <textarea
                                    class="form-control input-txtarea"
                                    v-model="REMARKS.NTUC_EXCESS_REMARKS"
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <td class="heading-td">Excess</td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .NTUC_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP.NTUC_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP
                                        .NTUC_EXCESS
                                    "
                                  />
                                </td>
                                <!-- <td></td> -->
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered mt-3">
                            <tbody>
                              <tr>
                                <td rowspan="2">
                                  <b-form-checkbox
                                    v-model="selected_company_pdf"
                                    name="LONPAC"
                                    value="LONPAC"
                                  ></b-form-checkbox>
                                </td>
                                <td class="heading-td">
                                  Lonpac<b-form-radio
                                    v-model="selected_company"
                                    name="company-radios"
                                    value="LONPAC"
                                    style="float: right"
                                  ></b-form-radio>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .LONPAC
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP.LONPAC
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP
                                        .LONPAC
                                    "
                                  />
                                </td>
                                <td rowspan="2" colspan="2">
                                  <textarea
                                    class="form-control input-txtarea"
                                    v-model="REMARKS.LONPAC_EXCESS_REMARKS"
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <td class="heading-td">Excess</td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .LONPAC_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP
                                        .LONPAC_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP
                                        .LONPAC_EXCESS
                                    "
                                  />
                                </td>
                                <!-- <td></td> -->
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered mt-3">
                            <tbody>
                              <tr>
                                <td rowspan="2">
                                  <b-form-checkbox
                                    v-model="selected_company_pdf"
                                    name="ETIQA"
                                    value="ETIQA"
                                  ></b-form-checkbox>
                                </td>
                                <td class="heading-td">
                                  ETIQA<b-form-radio
                                    v-model="selected_company"
                                    name="company-radios"
                                    value="ETIQA"
                                    style="float: right"
                                  ></b-form-radio>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .ETIQA
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP.ETIQA
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP.ETIQA
                                    "
                                  />
                                </td>
                                <td rowspan="2" colspan="2">
                                  <textarea
                                    class="form-control input-txtarea"
                                    v-model="REMARKS.ETIQA_EXCESS_REMARKS"
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <td class="heading-td">Excess</td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .ETIQA_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP.ETIQA_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP
                                        .ETIQA_EXCESS
                                    "
                                  />
                                </td>
                                <!-- <td></td> -->
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered mt-3">
                            <tbody>
                              <tr>
                                <td rowspan="2">
                                  <b-form-checkbox
                                    v-model="selected_company_pdf"
                                    name="ERGO"
                                    value="ERGO"
                                  ></b-form-checkbox>
                                </td>
                                <td class="heading-td">
                                  ERGO<b-form-radio
                                    v-model="selected_company"
                                    name="company-radios"
                                    value="ERGO"
                                    style="float: right"
                                  ></b-form-radio>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP.ERGO
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="workshop_details.ANY_WORKSHOP.ERGO"
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP.ERGO
                                    "
                                  />
                                </td>
                                <td rowspan="2" colspan="2">
                                  <textarea
                                    class="form-control input-txtarea"
                                    v-model="REMARKS.ERGO_EXCESS_REMARKS"
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <td class="heading-td">Excess</td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .ERGO_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP.ERGO_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP
                                        .ERGO_EXCESS
                                    "
                                  />
                                </td>
                                <!-- <td></td> -->
                              </tr>
                            </tbody>
                          </table>
                          <table class="table table-bordered mt-3">
                            <tbody>
                              <tr>
                                <td rowspan="2">
                                  <b-form-checkbox
                                    name="ECICS"
                                    v-model="selected_company_pdf"
                                    value="ECICS"
                                  ></b-form-checkbox>
                                </td>
                                <td class="heading-td">
                                  ECICS<b-form-radio
                                    v-model="selected_company"
                                    name="company-radios"
                                    value="ECICS"
                                    style="float: right"
                                  ></b-form-radio>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .ECICS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP.ECICS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP.ECICS
                                    "
                                  />
                                </td>
                                <td rowspan="2" colspan="2">
                                  <textarea
                                    class="form-control input-txtarea"
                                    v-model="REMARKS.ECICS_EXCESS_REMARKS"
                                  ></textarea>
                                </td>
                              </tr>
                              <tr>
                                <td class="heading-td">Excess</td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.DISTRIBUTOR_WORKSHOP
                                        .ECICS_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.ANY_WORKSHOP.ECICS_EXCESS
                                    "
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="
                                      workshop_details.AUTHORISED_WORKSHOP
                                        .ECICS_EXCESS
                                    "
                                  />
                                </td>
                                <!-- <td></td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="modal-footer">
                          <div class="row mt-4">
                            <div class="col-md-2">
                              <button
                                type="button"
                                class="custom-btn custom-success-btn"
                                :disabled="invalid"
                                @click="uploadQuotaion()"
                              >
                                Save
                              </button>
                            </div>
                            <div class="col-md-2" v-if="showA">
                              <button
                                type="button"
                                class="custom-btn custom-success-btn"
                                :disabled="invalid"
                                @click="AddApproval()"
                              >
                                Approval
                              </button>
                            </div>
                            <div class="col-md-2" v-if="showA">
                              <button
                                type="button"
                                class="custom-btn custom-success-btn"
                                @click="downLoadQuitation"
                              >
                                Download
                              </button>
                            </div>
                            <div class="col-md-2">
                              <button
                                type="button"
                                class="custom-btn custom-danger-btn"
                                @click="closeModal('#rokerupdate_modal')"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>

            <!-- Preview PDF File -->
            <b-modal id="quotation_preview_modal" size="lg" hide-footer hide-header>
              <div class="container-fluid d-flex">
                <iframe
                  id="fred"
                  style="border: 1px solid #666ccc"
                  title="PDF in an i-Frame"
                  :src="preview_pdf_url"
                  frameborder="1"
                  scrolling="auto"
                  width="100%"
                  height="550px"
                ></iframe>
              </div>
            </b-modal>
          </div>
        </div>
      </div>

      <!-- view quotation -->
      <div
        class="modal modal-left fade"
        id="left_modal_sm"
        tabindex="-1"
        role="dialog"
        aria-labelledby="left_modal_sm"
      >
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Search By</h5>
            </div>
            <div class="modal-body search-panel">
              <div class="row">
                <div class="col-md-12">
                  <label class="rad-label">
                    <input
                      type="radio"
                      class="rad-input"
                      name="rad"
                      value="All"
                    />
                    <div class="rad-design"></div>
                    <div class="rad-text">All Combinations</div>
                  </label>

                  <label class="rad-label">
                    <input
                      type="radio"
                      class="rad-input"
                      name="rad"
                      value="FY"
                    />
                    <div class="rad-design"></div>
                    <div class="rad-text">Name</div>
                  </label>

                  <label class="rad-label">
                    <input
                      type="radio"
                      class="rad-input"
                      name="rad"
                      value="program"
                    />
                    <div class="rad-design"></div>
                    <div class="rad-text">Mobile Number</div>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 div_1 mt-1">
                  <label class="frm-label">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="MODEL_NAME"
                    placeholder="Enter Name"
                  />
                </div>

                <div class="col-md-12 div_6 mt-2">
                  <div class="btn-conainer">
                    <button
                      type="button"
                      @click="getInsurer()"
                      id="srch-btn"
                      class="custom-btn custom-success-btn"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal modal-right fade"
        id="view_broker_modal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl" style="width: 75% !important">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">View Quotation</h5>
              <button
                type="button"
                class="btn-normal modal-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Insured Name </label>
                  <label>{{ view_quotation.INSURED_NAME }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">ROC/NRIC Number </label>
                  <label>{{ view_quotation.ROC_NRIC_NO }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Contact Number </label>
                  <label>{{ view_quotation.CONTACT_NO }}</label>
                </div>

                <div class="col-md-4 mt-3">
                  <label class="frm-label">Email </label>
                  <label>{{ view_quotation.EMAIL }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Address</label>
                  <label>{{ view_quotation.ADDRESS }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Vehicle No</label>
                  <label>{{ view_quotation.VEHICLE_NO }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Make</label>
                  <label>{{ view_quotation.MAKE_NAME }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Renewal NCD</label>
                  <label>{{ view_quotation.RENEWAL_NCD }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Model</label>
                  <label>{{ view_quotation.MODEL_NAME }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Existing Insurance</label>
                  <label>{{ view_quotation.EXISITING_INSURER }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Manufactory Year</label>
                  <label>{{ view_quotation.MANUFACTURE_YEAR }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Previous Registration Number</label>
                  <label>{{ view_quotation.PREVIOUS_REGISTRATION_NO }}</label>
                </div>
                <div class="col-md-4 mt-3">
                  <label class="frm-label">Orginal Reg. Date </label>
                  <label>{{ view_quotation.ORIGINAL_REGISTRATION_DATE }}</label>
                </div>

                <div class="col-md-4 mt-3">
                  <label class="frm-label">Yr Made </label>
                  <label>{{ view_quotation.REGISTRATION_DATE }}</label>
                </div>

                <div class="col-md-4 mt-3">
                  <label class="frm-label">Type Of Cover </label>
                  <label>{{ view_quotation.COVER_TYPE }}</label>
                </div>

                <div class="col-md-4 mt-3">
                  <label class="frm-label">Finace Company </label>
                  <label>{{ view_quotation.FINANCE_COMPANY }}</label>
                </div>

                <div class="col-md-4 mt-3">
                  <label class="frm-label">Remarks </label>
                  <label>{{ view_quotation.REMARKS }}</label>
                </div>

                <div class="col-md-4 mt-3">
                  <label class="frm-label"> </label>
                  <label></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal for download pdf -->
    <div
      class="modal fade animate__animated animate__fadeInDown"
      id="staticBackdrop-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="row" style="width: 100%">
              <div class="col-md-6">
                <h5 class="modal-title" id="staticBackdropLabel">
                  View Quotation
                </h5>
              </div>
              <div class="col-md-6">
                <button
                  class="downld animate__animated animate__bounceIn"
                  @click="generateReport"
                >
                  <i class="fa-solid fa-cloud-arrow-down"></i> Download
                </button>
              </div>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body debit-note-cont">
            <div class="content">
              <div class="content-header">
                <div class="row">
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-3">
                        <img
                          src="../../assets/images/icon.png"
                          class="img-fluid header-img"
                          alt="#"
                        />
                      </div>
                      <div class="col-md-9 p-0">
                        <ul>
                          <li>
                            <img src="../../assets/images/logo-txt.png" />
                          </li>
                          <li class="mt-2">
                            <p>
                              1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                              627615
                            </p>
                          </li>
                          <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                          <li><p>Tel: 62395191 Fax: 62395190</p></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="heading">
                      <p>View Quotation</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin-top: 15px">
                <div class="col-md-12">
                  <div class="content-header-2">
                    <div></div>
                    <div class="mb-1"><b>Date:</b> {{ current_date }}</div>
                    <div class="mb-1">
                      <b>Insured Name:</b> {{ DisplayCustomerName }}
                    </div>
                    <div class="mb-1">
                      <b>Email:</b> {{ DisplayCustomerEmail }}
                    </div>
                    <div class="mb-1">
                      <b>ROC/NRIC Number:</b> {{ DisplayIdNumber }}
                    </div>
                    <div class="mb-1">
                      <b>Contact Number:</b> {{ DisplayCustomerContact }}
                    </div>
                    <div class="mb-1">
                      <b>Address:</b> {{ DisplayCustomerAddress }}
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="content-body">
                    <ul>
                      <li>
                        <h5><b>Vehicle Details</b></h5>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Vehicle No</p></div>
                          <div class="col-md-8">
                            <span>: &nbsp;{{ vehicledetails.VEHICLE_NO }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Make</p></div>
                          <div class="col-md-8">
                            <span>: &nbsp;{{ vehicledetails.MAKE_NAME }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Renewal NCD</p></div>
                          <div class="col-md-8">
                            <span
                              >:&nbsp;{{
                                motor_quotaion_details.RENEWAL_NCD
                              }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Model</p></div>
                          <div class="col-md-8">
                            <span>:&nbsp;{{ vehicledetails.MODEL_NAME }}</span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Existing Insurance</p></div>
                          <div class="col-md-8">
                            <span
                              >:&nbsp;{{
                                motor_quotaion_details.EXISITING_INSURER
                              }}</span
                            >
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Manufactory Year</p></div>
                          <div class="col-md-8">
                            <span
                              >:&nbsp;{{ vehicledetails.MANUFACTURE_YEAR }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4">
                            <p>Previous Registration Number</p>
                          </div>
                          <div class="col-md-8">
                            <span
                              >:&nbsp;{{
                                motor_quotaion_details.PREVIOUS_REGISTRATION_NO
                              }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Orginal Reg. Date</p></div>
                          <div class="col-md-8">
                            <span
                              >:&nbsp;{{
                                vehicledetails.ORIGINAL_REGISTRATION_DATE
                              }}</span
                            >
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Type Of Cover</p></div>
                          <div class="col-md-8">
                            <span
                              >:&nbsp;{{ motor_quotaion_details.COVER_TYPE }}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Finace Company</p></div>
                          <div class="col-md-8">
                            <span>:&nbsp;{{ finace_company_name }} </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="row">
                          <div class="col-md-4"><p>Remarks</p></div>
                          <div class="col-md-8">
                            <span
                              >:&nbsp;{{
                                motor_quotaion_details.QUOTATION_REMARKS
                              }}</span
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="content-body"
                    style="border-top: none !important; padding-top: 0"
                    v-if="selected_data_pdf && selected_data_pdf.length > 0"
                  >
                    <h5><b>Premium Details</b></h5>
                    <br />
                    <b-table :items="selected_data_pdf" :fields="fields">
                      <template v-slot:cell(work_shop_amount)="row">
                        <div>S$&nbsp;{{ row.item.work_shop_amount }}</div>
                        <div class="pt-2" v-if="row.item.excess">
                          S$&nbsp;{{ row.item.excess }}
                        </div>
                      </template>
                      <template v-slot:cell(comapny_name)="row">
                        <div>
                          {{ row.item.comapny_name.split("_").join(" ") }}
                        </div>
                        <div class="pt-2" v-if="row.item.excess">EXCESS</div>
                      </template>
                      <template v-slot:cell(work_shop_name)="row">
                        <div>
                          {{ row.item.work_shop_name.split("_").join(" ") }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer debit-note-cont">
            <b>Note</b>: This Insurance Quotation is only Valid for 2 weeks from
            the date of quotation.
          </div>
        </div>
      </div>
    </div>

    <!-- pdf generate code -->
    <VueHtml2pdf
      style="display: none"
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="view-quotation"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="p-5 pdf-content">
        <div class="content">
          <div class="content-header">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-3">
                    <img
                      src="../../assets/images/icon.png"
                      class="img-fluid header-img"
                      alt="#"
                    />
                  </div>
                  <div class="col-md-9 p-0">
                    <ul>
                      <li>
                        <img src="../../assets/images/logo-txt.png" />
                      </li>
                      <li class="mt-2">
                        <p>
                          1 Sunview Road #01-46 Eco-Tech@Sunview Singapore
                          627615
                        </p>
                      </li>
                      <li><p>ROC No./ GST Reg No:. 201929524C</p></li>
                      <li><p>Tel: 62395191 Fax: 62395190</p></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="heading">
                  <p>View Quotation</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 15px">
            <div class="col-md-12">
              <div class="content-header-2">
                <div class="mb-1"><b>Date:</b> {{ current_date }}</div>
                <div class="mb-1">
                  <b>Insured Name:</b> {{ DisplayCustomerName }}
                </div>
                <div class="mb-1"><b>Email:</b> {{ DisplayCustomerEmail }}</div>
                <div class="mb-1">
                  <b>ROC/NRIC Number:</b> {{ DisplayIdNumber }}
                </div>
                <div class="mb-1">
                  <b>Contact Number:</b> {{ DisplayCustomerContact }}
                </div>
                <div class="mb-1">
                  <b>Address:</b> {{ DisplayCustomerAddress }}
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="content-body">
                <ul>
                  <li>
                    <h5><b>Vehicle Details</b></h5>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Vehicle No</p></div>
                      <div class="col-md-8">
                        <span>: &nbsp;{{ vehicledetails.VEHICLE_NO }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Make</p></div>
                      <div class="col-md-8">
                        <span>: &nbsp;{{ vehicledetails.MAKE_NAME }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Renewal NCD</p></div>
                      <div class="col-md-8">
                        <span
                          >:&nbsp;{{ motor_quotaion_details.RENEWAL_NCD }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Model</p></div>
                      <div class="col-md-8">
                        <span>:&nbsp;{{ vehicledetails.MODEL_NAME }}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Existing Insurance</p></div>
                      <div class="col-md-8">
                        <span
                          >:&nbsp;{{
                            motor_quotaion_details.EXISITING_INSURER
                          }}</span
                        >
                      </div>
                    </div>
                  </li>

                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Manufactory Year</p></div>
                      <div class="col-md-8">
                        <span
                          >:&nbsp;{{ vehicledetails.MANUFACTURE_YEAR }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4">
                        <p>Previous Registration Number</p>
                      </div>
                      <div class="col-md-8">
                        <span
                          >:&nbsp;{{
                            motor_quotaion_details.PREVIOUS_REGISTRATION_NO
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Orginal Reg. Date</p></div>
                      <div class="col-md-8">
                        <span
                          >:&nbsp;{{
                            vehicledetails.ORIGINAL_REGISTRATION_DATE
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Type Of Cover</p></div>
                      <div class="col-md-8">
                        <span
                          >:&nbsp;{{ motor_quotaion_details.COVER_TYPE }}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Finace Company</p></div>
                      <div class="col-md-8">
                        <span>:&nbsp;{{ finace_company_name }} </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="row">
                      <div class="col-md-4"><p>Remarks</p></div>
                      <div class="col-md-8">
                        <span
                          >:&nbsp;{{
                            motor_quotaion_details.QUOTATION_REMARKS
                          }}</span
                        >
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                class="content-body"
                style="border-top: none !important; padding-top: 0"
                v-if="selected_data_pdf && selected_data_pdf.length > 0"
              >
                <h5><b>Premium Details</b></h5>
                <br />
                <b-table :items="selected_data_pdf" :fields="fields">
                  <template v-slot:cell(work_shop_amount)="row">
                    <div>S$&nbsp;{{ row.item.work_shop_amount }}</div>
                    <div class="pt-2" v-if="row.item.excess">
                      S$&nbsp;{{ row.item.excess }}
                    </div>
                  </template>
                  <template v-slot:cell(comapny_name)="row">
                    <div>{{ row.item.comapny_name.split("_").join(" ") }}</div>
                    <div class="pt-2" v-if="row.item.excess">EXCESS</div>
                  </template>
                  <template v-slot:cell(work_shop_name)="row">
                    <div>
                      {{ row.item.work_shop_name.split("_").join(" ") }}
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="content-footer">
                <b>Note</b>: This Insurance Quotation is only Valid for 2 weeks
                from the date of quotation.
              </div>
            </div>
          </div>
        </div>
      </section>
    </VueHtml2pdf>
  </div>
</template>
<script>
import NProgress from "nprogress";
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";
export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      loading: false,
      showA: false,
      MAKE_SYS_ID: 1,
      vehicle_details: [],
      user_details: {},
      WORKSHOP_QUOTATION: [],
      crea_user: {
        ITEM: "PROFILE_PIC",
      },
      broker_details: {},
      user_detail: {},
      btnDisable: true,
      selected_company: null,
      selected_workshop: null,
      workshop_details: {
        DISTRIBUTOR_WORKSHOP: {},
        ANY_WORKSHOP: {},
        AUTHORISED_WORKSHOP: {},
      },
      REMARKS: {
        TAIPING_EXCESS_REMARKS: "",
        NTUC_EXCESS_REMARKS: "",
        LONPAC_EXCESS: "",
        ETIQA_EXCESS_REMARKS: "",
        ERGO_EXCESS_REMARKS: "",
        ECICS_EXCESS_REMARKS: "",
      },
      update_user: {},
      MODEL_SYS_ID: 1,
      crea_user: {},
      Manufracturename: {},
      MODEL_NAME: "",
      vehicledetails_page_no: 1,
      vehicledetails: {
        MODEL_NAME: "",
        MANUFACTURE_YEAR: "",
        ORIGINAL_REGISTRATION_DATE: "",
        MAKE_SYS_ID: "",
        VEHICLE_NO: "",
        VEHICLE_SYS_ID: "",
        MAKE_NAME: "",
      },
      motor_quotaion_details: {
        EXISITING_INSURER: "",
        PREVIOUS_REGISTRATION_NO: "",
        RENEWAL_NCD: "",
        COVER_TYPE: "",
        QUOTATION_REMARKS: "",
        FINANCE_COMPANY_SYS_ID: "",
      },
      view_quotation: [],
      selectedFile: null,
      selectedFileName: "",
      customer_name: "",
      DisplayCustomerType: "",
      DisplayCustomerName: "",
      DisplayCustomerAddress: "",
      DisplayIdNumber: "",
      DisplayCustomerEmail: "",
      DisplayCustomerContact: "",
      address: "",
      CUSTOMER_SYS_ID: null,
      all_customer_array: [],
      all_vehicle_details: [],
      QUOTATION_SYS_ID: "",
      selected_workshop_pdf: [],
      selected_company_pdf: [],
      selected_data_pdf: [],
      fields: [
        { key: "comapny_name", label: "Company Name" },
        { key: "work_shop_name", label: "Work Shop Name" },
        { key: "work_shop_amount", label: "Premium Amount" },
      ],
      finace_company_list: [],
      finace_company_name: "",
      current_date: "",
      ATTACHMENT_FILE_NAME : "",
      preview_pdf_url : "",
      isDisabledQuotatinFile : false,
    };
  },

  mounted() {
    this.getQuotaionDetails();
    this.getManufracturename();
    const today = moment(new Date());
    this.current_date = today.format("DD-MM-YYYY");
  },

  methods: {
    getQuotaionDetails() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      this.axios
        .get(apiEndPoints.view_quotation, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 1,
            PAGE_SIZE: 20,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.user_detail = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.user_detail = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    loadVehicleDetails(isVisible) {
      if (isVisible) {
        this.getInsurer(this.vehicledetails_page_no);
      }
    },

    getManufracturename() {
      this.axios
        .get(apiEndPoints.view_vehicle_manufacture_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          this.Manufracturename = response.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMakeName(id) {
      let existed_make = this.Manufracturename.find(
        (make) => id == make.VEHICLE_MAKE_SYS_ID
      );
      if (existed_make) {
        this.vehicledetails.MAKE_NAME = existed_make.VEHICLE_MAKE;
      }
    },

    setDistributor(details = null) {
      this.workshop_details.DISTRIBUTOR_WORKSHOP = {
        TAIPING: details ? details.TAIPING : "",
        TAIPING_EXCESS: details ? details.TAIPING_EXCESS : "",
        NTUC: details ? details.NTUC : "",
        NTUC_EXCESS: details ? details.NTUC_EXCESS : "",
        LONPAC: details ? details.LONPAC : "",
        LONPAC_EXCESS: details ? details.LONPAC_EXCESS : "",
        ETIQA: details ? details.ETIQA : "",
        ETIQA_EXCESS: details ? details.ETIQA_EXCESS : "",
        ERGO: details ? details.ERGO : "",
        ERGO_EXCESS: details ? details.ERGO_EXCESS : "",
        ECICS: details ? details.ECICS : "",
        ECICS_EXCESS: details ? details.ECICS_EXCESS : "",
        RECORD_SYS_ID: details ? details.RECORD_SYS_ID : 0,
      };
    },

    setAnyWorkshop(details = null) {
      this.workshop_details.ANY_WORKSHOP = {
        TAIPING: details ? details.TAIPING : "",
        TAIPING_EXCESS: details ? details.TAIPING_EXCESS : "",
        NTUC: details ? details.NTUC : "",
        NTUC_EXCESS: details ? details.NTUC_EXCESS : "",
        LONPAC: details ? details.LONPAC : "",
        LONPAC_EXCESS: details ? details.LONPAC_EXCESS : "",
        ETIQA: details ? details.ETIQA : "",
        ETIQA_EXCESS: details ? details.ETIQA_EXCESS : "",
        ERGO: details ? details.ERGO : "",
        ERGO_EXCESS: details ? details.ERGO_EXCESS : "",
        ECICS: details ? details.ECICS : "",
        ECICS_EXCESS: details ? details.ECICS_EXCESS : "",
        RECORD_SYS_ID: details ? details.RECORD_SYS_ID : 0,
      };
    },

    setAuthorized(details = null) {
      this.workshop_details.AUTHORISED_WORKSHOP = {
        TAIPING: details ? details.TAIPING : "",
        TAIPING_EXCESS: details ? details.TAIPING_EXCESS : "",
        NTUC: details ? details.NTUC : "",
        NTUC_EXCESS: details ? details.NTUC_EXCESS : "",
        LONPAC: details ? details.LONPAC : "",
        LONPAC_EXCESS: details ? details.LONPAC_EXCESS : "",
        ETIQA: details ? details.ETIQA : "",
        ETIQA_EXCESS: details ? details.ETIQA_EXCESS : "",
        ERGO: details ? details.ERGO : "",
        ERGO_EXCESS: details ? details.ERGO_EXCESS : "",
        ECICS: details ? details.ECICS : "",
        ECICS_EXCESS: details ? details.ECICS_EXCESS : "",
        RECORD_SYS_ID: details ? details.RECORD_SYS_ID : 0,
      };
    },

    setRemarks(details = null) {
      this.REMARKS = {
        TAIPING_EXCESS_REMARKS: details ? details.TAIPING_EXCESS_REMARKS : "",
        NTUC_EXCESS_REMARKS: details ? details.NTUC_EXCESS_REMARKS : "",
        LONPAC_EXCESS_REMARKS: details ? details.LONPAC_EXCESS_REMARKS : "",
        ETIQA_EXCESS_REMARKS: details ? details.ETIQA_EXCESS_REMARKS : "",
        ERGO_EXCESS_REMARKS: details ? details.ERGO_EXCESS_REMARKS : "",
        ECICS_EXCESS_REMARKS: details ? details.ECICS_EXCESS_REMARKS : "",
      };
    },

    resetWorkShopDetails() {
      this.setDistributor();
      this.setAnyWorkshop();
      this.setAuthorized();
      this.setRemarks();
    },

    getQuotation(QUOTATION_SYS_ID) {
      this.resetWorkShopDetails();
      this.axios
        .get(apiEndPoints.view_quotation, {
          params: {
            ITEM: "SPECIFIC",
            // RECORD_SYS_ID: "104",
            RECORD_SYS_ID: QUOTATION_SYS_ID,
            PAGE_NO: 1,
            PAGE_SIZE: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            let api_response = response.data.response;
            this.ATTACHMENT_FILE_NAME = api_response.ATTACHMENT_FILE_NAME;
            console.log(this.ATTACHMENT_FILE_NAME);
            if(this.ATTACHMENT_FILE_NAME == null || this.ATTACHMENT_FILE_NAME == "null"){
               this.isDisabledQuotatinFile = false;
            }
            else{
               this.isDisabledQuotatinFile = true;
               this.$refs.QuotationFile.value = null;
            }
            
            if (
              api_response.WORKSHOP_DETAILS &&
              api_response.WORKSHOP_DETAILS.length > 0
            ) {
              if (api_response.REMARKS) {
                this.setRemarks(api_response.REMARKS);
              }
              api_response.WORKSHOP_DETAILS.forEach((item) => {
                if (item.DISTRIBUTOR_WORKSHOP) {
                  this.setDistributor(item.DISTRIBUTOR_WORKSHOP);
                }
                if (item.ANY_WORKSHOP) {
                  this.setAnyWorkshop(item.ANY_WORKSHOP);
                }
                if (item.AUTHORISED_WORKSHOP) {
                  this.setAuthorized(item.AUTHORISED_WORKSHOP);
                }
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async Dele(data) {
      const result = await Global.showConfirmationAlert(
        `DELETE THIS ENTRY?`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.QUOTATION_SYS_ID,
          CREATED_BY: user_details.UserId,
        };
        this.axios
          .post(apiEndPoints.Add_Quotation, postarrydata)
          .then((response) => {
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getQuotaionDetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            // this.isProductModalLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },

    onLogIn() {
      this.$router.replace("/addquotation");
    },

    //approve part
    AddApproval() {
      if (!this.selected_workshop) {
        Global.showErrorAlert(
          true,
          "error",
          "Please Select At least one Workshop"
        );
        return;
      }
      if (!this.selected_company) {
        Global.showErrorAlert(
          true,
          "error",
          "Please Select At least one Company"
        );
        return;
      }
      if (
        !this.workshop_details[this.selected_workshop][this.selected_company]
      ) {
        Global.showErrorAlert(
          true,
          "error",
          "Premium Amount should not be zero for Selected Workshop and Company"
        );
        return;
      }
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let data = {};
      data.CREATED_BY = user_details.UserId;
      data.ITEM = "APPROVAL";
      data.RECORD_SYS_ID = this.QUOTATION_SYS_ID;
      // data.VEHICLE_SYS_ID = this.update_user.VEHICLE_NO;
      data.VEHICLE_SYS_ID = this.vehicledetails.VEHICLE_SYS_ID;

      data.PREMIUM_AMOUNT =
        this.workshop_details[this.selected_workshop][this.selected_company] ||
        0;
      data.WORKSHOP_NAME = this.selected_workshop.replaceAll("_", " ");
      data.COMPANY_NAME = this.selected_company;
      data.INSURANCE_TYPE = this.motor_quotaion_details.COVER_TYPE;

      this.axios
        .post(apiEndPoints.Add_Quotation, data)
        .then((response) => {
          if (response.data.status == "true") {
            this.showA = false;
            Global.showSuccessAlert(true, "success", response.data.response);
            this.closeModal("#rokerupdate_modal");
            this.getQuotaionDetails();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    uploadQuotaion() {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let input_data = {
        ITEM: "UPDATE",
        RECORD_SYS_ID: this.QUOTATION_SYS_ID,
        EXISITING_INSURER: this.motor_quotaion_details.EXISITING_INSURER,
        PREVIOUS_REGISTRATION_NO:
          this.motor_quotaion_details.PREVIOUS_REGISTRATION_NO,
        RENEWAL_NCD: this.motor_quotaion_details.RENEWAL_NCD,
        COVER_TYPE: this.motor_quotaion_details.COVER_TYPE,
        FINANCE_COMPANY_SYS_ID:
          this.motor_quotaion_details.FINANCE_COMPANY_SYS_ID,
        FINANCE_COMPANY: this.finace_company_name,
        QUOTATION_REMARKS: this.motor_quotaion_details.QUOTATION_REMARKS,
        CUSTOMER_SYS_ID: this.CUSTOMER_SYS_ID,
        MODEL_NAME: this.vehicledetails.MODEL_NAME,
        MANUFACTURE_YEAR: this.vehicledetails.MANUFACTURE_YEAR,
        ORIGINAL_REGISTRATION_DATE:
          this.vehicledetails.ORIGINAL_REGISTRATION_DATE,
        MAKE_SYS_ID: this.vehicledetails.MAKE_SYS_ID,
        MAKE_NAME: this.vehicledetails.MAKE_NAME,
        VEHICLE_NO: this.vehicledetails.VEHICLE_NO,
        WORKSHOP_DETAILS: this.workshop_details,
        REMARKS: this.REMARKS,
        CREATED_BY: user_details.UserId,
        WORKSHOP_TYPE: "",
        ATTACHMENT_FILE_NAME : this.ATTACHMENT_FILE_NAME,
      };

      this.axios
        .post(apiEndPoints.Add_Quotation, input_data)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.showA = true;
            //this.getQuotaionDetails();
            let file = this.$refs.QuotationFile.files[0];
            if(file != undefined) 
              this.uploadFile();
            else 
              this.getQuotation(this.QUOTATION_SYS_ID);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          console.error(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    showDetails(row, id) {
      this.view_quotation = row;
      $(id).modal("show");
    },

    showModal(row, id) {
      this.update_user = row;
      this.QUOTATION_SYS_ID = row.QUOTATION_SYS_ID;
      this.motor_quotaion_details = {
        EXISITING_INSURER: row.RENEWAL_NCD,
        PREVIOUS_REGISTRATION_NO: row.PREVIOUS_REGISTRATION_NO,
        RENEWAL_NCD: row.RENEWAL_NCD,
        COVER_TYPE: row.COVER_TYPE,
        FINANCE_COMPANY_SYS_ID: row.FINANCE_COMPANY_SYS_ID,
        QUOTATION_REMARKS: row.QUOTATION_REMARKS,
      };
      this.vehicledetails = {
        MODEL_NAME: row.MODEL_NAME,
        MANUFACTURE_YEAR: row.MANUFACTURE_YEAR,
        ORIGINAL_REGISTRATION_DATE: row.ORIGINAL_REGISTRATION_DATE,
        MAKE_SYS_ID: row.MAKE_SYS_ID,
        VEHICLE_NO: row.VEHICLE_NO,
        VEHICLE_SYS_ID: row.VEHICLE_SYS_ID,
      };
      this.getMakeName(row.MAKE_SYS_ID);
      this.getAllCustomerDetails(row.CUSTOMER_SYS_ID);
      this.getQuotation(row.QUOTATION_SYS_ID);
      this.getFinanceCompnyList(row.FINANCE_COMPANY_SYS_ID);
      this.CUSTOMER_SYS_ID = this.update_user.CUSTOMER_SYS_ID;
      //  let A= this.update_user.STATUS;
      //   if(A=="Approved"){
      //   this.showA=false
      //  }
      //  else{
      //   this.showA=true
      //  }
      $(id).modal("show");
    },

    closeModal(id) {
      $(id).modal("hide");
      this.update_user = {};

      this.vehicledetails = {
        MODEL_NAME: "",
        MANUFACTURE_YEAR: "",
        ORIGINAL_REGISTRATION_DATE: "",
        MAKE_SYS_ID: "",
        VEHICLE_NO: "",
        VEHICLE_SYS_ID: "",
        MAKE_NAME: "",
      };
      this.selectedFile = null;
      this.selectedFileName = "";
      this.customer_name = "";
      this.DisplayCustomerType = "";
      this.DisplayCustomerName = "";
      this.DisplayCustomerEmail = "";
      this.DisplayCustomerAddress = "";
      (this.DisplayCustomerContact = ""), (this.DisplayIdNumber = "");
      this.address = "";
      this.CUSTOMER_SYS_ID = null;
      this.motor_quotaion_details = {
        EXISITING_INSURER: "",
        PREVIOUS_REGISTRATION_NO: "",
        RENEWAL_NCD: "",
        COVER_TYPE: "",
        QUOTATION_REMARKS: "",
        FINANCE_COMPANY_SYS_ID: "",
      };
      this.finace_company_name = "";
      this.QUOTATION_SYS_ID = "";
      (this.selected_company = null),
        (this.selected_workshop = null),
        (this.workshop_details = {
          DISTRIBUTOR_WORKSHOP: {},
          ANY_WORKSHOP: {},
          AUTHORISED_WORKSHOP: {},
        });
      this.REMARKS = {
        TAIPING_EXCESS_REMARKS: "",
        NTUC_EXCESS_REMARKS: "",
        LONPAC_EXCESS: "",
        ETIQA_EXCESS_REMARKS: "",
        ERGO_EXCESS_REMARKS: "",
        ECICS_EXCESS_REMARKS: "",
      };
    },

    downLoadQuitation() {
      this.selected_data_pdf = [];
      let selected_data_pdf = [];
      this.selected_workshop_pdf.forEach((data) => {
        if (
          this.selected_company_pdf &&
          this.selected_company_pdf.length != 0
        ) {
          this.selected_company_pdf.forEach((comapny) => {
            if (this.workshop_details[data][comapny]) {
              selected_data_pdf.push({
                comapny_name: comapny,
                work_shop_amount: this.workshop_details[data][comapny],
                work_shop_name: data,
                excess: this.workshop_details[data][comapny + "_EXCESS"],
              });
            }
          });
        }
      });
      let newArray = [];
      selected_data_pdf.forEach((item) => {
        if (item.comapny_name === "TAIPING") {
          item["comapny_name"] = "CHINA TAIPING";
          newArray.push(item);
        } else {
          newArray.push(item);
        }
      });
      this.selected_data_pdf = selected_data_pdf;
      $("#staticBackdrop-1").modal("show");
    },

    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    onKeyUpCustomerInfo() {
      if (this.customer_name == "") {
        this.DisplayCustomerType = "";
        this.DisplayIdNumber = "";
        this.DisplayCustomerName = "";
        this.DisplayCustomerEmail = "";
        this.DisplayCustomerAddress = "";
        this.DisplayCustomerContact = "";
      }
    },

    onChnageCustomerInfo() {
      let CustomerIdNumber = this.customer_name;
      let cusObj = this.all_customer_array.find(
        (items) => items.CUSTOMER_NAME == CustomerIdNumber
      );
      if (cusObj) {
        this.DisplayCustomerType = cusObj.CUSTOMER_TYPE;
        this.CUSTOMER_SYS_ID = cusObj.CUSTOMER_SYS_ID;
        this.DisplayIdNumber = cusObj.ID_NUMBER;
        this.DisplayCustomerName = cusObj.CUSTOMER_NAME;
        this.DisplayCustomerEmail = cusObj.CUSTOMER_EMAIL;
        let address =
          cusObj.ADDRESS_LINE_1 +
          " ," +
          cusObj.COUNTRY_NAME +
          "-" +
          cusObj.PIN_CODE;
        this.DisplayCustomerAddress = address;
        this.DisplayCustomerContact = cusObj.CUSTOMER_CONTACT;
      }
    },

    //Get All Customer Info
    getAllCustomerDetails(CUSTOMER_SYS_ID) {
      NProgress.start();
      NProgress.set(0.1);
      this.loading = true;
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
            PAGE_SIZE: 20,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.all_customer_array = response.data.response;
            if (CUSTOMER_SYS_ID) {
              let existed_customer = response.data.response.find(
                (item) => item.CUSTOMER_SYS_ID == CUSTOMER_SYS_ID
              );
              if (existed_customer) {
                this.DisplayCustomerType = existed_customer.CUSTOMER_TYPE;
                this.customer_name = existed_customer.CUSTOMER_NAME;
                this.CUSTOMER_SYS_ID = existed_customer.CUSTOMER_SYS_ID;
                this.DisplayIdNumber = existed_customer.ID_NUMBER;
                this.DisplayCustomerName = existed_customer.CUSTOMER_NAME;
                this.DisplayCustomerEmail = existed_customer.CUSTOMER_EMAIL;
                let address =
                  existed_customer.ADDRESS_LINE_1 +
                  " ," +
                  existed_customer.COUNTRY_NAME +
                  "-" +
                  existed_customer.PIN_CODE;
                this.DisplayCustomerAddress = address;
                this.DisplayCustomerContact = existed_customer.CUSTOMER_CONTACT;
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          NProgress.done();
          this.loading = false;
        });
    },

    getFinanceCompnyList(id) {
      this.axios
        .get(apiEndPoints.view_finance_company_bank, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.finace_company_list = response.data.response;
            if (id) {
              this.getFinanceCompanyName(id);
            }
          }
        });
    },

    getFinanceCompanyName(id) {
      let existed_finance_company = this.finace_company_list.find(
        (items) => items.FINANCE_COMPANY_BANK_SYS_ID == id
      );
      if (existed_finance_company) {
        this.finace_company_name =
          existed_finance_company.FINANCE_COMPANY_BANK_NAME;
      } else {
        this.finace_company_name = "";
      }
    },

    handleQuotationFileObject() {
      let file = this.$refs.QuotationFile.files[0];
      let fileName = file.name;
      let fileExtension = fileName.split(".").pop();
      const today = new Date();
      const date = today.getFullYear() + "_" + (today.getMonth() + 1) + "_" + today.getDate();
      const time = today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
      let reFileName = this.QUOTATION_SYS_ID + "_" + date + "_" + time + "_" + "QUOTATION";
      this.ATTACHMENT_FILE_NAME = reFileName;
    },

    uploadFile(){
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let input_data = {
        ITEM: "FILE_UPLOAD",
        RECORD_SYS_ID: this.QUOTATION_SYS_ID,
        ATTACHMENT_FILE_NAME : this.ATTACHMENT_FILE_NAME,
        CREATED_BY: user_details.UserId,
      };
      this.axios
        .post(apiEndPoints.Add_Quotation, input_data)
        .then((response) => {
          console.log(response);
          if (response.data.status == "true") {
            this.ATTACHMENT_FILE_NAME = response.data.FILE_NAME;
            let file = this.$refs.QuotationFile.files[0];
            this.QUOTATION_FILE = new File([file], this.ATTACHMENT_FILE_NAME, { type: file.type, });
            console.log(this.QUOTATION_FILE);
            this.uploadQuotationFile();
          } 
        })
        .catch((err) => {
          console.error(err);
        });
    },

    uploadQuotationFile() {
      let formData = new FormData();
      formData.append( "QUOTATION", this.QUOTATION_FILE );
      formData.append("ITEM", "QUOTATION");
      this.axios
        .post(apiEndPoints.file_upload, formData, {
          headers: {
            "Content-Type":
              "multipart/form-data; charset=utf-8; boundary=" +
              Math.random().toString().substr(2),
          },
        })
        .then((response) => {
          console.log(response);
          if(response.data.status == "true"){
             this.getQuotation(this.QUOTATION_SYS_ID);
             

          }
          
        })
        .catch((err) => {});
    },

    previewLogCardPdfFile() {
      let file_name = "";
      this.preview_pdf_url = "";
      file_name = this.ATTACHMENT_FILE_NAME;
      let item_name = "QUOTATION";
      this.axios({
        url: apiEndPoints.file_download,
        method: "GET",
        responseType: "blob",
        params: {
          ITEM: item_name,
          FILE_NAME: file_name,
        },
      }).then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        this.preview_pdf_url = objectUrl;
      });
    },
  },
  computed: {
    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
  },
};
</script>

<style scoped>
.loading_main_background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  background: rgba(42, 42, 43, 0.45);
  z-index: 9999;
}
.preloder img {
  width: auto;
  margin: 12% 38%;
  border-radius: 50%;
  opacity: 0.6;
}
.loading_main_background img {
  width: 5% !important;
  margin: 20% 50% !important;
  opacity: 1 !important;
  position: absolute;
}
.pdf-content .content .content-header .heading {
  border: 1px solid #212121;
  padding: 10px 15px;
  font-size: 17px;
  font-weight: 700;
  margin-top: 50px;
  text-align: center;
}
.pdf-content .content .content-body {
  border-top: 3px solid #212121;
  border-bottom: 3px solid #212121;
  padding: 10px 0 20px 0;
  margin: 15px 0;
}
</style>
