import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
import VueHtml2pdf from "vue-html2pdf";
export const view_vehicledetails = {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      system_role_details: [],
      addmasterdata: {
        ITEM: "UPDATE",
        RECORD_SYS_ID: "0",
        VEHICLE_STATUS: "",
        VEHICLE_NUMBER: "",
        TEMPORARY_VEHICLE_NUMBER: "",
        MAKE_SYS_ID: "",
        MAKE_NAME: "",
        MODEL_SYS_ID: "",
        MODEL_NAME: "",
        MODEL_SHORT_NAME: "",
        PRICE_LIST_STATUS: "",
        BODY_TYPE_SYS_ID: "",
        BODY_TYPE: "",
        PRICE_LIST_PLUS: "",
        ACCESSORY: "",
        ASKING_PRICE: "",
        CHASIS_NO: "",
        ENGINE_NO: "",
        PROPELLANT: "",
        COLOR_SYS_ID: "",
        COLOR_NAME: "",
        LADEN: "",
        UNLADEN: "",
        ENGINE_CAP_TON: "",
        PAX: "",
        MANUFACTURE_YEAR: "",
        ORIGINAL_REGISTRATION_DATE: "",
        TSF_NUMBER: "",
        INCHARGE_BY_BROKER_SYS_ID: "",
        CREATED_BY: "",
        BULK_COUNT: "",

        COE_EXPIRY_DATE: "",
        COE_NUMBER: "",
        COE_TO_PAY: "",
        OPEN_MARKET_VALUE: "",
        PARF_AMOUNT: "",
        REGISTRATION_FEE: "",
        ARF_AMOUNT: "",
        CEVS_SURCHARGE: "",
        CEVS_REBATE: "",
        ETS_PAPER_FROM: "",
        USE_TCOE: "",
        COE_LOG_GUARD: "",
        COE_ACC_DPQP: "",
      },
      truex: false,
      addupdatesellerdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        ID_NUMBER: "",
        AGREEMENT_NUMBER: "",
        PURCHASE_DATE: "",
        sellertype: "",
        CUSTOMER_TYPE: null,
        SUB_CUSTOMER_TYPE: "",
        //CUSTOMER_CODE:"",
        vendorcode: "",
        //         BANK:"rt",
        AMOUNT: "0",
        //         REMARKS:"riju",
        PIN_CODE: "",
        AP_PAYMENT: "0",
        //         COUNTRY_NAME:"USA",
        //         CHEQUE_NUMBER:"74PO90",
        //         CUSTOMER_NAME:"VENDOR",
        //         ADDRESS_LINE_1:"KOLKATA",
        //         ADDRESS_LINE_2:"HOOGLY",
        //         COUNTRY_SYS_ID:"2",
        //         P_SETTLEMENT_REMARK:"RT",
        SALES_PERSON_SYS_ID: "58",
        //         TO_FROM_WHO:"RIJU",
        //         CHEQUE_DATE:"2022-09-16",
        //      RETURN_DATE_1:"2022-09-14",
        ADMIN_FEE: "",

        ID_NUMBER: "",
        SETTLEMENT_NUMBER: "",
        ESTIMATE_DELIVERY_IN_DATE: "",
        DELIVERY_IN_DATE: "",
        DELIVERY_IN_TIME: "",
        DN_CN_NUMBER: "",
        DN_CN_AMOUNT: "",
        NOTE: "",
        EXCLUDE_COE: "",
        EXCLUDE_REGISTRATION_FEE_AND_ARF: "",
        EXCLUDE_ROAD_TAX: "",
        PURCHASE_PRICE_IS_ZERO: "",
        PURCHASE_PRICE_SD: "",
        GST: "",
        GST_AMOUNT: "",
        GST_DECIMAL_ADJUSTMENT: "",
        GST_AMOUNT_BEFORE_ADJUSTMENT: "",
        TOTAL_PURCHASE_PRICE: "",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "",
        TAX_SYS_ID: "",
        ASKING_PRICE_SD: "",
        E_TRANSFER_BY: "",
        LOG_CARD_TRANSFER_IN: "",
        ALTERNATE_PRICE_SD: "",
        ALTERNATE: "",
        BUY_CODE: "",
        TRADE_IN_BY: "",
        BROKER_1: "",
        COM_RTN_1: "",
        BROKER_2: "",
        COM_RTN_2: 0,
        //RETURN_DATE_2: "",
        TRANSACTION_TYPE: "",
        CUSTOMER_SYS_ID: "",
        ID_NUMBER: "",
        PURCHASE_OUTSIDE_COMMISSION: "",
        NO_DELIVERY_DATE: "",
        BROKER_DEAL: "",
      },
      data: "",
      create_vendor: {
        ITEM: "ADD",

        CREATED_BY: "",
        RECORD_SYS_ID: "0",

        VENDOR_CODE: "",

        VENDOR_NAME: "",

        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        POST_CODE: "",
      },
      addupdatesellerapdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        MODULE: "SELLER",
        PAYMENT_DESC: "",
        AMOUNT: "",
        BALANCE: "",
        PAYMENT_MODE: "",
        BANK_NAME: "",
        BANK_SYS_ID: "",
        CHEQUE_DATE: "",
        CHEQUE_NO: "",
        TO_FROM_WHOM: "",
        SETTLEMENT_REMARKS: "",
        REMARKS: "",
        CREATED_BY: "",
      },
      truez: false,
      updat_user: {},
      create_company: {
        ITEM: "ADD",
        CUSTOMER_TYPE: "",
        COMPANY_NAME: "",
        PERSON_INCHARGE: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "",
        COUNTRY_SYS_ID: "1",
        RECORD_SYS_ID: "0",
        OFFICE_NUMBER: "",
        CREATED_BY: "",
        ID_NUMBER: "",
        GMAIL_ID: "",
      },
      insurance_company: {},
      system_details: {},
      update_user: {},
      create_customer: {
        ITEM: "ADD",
        NRIC_NUMBER: "",
        CREATED_BY: "",
        RECORD_SYS_ID: "0",
        CUSTOMER_TYPE: "",
        FIRST_NAME: "",
        LAST_NAME: "",
        CUSTOMER_CONTACT: "",
        CUSTOMER_ADDRESS_SYS_ID: "1",
        ADDRESS_LINE_1: "",
        ADDRESS_LINE_2: "",
        PIN_CODE: "",
        COUNTRY_NAME: "",
        COUNTRY_SYS_ID: "1",
        ID_NUMBER: "",
        GMAIL_ID: "",
      },

      create_user: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        CREATED_BY: "",
        VEHICLE_NUMBER: "",
        VEHICLE_SYS_ID: "",
        COST_TYPE: "",
        DESCRIPTION: "",
        TO_FROM: "",
        PAYMENT_MODE: "",
        AMOUNT_BEF_GST: "",
        DETAIL: "",
        LATE_RENEWAL_FEE: "",
        TAX_SYS_ID: "",

        GST_AMOUNT: "",
        TOTAL_AMOUNT: "",
      },
      loan_info: {
        ITEM: "ADD",
        RECORD_SYS_ID: "",
        LOAN_AMOUNT: "",
        LOAN_TENURE: "",
        INTEREST_RATE: "",
        FINANCE_COMPANY: "",
        FIN_COMP_SYS_ID: "",
        ADVANCED_PAYMENT: "",
        INTEREST_AMOUNT: "",
        SUBTOTAL: "",
        HP_FACILITIES_FEE: "",
        LOAN_COMMISSION: "",
        SALES_COMMISSION: "",
        LOAN_COMMISSION_PERCENTAGE: "",
      },
      taxapdetails: {},
      update_user: {},
      vendorname: false,
      vendoraddress: false,
      customername: false,
      customeraddress: false,

      pdf_det: {},
      addupdatebuyerdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        ID_NUMBER: 0,
        CUSTOMER_TYPE: "",
        SUB_CUSTOMER_TYPE: "",
        SALES_OUTSIDE_COMMISSION: "",
        DELIVERY_OUT_DATE: "26-07-2022",

        BUYER_TYPE: "",
        TIME: "",
        SELL_CODE: "",
        SALES_PERSON: "",

        INVOICE_NUMBER: "",
        INVOICE_DATE: "",
        P_L_DATE: "",
        BOOKING_DATE: "",
        SELL_CODE: "",
        SALES_AGREEMENT_NUMBER: "",
        SALE_AGGREEMENT_PRICE: 0,
        SELLING_PRICE: "",
        BROKER_1: "",
        GST: "",
        GST_AMOUNT: "",
        GST_DECIMAL_ADJUSTMENT: "",
        GST_AMOUNT_BEFORE_ADJUSTMENT: "",
        TOTAL_SELLING_PRICE: "",
        CREATED_BY: "",
        TOTAL_RECEIVABLE: "",
        TRANSACTION_TYPE: "",
        TOTAL_RECEIVED: "",
        E_TRANSFER_OUT_DATE: "",
        AR_BALANCE: "",
        TRANSFER_OUT_BY: "",
        REMARKS: "",
        LOG_CARD_TRANSFER_OUT: "",
        STATUS: "",
        SELLER_SYS_ID: "1",
        TAX_SYS_ID: "",
        PURCHASE_OUTSIDE_COMMISSION: "",
        BROKER_1_SYS_ID: "",
        BROKER_2_SYS_ID: "",
        SALES_PERSON_SYS_ID: 0,
        DEPOSIT: "0",
        HP_FACILITIES_FEE: "",
      },
      updatetaxinvoice: {},
      addupdatebuyerapdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 4,
        MODULE: "BUYER",
        PAYMENT_DESC: "",
        AMOUNT: "",
        BALANCE: "",
        ID_NUMBER1: "",
        ID_NUMBER: "",
        VENDORCODE: "",
        CUSTOMERTYPE: "",
        PAYMENT_MODE: "",
        BANK_NAME: "",
        BANK_SYS_ID: "",
        ADMIN_FEE: "",
        CHEQUE_DATE: "",
        CHEQUE_NO: "",
        TO_FROM_WHOM: "",
        SETTLEMENT_REMARKS: "",
        REMARKS: "",
        CREATED_BY: "",
        REDUCED_RECEIVABLE_AMOUNT: "",
      },
      addtaxinvoice: {
        DESCRIPTION: "",
        DISCOUNT_VALUE: "",
        TAX_VALUE: "",
        TAX_SYS_ID: "",
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        TOTAl_PRICE: "",
        UNIT_PRICE: "",
        VEHICLE_SYS_ID: "",
      },
      addupdateetsdata: {
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        VEHICLE_DETAILS_SYS_ID: "",
        BUY_OVER_DATE: "",
        FIGHT_PL: "",
        ETS_PAPER_TO: "",
        BODY_PRICE: "",
        DEREG_DATE: "",
        COE_ENCASHMENT: "",
        PARF_ENCASHMENT: "",
        ADMIN_FEE: "",
        ETS_TRANSFER_VALUE: "",
        ETS_PAPER_EXTERNAL: "",
        INVOICE_NUMBER_2: "",
        INVOICE_DATE_2: "",
        ETS_PAPER_BUYER: "",

        CREATED_BY: "",
      },
      addcreditnote: {
        ITEM: "CREDIT_NOTE",
        RECORD_SYS_ID: "",

        QTY: "",
        DESCRIPTION: "",
        UNIT_PRICE: "",
        AMOUNT: "",
      },

      sellerapdetails: {},
      buyerapdetails: {},
      statusvalue: false,
      numbervalue: false,
      makevalue: false,
      modelvalue: false,
      vehicledetails: {},
      FIN_COMPANY_SYS_ID: {},
      vehicledetails_page_no: 1,
      user_details: [],
      system_role_detai: {},
      vechile_color_details: [],
      Manufracturename: {},
      vechile_color_details: {},
      Modeltype: {},
      brokerdetails: {},
      MODELNAME: "",
      MAKENAME: "",
      STATUS: "",
      VEHICLENUMBER: "",
      vendorcode: "",
      vendordetails: {},
      ID_NUMBER: "",
      customerdetails: {},
      sellertype: "",
      customertype: "",
      //for buyer type
      vendorcode_: "",
      vendordetails_: {},
      ID_NUMBER_: "",
      customerdetails_: {},
      sellertype_: "",
      customertype_: "",
      //end
      isDisabled: false,
      isDisable: false,
      vehicletype: "",
      disabled: true,
      update_user: {},
      tax_info_detai: {},
      cost_type_info: {},
      user_detail: {},
      tax_info_details: {},
      cost_type_info_details: {},
      Manufracturename: "",
      insurance_company_details: {},
      Coloursname: "",
      Bodytype: "",
      Modeltype_: {},

      system_role_deta: {},
      Banklist: {},
      etsfromdisable: false,
      falsetrue: false,
      Vehiclelocations: "",
      brokerdetails: "",
      pdf_details: {},
      pdf_detail: {},
      pdf_detai: {},
      pdf_deta: {},
      pdf_de: {},
      VEHICLE_NO: "",
      canShowCreditNote: true,
      canShowSalesNote: true,
      canShowPurchaseNote: true,
      canShowTaxNote: true,
      show_add_loan_info: false,
      trade_info: [
        {
          ITEM: "ADD",
          RECORD_SYS_ID: 0,
          VEHICLE_SYS_ID: "",
          TRADE_IN_VEH_NO: "",
          TRADE_IN_AMOUNT: "",
          PAYMENT_MADE: "",
          FULL_SETTLEMENT_REIMBURSEMENT: "",
          CREATED_BY: null,
        },
      ],
    };
  },
  mounted() {
    $(document).ready(function () {
      // $("#left_modal_sm").modal('show');
      $("#dark-mode").click(function () {
        $("body").addClass("darkmodeactivated");
        $("#dark-mode").css("display", "none");
        $("#light-mode").css("display", "block");
        $(".light-mood-logo").css("display", "none");
        $(".dark-mood-logo").css("display", "block");
      });

      $("#light-mode").click(function () {
        $("body").removeClass("darkmodeactivated");
        $("#dark-mode").css("display", "block");
        $("#light-mode").css("display", "none");
        $(".light-mood-logo").css("display", "block");
        $(".dark-mood-logo").css("display", "none");
      });

      $("#collapse_btn").click(function () {
        $(".left-card-wrap").toggleClass("shrink");
        $(".right-card-wrap").toggleClass("expand");
        $(".ver-tab-txt").toggleClass("ver-tab-txt-d-none");
        $(".fa-angle-left").toggleClass("rotate");
      });

      $("#srch_btn").click(function () {
        $(".fixed-search-panel").toggleClass("active-search-panel");

        $("#left_modal_sm").modal("show");
      });

      $(".add_btn").click(function () {
        $("#right_modal_xl").modal("show");
      });

      $("#srch-btn").click(function () {
        $(".fixed-search-panel").removeClass("active-search-panel");
        $(".nav-transparent-bg").removeClass("nav-transparent-bg-active");
        $(".fixed-search-panel").removeClass("animate__animated");
        $("#srch_btn").css("display", "block");
        $(".show-hide-panel").addClass("panel-active");
        $("#left_modal_sm").modal("hide");
      });

      $(".show-single-panel-btn").click(function () {
        $(".tab-panel").slideToggle("slow");
        $(".show-single-panel-btn").toggleClass("rotate");
      });
      $(document).on("change", ".rad-input", function () {
        var _this = $(this);
        var _val = _this.filter(":checked").val();
        if (_val == "All") {
          $(".div_1,.div_2,.div_3,.div_4,.div_5,.div_6").show();
        } else if (_val == "FY") {
          $(".div_1,.div_6").show();
          $(".div_2,.div_3,.div_4,.div_5").hide();
        } else if (_val == "program") {
          $(".div_3,.div_6").show();
          $(".div_2,.div_5,.div_1,.div_4").hide();
        } else if (_val == "project") {
          $(".div_4,.div_6").show();
          $(".div_2,.div_1,.div_3,.div_5").hide();
        } else if (_val == "partner") {
          $(".div_5,.div_6").show();
          $(".div_2,.div_1,.div_3,.div_4").hide();
        } else {
        }
      });
    });
    $(document).ready(function () {
      $(".box").hide();
      $("#customertype")
        .change(function () {
          $(this)
            .find("option:selected")
            .each(function () {
              var optionValue = $(this).attr("value");
              if (optionValue) {
                $(".box")
                  .not("." + optionValue)
                  .hide();
                $("." + optionValue).show();
              } else {
                $(".box").hide();
              }
            });
        })
        .change();
    });
    $(".box2").hide();
    $("#customertypeupdate")
      .change(function () {
        $(this)
          .find("option:selected")
          .each(function () {
            var optionValue = $(this).attr("value");
            if (optionValue) {
              $(".box2")
                .not("." + optionValue)
                .hide();
              $("." + optionValue).show();
            } else {
              $(".box2").hide();
            }
          });
      })
      .change();
    this.getCountryInfo();
    this.getPriceDetails();
    this.getvehicledetails();
    this.getManufracturename();
    this.getmodeltype();
    this.getvendordetails();
    this.getcustomerdetails();
    //this.getCountryInfo(),
    //this.getInsuranceCompanyDetails(),
    //this.getVechileColorDetails(),
    this.getTaxInf();
    //this.getCreditNoteDetail(vehicle_detail);
    this.getLoanDetails();
    //this.getvendordetai()
    // this.getManufracturename();
    this.getcolour();
    this.getbodytype();
    this.getSystemRoleDetails();
    // this.getvehiclelocation();
    this.getbrokerdetails();
    this.getcustomerdetails_();
    this.getbrokerdeta();
    this.getvendordetails_();
    this.getTaxInfo();
  },
  computed: {
    selectedDate() {
      if (this.addmasterdata.ORIGINAL_REGISTRATION_DATE) {
        return this.formatDate(this.addmasterdata.ORIGINAL_REGISTRATION_DATE);
      }
      return "";
    },
    system_role_groups() {
      //console.log("hi");
      const gr = [];
      this.system_role_details.forEach((g) => {
        if (!gr.includes(g.BODY_TYPE)) gr.push(g.BODY_TYPE);
      });
      return gr;
    },
    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
  },
  methods: {
    onLoanInfoFinCompanyChange() {
      this.loan_info.FIN_COMPANY = this.system_role_deta.find(
        (role) => role.FINANCE_COMPANY_SYS_ID == this.loan_info.FIN_COMP_SYS_ID
      ).FINANCE_COMPANY;
    },
    getGroupDetails(group) {
      return this.system_role_details.filter((role) => role.BODY_TYPE == group);
    },
    generateRandomColor() {
      let maxVal = 0xffffff;
      let randomNumber = Math.random() * maxVal;
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`;
    },
    getGroupStyles() {
      let color = this.generateRandomColor();
      return {
        "background-color": color,
        padding: "5px 6px !important",
        "border-width": "3px",
        "border-color": "#fff",
      };
    },
    exportExce() {
      const tHeader = [Makname];

      const filterVal = [MAKE_NAME];
      let system_role_groups = [];
      this.system_role_groups.forEach((role) => {
        this.getGroupDetails(role).forEach((item, index) => {
          if (index > 0) {
            item.BODY_TYPE = "";
          }
          system_role_groups.push(item);
        });
      });
      const data = this.formatJson(filterVal, system_role_groups);
      this.generateXlsx(tHeader, data, "PL-STATEMENT");
    },
    vehicletypelebel() {
      if (
        this.addmasterdata.VEHICLE_STATUS == "ETS" ||
        this.addmasterdata.VEHICLE_STATUS == "Pre Owned"
      ) {
        this.vehicletype = "Old Vehicle";
      } else if (
        this.addmasterdata.VEHICLE_STATUS == "New Vehicle" ||
        this.addmasterdata.VEHICLE_STATUS == "Pre Order"
      ) {
        this.vehicletype = "New Vehicle";
      } else if (this.addmasterdata.VEHICLE_STATUS == "Ordered") {
        this.vehicletype = "Old Vehicle";
      }
      if (this.addmasterdata.VEHICLE_STATUS == "ETS") {
        this.etsfromdisable = true;
      } else {
        //this.etsfromdisable = false;
      }
    },
    onLogIn() {
      this.$router.replace("/adminmasteradd-data");
    },
    filtershowhide() {
      if (this.STATUS != "") {
        this.statusvalue = true;
      } else {
        this.statusvalue = false;
      }
      if (this.VEHICLENUMBER != "") {
        this.numbervalue = true;
      } else {
        this.numbervalue = false;
      }
      if (this.MAKENAME != "") {
        this.makevalue = true;
      } else {
        this.makevalue = false;
      }
      if (this.MODELNAME != "") {
        this.modelvalue = true;
      } else {
        this.modelvalue = false;
      }
    },
    getManufracturename() {
      this.axios
        .get(apiEndPoints.view_vehicle_manufacture_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("make", response);
          this.Manufracturename = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getmodeltype() {
      this.axios
        .get(apiEndPoints.view_vehicle_model_type, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("MODEL");
          //console.log(response);
          this.Modeltype = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    hasInGroup(group_name) {
      //console.log("1");
      if (group_name == this.row_indetifier) {
        return true;
      }
      this.row_indetifier = group_name;
      return false;
    },
    getGroupDetails(group) {
      //console.log("1");
      return this.system_role_details.filter((role) => role.BODY_TYPE == group);
    },
    generateRandomColor() {
      //console.log("1");
      let maxVal = 0xffffff;
      let randomNumber = Math.random() * maxVal;
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`;
    },
    getGroupStyles() {
      //console.log("1");
      let color = this.generateRandomColor();
      return {
        "background-color": color,
        padding: "60px 6px !important",
        margin: 0,
        "border-width": "3px",
        "border-color": "#fff",
      };
    },
    getvehicledetails(page_no = 1) {
      this.filtershowhide();
      //console.log("riju7", page_no);
      this.axios
        .get(apiEndPoints.view_vehicle_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            VEHICLE_STATUS: this.STATUS,
            VEHICLE_NUMBER: this.VEHICLENUMBER,
            VEHICLE_MAKE: this.MAKENAME,
            VEHICLE_MODEL: this.MODELNAME,
            PAGE_SIZE: 8,
            PAGE_NO: page_no,
          },
        })
        .then((response) => {
          //console.log("RIJUVAI56", response);

          if (response.data.status == "true") {
            if (page_no == 1) {
              this.vehicledetails = [];
            }
            this.vehicledetails = [
              ...this.vehicledetails,
              ...response.data.response,
            ];
            this.vehicledetails_page_no += 1;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            if (page_no == 1) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    loadVehicleDetails(isVisible) {
      if (isVisible) {
        //console.log("test47", this.vehicledetails_page_no);
        this.getvehicledetails(this.vehicledetails_page_no);
      }
    },

    getDebitNoteDetails(vehicle_detail) {
      if (!vehicle_detail.VEHICLE_NUMBER) {
        Global.showErrorAlert(true, "error", "Vehicle Number Not Found.");
        $("#staticBackdrop-1").modal("hide");
        return false;
      }
      this.axios
        .get(apiEndPoints.view_debit_note_info, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: vehicle_detail.VEHICLE_NUMBER || "",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("r", response);
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
            $("#staticBackdrop-1").modal("hide");
          } else {
            this.pdf_details = response.data.response[0];
            $("#staticBackdrop-1").modal("show");
          }
          //this.vehicledetails = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    generateReport() {
      this.$refs.htmlPdf.generatePdf();
    },
    //Credit note
    getCreditNoteDetails(vehicle_detail) {
      if (!vehicle_detail.VEHICLE_NUMBER) {
        Global.showErrorAlert(true, "error", "Vehicle Number Not Found.");
        $("#staticBackdrop-2").modal("hide");
        // this.canShowCreditNote = false;
        return false;
      }
      //console.log("riju");
      this.axios
        .get(apiEndPoints.view_credit_note_info, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: vehicle_detail.VEHICLE_NUMBER || "",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("riju", response.data.response[0]);
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
            $("#staticBackdrop-2").modal("hide");
          } else {
            this.pdf_detail = response.data.response[0];
            $("#staticBackdrop-2").modal("show");
            // this.canShowCreditNote = true;
            //this.vehicledetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    // getCreditNoteDetail(vehicle_detail) {

    //   if (!vehicle_detail.VEHICLE_NUMBER) {
    //     Global.showErrorAlert(true, "error", "Vehicle Number Not Found.");
    //     $('#staticBackdrop-2').modal('hide');
    //    // this.canShowCreditNote = false;
    //     return false;
    //   }
    //   //console.log("riju");
    //   this.axios
    //     .get(apiEndPoints.view_credit_note_info, {
    //       params: {
    //         ITEM: "SPECIFIC",
    //         VEHICLE_NO: vehicle_detail.VEHICLE_NUMBER || "",
    //         RECORD_SYS_ID: 0,
    //       },
    //     })
    //     .then((response) => {
    //       //console.log("riju", response.data.response);
    //       if (response.data.status == "false") {
    //         Global.showErrorAlert(true, "error", response.data.response);
    //         $('#staticBackdrop-2').modal('hide');
    //       }
    //       else{
    //         this.pdf_detail = response.data.response[0];
    //       $("#staticBackdrop-2").modal("show");
    //      // this.canShowCreditNote = true;
    //       //this.vehicledetails = response.data.response;
    //       }
    //     })
    //     .catch((err) => {
    //       //console.log(err);
    //     });
    // },
    generateReportcredit() {
      //console.log("click me");
      this.$refs.html2Pdf.generatePdf();
    },
    //DDL BIND
    //sale recipt
    getSalesNoteDetails(vehicle_detail) {
      //console.log("riju");
      if (!vehicle_detail.VEHICLE_NUMBER) {
        Global.showErrorAlert(true, "error", "Vehicle Number Not Found.");
        $("#staticBackdrop-3").modal("hide");
        //this.canShowSalesNote = false;
        return false;
      }

      this.axios
        .get(apiEndPoints.view_sales_note_info, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("z", response.data.response[0]);
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
            $("#staticBackdrop-3").modal("hide");
          } else {
            this.pdf_detai = response.data.response[0];
            $("#staticBackdrop-3").modal("show");
            //this.canShowSalesNote = true;
            //this.vehicledetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    generateReportsales() {
      //console.log("click me");
      this.$refs.htmlPdf2.generatePdf();
    },
    getTaxNoteDetails(vehicle_detail) {
      //console.log("riju");
      if (!vehicle_detail.VEHICLE_NUMBER) {
        Global.showErrorAlert(true, "error", "Vehicle Number Not Found.");
        $("#staticBackdrop-4").modal("hide");

        return false;
      }
      this.axios
        .get(apiEndPoints.view_tax_note_info, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log(response.data.response[0]);
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
            $("#staticBackdrop-4").modal("hide");
          } else {
            this.pdf_deta = response.data.response[0];
            $("#staticBackdrop-4").modal("show");

            //this.vehicledetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    gettax(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.Tax_detailes, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: RECORD_SYS_ID,
            PAGE_SIZE: 20,
            PAGE_NO: 1,
            //P_MODULE: "SELLER",
          },
        })
        .then((response) => {
          //console.log("appayment4", response);

          if (response.data.status == "true") {
            this.taxapdetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    gettaxinvoice(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.Tax_detailes, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: RECORD_SYS_ID,
            PAGE_SIZE: 20,
            PAGE_NO: 1,
            //P_MODULE: "SELLER",
          },
        })
        .then((response) => {
          //console.log("appayment4", response);

          if (response.data.status == "true") {
            this.taxapdetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    generateReportTax() {
      //console.log("click me");
      this.$refs.htmlPdf3.generatePdf();
    },
    getPlStatmentDetails(vehicle_detail) {
      //console.log("riju");
      if (!vehicle_detail.VEHICLE_NUMBER) {
        Global.showErrorAlert(true, "error", "Vehicle Number Not Found.");
        $("#staticBackdrop-4").modal("hide");

        return false;
      }
      this.axios
        .get(apiEndPoints.pl_statment, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          this.system_role_details = response.data.response;
          //console.log("pl", response.data.response[0]);
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          } else {
            this.system_role_detals = response.data.response[0];

            //this.vehicledetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getPurchasesNoteDetails(vehicle_detail) {
      //console.log("purchase seetlement");
      if (!vehicle_detail.VEHICLE_NUMBER) {
        Global.showErrorAlert(true, "error", "Vehicle Number Not Found.");
        $("#staticBackdrop-6").modal("hide");

        return false;
      }
      this.axios
        .get(apiEndPoints.purchase_settlement, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
            VEHICLE_NO: "null",
          },
        })
        .then((response) => {
          //console.log("purchase", response);
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
            $("#staticBackdrop-6").modal("hide");
          } else {
            this.pdf_de = response.data.response[0];
            $("#staticBackdrop-6").modal("show");
            //this.vehicledetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    generateReportPurchase() {
      //console.log("click me");
      this.$refs.htmlPdf6.generatePdf();
    },
    //generate excel
    exportExcel() {
      //console.log("excel download");
      const t = ["Make", "Color", "Model", "Vehicle-number"];
      const filterVal = [
        "MAKE_NAME",
        "COLOR_NAME",
        "MODEL_NAME",
        "VEHICLE_NUMBER",
      ];
      let system_role_groups = [];
      this.system_role_groups.forEach((role) => {
        this.getGroupDetails(role).forEach((item, index) => {
          if (index > 0) {
            item.BODY_TYPE = "";
          }
          system_role_groups.push(item);
        });
      });
      const data = this.formatJson(filterVal, system_role_groups);
      this.generateXlsx(t, data, "pl_statement");
    },
    showhide() {
      //console.log("riju");
    },

    ViewSalesNoteDetails(vehicle_detail) {
      //console.log("rift");

      this.axios
        .get(apiEndPoints.view_sales_note_info, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("z", response.data.response[0]);
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          } else {
            this.pdf_detai = response.data.response[0];

            //this.canShowSalesNote = true;
            //this.vehicledetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getcolour() {
      this.axios
        .get(apiEndPoints.view_vehicle_color_name, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("M", response);
          this.Coloursname = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getbodytype() {
      this.axios
        .get(apiEndPoints.view_vehicle_body_type, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.Bodytype = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getmodeltype_() {
      this.axios
        .get(apiEndPoints.view_vehicle_model_type, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: this.addmasterdata.MAKE_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("2", response);
          this.Modeltype_ = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getvehiclelocation() {
      this.axios
        .get(apiEndPoints.view_vehicle_location, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          this.Vehiclelocations = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getbrokerdetails() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            // ITEM: "VIEW_ALL",
            // RECORD_SYS_ID: 0,
            ITEM: "SPECIFIC",
            USER_TYPE: "External",

            PAGE_SIZE: 100,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          //console.log("hi");
          //console.log(response);
          this.brokerdetails = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getbrokerdeta() {
      this.axios
        .get(apiEndPoints.view_user, {
          params: {
            // ITEM: "VIEW_ALL",
            // RECORD_SYS_ID: 0,
            ITEM: "SPECIFIC",
            USER_TYPE: "Internal",
            PAGE_SIZE: 100,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          //console.log("hii riju", response);
          this.brokerdeta = response.data.response;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //#region  get make name
    getmakeName() {
      this.addmasterdata.MAKE_NAME = this.Manufracturename.find(
        (o) => o.VEHICLE_MAKE_SYS_ID === this.addmasterdata.MAKE_SYS_ID
      ).VEHICLE_MAKE;
      this.getmodeltype_();
    }, //#endregion
    //#region  get modelshort name
    getmodelshortName() {
      this.addmasterdata.MODEL_SHORT_NAME = this.Modeltype_.find(
        (o) => o.VEHICLE_MODEL_SYS_ID === this.addmasterdata.MODEL_SYS_ID
      ).MODEL_SHORT_NAME;
      this.addmasterdata.MODEL_NAME = this.Modeltype_.find(
        (o) => o.VEHICLE_MODEL_SYS_ID === this.addmasterdata.MODEL_SYS_ID
      ).VEHICLE_MODEL;
    }, //#endregion
    //#region  get color name
    getcolorName() {
      this.addmasterdata.COLOR_NAME = this.Coloursname.find(
        (o) => o.VEHICLE_COLOR_SYS_ID === this.addmasterdata.COLOR_SYS_ID
      ).VEHICLE_COLOR;
    }, //#endregion
    //#region  get body name
    getbodyName() {
      this.addmasterdata.BODY_TYPE = this.Bodytype.find(
        (o) =>
          o.VEHICLE_BODY_TYPE_SYS_ID === this.addmasterdata.BODY_TYPE_SYS_ID
      ).VEHICLE_BODY_TYPE;
    }, //#endregion
    //engin cap calculation
    engincap() {
      var laden = parseInt(this.addmasterdata.LADEN);
      var unladen = parseInt(this.addmasterdata.UNLADEN);
      this.addmasterdata.ENGINE_CAP_TON = laden - unladen;
    },
    //Bank list
    getBanklist() {
      this.axios
        .get(apiEndPoints.bank_name_api, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);

          if (response.data.status == "true") {
            this.Banklist = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.Banklist = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //#region  get bank name
    getbankName() {
      this.addupdatesellerapdata.BANK_NAME = this.Banklist.find(
        (o) => o.BANK_SYS_ID === this.addupdatesellerapdata.BANK_SYS_ID
      ).BANK_NAME;
    }, //#endregion
    updatebankname() {
      this.updat_user.BANK_NAME = this.Banklist.find(
        (o) => o.BANK_SYS_ID === this.updat_user.BANK_SYS_ID
      ).BANK_NAME;
    },
    getbankName_buyer() {
      this.addupdatebuyerapdata.BANK_NAME = this.Banklist.find(
        (o) => o.BANK_SYS_ID === this.addupdatebuyerapdata.BANK_SYS_ID
      ).BANK_NAME;
    }, //#endregion
    //#region  get bank name
    // getbankName() {
    //   this.addupdatesellerapdata.BANK_NAME = this.tax_info_details.find(o => o.BANK_SYS_ID === this.addupdatesellerapdata.BANK_SYS_ID).BANK_NAME;

    // }, //#endregion
    //gst list
    getTaxInfo() {
      this.axios
        .get(apiEndPoints.tax_info, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          //this.tax_info_details = response.data.response;
          if (response.data.status == "true") {
            this.tax_info_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.tax_info_details = [];
            // Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //#region  get body name
    getgstName() {
      this.addupdatesellerdata.GST = this.tax_info_details.find(
        (o) => o.TAX_SYS_ID === this.addupdatesellerdata.TAX_SYS_ID
      ).TAX_PERCENTAGE;
      this.gstcalculation();
    }, //#endregion
    gstcalculation() {
      let aount = this.addupdatesellerdata.PURCHASE_PRICE_SD;
      let gstpercentage = this.addupdatesellerdata.GST;
      let gst = (aount * gstpercentage) / 100;
      this.addupdatesellerdata.GST_AMOUNT = gst;
      this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = Math.ceil(
        (aount ? parseFloat(aount) : 0) + (gst ? parseFloat(gst) : 0)
      );
    },
    //decimaladjustment
    decimaladjustment() {
      let gstamount = this.addupdatesellerdata.GST_AMOUNT;
      this.addupdatesellerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = gstamount;
      let gstafteradjustment =
        parseFloat(gstamount) -
        parseFloat(this.addupdatesellerdata.GST_DECIMAL_ADJUSTMENT || 0);
      let aount = this.addupdatesellerdata.PURCHASE_PRICE_SD;

      let commission = this.addupdatesellerdata.PURCHASE_OUTSIDE_COMMISSION;
      this.addupdatesellerdata.TOTAL_PURCHASE_PRICE = Math.ceil(
        (aount ? parseFloat(aount) : 0) +
          (gstafteradjustment ? parseFloat(gstafteradjustment) : 0) +
          (commission ? parseFloat(commission) : 0)
      );
    },

    //for buyer gst calculation
    getgstName_() {
      this.addupdatebuyerdata.GST = this.tax_info_details.find(
        (o) => o.TAX_SYS_ID === this.addupdatebuyerdata.TAX_SYS_ID
      ).TAX_PERCENTAGE;
      this.gstcalculation_();
    }, //#endregion
    gstcalculation_() {
      let aount = this.addupdatebuyerdata.SELLING_PRICE;
      let gstpercentage = this.addupdatebuyerdata.GST;
      let gst = (aount * gstpercentage) / 100;
      this.addupdatebuyerdata.GST_AMOUNT = gst;
      this.addupdatebuyerdata.TOTAL_SELLING_PRICE = Math.ceil(
        (aount ? parseFloat(aount) : 0) + (gst ? parseFloat(gst) : 0)
      );
    },
    decimaladjustment_() {
      let gstamount = this.addupdatebuyerdata.GST_AMOUNT;
      this.addupdatebuyerdata.GST_AMOUNT_BEFORE_ADJUSTMENT = gstamount;
      let gstafteradjustment =
        parseFloat(gstamount) -
        parseFloat(this.addupdatebuyerdata.GST_DECIMAL_ADJUSTMENT || 0);
      let aount = this.addupdatebuyerdata.SELLING_PRICE;

      let commission = this.addupdatebuyerdata.PURCHASE_OUTSIDE_COMMISSION;
      this.addupdatebuyerdata.TOTAL_SELLING_PRICE = Math.ceil(
        (aount ? parseFloat(aount) : 0) +
          (gstafteradjustment ? parseFloat(gstafteradjustment) : 0) +
          (commission ? parseFloat(commission) : 0)
      );
    },
    //engin cap calculation
    engincaps() {
      var ladens = parseInt(this.addmasterdata.LADEN);
      var unladen = parseInt(this.addmasterdata.UNLADEN);
      this.addmasterdata.ENGINE_CAP_TON = laden - unladen;
    },

    getcalculation() {
      //console.log("ha");
      var laden = this.addmasterdata.ASKING_PRICE;
      var unladen = this.addupdatesellerdata.ALTERNATE_PRICE_SD;
      this.addupdatesellerdata.ALTERNATE = laden - unladen;
    },
    getloancalculation() {
      //console.log("hayes");
      var laden = this.loan_info.LOAN_AMOUNT;
      var unladen = this.loan_info.LOAN_COMMISSION_PERCENTAGE;
      this.loan_info.LOAN_COMMISSION =
        (parseInt(laden) * parseInt(unladen)) / 100;
    },

    editfun(vehicle_detail) {
      //console.log("tyiu", vehicle_detail);
      this.getBanklist();
      this.getSalesNoteDetail(vehicle_detail);
      this.getsellerAPdetails(vehicle_detail.RECORD_SYS_ID);
      this.gettaxinvoice(vehicle_detail.RECORD_SYS_ID);
      this.getCountryInfo(vehicle_detail.RECORD_SYS_ID);
      this.getInsuranceCompanyDetails(vehicle_detail.RECORD_SYS_ID);
      this.getInsuranceCompany(vehicle_detail.RECORD_SYS_ID);
      this.getvehiclelocation(vehicle_detail.RECORD_SYS_ID);
      this.getVechileColorDetails(vehicle_detail.RECORD_SYS_ID);
      this.getvendordetai(vehicle_detail.RECORD_SYS_ID);
      this.getvehiclelocation(vehicle_detail.RECORD_SYS_ID);
      this.getsellerdetails(vehicle_detail);
      this.getbuyerdetails(vehicle_detail);
      this.getBuyerETSdetails(vehicle_detail);
      this.getBuyerAPdetails(vehicle_detail.RECORD_SYS_ID);
      this.axios
        .get(apiEndPoints.view_vehicle_details_for_edit, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("MSH", response);

          this.addmasterdata = response.data.response[0];
          this.addmasterdata.ORIGINAL_REGISTRATION_DATE = this.parseDate(
            this.addmasterdata.ORIGINAL_REGISTRATION_DATE
          );

          // this.addmasterdata.ROAD_TAX_EXPIRY_DATE=this.parseDate(this.addmasterdata.ROAD_TAX_EXPIRY_DATE);
          // this.addmasterdata.INSPECTION_EXPIRY_DATE=this.parseDate(this.addmasterdata.INSPECTION_EXPIRY_DATE);
          // this.addmasterdata.LAYUP_EXP_DATE=this.parseDate(this.addmasterdata.LAYUP_EXP_DATE);
          // this.addmasterdata.COE_EXPIRY_DATE=this.parseDate(this.addmasterdata.COE_EXPIRY_DATE);

          this.addupdatesellerdata.PURCHASE_DATE = this.parseDate(
            this.addupdatesellerdata.PURCHASE_DATE
          );
          this.addupdatesellerdata.ESTIMATE_DELIVERY_IN_DATE = this.parseDate(
            this.addupdatesellerdata.ESTIMATE_DELIVERY_IN_DATE
          );
          this.addupdatesellerdata.DELIVERY_IN_DATE = this.parseDate(
            this.addupdatesellerdata.DELIVERY_IN_DATE
          );
          this.addupdatesellerdata.E_TRANSFER_IN_DATE = this.parseDate(
            this.addupdatesellerdata.E_TRANSFER_IN_DATE
          );
          this.addupdatesellerdata.RETURN_DATE_2 = this.parseDate(
            this.addupdatesellerdata.RETURN_DATE_2
          );
          //this.addupdatesellerapdata.CHEQUE_DATE=this.parseDate(this.addupdatesellerapdata.CHEQUE_DATE);
          //this.addupdateetsdata.BUY_OVER_DATE=this.parseDate(this.addupdateetsdata.BUY_OVER_DATE);
          this.addupdatebuyerdata.BOOKING_DATE = this.parseDate(
            this.addupdatebuyerdata.BOOKING_DATE
          );
          this.addupdatebuyerdata.INVOICE_DATE = this.parseDate(
            this.addupdatebuyerdata.INVOICE_DATE
          );
          this.addupdatebuyerdata.P_L_DATE = this.parseDate(
            this.addupdatebuyerdata.P_L_DATE
          );
          this.addupdatebuyerdata.E_TRANSFER_OUT_DATE = this.parseDate(
            this.addupdatebuyerdata.E_TRANSFER_OUT_DATE
          );

          //this.addupdatebuyerdata.P_L_DATE=this.parseDate(this.addupdatebuyerdata.P_L_DATE);

          this.addmasterdata.TEMPORARY_VEHICLE_NO =
            response.data.response[0].TEMPORARY_VEHICLE_NUMBER;
          this.addmasterdata.TSF_NUMBER = response.data.response[0].OMV_E_TSF;
          this.getmodeltype_();
          this.vehicletypelebel();
          if (response.data.response[0].PRICE_LIST_STATUS == true) {
            this.addmasterdata.PRICE_LIST_STATUS = "Y";
          } else {
            this.addmasterdata.PRICE_LIST_STATUS = "N";
          }
        })
        .catch((err) => {
          //console.log(err);
        });
      // this.addmasterdata["RECORD_SYS_ID"]=vehicle_detail.RECORD_SYS_ID;
      // this.addmasterdata["CREATED_NAME"]=vehicle_detail.CREATED_NAME;
      /// this.addmasterdata["ENTRY_DATE"]=vehicle_detail.ENTRY_DATE;
      this.addupdatesellerdata["RECORD_SYS_ID"] = vehicle_detail.RECORD_SYS_ID;
      this.addupdatesellerapdata.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.addupdatebuyerapdata.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.create_user.VEHICLE_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.addtaxinvoice.VEHICLE_SYS_ID = vehicle_detail.RECORD_SYS_ID;
      this.loan_info.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;

      this.getVechileLoanDetails(vehicle_detail.RECORD_SYS_ID);
      this.getVechileTradeDetatils(vehicle_detail.RECORD_SYS_ID);
      // this.addmasterdata.TEMPORARY_VEHICLE_NO=vehicle_detail.TEMPORARY_VEHICLE_NUMBER;
    },

    getVechileTradeDetatils(vechile_id) {
      //console.log("trade");
      this.axios
        .get(apiEndPoints.view_trade, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vechile_id,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            //console.log("trade", response);
            let api_response = response.data.response;
            if (api_response && api_response.length > 0) {
              this.trade_info = [];
              api_response.forEach((item) => {
                this.trade_info.push({
                  ITEM: "UPDATE",
                  RECORD_SYS_ID: item.TRADE_IN_SYS_ID,
                  SEQUENCE_NO: item.SEQUENCE_NO,
                  TRADE_IN_VEH_NO: item.TRADE_IN_VEH_NO,
                  TRADE_IN_AMOUNT: item.TRADE_IN_AMOUNT,
                  PAYMENT_MADE: item.PAYMENT_MADE,
                  FULL_SETTLEMENT_REIMBURSEMENT:
                    item.FULL_SETTLEMENT_REIMBURSEMENT,
                  CREATED_BY: null,
                });
              });
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getVechileLoanDetails(vechile_id) {
      this.loan_info.LOAN_AMOUNT = "";
      this.loan_info.LOAN_TENURE = "";
      this.loan_info.INTEREST_RATE = "";
      this.loan_info.FINANCE_COMPANY = "";
      this.loan_info.FIN_COMP_SYS_ID = "";
      this.loan_info.ADVANCED_PAYMENT = "";
      this.loan_info.SUBTOTAL = "";
      THIS.loan_info.INTEREST_AMOUNT = "";
      this.axios
        .get(apiEndPoints.view_loan, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vechile_id,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            let loan_details = response.data.response[0];
            this.loan_info.ADVANCE_PAYMENT = loan_details.ADVANCE_PAYMENT;
            this.loan_info.FINANCE_COMPANY = loan_details.FINANCE_COMPANY;
            this.loan_info.FIN_COMP_SYS_ID = loan_details.FIN_COMP_SYS_ID;
            this.loan_info.INTEREST_RATE = loan_details.INTEREST_RATE;
            this.loan_info.LOAN_AMOUNT = loan_details.LOAN_AMOUNT;
            this.loan_info.LOAN_TENURE = loan_details.LOAN_TENURE;
            this.loan_info.INTEREST_AMOUNT = loan_details.INTEREST_AMOUNT;
            this.show_add_loan_info = true;
            this.loancalculator();
          } else {
            this.show_add_loan_info = false;
          }
          //console.log("Loan Info Apr view", response);
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    // this.$refs.vehicledetailsaddForm.validate().then((success) => {
    //  if(!success){
    //    Global.showErrorAlert(true, "error", "All Fields Are Required!");
    //   return false
    //  }else{
    AddUpdatevehicledetails() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addmasterdata["CREATED_BY"] = user_details.UserId;
      this.addmasterdata["ITEM"] = "UPDATE";
      this.addmasterdata["PRICE_LIST_STATUS"] =
        this.addmasterdata.PRICE_LIST_STATUS == true ? "Y" : "N";
      this.addmasterdata.ORIGINAL_REGISTRATION_DATE = this.formatDate(
        this.addmasterdata.ORIGINAL_REGISTRATION_DATE
      );

      // this.addmasterdata.ROAD_TAX_EXPIRY_DATE=this.formatDate(this.addmasterdata.ROAD_TAX_EXPIRY_DATE);
      // this.addmasterdata.INSPECTION_EXPIRY_DATE=this.formatDate(this.addmasterdata.INSPECTION_EXPIRY_DATE);
      // this.addmasterdata.LAYUP_EXP_DATE=this.formatDate(this.addmasterdata.LAYUP_EXP_DATE);
      // this.addmasterdata.COE_EXPIRY_DATE=this.formatDate(this.addmasterdata.COE_EXPIRY_DATE);

      //console.log("msh", JSON.stringify(this.addmasterdata));
      this.axios
        .post(apiEndPoints.add_vehicle_details, this.addmasterdata)
        .then((response) => {
          //console.log(response);
          this.addmasterdata.ORIGINAL_REGISTRATION_DATE = this.parseDate(
            this.addmasterdata.ORIGINAL_REGISTRATION_DATE
          );

          // this.addmasterdata.ROAD_TAX_EXPIRY_DATE=this.parseDate(this.addmasterdata.ROAD_TAX_EXPIRY_DATE);
          // this.addmasterdata.INSPECTION_EXPIRY_DATE=this.parseDate(this.addmasterdata.INSPECTION_EXPIRY_DATE);
          // this.addmasterdata.LAYUP_EXP_DATE=this.parseDate(this.addmasterdata.LAYUP_EXP_DATE);
          // this.addmasterdata.COE_EXPIRY_DATE=this.parseDate(this.addmasterdata.COE_EXPIRY_DATE);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
      // }

      // });
    },
    AddUpdaterecordsectiondetails() {
      // this.$refs.vehicledetailsaddForm.validate().then((success) => {
      //  if(!success){
      //    Global.showErrorAlert(true, "error", "All Fields Are Required!");
      //   return false
      //  }else{
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addmasterdata["CREATED_BY"] = user_details.UserId;
      this.addmasterdata["ITEM"] = "ADD";
      // this.addmasterdata.ROAD_TAX_EXPIRY_DATE=this.formatDate(this.addmasterdata.ROAD_TAX_EXPIRY_DATE);
      // this.addmasterdata.INSPECTION_EXPIRY_DATE=this.formatDate(this.addmasterdata.INSPECTION_EXPIRY_DATE);
      // this.addmasterdata.LAYUP_EXP_DATE=this.formatDate(this.addmasterdata.LAYUP_EXP_DATE);
      // this.addmasterdata.COE_EXPIRY_DATE=this.formatDate(this.addmasterdata.COE_EXPIRY_DATE);

      //console.log(JSON.stringify(this.addmasterdata));
      this.axios
        .post(apiEndPoints.add_update_record_section, this.addmasterdata)
        .then((response) => {
          //console.log(response);
          // this.addmasterdata.ROAD_TAX_EXPIRY_DATE=this.parseDate(this.addmasterdata.ROAD_TAX_EXPIRY_DATE);
          // this.addmasterdata.INSPECTION_EXPIRY_DATE=this.parseDate(this.addmasterdata.INSPECTION_EXPIRY_DATE);
          // this.addmasterdata.LAYUP_EXP_DATE=this.parseDate(this.addmasterdata.LAYUP_EXP_DATE);
          // this.addmasterdata.COE_EXPIRY_DATE=this.parseDate(this.addmasterdata.COE_EXPIRY_DATE);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
      // }

      // });
    },
    async DeleteSELLERAP(data) {
      const result = await Global.showConfirmationAlert(
        `Delete AP Details ${data.PAYMENT_DESC}`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.RECORD_SYS_ID,
          MODULE: data.MODULE,
          PAYMENT_DESC: data.PAYMENT_DESC,
          CREATED_BY: user_details.UserId,
        };

        //console.log(postarrydata);
        this.axios
          .post(apiEndPoints.add_update_ap_payment_details, postarrydata)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getsellerAPdetails(data.RECORD_SYS_ID);
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            // this.isProductModalLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    getsellerdetails(vehicle_detail) {
      this.axios
        .get(apiEndPoints.view_seller_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("riju7", response);
          if (response.data.status == "true") {
            let api_response = response.data.response[0];
            this.addupdatesellerdata = api_response;
            this.addupdatesellerdata.sellertype = api_response.CUSTOMER_TYPE;
            this.customertype = api_response.SUB_CUSTOMER_TYPE;
            this.customerdetails.COUNTRY_NAME = api_response.COUNTRY_NAME;
            this.customerdetails.CUSTOMER_NAME = api_response.CUSTOMER_NAME;
            this.customerdetails.ADDRESS_LINE_1 = api_response.ADDRESS_LINE_1;
            this.customerdetails.ADDRESS_LINE_2 = api_response.ADDRESS_LINE_2;
            this.customerdetails.PIN_CODE = api_response.PIN_CODE;

            if (response.data.response[0].NO_DELIVERY_DATE == "Y") {
              this.addupdatesellerdata.NO_DELIVERY_DATE = true;
            } else {
              this.addupdatesellerdata.NO_DELIVERY_DATE = false;
            }
            if (response.data.response[0].EXCLUDE_ROAD_TAX == "Y") {
              this.addupdatesellerdata.EXCLUDE_ROAD_TAX = true;
            } else {
              this.addupdatesellerdata.EXCLUDE_ROAD_TAX = false;
            }
            if (
              response.data.response[0].EXCLUDE_REGISTRATION_FEE_AND_ARF == "Y"
            ) {
              this.addupdatesellerdata.EXCLUDE_REGISTRATION_FEE_AND_ARF = true;
            } else {
              this.addupdatesellerdata.EXCLUDE_REGISTRATION_FEE_AND_ARF = false;
            }
            if (response.data.response[0].EXCLUDE_COE == "Y") {
              this.addupdatesellerdata.EXCLUDE_COE = true;
            } else {
              this.addupdatesellerdata.EXCLUDE_COE = false;
            }
            if (response.data.response[0].NO_DELIVERY_DATE == "Y") {
              this.addupdatesellerdata.NO_DELIVERY_DATE = true;
            } else {
              this.addupdatesellerdata.NO_DELIVERY_DATE = false;
            }
            if (response.data.response[0].PURCHASE_PRICE_IS_ZERO == "Y") {
              this.addupdatesellerdata.PURCHASE_PRICE_IS_ZERO = true;
            } else {
              this.addupdatesellerdata.PURCHASE_PRICE_IS_ZERO = false;
            }
            if (response.data.response[0].BROKER_DEAL == "Y") {
              this.addupdatesellerdata.BROKER_DEAL = true;
            } else {
              this.addupdatesellerdata.BROKER_DEAL = false;
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
      this.addupdatesellerdata["RECORD_SYS_ID"] = vehicle_detail.RECORD_SYS_ID;
      //this.getgstName();
    },
    addmaster() {
      //console.log("riju");
      this.addupdatesellerdata.PURCHASE_PRICE_SD =
        0 || this.addupdatesellerdata.PURCHASE_PRICE_SD == "";
      var vehiclestatus = this.addupdatesellerdata.PURCHASE_PRICE_SD;
      if (vehiclestatus == false) {
        this.addupdatesellerdata.PURCHASE_PRICE_SD = "";
        this.isDisable = false;
      } else {
        this.addupdatesellerdata.PURCHASE_PRICE_SD = "0";
        this.isDisable = true;
      }
    },
    AddUpdatesellerdetails() {
      // this.$refs.vehicledetailsaddForm.validate().then((success) => {
      //  if(!success){
      //    Global.showErrorAlert(true, "error", "All Fields Are Required!");
      //   return false
      //  }else{
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatesellerdata["CREATED_BY"] = user_details.UserId;
      this.addupdatesellerdata["ITEM"] = "ADD";
      this.addupdatesellerdata["EXCLUDE_ROAD_TAX"] =
        this.addupdatesellerdata.EXCLUDE_ROAD_TAX == true ? "Y" : "N";
      this.addupdatesellerdata["EXCLUDE_REGISTRATION_FEE_AND_ARF"] =
        this.addupdatesellerdata.EXCLUDE_REGISTRATION_FEE_AND_ARF == true
          ? "Y"
          : "N";
      this.addupdatesellerdata["EXCLUDE_COE"] =
        this.addupdatesellerdata.EXCLUDE_COE == true ? "Y" : "N";
      this.addupdatesellerdata["NO_DELIVERY_DATE"] =
        this.addupdatesellerdata.NO_DELIVERY_DATE == true ? "Y" : "N";
      this.addupdatesellerdata["PURCHASE_PRICE_IS_ZERO"] =
        this.addupdatesellerdata.PURCHASE_PRICE_IS_ZERO == true ? "Y" : "N";
      this.addupdatesellerdata["BROKER_DEAL"] =
        this.addupdatesellerdata.BROKER_DEAL == true ? "Y" : "N";
      this.addupdatesellerdata.PURCHASE_DATE = this.formatDate(
        this.addupdatesellerdata.PURCHASE_DATE
      );
      this.addupdatesellerdata.ESTIMATE_DELIVERY_IN_DATE = this.formatDate(
        this.addupdatesellerdata.ESTIMATE_DELIVERY_IN_DATE
      );
      this.addupdatesellerdata.DELIVERY_IN_DATE = this.formatDate(
        this.addupdatesellerdata.DELIVERY_IN_DATE
      );
      this.addupdatesellerdata.E_TRANSFER_IN_DATE = this.formatDate(
        this.addupdatesellerdata.E_TRANSFER_IN_DATE
      );
      this.addupdatesellerdata.RETURN_DATE_2 = this.formatDate(
        this.addupdatesellerdata.RETURN_DATE_2
      );
      //this.addupdatesellerapdata.CHEQUE_DATE=this.formatDate(this.addupdatesellerapdata.CHEQUE_DATE);

      //console.log(JSON.stringify(this.addupdatesellerdata));
      this.axios
        .post(apiEndPoints.add_update_seller_details, this.addupdatesellerdata)
        .then((response) => {
          //console.log(response);
          this.addupdatesellerdata.PURCHASE_DATE = this.parseDate(
            this.addupdatesellerdata.PURCHASE_DATE
          );
          this.addupdatesellerdata.ESTIMATE_DELIVERY_IN_DATE = this.parseDate(
            this.addupdatesellerdata.ESTIMATE_DELIVERY_IN_DATE
          );
          this.addupdatesellerdata.DELIVERY_IN_DATE = this.parseDate(
            this.addupdatesellerdata.DELIVERY_IN_DATE
          );
          this.addupdatesellerdata.E_TRANSFER_IN_DATE = this.parseDate(
            this.addupdatesellerdata.E_TRANSFER_IN_DATE
          );
          this.addupdatesellerdata.RETURN_DATE_2 = this.parseDate(
            this.addupdatesellerdata.RETURN_DATE_2
          );

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
      // }

      // });
    },
    getPriceDetails() {
      //console.log("PRICE");
      this.axios
        .get(apiEndPoints.item_description, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("item", response);
          if (response.data.status == "true") {
            this.system_details = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    //seller date
    showdate() {
      this.addupdatesellerdata.NO_DELIVERY_DATE = this.formatDate(
        this.addupdatesellerdata.NO_DELIVERY_DATE
      );
      this.isDisabled = this.addupdatesellerdata.NO_DELIVERY_DATE;
    },
    getfun() {
      this.isDisabled = this.addupdatesellerdata.BROKER_DEAL;
    },
    editsellerApdetails(data) {
      this.addupdatesellerapdata = data;
    },
    getsellerAPdetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.view_ap_payment_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
            P_MODULE: "SELLER",
          },
        })
        .then((response) => {
          //console.log("appayment", response);

          if (response.data.status == "true") {
            this.sellerapdetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    AddUpdatesellerapdetails() {
      // this.$refs.vehicledetailsaddForm.validate().then((success) => {
      //  if(!success){
      //    Global.showErrorAlert(true, "error", "All Fields Are Required!");
      //   return false
      //  }else{
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatesellerapdata["CREATED_BY"] = user_details.UserId;
      this.addupdatesellerapdata["ITEM"] = "ADD";
      this.addupdatesellerapdata["CUSTOMER_NAME"] =
        this.customerdetails.CUSTOMER_NAME;
      //this.addupdatesellerapdata["CUSTOMER_ADDRESS"] = this.customerdetails.ADDRESS_LINE_1;

      //console.log(JSON.stringify(this.addupdatesellerapdata));
      this.axios
        .post(
          apiEndPoints.add_update_ap_payment_details,
          this.addupdatesellerapdata
        )
        .then((response) => {
          //console.log(response);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.AddUpdatesellerap();
            this.DeleteSELLERAP();

            this.getsellerAPdetails(this.addupdatesellerapdata.RECORD_SYS_ID);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
      // }

      // });
    },
    AddUpdatesellerap() {
      //console.log("update");
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updat_user["CREATED_BY"] = user_details.UserId;
      this.updat_user["ITEM"] = "UPDATE";
      this.updat_user["RECORD_SYS_ID"] = this.updat_user.VEHICLE_SYS_ID;

      //console.log(JSON.stringify(this.updat_user));
      this.axios
        .post(apiEndPoints.add_update_ap_payment_details, this.updat_user)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //tax invoice
    // gettaxinvoice(RECORD_SYS_ID) {
    //   this.axios
    //     .get(apiEndPoints.Tax_detailes, {
    //       params: {
    //         ITEM: "VIEW_ALL",
    //         RECORD_SYS_ID: RECORD_SYS_ID,
    //         PAGE_SIZE:20,
    //         PAGE_NO:1
    //         //P_MODULE: "SELLER",
    //       },
    //     })
    //     .then((response) => {
    //       //console.log("appayment4", response);

    //       if (response.data.status == "true") {
    //         this.taxapdetails = response.data.response;
    //       }
    //     })
    //     .catch((err) => {
    //       //console.log(err);
    //     });
    // },
    getitemDes() {
      this.addtaxinvoice.DESCRIPTION = this.system_details.find(
        (o) => o.ITEM_LIST_SYS_ID === this.addtaxinvoice.ITEM_LIST_SYS_ID
      ).DESCRIPTION;
    },
    getitemDesUpdate() {
      this.updatetaxinvoice.DESCRIPTION = this.system_details.find(
        (o) => o.ITEM_LIST_SYS_ID === this.addtaxinvoice.ITEM_LIST_SYS_ID
      ).DESCRIPTION;
    },

    getgstN() {
      this.addtaxinvoice.TAX_PERCENTAGE = this.tax_info_details.find(
        (o) => o.TAX_SYS_ID === this.addtaxinvoice.TAX_SYS_ID
      ).TAX_PERCENTAGE;
      this.quentity();
    },
    quentity() {
      var amount = this.addtaxinvoice.UNIT_PRICE;
      var quentity = this.addtaxinvoice.DISCOUNT_VALUE;

      var gross = parseInt(amount) * parseInt(quentity);
      let gstpercentage = this.addtaxinvoice.TAX_PERCENTAGE;
      let gst = (gross * gstpercentage) / 100;
      this.addtaxinvoice.TAX_VALUE = gst;
      let grossAmount = parseInt(gross) + parseInt(gst);

      this.addtaxinvoice.TOTAL_PRICE = grossAmount;
    },
    AddTaxinvoice() {
      //console.log("update");
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addtaxinvoice["CREATED_BY"] = user_details.UserId;

      //console.log(JSON.stringify(this.updat_user));
      this.axios
        .post(apiEndPoints.Add_tax, this.addtaxinvoice)
        .then((response) => {
          //console.log("taxriju", response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.gettaxinvoice();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    async tax(data) {
      //console.log("delete");
      const result = await Global.showConfirmationAlert(
        `Delete  ${data.DESCRIPTION}`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarryda = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.RECORD_SYS_ID,
          CREATED_BY: user_details.UserId,
        };

        //console.log(postarryda);
        this.axios
          .post(apiEndPoints.Add_tax, postarryda)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              //$('#delete_frm').modal('hide')
              Global.showSuccessAlert(true, "success", response.data.response);
              this.gettaxinvoice();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            // this.isProductModalLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    gettaxinvoice(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.Tax_detailes, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: RECORD_SYS_ID,
            PAGE_SIZE: 20,
            PAGE_NO: 1,
            //P_MODULE: "SELLER",
          },
        })
        .then((response) => {
          //console.log("appayment5", response);

          if (response.data.status == "true") {
            this.taxapdetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getupdategstN() {
      this.updatetaxinvoice.TAX_PERCENTAGE = this.tax_info_details.find(
        (o) => o.TAX_SYS_ID === this.updatetaxinvoice.TAX_SYS_ID
      ).TAX_PERCENTAGE;
      this.quentit();
    },
    quentit() {
      //console.log("riju");
      var amount = this.updatetaxinvoice.UNIT_PRICE;
      var quentity = this.updatetaxinvoice.DISCOUNT_VALUE;

      var gross = parseInt(amount) * parseInt(quentity);
      let gstpercentage = this.updatetaxinvoice.TAX_PERCENTAGE;
      let gst = (gross * gstpercentage) / 100;
      this.updatetaxinvoice.TAX_VALUE = gst;
      let grossAmount = parseInt(gross) + parseInt(gst);

      this.updatetaxinvoice.TOTAL_PRICE = grossAmount;
    },

    getSalesNoteDetail(vehicle_detail) {
      //console.log("Sales Receipt");

      this.axios
        .get(apiEndPoints.view_sales_note_info, {
          params: {
            ITEM: "FINANCE_COMPANY",
            VEHICLE_NO: "null",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("apiresponse", response.data.response);
          if (response.data.status == "false") {
            Global.showErrorAlert(true, "error", response.data.response);
          } else {
            // let c=JSON.parse(response.data.response)
            this.pdf_detai = response.data.response;
            //console.log("xyz", response.data);
            // this.canShowCreditNote = true;
            //this.vehicledetails = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    AddTaxCreditnote() {
      ////console.log("update")
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addcreditnote["CREATED_BY"] = user_details.UserId;
      this.addcreditnote.RECORD_SYS_ID = this.create_user.VEHICLE_SYS_ID;

      //console.log(JSON.stringify(this.addcreditnote));
      this.axios
        .post(apiEndPoints.add_vehicle_details, this.addcreditnote)
        .then((response) => {
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            pdf_detail.push(this.addcreditnote);
            //console.log(response.data);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    async taxcredit(data) {
      //console.log("delete");
      const result = await Global.showConfirmationAlert(
        `Delete  ${data.DESCRIPTION}`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarryda = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.RECORD_SYS_ID,
          CREATED_BY: user_details.UserId,
        };

        //console.log(postarryda);
        this.axios
          .post(apiEndPoints.Add_tax, postarryda)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              //$('#delete_frm').modal('hide')
              Global.showSuccessAlert(true, "success", response.data.response);
              this.gettaxinvoice();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            // this.isProductModalLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    gettaxCreditinvoice() {
      this.axios
        .get(apiEndPoints.view_credit_note_info, {
          params: {
            ITEM: "SPECIFIC",
            VEHICLE_NO: vehicle_detail.VEHICLE_NUMBER || "",
            RECORD_SYS_ID: 0,
            //P_MODULE: "SELLER",
          },
        })
        .then((response) => {
          //console.log("appayment5", response);

          if (response.data.status == "true") {
            this.creditnote = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    updateTaxCreditnote() {
      //console.log("update");
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.updatetaxinvoice["ITEM"] = "UPDATE";
      this.updatetaxinvoice["RECOED_SYS_ID"] =
        this.updatetaxinvoice.RECORD_SYS_ID;
      this.updatetaxinvoice["CREATED_BY"] = user_details.UserId;

      //console.log(JSON.stringify(this.updatetaxinvoice));
      this.axios
        .post(apiEndPoints.Add_tax, this.updatetaxinvoice)
        .then((response) => {
          //console.log("taxriju", response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    showModa(row, id) {
      this.updat_user = row;
      //console.log("ty", this.updat_user);
      $(id).modal("show");
    },
    modalshow(row, id) {
      this.updat_user = row;
      //console.log("ty", this.updat_user);
      $(id).modal("show");
    },

    getbuyerdetails(vehicle_detail) {
      this.axios
        .get(apiEndPoints.view_buyer_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("buyer");
          //console.log(response);
          if (response.data.status == "true") {
            this.addupdatebuyerdata = response.data.response[0];
            this.addupdatebuyerdata.RECORD_SYS_ID =
              vehicle_detail.RECORD_SYS_ID;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
      this.addupdatebuyerdata.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
    },
    AddUpbuyerdetails() {
      // this.$refs.vehicledetailsaddForm.validate().then((success) => {
      //  if(!success){
      //    Global.showErrorAlert(true, "error", "All Fields Are Required!");
      //   return false
      //  }else{
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatebuyerdata["CREATED_BY"] = user_details.UserId;
      this.addupdatebuyerdata["ITEM"] = "ADD";
      this.addupdatebuyerdata["COM_RTN"] = "0";
      this.addupdatebuyerdata["COM_RTN_2"] = "0";

      this.addupdatebuyerdata.BOOKING_DATE = this.formatDate(
        this.addupdatebuyerdata.BOOKING_DATE
      );
      this.addupdatebuyerdata.INVOICE_DATE = this.formatDate(
        this.addupdatebuyerdata.INVOICE_DATE
      );
      this.addupdatebuyerdata.E_TRANSFER_OUT_DATE = this.formatDate(
        this.addupdatebuyerdata.E_TRANSFER_OUT_DATE
      );
      //this.addupdatebuyerdata.P_L_DATE=this.formatDate(this.addupdatebuyerdata.P_L_DATE);

      //console.log(JSON.stringify(this.addupdatebuyerdata));
      this.axios
        .post(apiEndPoints.add_update_buyer_details, this.addupdatebuyerdata)
        .then((response) => {
          //console.log(response);
          this.addupdatebuyerdata.BOOKING_DATE = this.parseDate(
            this.addupdatebuyerdata.BOOKING_DATE
          );
          this.addupdatebuyerdata.INVOICE_DATE = this.parseDate(
            this.addupdatebuyerdata.INVOICE_DATE
          );
          this.addupdatebuyerdata.E_TRANSFER_OUT_DATE = this.parseDate(
            this.addupdatebuyerdata.E_TRANSFER_OUT_DATE
          );
          //this.addupdatebuyerdata.P_L_DATE=this.parseDate(this.addupdatebuyerdata.P_L_DATE);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
      // }

      // });
    },
    Newbuyerdetails() {
      // this.$refs.vehicledetailsaddForm.validate().then((success) => {
      //  if(!success){
      //    Global.showErrorAlert(true, "error", "All Fields Are Required!");
      //   return false
      //  }else{
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatebuyerdata["CREATED_BY"] = user_details.UserId;
      this.addupdatebuyerdata["ITEM"] = "ADD";
      this.addupdatebuyerdata["COM_RTN"] = "0";
      this.addupdatebuyerdata["COM_RTN_2"] = "0";

      // this.addupdatebuyerdata.BOOKING_DATE=this.formatDate(this.addupdatebuyerdata.BOOKING_DATE);
      // this.addupdatebuyerdata.INVOICE_DATE=this.formatDate(this.addupdatebuyerdata.INVOICE_DATE);
      // this.addupdatebuyerdata.E_TRANSFER_OUT_DATE=this.formatDate(this.addupdatebuyerdata.E_TRANSFER_OUT_DATE);
      // this.addupdatebuyerdata.P_L_DATE=this.formatDate(this.addupdatebuyerdata.P_L_DATE);

      //console.log(JSON.stringify(this.addupdatebuyerdata));
      this.axios
        .post(apiEndPoints.add_update_buyer_details, this.addupdatebuyerdata)
        .then((response) => {
          //console.log("jaga");
          // this.addupdatebuyerdata.BOOKING_DATE=this.parseDate(this.addupdatebuyerdata.BOOKING_DATE);
          // this.addupdatebuyerdata.INVOICE_DATE=this.parseDate(this.addupdatebuyerdata.INVOICE_DATE);
          // this.addupdatebuyerdata.E_TRANSFER_OUT_DATE=this.parseDate(this.addupdatebuyerdata.E_TRANSFER_OUT_DATE);
          // this.addupdatebuyerdata.P_L_DATE=this.parseDate(this.addupdatebuyerdata.P_L_DATE);

          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
      // }

      // });
    },
    editbuyerApdetails(data) {
      this.addupdatebuyerapdata = data;
    },
    getBuyerAPdetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.view_ap_payment_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
            P_MODULE: "BUYER",
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.buyerapdetails = response.data.response;
            // this.addupdatebuyerapdata=response.data.response[0];
            // this.addupdatebuyerapdata.RECORD_SYS_ID=vehicle_detail.RECORD_SYS_ID;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
      // this.addupdatebuyerapdata.RECORD_SYS_ID=vehicle_detail.RECORD_SYS_ID;
    },
    AddUpdatebuyerapdetails() {
      // this.$refs.vehicledetailsaddForm.validate().then((success) => {
      //  if(!success){
      //    Global.showErrorAlert(true, "error", "All Fields Are Required!");
      //   return false
      //  }else{
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdatebuyerapdata["CREATED_BY"] = user_details.UserId;
      this.addupdatebuyerapdata["ITEM"] = "ADD";

      //console.log(JSON.stringify(this.addupdatebuyerapdata));
      this.axios
        .post(
          apiEndPoints.add_update_ap_payment_details,
          this.addupdatebuyerapdata
        )
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getBuyerAPdetails(this.addupdatebuyerapdata.RECORD_SYS_ID);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
      // }

      // });
    },
    getBuyerETSdetails(vehicle_detail) {
      //this.addupdateetsdata.BUY_OVER_DATE=this.formatDate(this.addupdateetsdata.BUY_OVER_DATE);

      this.axios
        .get(apiEndPoints.view_ets_details, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: vehicle_detail.RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //this.addupdateetsdata.BUY_OVER_DATE=this.parseDate(this.addupdateetsdata.BUY_OVER_DATE);

          if (response.data.status == "true") {
            this.addupdateetsdata = response.data.response[0];
            this.addupdateetsdata.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
            if (response.data.response[0].FIGHT_PL == "Y") {
              this.addupdateetsdata.FIGHT_PL = true;
            } else {
              this.addupdateetsdata.FIGHT_PL = false;
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
      this.addupdateetsdata.RECORD_SYS_ID = vehicle_detail.RECORD_SYS_ID;
    },
    getSystemRoleDetails() {
      this.axios
        .get(apiEndPoints.cost_type_info, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log(response);
          //this.system_role_details = response.data.response;
          if (response.data.status == "true") {
            this.system_role_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          );
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    AddInsurance() {
      //console.log("clickable");

      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_user["CREATED_BY"] = user_details.UserId;
      //console.log(JSON.stringify(this.create_user));

      this.axios
        .post(apiEndPoints.add_update_vehicle_cost, this.create_user)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getvendordetai();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        });
    },
    UpdateInsurance() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.update_user["CREATED_BY"] = user_details.UserId;
      this.update_user["ITEM"] = "UPDATE";
      this.update_user["RECORD_SYS_ID"] = this.update_user.VEHICLE_COST_SYS_ID;

      //console.log(JSON.stringify(this.update_user));
      this.axios
        .post(apiEndPoints.add_update_vehicle_cost, this.update_user)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
            this.getvendordetai();
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    async Delete(data) {
      const result = await Global.showConfirmationAlert(
        `Delete  ${data.COST_TYPE}`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.VEHICLE_COST_SYS_ID,
          CREATED_BY: user_details.UserId,
        };

        //console.log(postarrydata);
        this.axios
          .post(apiEndPoints.add_update_vehicle_cost, postarrydata)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getvendordetai();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            // this.isProductModalLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    getCountryInfo(RECORD_SYS_ID) {
      //console.log("purchase");
      this.axios
        .get(apiEndPoints.cost_purchase, {
          params: { ITEM: "SPECIFIC", RECORD_SYS_ID: RECORD_SYS_ID },
        })
        .then((response) => {
          //console.log("riju5", response);
          //this.cost_type_info_details = response.data.response;
          if (response.data.status == "true") {
            this.cost_type_info_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          );
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    getInsuranceCompanyDetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.cost_sales, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("costselesr", response);
          //this.insurance_company_details = response.data.response;
          if (response.data.status == "true") {
            this.insurance_company_details = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //for broker detailes
    getInsuranceCompany(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.broker_detailes, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("broker riju", response);
          //this.insurance_company_details = response.data.response;
          if (response.data.status == "true") {
            this.insurance_company = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getTaxInf() {
      //console.log("call");
      this.axios
        .get(apiEndPoints.tax_info, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("RIJU5", response);
          //this.tax_info_details = response.data.response;
          if (response.data.status == "true") {
            this.tax_info_detai = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.tax_info_detai = [];
            // Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    showhde() {
      //console.log("riju2");
      var vs = this.addupdatesellerapdata.PAYMENT_MODE;
      if (vs == "Cheque") {
        //console.log("true");
        this.truez = true;
      } else {
        this.truez = false;
      }
    },
    showhde() {
      //console.log("riju2");
      var vs = this.addupdatesellerapdata.PAYMENT_MODE;
      if (vs == "Cheque") {
        //console.log("true");
        this.truez = true;
      } else {
        this.truez = false;
      }
    },
    hideshow() {
      //console.log("riju2");
      var vs = this.updat_user.PAYMENT_MODE;
      if (vs == "Cheque") {
        //console.log("true");
        this.falsetrue = true;
      } else {
        this.falsetrue = false;
      }
    },
    show() {
      //console.log("riju2");
      var vs = this.addupdatebuyerapdata.PAYMENT_MODE;
      if (vs == "Cheque") {
        //console.log("true");
        this.truex = true;
      } else {
        this.truex = false;
      }
    },
    showbiz() {
      //console.log("zzz");
      var vs = this.addupdatesellerapdata.PAYMENT_MODE;
      if (vs == "Cheque") {
        //console.log("true");
        this.truex = true;
      } else {
        this.truex = false;
      }
    },
    getgstNa() {
      this.create_user.AMOUNT_BEF_GST = this.tax_info_detai.find(
        (o) => o.TAX_SYS_ID === this.create_user.TAX_SYS_ID
      ).TAX_PERCENTAGE;

      this.gstcalculatio();
    },
    gstcalculatio() {
      var aount = this.create_user.LATE_RENEWAL_FEE;
      var gstpercentage = this.create_user.AMOUNT_BEF_GST;
      var gst = (parseInt(aount) * parseInt(gstpercentage)) / 100;
      this.create_user.GST_AMOUNT = gst;
      this.create_user.TOTAL_AMOUNT = parseInt(aount) + parseInt(gst);
    },
    getgst() {
      this.update_user.AMOUNT_BEF_GST = this.tax_info_detai.find(
        (o) => o.TAX_SYS_ID === this.update_user.TAX_SYS_ID
      ).TAX_PERCENTAGE;

      this.gstcalculat();
    },
    gstcalculat() {
      var aount = this.update_user.LATE_RENEWAL_FEE;
      var gstpercentage = this.update_user.AMOUNT_BEF_GST;
      var gst = (parseInt(aount) * parseInt(gstpercentage)) / 100;
      this.update_user.GST_AMOUNT = gst;
      this.update_user.TOTAL_AMOUNT = parseInt(aount) + parseInt(gst);
    },
    loancalculator() {
      //var amount = this.loan_info.LOAN_AMOUNT || 0;
      //var gstpercentage = this.loan_info.ADVANCE_PAYMENT || 0;
      //var gst = parseInt(amount) - parseInt(gstpercentage);

      var amt = this.loan_info.LOAN_AMOUNT;
      var r = this.loan_info.INTEREST_RATE;
      var t = this.loan_info.LOAN_TENURE;
      var int = (parseInt(amt) * parseInt(r) * parseInt(t)) / 100;
      this.loan_info.INTEREST_AMOUNT = int;
      var total =
        (parseInt(amt) * parseInt(r) * parseInt(t)) / 100 + parseInt(amt);
      this.loan_info.SUBTOTAL = total;
    },
    quentityw() {
      //console.log("rtyuhgk");
      var amount = this.addcreditnote.UNIT_PRICE;
      var quentity = this.addcreditnote.QTY;

      var gross = parseInt(amount) * parseInt(quentity);
      this.addcreditnote.AMOUNT = gross;
    },
    AddExtraTradeInfo() {
      this.trade_info.push({
        ITEM: "ADD",
        RECORD_SYS_ID: 0,
        VEHICLE_SYS_ID: "",
        TRADE_IN_VEH_NO: "",
        TRADE_IN_AMOUNT: "",
        PAYMENT_MADE: "",
        FULL_SETTLEMENT_REIMBURSEMENT: "",
        CREATED_BY: null,
      });
    },
    AddTradeInfo(index) {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      let trade_info = this.trade_info[index];
      trade_info["CREATED_BY"] = user_details.UserId;
      trade_info.VEHICLE_SYS_ID = this.loan_info.RECORD_SYS_ID;
      trade_info["SEQUENCE_NO"] = index + 1;
      this.axios.post(apiEndPoints.add_trade, trade_info).then((response) => {
        if (response.data.status == "true") {
          Global.showSuccessAlert(true, "success", response.data.response);
        } else if (
          response.data.status == false ||
          response.data.status == "false"
        ) {
          Global.showErrorAlert(true, "error", response.data.response);
        }
      });
    },
    AddLoanInfo() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.loan_info["CREATED_BY"] = user_details.UserId;
      this.axios
        .post(apiEndPoints.add_loan, this.loan_info)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        });
    },
    getvendordetai(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.vehic_cost, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("UI", response);
          //this.system_role_details = response.data.response;
          if (response.data.status == "true") {
            this.system_role_detai = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    //loan
    getLoanDetails() {
      this.axios
        .get(apiEndPoints.finace_company, {
          params: {
            ITEM: "VIEW_ALL",
            RECORD_SYS_ID: 0,
          },
        })
        .then((response) => {
          //console.log("finance company", response);
          //this.system_role_details = response.data.response;
          if (response.data.status == "true") {
            this.system_role_deta = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.system_role_deta = [];
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    AddBroker() {
      //console.log("ty");
      //this.generate();
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      //console.log("riju47", this.user_details);
      this.create_user["CREATED_BY"] = user_details.UserId;
      //console.log(JSON.stringify(this.create_user));
      this.$refs.bankform.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All field Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.create_update_broker, this.create_user)
            .then((response) => {
              //console.log(response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getBrokerDetails();
                getSystemRoleDetails();
                this.emptyfu();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },
    //vehicle cost
    getVechileColorDetails(RECORD_SYS_ID) {
      this.axios
        .get(apiEndPoints.vehicle_cost, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: RECORD_SYS_ID,
          },
        })
        .then((response) => {
          //console.log("VEHICLE DETAILS", response);
          //this.vechile_color_details = response.data.response;
          if (response.data.status == "true") {
            this.vechile_color_details = response.data.response;
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    showModal(row, id) {
      this.update_user = row;
      //console.log(this.update_user);
      $(id).modal("show");
    },
    showModa(row, id) {
      this.updatetaxinvoice = row;
      //console.log(this.updatetaxinvoice);
      $(id).modal("show");
    },

    AddUpdatebuyerETSdetails() {
      // this.$refs.vehicledetailsaddForm.validate().then((success) => {
      //  if(!success){
      //    Global.showErrorAlert(true, "error", "All Fields Are Required!");
      //   return false
      //  }else{
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.addupdateetsdata["CREATED_BY"] = user_details.UserId;
      this.addupdateetsdata["ITEM"] = "ADD";
      this.addupdateetsdata["FIGHT_PL"] =
        this.addupdateetsdata.FIGHT_PL == true ? "Y" : "N";

      this.addupdateetsdata.BUY_OVER_DATE = this.formatDate(
        this.addupdateetsdata.BUY_OVER_DATE
      );
      this.addupdateetsdata.DEREG_DATE = this.formatDate(
        this.addupdateetsdata.DEREG_DATE
      );
      this.addupdateetsdata.INVOICE_DATE_2 = this.formatDate(
        this.addupdateetsdata.INVOICE_DATE_2
      );

      //this.addupdateetsdata.BUY_OVER_DATE
      //this.addupdateetsdata.DEREG_DATE
      //this.addupdateetsdata.INVOICE_DATE_2

      //console.log(JSON.stringify(this.addupdateetsdata));
      this.addupdateetsdata.BUY_OVER_DATE = this.parseDate(
        this.addupdateetsdata.BUY_OVER_DATE
      );
      this.addupdateetsdata.DEREG_DATE = this.parseDate(
        this.addupdateetsdata.DEREG_DATE
      );
      this.addupdateetsdata.INVOICE_DATE_2 = this.parseDate(
        this.addupdateetsdata.INVOICE_DATE_2
      );

      this.axios
        .post(apiEndPoints.add_update_ets_details, this.addupdateetsdata)
        .then((response) => {
          //console.log(response);
          if (response.data.status == "true") {
            Global.showSuccessAlert(true, "success", response.data.response);
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
      // }

      // });
    },
    async DeleteAPBUYER(data) {
      const result = await Global.showConfirmationAlert(
        `Delete AP Details ${data.PAYMENT_DESC}`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: data.RECORD_SYS_ID,
          MODULE: data.MODULE,
          PAYMENT_DESC: data.PAYMENT_DESC,
          CREATED_BY: user_details.UserId,
        };

        //console.log(postarrydata);
        this.axios
          .post(apiEndPoints.add_update_ap_payment_details, postarrydata)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.getBuyerAPdetails(data.RECORD_SYS_ID);
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            // this.isProductModalLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    getvendordetails() {
      this.axios
        .get(apiEndPoints.view_vendor, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            VENDOR_CODE: this.addupdatesellerdata.ID_NUMBER,
            PAGE_SIZE: 10,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.vendorname = true;
            this.vendoraddress = true;

            //console.log("riju", response);
            this.vendordetails = response.data.response[0];
            this.addupdatesellerdata.CUSTOMER_SYS_ID =
              response.data.response[0].VENDOR_SYS_ID;
          } else {
            this.vendordetails = "";
          }
        })
        .catch((err) => {
          //console.log(err);
          this.vendordetails = "";
        });
    },
    getcustomerdetails() {
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            ID_NUMBER: this.addupdatesellerdata.ID_NUMBER,
            PAGE_SIZE: 10,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.customername = true;
            this.customeraddress = true;
            //console.log("TEST", response);
            this.customerdetails = response.data.response[0];
            this.addupdatesellerdata.CUSTOMER_SYS_ID =
              response.data.response[0].ID_NUMBER;
          } else {
            this.customerdetails = "";
          }
        })
        .catch((err) => {
          //console.log(err);
          this.customerdetails = "";
        });
    },
    onchnagefun() {
      this.customerdetails = "";
      this.vendordetails = "";
      this.ID_NUMBER = "";
      this.vendorcode = "";
    },
    //for buyer type
    getvendordetails_() {
      this.axios
        .get(apiEndPoints.view_vendor, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            VENDOR_CODE: this.addupdatebuyerdata.SELL_CODE,
            PAGE_SIZE: 10,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            //console.log(response);
            this.vendordetails_ = response.data.response[0];
            this.addupdatebuyerdata.CUSTOMER_SYS_ID =
              response.data.response[0].VENDOR_SYS_ID;
          } else {
            this.vendordetails_ = "";
          }
        })
        .catch((err) => {
          //console.log(err);
          this.vendordetails_ = "";
        });
    },

    //delete for admin master modal
    async Delete(X) {
      const result = await Global.showConfirmationAlert(
        `Delete  ${X.MAKE_NAME}`,
        "Are you sure to delete",

        "warning"
      );
      if (result.isConfirmed) {
        let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
        let postarrydata = {
          ITEM: "DELETE",
          RECORD_SYS_ID: X.RECORD_SYS_ID,
        };

        //console.log(postarrydata);
        this.axios
          .post(apiEndPoints.add_vehicle_details, postarrydata)
          .then((response) => {
            //console.log(response);
            if (response.data.status == "true") {
              Global.showSuccessAlert(true, "success", response.data.response);
              this.vehicledetails();
            } else if (
              response.data.status == false ||
              response.data.status == "false"
            ) {
              Global.showErrorAlert(true, "error", response.data.response);
            }
          })
          .catch((err) => {
            // this.isProductModalLoaderActive = false;
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    getcustomerdetails_() {
      this.axios
        .get(apiEndPoints.view_customer, {
          params: {
            ITEM: "SPECIFIC",
            RECORD_SYS_ID: 0,
            ID_NUMBER: this.addupdatebuyerdata.SELL_CODE,
            PAGE_SIZE: 10,
            PAGE_NO: 1,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            //console.log("indiviaual", response);
            this.customerdetails_ = response.data.response[0];
            this.addupdatebuyerdata.CUSTOMER_SYS_ID =
              response.data.response[0].ID_NUMBER;
          } else {
            this.customerdetails_ = "";
          }
        })
        .catch((err) => {
          //console.log(err);
          this.customerdetails_ = "";
        });
    },
    onchnagefun_() {
      this.customerdetails_ = "";
      this.vendordetails_ = "";
      this.ID_NUMBER_ = "";
      this.vendorcode_ = "";
    },
    emptyfu() {
      $("#employee_modal").modal("hide");
      $("#employee_modal").modal("hide");
      $("#employee_moda").modall("hide");
      this.create_company.ADDRESS_LINE_1 = "";
      this.create_company.ADDRESS_LINE_2 = "";
      this.create_company.PERSON_INCHARGE = "";
      this.create_company.LAST_NAME = "";
      this.create_company.CUSTOMER_CONTACT = "";
      this.create_company.COUNTRY_NAME = "";
      this.create_company.PIN_CODE = "";
      this.create_company.ID_NUMBER = "";
      this.create_company.CUSTOMER_TYPE = "";
      this.create_company.COMPANY_NAME = "";
    },
    emptyfu2() {
      $("#vendor_modal").modall("hide");
      this.create_vendor.ADDRESS_LINE_1 = "";
      this.create_vendor.ADDRESS_LINE_2 = "";
      this.create_vendor.VENDOR_NAME = "";
      this.create_vendor.VENDOR_CODE = "";
      this.create_vendor.MOBILE_NO = "";
      this.create_vendor.POST_CODE = "";
      this.create_vendor.GENDER = "";
      this.create_vendor.EMAIL_ID = "";
      this.create_vendor.COUNTRY_SYS_ID =
        this.cost_type_info_details[0].COUNTRY_SYS_ID;
      this.getcountrysysid();
    },
    getcountrysysid() {
      this.create_vendor.COUNTRY_NAME = this.cost_type_info_details.find(
        (o) => o.COUNTRY_SYS_ID === this.create_vendor.COUNTRY_SYS_ID
      ).COUNTRY_NAME;
      //this.update_user.COUNTRY_NAME= this.cost_type_info_details.find(o=> o.COUNTRY_SYS_ID===this.update_user.COUNTRY_SYS_ID).COUNTRY_NAME;
    },
    getCountryInfo() {
      this.axios
        .get(apiEndPoints.view_country, {
          params: { ITEM: "VIEW_ALL", RECORD_SYS_ID: 0 },
        })
        .then((response) => {
          //console.log(response);
          //this.cost_type_info_details = response.data.response;
          if (response.data.status == "true") {
            this.cost_type_info_details = response.data.response;
            this.$nextTick(() => {
              this.create_vendor.COUNTRY_SYS_ID =
                this.cost_type_info_details[0].COUNTRY_SYS_ID;
              this.getcountrysysid();
            });
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.cost_type_info_details = [];
            this.create_vendor.COUNTRY_SYS_ID = null;
            Global.showErrorAlert(true, "error", response.data.response);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    Addvendor() {
      //console.log("yu");
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_vendor["CREATED_BY"] = user_details.UserId;
      //console.log(JSON.stringify(this.create_vendor));
      this.$refs.bankform2.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.create_update_vendor, this.create_vendor)
            .then((response) => {
              //console.log(response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getvendordetails();
                this.emptyfu2();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },

    Addcustomer() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_customer["CREATED_BY"] = user_details.UserId;
      //console.log(JSON.stringify(this.create_customer));
      this.$refs.cus.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_customer)
            .then((response) => {
              //console.log(response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getCustomer();
                this.emptyfu();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },

    Addcompany() {
      let user_details = JSON.parse(localStorage.getItem("lek_user_details"));
      this.create_company["CREATED_BY"] = user_details.UserId;
      this.create_company["CUSTOMER_TYPE"] = this.create_customer.CUSTOMER_TYPE;
      //console.log(JSON.stringify(this.create_company));
      this.$refs.bankfo.validate().then((success) => {
        if (!success) {
          Global.showErrorAlert(true, "error", "All fields Are Required!");
          return;
        } else {
          this.axios
            .post(apiEndPoints.create_update_customer, this.create_company)
            .then((response) => {
              //console.log(response);
              if (response.data.status == "true") {
                Global.showSuccessAlert(
                  true,
                  "success",
                  response.data.response
                );
                this.getCustomer();
                this.emptyfu();
              } else if (
                response.data.status == false ||
                response.data.status == "false"
              ) {
                Global.showErrorAlert(true, "error", response.data.response);
              }
            });
        }
      });
    },
  },
};
