<template>
  <div>
    <div class="floating-heading">
      <h4><b>Pending Handover Vehicle Report</b></h4>
    </div>
    <div class="col-md-12 mt-1 text-success">
      <div class="form-outline text-primary">
        <span class="inline">
          <div class="row mt-4">
            <div class="col-md-1 mt-2">
              <label class="form-label" for="form1" display="inline"
                >Start Date</label
              >
            </div>
            <div class="col-md-3">
              <input
                style="height: 3em"
                type="date"
                id="form1"
                class="form-control form-icon-trailing"
                placeholder="dd/mm/yyyy"
                v-model="BOOKING_START_DATE"
              />
            </div>
            <div class="col-md-1 mt-2">
              <label class="form-label" for="form1">End Date</label>
            </div>
            <div class="col-md-3">
              <input
                style="height: 3em"
                type="date"
                id="form1"
                class="form-control form-icon-trailing"
                placeholder="dd/mm/yyyy"
                v-model="BOOKING_END_DATE"
              />
            </div>
            <div class="col-md-2">
              <button
                type="submit"
                style="font-size: 0.9rem !important"
                class="btn btn-primary"
                @click="getPendingReportDetails"
              >
                Search
              </button>
            </div>
            <div class="col-md-2 p-0">
              <button
                style="margin-left: 5em"
                type="button"
                class="custom-btn custom-excel-btn"
                @click="exportExcel()"
              >
                <i class="fa-solid fa-file-excel"></i> Excel
              </button>
            </div>
          </div>
        </span>
      </div>
    </div>

    <div class="content-body-wrapper mt-3">
      <div class="page-content">
        <div class="page-data">
          <div class="data-table master-table">
            <table class="table table-bordered">
              <thead>
                <tr style="text-align: center">
                  <th>SERIAL NUMBER</th>
                  <th>BROKER</th>
                  <th>VEH. NO.</th>
                  <th>SOLD_DATE</th>
                  <th>E-OUT_DATE</th>
                  <th>SALESMAN</th>
                  <th>DEPOSIT</th>
                  <th>H/O_DATE</th>
                  <th>NO._OF_DAYS</th>
                  <th>REMARKS</th>
                </tr>
              </thead>
              <tbody
                v-for="system_role_detail in system_role_details"
                :key="system_role_detail.SYSTEM_ROLE_SYS_ID"
              >
                <tr style="text-align: center">
                  <td>{{ system_role_detail.SL_NO }}</td>
                  <td>{{ system_role_detail.BROKER_1 }}</td>
                  <td>{{ system_role_detail.VEHICLE_NUMBER }}</td>
                  <td>{{ system_role_detail.SOLD_DATE }}</td>
                  <td>{{ system_role_detail.E_TRANSFER_OUT_DATE }}</td>
                  <td>{{ system_role_detail.SALES_PERSON }}</td>
                  <td>{{ system_role_detail.COLOR_NAME }}</td>
                  <td>{{ system_role_detail.H_O_DATE }}</td>
                  <td>{{ system_role_detail.NO_OF_DAYS }}</td>
                  <td>{{ system_role_detail.REMARKS }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";

export default {
  data() {
    return {
      role_details: [],
      system_role_details: [],
      user_details: [],

      USER_SYS_ID: "",
      BOOKING_START_DATE: "",
      BOOKING_END_DATE: "",
    };
  },
  mounted() {},
  methods: {
    getPendingReportDetails() {
      this.BOOKING_START_DATE = this.formatDate(this.BOOKING_START_DATE);
      this.BOOKING_END_DATE = this.formatDate(this.BOOKING_END_DATE);
      this.axios
        .get(apiEndPoints.view_pendingreport, {
          params: {
            ITEM: "SPECIFIC",
            BOOKING_START_DATE: this.BOOKING_START_DATE,
            BOOKING_END_DATE: this.BOOKING_END_DATE,
          },
        })
        .then((response) => {
          if (response.data.status == "true") {
            this.system_role_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.system_role_details = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    exportExcel() {
      const tHeader = [
        "SERIAL_NUMBER",
        "BROKER",
        "VEH. NO.",
        "SOLD_DATE",
        "E-OUT_DATE",
        "SALESMAN",
        "DEPOSIT",
        "H/O_DATE",
        "NO._OF_DAYS",
        "REMARKS",
      ];

      const filterVal = [
        "SL_NO",
        "BROKER_1",
        "VEHICLE_NUMBER",
        "SOLD_DATE",
        "E_TRANSFER_OUT_DATE",
        "SALES_PERSON",
        "COLOR_NAME",
        "H_O_DATE",
        "NO_OF_DAYS",
        "REMARKS",
      ];

      let system_role_groups = [];
      this.system_role_groups.forEach((role) => {
        this.getGroupDetails(role).forEach((item, index) => {
          if (index > 0) {
            item.BODY_TYPE = "";
          }
          system_role_groups.push(item);
        });
      });
      const data = this.formatJson(filterVal, system_role_groups);
      this.generateXlsx(tHeader, data, "Lek-Pending-Hangover-Report");
    },
    getGroupDetails(group) {
      return this.system_role_details.filter((role) => role.BODY_TYPE == group);
    },
    generateRandomColor() {
      let maxVal = 0xffffff;
      let randomNumber = Math.random() * maxVal;
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`;
    },
    getGroupStyles() {
      let color = this.generateRandomColor();
      return {
        "background-color": color,
        padding: "5px 6px !important",
        "border-width": "3px",
        "border-color": "#fff",
      };
    },
    getGroupStyles() {
      let color = this.generateRandomColor();
      return {
        "background-color": color,
        padding: "5px 6px !important",
        "border-width": "3px",
        "border-color": "#fff",
      };
    },
  },
  computed: {
    menuPermissions() {
      let menu = JSON.parse(localStorage.getItem("activeMenu"));
      return menu.ACCESS;
    },
    system_role_groups() {
      const gr = [];
      this.system_role_details.forEach((g) => {
        if (!gr.includes(g.BODY_TYPE)) gr.push(g.BODY_TYPE);
      });
      return gr;
    },
  },
};
</script>
