<template>
  <div>
    <div>
      <h4><b>Menu Management</b></h4>
    </div>
    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="row">
          <div class="col-md-4">
            <div
              class="card p-3"
              style="
                border: none;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
              "
            >
              <div class="card-body p-0">
                <strong class="card-title">Add Menu Items</strong>

                <div
                  class="form-check p-0 mt-3"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <label class="form-check-label" for="defaultCheck1"
                    ><strong>Select All</strong></label
                  >
                  <b-form-checkbox
                    id="defaultCheck1"
                    name="defaultCheck1"
                    :value="true"
                    :unchecked-value="false"
                    @change="onSelectAllMenuChange"
                  ></b-form-checkbox>
                </div>

                <div
                  class="form-check p-0"
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                  v-for="menu in menuItems"
                  :key="menu.ID"
                >
                  <label class="form-check-label" for="defaultCheck1">{{
                    menu.MODULE
                  }}</label>
                  <b-form-checkbox
                    id="defaultCheck1"
                    v-model="menu.checked"
                    name="defaultCheck1"
                    :value="true"
                    :unchecked-value="false"
                  ></b-form-checkbox>
                </div>

                <button
                  type="button"
                  class="custom-btn custom-success-btn mt-3"
                  @click="onMenuSelected"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-8 pt-3">
            <strong class="card-title">Menu Structure</strong>
            <p>Select catagories, pages or add custom links to menus.</p>

            <div class="accordion mt-3" id="accordionExample">
              <div v-for="menu in selectedMenuItems" :key="menu.ID">
                <button
                  class="accordion-button mb-3"
                  style="display: flex; justify-content: space-between"
                  v-if="!menu.SUBMODULE"
                >
                  {{ menu.MODULE }}
                  <a href="javascript:void(0);"
                    ><i class="fa fa-trash" aria-hidden="true"></i
                  ></a>
                </button>
                <div class="accordion-item" v-else>
                  <h2 class="accordion-header" :id="`heading${menu.ID}`">
                    <button
                      class="accordion-button"
                      style="display: flex; justify-content: space-between"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="`#collapse${menu.ID}`"
                      aria-expanded="true"
                      :aria-controls="`collapse${menu.ID}`"
                    >
                      {{ menu.MODULE }}
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                  </h2>
                  <div
                    :id="`collapse${menu.ID}`"
                    class="accordion-collapse collapse show"
                    :aria-labelledby="`heading${menu.ID}`"
                    data-bs-parent="#accordionExample"
                  >
                    <div class="accordion-body dragmenu">
                      <button
                        class="accordion-button item"
                        style="display: flex; justify-content: space-between"
                        draggable="true"
                        v-for="submenu in menu.SUBMODULE"
                        :key="submenu.NAME"
                      >
                        {{ submenu.NAME }}
                        <a href="javascript:void(0);"
                          ><i class="fa fa-trash" aria-hidden="true"></i
                        ></a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!---
            <div class="accordion mt-3" id="accordionExample">
              <button
                class="accordion-button mb-3"
                style="display: flex; justify-content: space-between"
              >
                1. Dashboard
                <a href="javascript:void(0);"
                  ><i class="fa fa-trash" aria-hidden="true"></i
                ></a>
              </button>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    style="display: flex; justify-content: space-between"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    2. Admin Master
                    <a href="javascript:void(0);"
                      ><i class="fa fa-trash" aria-hidden="true"></i
                    ></a>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body dragmenu">
                    <button
                      class="accordion-button item"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      2.1 Add Vehicle Master
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      2.2 View Vehicle Master
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                  </div>
                </div>
              </div>

              <button
                class="accordion-button mt-3 mb-3"
                style="display: flex; justify-content: space-between"
              >
                3. Master Data Setup
                <a href="javascript:void(0);"
                  ><i class="fa fa-trash" aria-hidden="true"></i
                ></a>
              </button>

              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    style="display: flex; justify-content: space-between"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    4. Access Management
                    <a href="javascript:void(0);"
                      ><i class="fa fa-trash" aria-hidden="true"></i
                    ></a>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body dragmenu">
                    <button
                      class="accordion-button item"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      4.1 System Role
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      4.2 Module
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      4.3 Sub Module
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      4.4 Privilage
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      4.5 Menu Management
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                  </div>
                </div>
              </div>

              <div class="accordion-item mt-3">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    style="display: flex; justify-content: space-between"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    5. User Management
                    <a href="javascript:void(0);"
                      ><i class="fa fa-trash" aria-hidden="true"></i
                    ></a>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body dragmenu">
                    <button
                      class="accordion-button item"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      5.1 Broker
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      5.2 Customer
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      5.3 Employee
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      5.4 Vendor List
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                  </div>
                </div>
              </div>

              <div class="accordion-item mt-3">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    style="display: flex; justify-content: space-between"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    6. Price List
                    <a href="javascript:void(0);"
                      ><i class="fa fa-trash" aria-hidden="true"></i
                    ></a>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body dragmenu">
                    <button
                      class="accordion-button item"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      6.1 Price List
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      6.2 ETS Price List
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      6.3 Asking Price
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                  </div>
                </div>
              </div>

              <div class="accordion-item mt-3">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    style="display: flex; justify-content: space-between"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    7. Insurance
                    <a href="javascript:void(0);"
                      ><i class="fa fa-trash" aria-hidden="true"></i
                    ></a>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body dragmenu">
                    <button
                      class="accordion-button item"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      7.1 View Insurance
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      7.2 Add Insurance
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      7.3 View Quotation
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                    <button
                      class="accordion-button item mt-2"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      7.4 Add Quotation
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                  </div>
                </div>
              </div>

              <div class="accordion-item mt-3">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    style="display: flex; justify-content: space-between"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    8. Commission
                    <a href="javascript:void(0);"
                      ><i class="fa fa-trash" aria-hidden="true"></i
                    ></a>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body dragmenu">
                   
                    <button
                      class="accordion-button item"
                      style="display: flex; justify-content: space-between"
                      draggable="true"
                    >
                      8.1 Vehicle Commission
                      <a href="javascript:void(0);"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            -->

            <button
              type="button"
              class="custom-btn custom-success-btn mt-3"
              v-show="selectedMenuItems.length > 0"
              @click="onMenuSave"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiEndPoints from "../../endpoints.js";

import { Global } from "@/global.js";

export default {
  data() {
    return {
      menuItems: [],
      selectedMenuItems: [],
    };
  },

  mounted() {
    this.getMenuItems();
    function toggle(source) {
      checkboxes = document.getElementsByName("foo");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = source.checked;
      }
    }

    function handleDragStart(e) {
      this.style.opacity = "0.4";

      dragSrcEl = this;

      e.dataTransfer.effectAllowed = "move";
      //e.dataTransfer.setData("text/html", this.innerHTML);
    }

    function handleDragEnd(e) {
      this.style.opacity = "1";
    }

    function handleDragEnd(e) {
      this.style.opacity = "1";

      items.forEach(function (item) {
        item.classList.remove("over");
      });
      dragSrcEl = undefined;
    }

    function handleDragOver(e) {
      if (e.preventDefault) {
        e.preventDefault();
      }

      return false;
    }

    function handleDragEnter(e) {
      this.classList.add("over");
    }

    function handleDragLeave(e) {
      this.classList.remove("over");
    }

    function handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (dragSrcEl !== this) {
        if (dropAction === "swap") {
          swapItems(dragSrcEl, this);
        } else {
          shiftItems(dragSrcEl, this);
        }
        dragSrcEl = undefined;
      }
      return false;
    }

    function swapItems(a, b) {
      const tmp = a.innerHTML;
      a.innerHTML = b.innerHTML;
      b.innerHTML = tmp;
    }

    function shiftItems(srcElem, destElem) {
      const items = Array.from(document.querySelectorAll(".dragmenu .item"));
      const srcIdx = items.indexOf(srcElem);
      const destIdx = items.indexOf(destElem);
      //console.log(`srcIdx = ${srcIdx}, destIdx= ${destIdx}`);
      if (srcIdx < destIdx) {
        // moving down
        for (let i = srcIdx; i < destIdx - 1; i++) {
          //console.log(`swapping ${i} and ${i + 1}`);
          swapItems(items[i], items[i + 1]);
        }
      } else {
        // moving up
        for (let i = srcIdx - 1; i >= destIdx; i--) {
          //console.log(`swapping ${i} and ${i + 1}`);
          swapItems(items[i], items[i + 1]);
        }
      }
    }

    let dragSrcEl;
    let dropAction = "shift";
    const items = document.querySelectorAll(".dragmenu .item");
    items.forEach(function (item) {
      item.addEventListener("dragstart", handleDragStart);
      item.addEventListener("dragover", handleDragOver);
      item.addEventListener("dragenter", handleDragEnter);
      item.addEventListener("dragleave", handleDragLeave);
      item.addEventListener("dragend", handleDragEnd);
      item.addEventListener("drop", handleDrop);
    });

    document.querySelector("#dropaction").addEventListener("change", (e) => {
      dropAction = e.target.value;
    });
  },

  methods: {
    onSelectAllMenuChange(isChecked) {
      if (isChecked) {
        this.menuItems.map((item) => (item.checked = true));
      } else {
        this.menuItems.map((item) => (item.checked = false));
      }
    },

    onMenuSelected() {
      this.selectedMenuItems = [];
      this.menuItems.forEach((item) => {
        if (item.checked) {
          this.selectedMenuItems.push(item);
        }
      });
    },

    onMenuSave() {
      //console.log(this.selectedMenuItems, "selected menu items");
      let user = JSON.parse(localStorage.getItem("lek_user_details"));
      let collection = [];
      this.selectedMenuItems.forEach((item, index) => {
        if (item.SUBMODULE) {
          item.SUBMODULE.forEach((sub, subIndex) => {
            collection.push({
              ITEM: "ADD_UPDATE",
              MODULE_SYS_ID: item.ID,
              MENU_SEQ_NO: index + 1,
              SUB_MENU_SEQ_NO: subIndex + 1,
              SUB_MODULE_SYS_ID: sub.ID,
            });
          });
        } else {
          collection.push({
            ITEM: "ADD_UPDATE",
            MODULE_SYS_ID: item.ID,
            MENU_SEQ_NO: index + 1,
          });
        }
      });
      //console.log(collection, "collection");
      return false;
      this.axios
        .post(apiEndPoints.add_update_menu_structure, collection)
        .then((response) => {
          //console.log(response, "previllge");
          // on get call we need module->submodule->previleges data with default values 'N'
          // {"ITEM":"ADD","RECORD_SYS_ID":"0","ROLE_SYS_ID":"4","SUB_MODULE_SYS_ID":"3","CREATE_ACCESS":"Y","VIEW_ACCESS":"Y","UPDATE_ACCESS":"N","DELETE_ACCESS":"N","FULL_ACCESS":"N","CREATED_BY":"1"}
        })
        .catch((err) => {
          //console.log(err);
        });
    },

    getMenuItems() {
      let menuItems = JSON.parse(localStorage.getItem("my_menu"));
      this.menuItems = menuItems.map((item) => {
        item.checked = false;
        return item;
      });
      //console.log(this.menuItems, "original menu");
    },
  },
};
</script>
