import { extend } from "vee-validate";
import { required,length,email,confirmed } from "vee-validate/dist/rules";

// #region Required Rule
extend('required', {
  ...required,
  message: 'This {_field_} field is required'
});
// #endregion

// #region Email Rule
extend('email', {
  ...email,
  message: 'Provide valid email'
});
// #endregion

// #region Length Rule
extend('length', {
  ...length,
  message: 'This {_field_} field must be of 8 digits'
});
// #endregion

// #region Mobile Number Rule
extend('mobileNumber', {
  validate: value => {
    return /^[6-9]\d{7}$/.test(value);
  },
  message: 'This {_field_} must start with 6/7/8/9'
});
// #endregion


// #region series Required Rule
extend('seriesRequired', {
  ...required,
  message: 'Series field is required'
});
// #endregion

// #region brand Required Rule
extend('brandRequired', {
  ...required,
  message: 'Brand field is required'
});
// #endregion

// #region confirm password Rule
extend('confirmed', {
  ...confirmed,
  message: 'New Password & Confirm Password does not match'
});
// #endregion

// #region Length Rule
extend('lengthy', {
  ...length,
  message: 'This {_field_} field must be of 4 digits'
});
// #endregion


