import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from "vue-cookies";
import tokenInterceptor from "./token-interceptor";
import Corejs from "core-js";
import '../node_modules/nprogress/nprogress.css'

Vue.use(Corejs);
Vue.use(VueAxios, axios, VueCookies)
Vue.prototype.$api = axios;

Vue.config.productionTip = false;
Vue.use(tokenInterceptor);
//#region - Sweet Alert
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
// #endregion
//#region - Bootstarp Vue
import { BootstrapVue  } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);
//#endregion
//* Vee-Validate setting
import { ValidationProvider,ValidationObserver} from 'vee-validate';
import './validationRules';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
//#endregion

//adding global mixin
import globalMixin from './mixins/global';
Vue.mixin(globalMixin);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
