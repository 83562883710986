<template>
  <div>
    <div class="floating-heading">
      <h4><b>E Transfer in Report</b></h4>
    </div>
    <div class="row mt-3">
      <div class="col-md-10 mt-1 text-success">
        <div class="form-outline text-primary">
          <span class="inline">
            <div class="row">
              <div class="col-md-3">
                <label class="form-label" for="form1" display="inline"
                  >Purchase Start Date</label
                >
                <input
                  style="height: 3em;"
                  type="date"
                  placeholder="dd/mmy/yyyy"
                  id="form1"
                  class="form-control form-icon-trailing"
                  v-model="PURCHASE_START_DATE"
                />
              </div>
              <div class="col-md-3">
                <label class="form-label" for="form1">Purchase End Date</label>
                <input
                  style="height: 3em;"
                  type="date"
                  placeholder="dd/mmy/yyyy"
                  id="form1"
                  class="form-control form-icon-trailing"
                  v-model="PURCHASE_END_DATE"
                />
              </div>
              <div class="col-md-3">
                <label class="form-label" for="form1" display="inline"
                  >E-Transfer Start Date</label
                >
                <input
                  style="height: 3em;"
                  type="date"
                  placeholder="dd/mmy/yyyy"
                  id="form1"
                  class="form-control form-icon-trailing"
                  v-model="E_TRANSFER_IN_START_DATE"
                />
              </div>
              <div class="col-md-3">
                <label class="form-label" for="form1"
                  >E-Transfer End Date</label
                >
                <input
                  style="height: 3em;"
                  type="date"
                  id="form1"
                  placeholder="dd/mmy/yyyy"
                  class="form-control form-icon-trailing"
                  v-model="E_TRANSFER_IN_END_DATE"
                />
              </div>
            </div>
          </span>
        </div>
      </div>
      <div class="col-md-2 mt-1 text-success">
        <button
          type="submit"
          style="margin-top: 27px;font-size: 0.9rem !important;"
          class="btn btn-primary"
          @click="getPriceDetails"
        >
          Search
        </button>
        <button
          type="submit"
          style="margin-top: 27px;font-size: 0.9rem !important;margin-left:3px;"
          class="btn custom-excel-btn"
          @click="exportExcel"
        >
          <i class="fa-solid fa-file-excel"></i>Excel
        </button>
      </div>
    </div>

    <div class="content-body-wrapper mt-4">
      <div class="page-content">
        <div class="page-data">
          <div class="data-table master-table">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Serial Number</th>
                  <th>Purchase Agreement Number</th>
                  <th>Purchase Date</th>
                  <th>Car Plate</th>
                  <th>Make and Model</th>
                  <th>E-Transfer In Date</th>
                  <th>Broker</th>
                  <th>Purchase Amount</th>
                </tr>
              </thead>
              <tbody
                v-for="user_detail in system_role_details"
                :key="user_detail.RECORD_SYS_ID"
              >
                <tr style="text-align: center">
                  <td>{{ user_detail.SL_NO }}</td>
                  <td>{{ user_detail.PURCHASE_AGREEMENT_NUMBER }}</td>
                  <td>{{ user_detail.PURCHASE_DATE }}</td>
                  <td>{{ user_detail.VEHICLE_NUMBER }}</td>
                  <td>
                    {{ user_detail.MAKE_NAME }}-{{ user_detail.MODEL_NAME }}
                  </td>
                  <td>{{ user_detail.E_TRANSFER_IN_DATE }}</td>
                  <td>{{ user_detail.BROKER_1 }}</td>
                  <td>{{ user_detail.TOTAL_PURCHASE_PRICE }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br /><br /><br /><br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import apiEndPoints from "../../endpoints.js";
import { Global } from "@/global.js";
export default {
  data() {
    return {
      system_role_details: [],
      PURCHASE_START_DATE: "",
      PURCHASE_END_DATE: "",
      E_TRANSFER_IN_START_DATE: "",
      E_TRANSFER_IN_END_DATE: "",
    };
  },
  computed: {
    system_role_groups() {
      const gr = [];
      this.system_role_details.forEach((g) => {
        if (!gr.includes(g.BODY_TYPE)) gr.push(g.BODY_TYPE);
      });
      return gr;
    },
  },

  methods: {
    getGroupDetails(group) {
      return this.system_role_details.filter((role) => role.BODY_TYPE == group);
    },
    generateRandomColor() {
      let maxVal = 0xffffff;
      let randomNumber = Math.random() * maxVal;
      randomNumber = Math.floor(randomNumber);
      randomNumber = randomNumber.toString(16);
      let randColor = randomNumber.padStart(6, 0);
      return `#${randColor.toUpperCase()}`;
    },
    getGroupStyles() {
      let color = this.generateRandomColor();
      return {
        "background-color": color,
        padding: "5px 6px !important",
        "border-width": "3px",
        "border-color": "#fff",
      };
    },

    getPriceDetails() {
      let params = {
        ITEM: "SPECIFIC",
        PURCHASE_START_DATE: this.PURCHASE_START_DATE
          ? this.formatDate(this.PURCHASE_START_DATE)
          : "",
        PURCHASE_END_DATE: this.PURCHASE_END_DATE
          ? this.formatDate(this.PURCHASE_END_DATE)
          : "",
        E_TRANSFER_IN_START_DATE: this.E_TRANSFER_IN_START_DATE
          ? this.formatDate(this.E_TRANSFER_IN_START_DATE)
          : "",
        E_TRANSFER_IN_END_DATE: this.E_TRANSFER_IN_END_DATE
          ? this.formatDate(this.E_TRANSFER_IN_END_DATE)
          : "",
      };

      this.axios
        .get(apiEndPoints.e_trasnfer_in, { params: params })
        .then((response) => {
          this.PURCHASE_START_DATE = this.parseDate(this.PURCHASE_START_DATE);
          this.PURCHASE_END_DATE = this.parseDate(this.PURCHASE_END_DATE);
          this.E_TRANSFER_IN_START_DATE = this.parseDate(
            this.E_TRANSFER_IN_START_DATE
          );
          this.E_TRANSFER_IN_END_DATE = this.parseDate(
            this.E_TRANSFER_IN_END_DATE
          );
          if (response.data.status == "true") {
            this.system_role_details = response.data.response;
          } else if (
            response.data.status == false ||
            response.data.status == "false"
          ) {
            this.system_role_details = [];
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    exportExcel() {
      const tHeader = [
        "SL No.",
        "PURCHASE_AGREEMENT_NUMBER",
        "Purchase Date",
        "Car Plate",
        "Make and Model",
        "E-Transfer In Date",
        "Broker",
        "Purchase Amount",
      ];

      const filterVal = [
        "SL_NO",
        "PURCHASE_AGREEMENT_NUMBER",
        "PURCHASE_DATE",
        "VEHICLE_NUMBER",
        "MAKE_NAME",
        "E_TRANSFER_IN_DATE",
        "BROKER_1",
        "TOTAL_PURCHASE_PRICE",
      ];
      let system_role_groups = [];
      this.system_role_groups.forEach((role) => {
        this.getGroupDetails(role).forEach((item, index) => {
          if (index > 0) {
            item.BODY_TYPE = "";
          }
          system_role_groups.push(item);
        });
      });
      const data = this.formatJson(filterVal, system_role_groups);
      this.generateXlsx(tHeader, data, "etransferin");
    },
  },
  // computed:{
  //   menuPermissions() {
  //   let menu = JSON.parse(localsStorage.getItem('activeMenu'));
  //   return menu.ACCESS;
  // },
  // }
};
</script>
